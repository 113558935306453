import { IDepositsService } from "@api/contracts/odata/IDepositsService";
import { IDeposit } from "@api/models/market/IDeposit";
import { IPackage } from "@api/models/market/IPackage";
import { IPigeon } from "@api/models/market/IPigeon";
import { ISale } from "@api/models/market/ISale";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, depositStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, FilterDate, odataQuery } from "odata-fluent-query";

export class DepositsService extends BaseODataService implements IDepositsService {
  constructor() {
    super();
  }

  public Insert(deposit: IDeposit): AxiosPromise<IDeposit> {
    return this.Client().post("Deposits", deposit);
  }

  public Update(depositKey: string, deposit: IDeposit): AxiosPromise<void> {
    return this.Client().put(`Deposits('${depositKey}')`, deposit);
  }

  public Patch(depositKey: string, deposit: Partial<IDeposit>): AxiosPromise<void> {
    return this.Client().patch(`Deposits('${depositKey}')`, deposit);
  }

  public PatchAsProcessed(depositKey: string, processed = true): AxiosPromise<void> {
    depositStore.RemovePendingDeposits(depositKey);
    return this.Client().patch(`Deposits('${depositKey}')`, { flagProcessed: processed } as Partial<IDeposit>);
  }

  public Delete(depositKey: string): AxiosPromise<void> {
    return this.Client().delete(`Deposits('${depositKey}')`);
  }

  public DeleteSoft(depositKey: string): AxiosPromise<void> {
    return this.Client().patch(`Deposits('${depositKey}')`, { flagSoftDeleted: true } as Partial<IDeposit>);
  }

  public Fetch(depositKey: string): AxiosPromise<IDeposit> {
    let query = odataQuery<IDeposit>();

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));

    return this.Client().get(`Deposits('${depositKey}')?${query.toString()}`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IDeposit>> {
    let query = odataQuery<IDeposit>().orderBy("paymentDate", "desc");

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));

    return this.Client().get(`Deposits?${query.toString()}`);
  }

  public FetchAllDeposits(fromDate?: Date, toDate?: Date): AxiosPromise<IODataCollectionResponse<IDeposit>> {
    let query = odataQuery<IDeposit>().orderBy("paymentDate", "desc");

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));
    if (fromDate)
      query = query.filter((d) => (d.paymentDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((d) => (d.paymentDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`Deposits?${query.toString()}`);
  }

  public FetchAllPendingProcessing(): AxiosPromise<IODataCollectionResponse<IDeposit>> {
    let query = odataQuery<IDeposit>()
      .filter((s) => s.flagProcessed.isNull())
      .orderBy("paymentDate", "desc");

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));

    return this.Client().get(`Deposits?${query.toString()}`);
  }

  public FetchAllWithSaleProduct(): AxiosPromise<IODataCollectionResponse<IDeposit>> {
    let query = odataQuery<IDeposit>();

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));

    query = query.orderBy("paymentDate", "desc").expand("sale", (s: ExpandObjectQuery<ISale>) =>
      s
        .select("id", "type", "product", "price", "feesRate", "vatRate", "vatInclusive")
        .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p.select("name", "ring"))
        .expand("package", (p: ExpandObjectQuery<IPackage>) => p.select("name", "discipline"))
    );

    return this.Client().get(`Deposits?${query.toString()}`);
  }

  public FetchAllDepositsByVendor(
    vendorKey: string,
    from?: Date,
    to?: Date
  ): AxiosPromise<IODataCollectionResponse<IDeposit>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<IDeposit>();

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));
    if (from) query = query.filter((s) => (s.paymentDate as any as FilterDate).isAfterOrEqual(from));
    if (to) query = query.filter((s) => (s.paymentDate as any as FilterDate).isBeforeOrEqual(to));

    query = query.filter((d) => d.vendorId.equals(vendorKey, { ignoreGuid: true })).orderBy("paymentDate", "desc");

    return this.Client().get(`Deposits?${query.toString()}`);
  }

  public FetchAllByVendorWithSaleProduct(
    vendorKey: string,
    from?: Date,
    to?: Date
  ): AxiosPromise<IODataCollectionResponse<IDeposit>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<IDeposit>();

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));
    if (from) query = query.filter((s) => (s.paymentDate as any as FilterDate).isAfterOrEqual(from));
    if (to) query = query.filter((s) => (s.paymentDate as any as FilterDate).isBeforeOrEqual(to));

    query = query
      .filter((d) => d.vendorId.equals(vendorKey, { ignoreGuid: true }))
      .orderBy("paymentDate", "desc")
      .expand("sale", (s: ExpandObjectQuery<ISale>) =>
        s
          .select("id", "type", "product", "price", "feesRate", "vatRate", "vatInclusive")
          .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p.select("name", "ring"))
          .expand("package", (p: ExpandObjectQuery<IPackage>) => p.select("name", "discipline"))
      );

    return this.Client().get(`Deposits?${query.toString()}`);
  }

  public FetchAllSummaryWithUserBySale(saleKey: number): AxiosPromise<IODataCollectionResponse<IDeposit>> {
    const query = odataQuery<IDeposit>()
      .filter((b) => b.saleId.equals(saleKey))
      .select("id", "amount", "rateOfTotal", "paymentDate", "userId")
      .orderBy("paymentDate", "desc")
      .expand("user", (u) => u.select("id", "firstname", "lastname", "buyerAlias", "email", "phoneNumber"));

    return this.Client().get(`Deposits?${query.toString()}`);
  }
}
