import { IRingsService } from "@api/contracts/odata/IRingsService";
import { AppThemes } from "@api/models/market/constants/AppThemes";
import { SexTypes } from "@api/models/market/constants/SexTypes";
import { IPigeon } from "@api/models/market/IPigeon";
import { IRing } from "@api/models/market/IRing";
import { ISale } from "@api/models/market/ISale";
import { AlertTypes } from "@pigeon/components/app/AppAlert";
import ProductDiscipline from "@pigeon/components/product/ProductDiscipline.vue";
import ProductCountryFlag from "@pigeon/components/product/ProductCountryFlag.vue";
import { PigeonAssetModes } from "@pigeon/enumerations/PigeonAssetModes";
import { ProductDisplayModes } from "@pigeon/enumerations/ProductDisplayModes";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import ProductCharacteristicsView from "@pigeon/views/product/ProductCharacteristics.vue";
import { Inject } from "inversify-props";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AssetTypes } from "@api/models/market/constants/AssetTypes";

@Component({
  components: {
    ProductDiscipline,
    ProductCountryFlag,
    ProductCharacteristics: ProductCharacteristicsView
  }
})
export default class PigeonSheet extends Vue {
  @Prop()
  readonly pigeon: IPigeon;
  @Prop()
  readonly sale?: ISale;
  @Prop({
    default: ProductDisplayModes.Full,
    validator: (value: ProductDisplayModes) =>
      [ProductDisplayModes.Full, ProductDisplayModes.Compact, ProductDisplayModes.Minimum].includes(value)
  })
  readonly mode: string;
  @Prop({ default: AppThemes.Dark, validator: (value: AppThemes) => Object.values(AppThemes).includes(value) })
  readonly theme: AppThemes;

  @Inject()
  private ringsService: IRingsService;
  @Inject()
  private pigeonManager: IPigeonManager;

  AssetModes = PigeonAssetModes;
  DisplayModes = ProductDisplayModes;
  SexTypes = SexTypes;
  AlertTypes = AlertTypes;
  ringAreas: IRing[] = [];
  showVoucherMessage: boolean = false;

  get CountryName(): string {
    const country = this.ringAreas.find((c) => c.areaCode === this.pigeon.ringArea);

    return country ? country.areaLabel : "";
  }

  get PigeonIcon(): string {
    return this.pigeonManager.GetSexIcon(this.pigeon);
  }

  get PigeonSexLabel(): string {
    return this.pigeonManager.GetSexLabel(this.pigeon);
  }

  get PigeonAgeCategory(): string {
    if (!this.pigeon.birthYear) return "";

    return this.pigeonManager.GetAgeCategory(this.pigeon);
  }

  get PigeonFancierName(): string {
    if (!this.pigeon || !this.pigeon.fancier) return "";

    return this.pigeon.fancier.name;
  }

  get PigeonHasCharacteristics(): boolean {
    if (!this.pigeon) return false;

    return this.pigeonManager.HasCharacteristics(this.pigeon);
  }

  get PigeonHasAsset(): boolean {
    if (!this.pigeon) return false;
    if (this.pigeon.asset == AssetTypes.Text && !this.pigeon.assetText) return false;

    return this.pigeonManager.HasAssetType(this.pigeon);
  }

  get ShouldCharacteristicsAccordionBeOpened(): boolean {
    return true;
  }

  get ShouldAssetsAccordionBeOpened(): boolean {
    return !(
      this.PigeonHasCharacteristics &&
      (this.pigeon.asset != AssetTypes.Text || (this.pigeon.assetText?.length ?? 0) > 40)
    );
  }

  async mounted() {
    const { data } = await this.ringsService.FetchAll();
    this.ringAreas = data.value;
  }

  //#region alerts
  public ToggleVoucherMessage(): void {
    this.showVoucherMessage = !this.showVoucherMessage;
  }
  //#endregion

  public NavigateToBreederTab() {
    if (!this.$route.name) return;

    if (this.$route.name.match(/sale./gi)) {
      this.$router.push({ name: "sale.breeder", params: this.$route.params });
      this.$scrollTo("#breeder", 300, { offset: -100 });
    }
  }
}
