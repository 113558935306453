import { ITaskTrackingsService } from "@api/contracts/odata/ITaskTrackingsService";
import { ITaskTracking } from "@api/models/market/ITaskTracking";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import odataQuery from "odata-fluent-query";

export class TaskTrackingsService extends BaseODataService implements ITaskTrackingsService {
  constructor() {
    super();
  }

  public Insert(taskTracking: ITaskTracking): AxiosPromise<ITaskTracking> {
    return this.Client().post("TaskTrackings", taskTracking);
  }

  public Update(taskTrackingKey: number, taskTracking: ITaskTracking): AxiosPromise<void> {
    return this.Client().put(`TaskTrackings(${taskTrackingKey})`, taskTracking);
  }

  public Delete(taskTrackingKey: number): AxiosPromise<void> {
    return this.Client().delete(`TaskTrackings(${taskTrackingKey})`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<ITaskTracking>> {
    const query = odataQuery<ITaskTracking>().orderBy("doneAt", "desc");

    return this.Client().get(`TaskTrackings?${query.toString()}`);
  }

  public FetchAllBySaleAndByVendor(
    saleKey: number,
    vendorKey: string
  ): AxiosPromise<IODataCollectionResponse<ITaskTracking>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<ITaskTracking>()
      .filter((tt) => tt.saleId.equals(saleKey))
      .filter((tt) => tt.userId.equals(vendorKey, { ignoreGuid: true }))
      .orderBy("doneAt", "desc");

    return this.Client().get(`TaskTrackings?${query.toString()}`);
  }

  public Fetch(taskTrackingKey: number): AxiosPromise<ITaskTracking> {
    return this.Client().get(`TaskTrackings(${taskTrackingKey})`);
  }
}
