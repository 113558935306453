import { store } from "@market/stores";
import { IAppsStore } from "@market/stores/contracts/IAppsStore";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "apps" = "apps";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class AppsStore extends VuexModule implements IAppsStore {
  launcherIsOpen: boolean = false;

  @Mutation
  APPS_LAUNCHER(openState: boolean): void {
    this.launcherIsOpen = openState;
  }

  @Action
  ToggleAppsLauncher(): void {
    this.APPS_LAUNCHER(!this.launcherIsOpen);
  }

  @Action
  CloseAppsLauncher(): void {
    if (!this.launcherIsOpen) return;

    this.APPS_LAUNCHER(false);
  }

  @Action
  OpenAppsLauncher(): void {
    if (this.launcherIsOpen) return;

    this.APPS_LAUNCHER(true);
  }
}
