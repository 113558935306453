import { IPigeon } from "@api/models/market/IPigeon";
import { IRing } from "@api/models/market/IRing";
import { ArrayHelper } from "@api/services/helpers/ArrayHelper";
import dayjs from "@pigeon/i18n/dayjs";
import { IListOption } from "@pigeon/models/IListOption";
import { IRingManager } from "@pigeon/services/contracts/IRingManager";
import { groupBy } from "lodash-es";
import cloneDeep from "lodash-es/cloneDeep";

export class RingManager implements IRingManager {
  public static readonly ringAreasHardcoded: IRing[] = []; //TODO
  static readonly SEPTEMBER: number = 8;

  get RingAreasHardcoded() {
    return RingManager.ringAreasHardcoded;
  }

  get CurrentYear(): number {
    return dayjs().year();
  }

  public GetMaxBirthYear(): number {
    // Business Rule: Max year should be current year except in december it s the next year because fancier can order new rings for new young pigeon on december
    return dayjs().month() >= RingManager.SEPTEMBER ? this.CurrentYear + 1 : this.CurrentYear;
  }

  public HasRingInput(pigeon: IPigeon): boolean {
    return pigeon.ringArea &&
      pigeon.birthYear &&
      pigeon.birthYear > 1000 &&
      pigeon.ringNumber &&
      pigeon.ringNumber.length >= 3
      ? true
      : false;
  }

  public HasRingClub(pigeon: IPigeon): boolean {
    return this.IsRingAreaHasRingClub(pigeon.ringArea);
  }

  public IsRingAreaHasRingClub(ringArea?: string): boolean {
    switch (ringArea) {
      // Germany
      case "DV":
        return true;

      default:
        return false;
    }
  }

  private FormatRingBirthyearOnTwoDigits(pigeon: IPigeon): string {
    return (pigeon.birthYear as number).toString().substr(-2);
  }

  private RawFormatRingBirthyearOnTwoDigits(birthyear: number | null): string {
    if (!birthyear) return "";

    return birthyear.toString().substr(-2);
  }

  public FormatRing(pigeon: IPigeon): string {
    switch (pigeon.ringArea) {
      // Germany
      case "DV":
        return `${pigeon.ringArea} ${pigeon.ringClub}-${this.FormatRingBirthyearOnTwoDigits(pigeon)}-${
          pigeon.ringNumber
        }`;

      default:
        return `${pigeon.ringArea} ${this.FormatRingBirthyearOnTwoDigits(pigeon)}-${pigeon.ringNumber}`;
    }
  }

  public RawFormatRing(
    ringArea: string | null,
    ringClub: string | null | undefined,
    birthyear: number | null,
    ringNumber: string | null
  ): string {
    let formattedRing = "";

    if (ringArea) formattedRing += ringArea + " ";
    if (ringArea == "DV" && ringClub) formattedRing += `${ringClub} -`;
    if (birthyear) formattedRing += `${this.RawFormatRingBirthyearOnTwoDigits(birthyear)}-`;
    if (ringNumber) formattedRing += ringNumber;

    return formattedRing;
  }

  private SortRingAreas(ringAreas: IRing[]): IRing[] {
    return cloneDeep(ringAreas).sort(function (a, b) {
      return (
        ArrayHelper.SortAscendingCompare(a.discriminator, b.discriminator) ||
        ArrayHelper.SortAscendingCompare(a.areaCode, b.areaCode)
      );
    });
  }

  public BuildRingAreasGroupOptions(ringAreas: IRing[]): { [key: string]: IListOption[] } {
    const sortedRingAreas = this.SortRingAreas(ringAreas);
    const ringAreasGroupedByDiscriminator = groupBy(sortedRingAreas, (ring: IRing) => ring.discriminator);
    const ringAreasOptionGroups: { [key: string]: IListOption[] } = {};

    for (const discriminator in ringAreasGroupedByDiscriminator) {
      const hasDiscriminator = Object.prototype.hasOwnProperty.call(ringAreasGroupedByDiscriminator, discriminator);

      if (hasDiscriminator) {
        ringAreasOptionGroups[discriminator] = ringAreasGroupedByDiscriminator[discriminator].map((option) => {
          return {
            label: option.areaCode,
            value: option.areaCode
          } as IListOption;
        });
      }
    }

    return ringAreasOptionGroups;
  }
}
