import { IStrainsService } from "@api/contracts/odata/IStrainsService";
import { IStrain } from "@api/models/market/IStrain";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { odataQuery } from "odata-fluent-query";
import { memoizeAsync } from "utils-decorators";

export class StrainsService extends BaseODataService implements IStrainsService {
  constructor() {
    super();
  }

  public Insert(strain: IStrain): AxiosPromise<IStrain> {
    return this.Client().post("Strains", strain);
  }

  public Fetch(strainKey: number): AxiosPromise<IStrain> {
    return this.Client().get(`Strains(${strainKey})`);
  }

  @memoizeAsync(10 * 60 * 1000)
  public FetchAll(): AxiosPromise<IODataCollectionResponse<IStrain>> {
    const query = odataQuery<IStrain>().select("id", "name").orderBy("name", "asc");

    return this.Client().get(`Strains/?${query.toString}`);
  }
}
