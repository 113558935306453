var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{attrs:{"rules":_vm.validationRules,"tag":"div","name":_vm.validationName || _vm.label || _vm.Attrs.name,"vid":_vm.validationId,"mode":_vm.validationMode,"customMessages":_vm.validationCustomMessages},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"field",class:{
      'floating-labels': _vm.hasFloatingLabel,
      'flex-column-reverse': !_vm.hasFloatingLabel,
      'is-invalid': _vm.hasError || (errors && errors.length > 0)
    }},[_c('textarea',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],staticClass:"input",class:{ 'is-invalid': _vm.hasError || (errors && errors.length > 0) },attrs:{"id":_vm.id},domProps:{"value":(_vm.innerValue)},on:{"input":function($event){if($event.target.composing)return;_vm.innerValue=$event.target.value}}},'textarea',_vm.Attrs,false),_vm.Listeners)),_c('label',{staticClass:"label",class:{
        'floating-label': _vm.hasFloatingLabel,
        'is-floating':
          !_vm.hasFloatingLabel || (_vm.hasFloatingLabel && _vm.HasFocus) || (_vm.hasFloatingLabel && _vm.value && _vm.value.length)
      },attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))])]),(errors && errors.length)?_c('AppError',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }