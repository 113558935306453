import { AppRoles } from "@api/models/market/constants/AppRoles";
import { accountStore, authStore } from "@market/stores/App.store.modules";
import { IAuthorizeVNodeDirective } from "./IAuthorizeVNodeDirective";

/**
 * @deprecated should use v-if="$auth.Property" instead
 */
function IsAuthorized(requiredRoles?: string[] | AppRoles[], shouldAccountBeVerified = false): Promise<boolean> {
  let isAuthorized = false;

  // v-if-auth (no parameter) => User must be authenticated
  if (!requiredRoles) {
    isAuthorized = authStore.IsAuthenticated;
  }
  // v-if-auth="['roleName']" => User must be authenticated and have the required role
  else {
    if (!(requiredRoles instanceof Array) || requiredRoles.length < 1)
      Promise.reject(
        new Error(
          "[Error] invalid argument for v-if-auth directive: the parameter roles is must be an array of AppRoles `v-if-auth=\"['roleName']\"`"
        )
      );

    const appRoles = Object.values(AppRoles);
    isAuthorized = requiredRoles.some((requiredRole: string | AppRoles) => {
      return appRoles.includes(requiredRole as AppRoles) && authStore.IsAuthorized(requiredRole);
    });
  }

  // Modifiers account
  // v-if-auth:verified OR v-if-auth:verified="['roleName']"
  if (shouldAccountBeVerified) isAuthorized = isAuthorized && accountStore.AccountIsVerified;

  return Promise.resolve(isAuthorized);
}

async function HideNodeIfNotAuthorized(el: HTMLElement, binding: IAuthorizeVNodeDirective) {
  const { value: requiredRoles } = binding;
  const shouldAccountBeVerified = binding.modifiers && binding.modifiers["verified"] ? true : false;
  const isAuthorized = await IsAuthorized(requiredRoles, shouldAccountBeVerified);

  if (!isAuthorized) {
    el.style.display = "none";
  } else {
    el.style.display = "";
  }
}

export default {
  bind(el: HTMLElement, binding: IAuthorizeVNodeDirective) {
    el.style.display = "none";
  },
  inserted(el: HTMLElement, binding: IAuthorizeVNodeDirective) {
    HideNodeIfNotAuthorized(el, binding);
  },
  update(el: HTMLElement, binding: IAuthorizeVNodeDirective) {
    HideNodeIfNotAuthorized(el, binding);
  }
};
