import { ProductItemFactory } from "@api/factories/productItemFactory";
import { AppThemes } from "@api/models/market/constants/AppThemes";
import { SexTypes } from "@api/models/market/constants/SexTypes";
import { nameof } from "@pigeon/extensions/nameof";
import { IProductDropdownListItem } from "@pigeon/models/IProductDropdownListItem";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { Inject } from "inversify-props";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class PigeonDropdownlistItem extends Vue {
  @Prop({ default: () => ProductItemFactory.GetUnknowItem() })
  readonly pigeon: IProductDropdownListItem;
  @Prop({ default: false })
  readonly editable: false;
  @Prop({ default: AppThemes.Light, validator: (value: AppThemes) => Object.values(AppThemes).includes(value) })
  readonly theme: AppThemes;

  @Inject()
  private pigeonManager: IPigeonManager;

  $refs: {
    pigeonName: Element;
    pigeonRing: Element;
  };
  SexTypes = SexTypes;

  mounted() {
    // if (this.$refs.pigeonName) {
    //   new MutationObserver(() => {
    //     if (this.$refs.pigeonName.textContent !== "undefined") {
    //       this.FilterByName(this.$refs.pigeonName.textContent);
    //     }
    //   }).observe(this.$refs.pigeonName, { childList: true, subtree: true, characterData: true });
    // }
    // if (this.$refs.pigeonRing) {
    //   new MutationObserver(() => {
    //     if (this.$refs.pigeonRing.textContent !== "undefined") {
    //       this.FilterByRing(this.$refs.pigeonRing.textContent);
    //     }
    //   }).observe(this.$refs.pigeonRing, { childList: true, subtree: true, characterData: true });
    // }
  }

  @Watch(nameof("$i18n.locale"), { immediate: true })
  ObserveUnknownDefaultValue(): void {
    if (this.pigeon === ProductItemFactory.GetUnknowItem()) {
      this.pigeon.name = this.$i18n.t("common.unknown") as string;
    }
  }

  @Emit()
  public FilterByName(name: string | null) {
    if (!name) return;

    return name;
  }

  @Emit()
  public FilterByRing(ring: string | null) {
    if (!ring) return;

    return ring;
  }

  get PigeonIcon(): string {
    return this.pigeonManager.GetSexIcon(this.pigeon);
  }

  public ClearElementText(element: HTMLSpanElement): void {
    element.innerHTML = "";
  }
}
