/* eslint-disable no-console */
import { register } from "register-service-worker";

/* eslint-disable no-var */
var refreshing: boolean;

if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      if (refreshing) return;

      console.log("New content is available; please refresh.");
      refreshing = true;

      let userLocale: string = navigator.language || "en";
      if (userLocale.length > 2) {
        userLocale = userLocale.substring(0, 2);
      }

      let alertMessage = "";

      switch (userLocale) {
        case "fr":
          alertMessage =
            "Une nouvelle version de pigeonmarket.be est disponible. L'équipe de PigeonMarket travaille dur pour vous offrir la meilleur expérience possible.  L'application va se mettre à jour maintenant et la page va se rafraichir.";
          break;
        case "nl":
          alertMessage =
            "Er is een nieuwe versie van pigeonmarket.be beschikbaar. Het PigeonMarket-team werkt hard om u de best mogelijke ervaring te bieden. De applicatie wordt nu bijgewerkt en de pagina wordt vernieuwd.";
          break;
        case "pl":
          alertMessage =
            "Dostępna jest nowa wersja pigeonmarket.be. Zespół PigeonMarket ciężko pracuje, aby zapewnić Ci jak najlepsze wrażenia. Aplikacja zaktualizuje się teraz, a strona odświeży się.";
          break;
        case "pt":
          alertMessage =
            "Uma nova versão do pigeonmarket.be está disponível. A equipe PigeonMarket está trabalhando muito para oferecer a você a melhor experiência possível. O aplicativo será atualizado agora e a página será atualizada.";
          break;

        case "en":
        default:
          alertMessage =
            "A new version of pigeonmarket.be is available. The PigeonMarket team is working hard to give you the best possible experience. The application will update now and the page will refresh.";
          break;
      }

      // Alert user that app will reload
      window.alert(alertMessage);

      //if (window.confirm(alertMessage)) {
      // Reload the current page
      window.setTimeout(() => {
        document.location.reload();
      }, 0);
      //}
    },
    offline() {
      console.log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
