// Note: the bundle size is optimized via the plugin babel-transform-imports
// See https://bitbucket.org/amctheatres/babel-transform-imports/src/master/
import { config, library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";

// free-brands-svg-icons
import { faCcAmex as fabCcAmex } from "@fortawesome/free-brands-svg-icons/faCcAmex";
import { faCcDinersClub as fabCcDinersClub } from "@fortawesome/free-brands-svg-icons/faCcDinersClub";
import { faCcDiscover as fabCcDiscover } from "@fortawesome/free-brands-svg-icons/faCcDiscover";
import { faCcJcb as fabCcJcb } from "@fortawesome/free-brands-svg-icons/faCcJcb";
import { faCcMastercard as fabCcMastercard } from "@fortawesome/free-brands-svg-icons/faCcMastercard";
import { faCcPaypal as fabCcPaypal } from "@fortawesome/free-brands-svg-icons/faCcPaypal";
import { faCcStripe as fabCcStripe } from "@fortawesome/free-brands-svg-icons/faCcStripe";
import { faCcVisa as fabCcVisa } from "@fortawesome/free-brands-svg-icons/faCcVisa";
import { faFacebook as fabFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faStripe as fabStripe } from "@fortawesome/free-brands-svg-icons/faStripe";
import { faTwitter as fabTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";

// free-regular-svg-icons
import { faBuilding as farBuilding } from "@fortawesome/free-regular-svg-icons/faBuilding";
import { faCaretSquareDown as farCaretSquareDown } from "@fortawesome/free-regular-svg-icons/faCaretSquareDown";
import { faCheckCircle as farCheckCircle } from "@fortawesome/free-regular-svg-icons/faCheckCircle";
import { faClock as farClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { faEnvelope as farEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";
import { faFilePdf as farFilePdf } from "@fortawesome/free-regular-svg-icons/faFilePdf";
import { faFileVideo } from "@fortawesome/free-regular-svg-icons/faFileVideo";
import { faFlag as farFlag } from "@fortawesome/free-regular-svg-icons/faFlag";
import { faGem as farGem } from "@fortawesome/free-regular-svg-icons/faGem";
import { faHandshake as farHandshake } from "@fortawesome/free-regular-svg-icons/faHandshake";
import { faImage as farImage } from "@fortawesome/free-regular-svg-icons/faImage";
import { faPaperPlane as farPaperPlane } from "@fortawesome/free-regular-svg-icons/faPaperPlane";
import { faQuestionCircle as farQuestionCircle } from "@fortawesome/free-regular-svg-icons/faQuestionCircle";
import { faSave as farSave } from "@fortawesome/free-regular-svg-icons/faSave";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons/faStar";
import { faUser as farUser } from "@fortawesome/free-regular-svg-icons/faUser";
import { faSmileBeam as farSmileBeam } from "@fortawesome/free-regular-svg-icons/faSmileBeam";
import { faAngry as farAngry } from "@fortawesome/free-regular-svg-icons/faAngry";
import { faSadTear as farSadTear } from "@fortawesome/free-regular-svg-icons/faSadTear";
import { faFrown as farFrown } from "@fortawesome/free-regular-svg-icons/faFrown";
import { faDizzy as farDizzy } from "@fortawesome/free-regular-svg-icons/faDizzy";

// free-solid-svg-icons
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons/faAddressBook";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faAt } from "@fortawesome/free-solid-svg-icons/faAt";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { faBox } from "@fortawesome/free-solid-svg-icons/faBox";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons/faCalendarWeek";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faCloud } from "@fortawesome/free-solid-svg-icons/faCloud";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faCompass } from "@fortawesome/free-solid-svg-icons/faCompass";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { faDirections } from "@fortawesome/free-solid-svg-icons/faDirections";
import { faDna } from "@fortawesome/free-solid-svg-icons/faDna";
import { faDove } from "@fortawesome/free-solid-svg-icons/faDove";
import { faEgg } from "@fortawesome/free-solid-svg-icons/faEgg";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons/faEuroSign";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faFeatherAlt } from "@fortawesome/free-solid-svg-icons/faFeatherAlt";
import { faFileImage } from "@fortawesome/free-solid-svg-icons/faFileImage";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons/faFileInvoice";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons/faFileInvoiceDollar";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { faFlag } from "@fortawesome/free-solid-svg-icons/faFlag";
import { faFont } from "@fortawesome/free-solid-svg-icons/faFont";
import { faGavel } from "@fortawesome/free-solid-svg-icons/faGavel";
import { faGenderless } from "@fortawesome/free-solid-svg-icons/faGenderless";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons/faHandHoldingUsd";
import { faHashtag } from "@fortawesome/free-solid-svg-icons/faHashtag";
import { faHistory } from "@fortawesome/free-solid-svg-icons/faHistory";
import { faIdBadge } from "@fortawesome/free-solid-svg-icons/faIdBadge";
import { faIdCard } from "@fortawesome/free-solid-svg-icons/faIdCard";
import { faImage } from "@fortawesome/free-solid-svg-icons/faImage";
import { faInfo } from "@fortawesome/free-solid-svg-icons/faInfo";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons/faMailBulk";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faMars } from "@fortawesome/free-solid-svg-icons/faMars";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { faMicroscope } from "@fortawesome/free-solid-svg-icons/faMicroscope";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons/faMobileAlt";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons/faMoneyCheck";
import { faMoneyCheckAlt } from "@fortawesome/free-solid-svg-icons/faMoneyCheckAlt";
import { faPause } from "@fortawesome/free-solid-svg-icons/faPause";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faPrint } from "@fortawesome/free-solid-svg-icons/faPrint";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faReceipt } from "@fortawesome/free-solid-svg-icons/faReceipt";
import { faRedo } from "@fortawesome/free-solid-svg-icons/faRedo";
import { faRetweet } from "@fortawesome/free-solid-svg-icons/faRetweet";
import { faRing } from "@fortawesome/free-solid-svg-icons/faRing";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons/faSearchPlus";
import { faSearchMinus } from "@fortawesome/free-solid-svg-icons/faSearchMinus";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons/faShieldAlt";
import { faShippingFast } from "@fortawesome/free-solid-svg-icons/faShippingFast";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { faSitemap } from "@fortawesome/free-solid-svg-icons/faSitemap";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faStarHalfAlt } from "@fortawesome/free-solid-svg-icons/faStarHalfAlt";
import { faStop } from "@fortawesome/free-solid-svg-icons/faStop";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons/faStopwatch";
import { faStore } from "@fortawesome/free-solid-svg-icons/faStore";
import { faTable } from "@fortawesome/free-solid-svg-icons/faTable";
import { faTag } from "@fortawesome/free-solid-svg-icons/faTag";
import { faTasks } from "@fortawesome/free-solid-svg-icons/faTasks";
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh";
import { faThLarge } from "@fortawesome/free-solid-svg-icons/faThLarge";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons/faThumbsDown";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons/faThumbtack";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faTrophy } from "@fortawesome/free-solid-svg-icons/faTrophy";
import { faTruck } from "@fortawesome/free-solid-svg-icons/faTruck";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons/faUserCheck";
import { faUserLock } from "@fortawesome/free-solid-svg-icons/faUserLock";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { faUserTag } from "@fortawesome/free-solid-svg-icons/faUserTag";
import { faVenus } from "@fortawesome/free-solid-svg-icons/faVenus";
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons/faWindowMaximize";
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons/faWindowMinimize";
import { faTicket } from "@fortawesome/free-solid-svg-icons/faTicket";
import { faLanguage } from "@fortawesome/free-solid-svg-icons/faLanguage";
import { faPercent } from "@fortawesome/free-solid-svg-icons/faPercent";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { faFunnelDollar } from "@fortawesome/free-solid-svg-icons/faFunnelDollar";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faComments } from "@fortawesome/free-solid-svg-icons/faComments";
import { faDolly } from "@fortawesome/free-solid-svg-icons/faDolly";
import { faBackspace } from "@fortawesome/free-solid-svg-icons/faBackspace";
import { faPiggyBank } from "@fortawesome/free-solid-svg-icons/faPiggyBank";
import { faUniversity } from "@fortawesome/free-solid-svg-icons/faUniversity";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons/faExpandAlt";
import { faEraser } from "@fortawesome/free-solid-svg-icons/faEraser";

// solid icons
library.add(
  faImage,
  faVideo,
  faFileImage,
  faFileVideo,
  faVenus,
  faMars,
  faGenderless,
  faTh,
  faThLarge,
  faTable,
  faAngleUp,
  faAngleRight,
  faAngleDown,
  faAngleLeft,
  faInfo,
  faInfoCircle,
  faQuestion,
  faQuestionCircle,
  faExclamation,
  faExclamationTriangle,
  faExclamationCircle,
  faClock,
  faHistory,
  faPlus,
  faMinus,
  faRedo,
  faTimes,
  faEnvelope,
  faBars,
  faStar,
  faUser,
  faUserTie,
  faUserTag,
  faUserPlus,
  faUserLock,
  faUserCheck,
  faUsers,
  faShoppingCart,
  faAt,
  faMobileAlt,
  faMapMarkerAlt,
  faGavel,
  faLink,
  faExternalLinkAlt,
  faStore,
  faBolt,
  faCheck,
  faKey,
  faShieldAlt,
  faCog,
  faEye,
  faSitemap,
  faEuroSign,
  faCreditCard,
  faIdCard,
  faLock,
  faShippingFast,
  faTruck,
  faBox,
  faDirections,
  faFileInvoice,
  faCalendar,
  faCalendarWeek,
  faHashtag,
  faThumbsUp,
  faThumbsDown,
  faFeatherAlt,
  faMicroscope,
  faDna,
  faDatabase,
  faMedal,
  faTrophy,
  faSearch,
  faSearchPlus,
  faSearchMinus,
  faFont,
  faWindowMaximize,
  faWindowMinimize,
  faPencilAlt,
  faArrowUp,
  faArrowDown,
  faTag,
  faCompass,
  faCheckCircle,
  faTimesCircle,
  faFlag,
  faBell,
  faDove,
  faBars,
  faList,
  faGlobe,
  faMoneyBill,
  faStar,
  faStarHalfAlt,
  faMoneyCheck,
  faMoneyCheckAlt,
  faCloud,
  faCircle,
  faEllipsisH,
  faEllipsisV,
  faPlay,
  faPause,
  faStop,
  faRing,
  faEgg,
  faGavel,
  faStopwatch,
  faMoneyBill,
  faMoneyCheck,
  faFileInvoiceDollar,
  faRetweet,
  faThumbtack,
  faMailBulk,
  faPrint,
  faReceipt,
  faFilter,
  faAddressBook,
  faHandHoldingUsd,
  faTasks,
  faIdBadge,
  faTicket,
  faLanguage,
  faPercent,
  faTools,
  faFunnelDollar,
  faBan,
  faTrash,
  faComments,
  faCoins,
  faDolly,
  faBackspace,
  faPiggyBank,
  faUniversity,
  faExpandAlt,
  faEraser
);

// regular icons
library.add(
  farImage,
  farFilePdf,
  farEnvelope,
  farClock,
  farPaperPlane,
  farHandshake,
  farGem,
  farSave,
  farCheckCircle,
  farQuestionCircle,
  farBuilding,
  farCaretSquareDown,
  farFlag,
  farStar,
  farUser,
  farSmileBeam,
  farAngry,
  farDizzy,
  farFrown,
  farSadTear
);

// brand icons
library.add(
  fabCcVisa,
  fabCcMastercard,
  fabCcPaypal,
  fabCcAmex,
  fabCcDiscover,
  fabCcDinersClub,
  fabCcJcb,
  fabTwitter,
  fabFacebook,
  fabCcStripe,
  fabStripe
);

// Skip auto-inserting CSS into the <head>
config.autoAddCss = false;

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Icon", FontAwesomeIcon);
