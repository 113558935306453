import { signInDialogStore } from "@market/stores/App.store.modules";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AccountSignInRecoveryDoneView extends Vue {
  @Prop()
  readonly email: string;

  public ToggleSignInDialog(): void {
    signInDialogStore.Toggle();
  }
}
