import NavStepper, { StepperBehaviorModes } from "@pigeon/components/nav/NavStepper";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";

@Component
export default class NavStepperStep extends Vue {
  @Prop()
  readonly to?: Location;
  @Prop()
  readonly stepId?: string;
  @Prop()
  readonly isActive?: boolean;
  @Prop()
  readonly isCompleted?: boolean;

  StepperBehaviorModes = StepperBehaviorModes;

  get Stepper(): NavStepper {
    return this.$parent as NavStepper;
  }

  get IsStepActive() {
    if (typeof this.isActive != "undefined") return this.isActive;
    if (typeof this.isCompleted != "undefined") return false;
    if (!this.stepId) return false;

    if (this.Stepper.behaviorMode === StepperBehaviorModes.RouteName) {
      return this.IsStepActiveFrom(this.$route?.name);
    } else if (this.Stepper.behaviorMode === StepperBehaviorModes.RouteMeta) {
      return this.IsStepActiveFrom(this.$route?.meta?.step);
    } else if (this.Stepper.behaviorMode === StepperBehaviorModes.Parent) {
      return this.IsStepActiveFrom(this.Stepper.step);
    }
  }

  get IsStepCompleted() {
    if (typeof this.isCompleted != "undefined") return this.isCompleted;
    if (!this.stepId) return false;

    if (this.Stepper.behaviorMode === StepperBehaviorModes.RouteName) {
      return this.IsStepCompletedFrom(this.$route?.name);
    } else if (this.Stepper.behaviorMode === StepperBehaviorModes.RouteMeta) {
      return this.IsStepCompletedFrom(this.$route?.meta?.step);
    } else if (this.Stepper.behaviorMode === StepperBehaviorModes.Parent) {
      return this.IsStepCompletedFrom(this.Stepper.step);
    }
  }

  created() {
    if (this.stepId) this.Stepper.Register(this.stepId);
  }

  beforeDestroy() {
    if (this.stepId) this.Stepper.Unregister(this.stepId);
  }

  private IsStepActiveFrom(currentStepId?: string | null): boolean {
    if (!currentStepId || !this.stepId) return false;

    if (this.stepId === currentStepId) {
      return true;
    } else {
      return false;
    }
  }

  private IsStepCompletedFrom(currentStepId?: string | null): boolean {
    if (!currentStepId || !this.stepId) return false;

    const stepper = this.$parent as NavStepper;
    const stepIndex = stepper.Steps.indexOf(this.stepId);
    const currentStepIndex = stepper.Steps.indexOf(currentStepId);

    if (currentStepIndex > stepIndex) {
      return true;
    } else {
      return false;
    }
  }

  @Emit("click")
  public OnClick(): void {}
}
