import { render, staticRenderFns } from "./TheSearchBar.vue?vue&type=template&id=669b39d3&scoped=true&"
import script from "./TheSearchBar.ts?vue&type=script&lang=ts&"
export * from "./TheSearchBar.ts?vue&type=script&lang=ts&"
import style0 from "./TheSearchBar.vue?vue&type=style&index=0&id=669b39d3&prod&lang=scss&scoped=true&"
import style1 from "./TheSearchBar.vue?vue&type=style&index=1&id=669b39d3&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "669b39d3",
  null
  
)

/* custom blocks */
import block0 from "./TheSearchBar.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports