import { ISale } from "@api/models/market/ISale";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class ToastMessage extends Vue {
  @Prop()
  readonly title: string;
  @Prop()
  readonly message: string;

  @Prop()
  readonly sale?: ISale;

  @Emit("click")
  public EmitClickOnToast() {
    return this.sale;
  }
}
