import { IPayout } from "@api/models/market/IPayout";
import { ISale } from "@api/models/market/ISale";
import { IPaymentManager } from "./contracts/IPaymentManager";

export class PaymentManager implements IPaymentManager {
  public FindPayoutBySale(payouts: IPayout[], sale: ISale): IPayout | undefined {
    if (!sale || !sale.id) return;

    return payouts.find((p) => p.saleIds?.includes((sale.id as number).toString()));
  }
}
