import { v4 as UUIDv4 } from "uuid";
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class FormInputRadio extends Vue {
  @Model("change", { type: [String, Number, Boolean, Object, Array] })
  readonly model: any;
  @Prop({ type: [String, Number, Boolean, Object] })
  readonly value?: string | number | boolean | object;
  @Prop({ type: String, default: () => `check-${UUIDv4()}` })
  readonly id: string;

  hovered: boolean = false;

  get Attrs(): any {
    return { ...this.$attrs, value: this.value };
  }

  get Listeners() {
    return { ...this.$listeners, change: this.OnChange };
  }

  get IsChecked() {
    if (Array.isArray(this.model)) {
      return this.model.includes(this.value);
    } else {
      return this.model === this.value;
    }
  }

  public OnChange() {
    if (this.$attrs.disabled || this.$attrs.readonly) return;

    this.Check();
  }

  @Emit("change")
  public Check() {
    return this.value;
  }

  public Highlight(state: boolean) {
    this.hovered = state;
  }
}
