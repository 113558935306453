import { appsStore } from "@market/stores/App.store.modules";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppsLauncher extends Vue {
  get LauncherIcon(): string {
    return appsStore.launcherIsOpen ? "angle-up" : "angle-down";
  }

  public ToggleAppsLauncher(): void {
    appsStore.ToggleAppsLauncher();
  }
}
