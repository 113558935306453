import { IFacebookService } from "@api/contracts/webapi/IFacebookService";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { AxiosPromise } from "axios";

export interface IFacebookPost {
  id: string;
  created_time: string;
  message?: string;
  full_picture?: string;
  permalink_url: string;
  status_type: string;
  is_published: boolean;
  comments?: any;
  reactions?: { pic: any; type: any };
  likes?: { username: any };
  sharedposts?: { description: any; full_picture: any; message: any };
}

export interface IFacebookDataPaging<T> {
  data: T;
  paging: {
    cursors: {
      before: string;
      after: string;
    };
    next: string;
  };
}

// See https://developers.facebook.com/docs/graph-api
// See: https://developers.facebook.com/tools/explorer/?method=GET&path=PigeonMarket.be&version=v15.0
// See: https://developers.facebook.com/tools/explorer/?method=GET&path=PigeonMarket.be%2Ffeed&version=v15.0
export class FacebookService extends BaseApiService implements IFacebookService {
  public GetLikesCount(): AxiosPromise<number> {
    return this.Client().get("Facebook/likes-count");
  }

  // public GetUserAccessToken(): AxiosPromise<IFBAccessToken> {
  //   return this.Client().get("Facebook/user-access-token");
  // }

  // public GetPageAccessToken(): AxiosPromise<IFBAccessToken> {
  //   return this.Client().get("Facebook/page-access-token");
  // }

  public InitFacebookSDK(): void {
    const pigeonMarketFbAppId: string = process.env.VUE_APP_FACEBOOK_APP_ID as string;
    if (!pigeonMarketFbAppId) console.error("Settings error: The VUE_APP_FACEBOOK_APP_ID env variable is undefined.");

    // Init Facebook SDK
    if (FB) {
      FB.XFBML.parse();
    } else {
      // See: https://developers.facebook.com/docs/javascript/quickstart
      (window as any).fbAsyncInit = () => {
        FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID as string,
          autoLogAppEvents: true,
          xfbml: true,
          version: "v15.0"
        });
      };
    }
  }

  public async FetchFanCount(): Promise<number> {
    const FBPageAccessToken = process.env.VUE_APP_FACEBOOK_PAGE_ACCESS_TOKEN as string;

    return new Promise((resolve, reject) => {
      try {
        FB.api("/PigeonMarket.be", "get", { access_token: FBPageAccessToken, fields: "fan_count" }, (response: any) => {
          return resolve(response["fan_count"]);
        });
      } catch (error) {
        return reject(error);
      }
    });
  }

  public async FetchFollowersCount(): Promise<number> {
    const FBPageAccessToken = process.env.VUE_APP_FACEBOOK_PAGE_ACCESS_TOKEN as string;

    return new Promise((resolve, reject) => {
      try {
        FB.api(
          "/PigeonMarket.be",
          "get",
          { access_token: FBPageAccessToken, fields: "followers_count" },
          (response: any) => {
            return resolve(response["followers_count"]);
          }
        );
      } catch (error) {
        return reject(error);
      }
    });
  }

  public async FetchFeed(): Promise<IFacebookDataPaging<IFacebookPost>> {
    const FBPageAccessToken = process.env.VUE_APP_FACEBOOK_PAGE_ACCESS_TOKEN as string;

    return new Promise((resolve, reject) => {
      try {
        FB.api(
          "/PigeonMarket.be/feed",
          "get",
          {
            access_token: FBPageAccessToken,
            fields:
              "id,permalink_url,message,created_time,status_type,full_picture,comments,reactions{pic,type},likes{username},sharedposts{description,full_picture,message},is_published"
          },
          (response: any) => {
            return resolve(response);
          }
        );
      } catch (error) {
        return reject(error);
      }
    });
  }
}
