import { IDepositsService } from "@api/contracts/odata/IDepositsService";
import { IDeposit } from "@api/models/market/IDeposit";
import { store } from "@market/stores";
import { authStore } from "@market/stores/App.store.modules";
import { IDepositStore } from "@market/stores/contracts/IDepositStore";
import { cid, container, Inject } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "deposit" = "deposit";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class DepositStore extends VuexModule implements IDepositStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private depositsService: IDepositsService;

  pendingDeposits: IDeposit[] = [];
  showPendingDepositTask: boolean = true;

  get pendingDepositsCount(): number {
    return this.pendingDeposits?.length ?? 0;
  }

  @Mutation
  SET_PENDING_DEPOSITS(data: IDeposit[]): void {
    this.pendingDeposits = data;
  }

  @Mutation
  REMOVE_PENDING_DEPOSIT(depositKeyToRemove: string): void {
    const depositIndex = this.pendingDeposits.findIndex((s) => s.id == depositKeyToRemove);

    if (depositIndex > -1) {
      this.pendingDeposits.splice(depositIndex, 1);
    }
  }

  @Mutation
  SET_SHOW_PENDING_DEPOSIT_TASK(show: boolean): void {
    this.showPendingDepositTask = show;
  }

  @Action
  public DismissPendingDepositTask() {
    this.showPendingDepositTask = false;
  }

  @Action
  public async FetchPendingDeposits(): Promise<void> {
    const depositsService = container.get<IDepositsService>(cid.DepositsService);

    if (authStore.IsAdministrator) {
      depositsService.FetchAllPendingProcessing().then((response) => {
        this.SET_PENDING_DEPOSITS(response.data.value);
      });
    }
  }

  @Action
  public async RemovePendingDeposits(depositKey: string): Promise<void> {
    this.REMOVE_PENDING_DEPOSIT(depositKey);
  }

  @Action
  public async Reset(): Promise<void> {
    this.SET_PENDING_DEPOSITS([]);
  }
}
