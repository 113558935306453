import { AppThemes } from "@api/models/market/constants/AppThemes";
import { IPigeon } from "@api/models/market/IPigeon";
import PigeonAssetParent from "@pigeon/components/pigeon/PigeonAssetParent.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    AssetParent: PigeonAssetParent
  }
})
export default class PigeonAssetParents extends Vue {
  @Prop()
  readonly parents: IPigeon[];
  @Prop()
  readonly childId: number;
  @Prop({ default: true })
  readonly showAsset: boolean;
  @Prop({ validator: (value: AppThemes) => Object.values(AppThemes).includes(value) })
  readonly theme?: AppThemes;
}
