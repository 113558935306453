import { IAuthSession } from "@api/models/auth/IAuthSession";
import { AddressTypes } from "@api/models/market/constants/AddressTypes";
import { IAddress } from "@api/models/market/IAddress";
import { IUserData } from "@api/models/market/IUserData";
import { IVendor } from "@api/models/market/IVendor";
import { ArrayHelper } from "@api/services/helpers/ArrayHelper";
import { store } from "@market/stores";
import { IUserStore } from "@market/stores/contracts/IUserStore";
import AuthStore from "@market/stores/modules/authStore";
import { HasFunctionalityCookieConsent } from "@pigeon/components/layout/TheBannerCookies";
import { MailHelper } from "@pigeon/helpers/MailHelper";
import dayjs from "@pigeon/i18n/dayjs";
import { cloneDeep } from "lodash-es";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "user" = "user";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class UserStore extends VuexModule implements IUserStore {
  static readonly STORE_KEY: string = "user";
  pending: boolean = false;
  user: IUserData | null = null;
  intermediary: IUserData | null = null;

  get DefaultAddress(): IAddress {
    return {
      addressLine: "",
      district: "",
      locality: "",
      postalCode: "",
      country: "",
      countryTwoIsoLetters: "",
      type: AddressTypes.None,
      createdAt: new Date()
    };
  }

  get DefaultPickupAddress(): IAddress {
    if (!this.user || !this.user.addresses || !this.user.addresses.length) return { ...this.DefaultAddress };

    const pickupAddresses = cloneDeep(this.user.addresses)
      .filter((a) => a.type === AddressTypes.PickUp)
      .sort((a: IAddress, b: IAddress) => ArrayHelper.SortDescendingCompare(a.createdAt, b.createdAt));
    if (!pickupAddresses.length) {
      return { ...this.DefaultAddress, type: AddressTypes.PickUp };
    } else {
      const lastPickupAddress = pickupAddresses[0];
      lastPickupAddress.createdAt = dayjs(lastPickupAddress.createdAt).toDate();
      return { ...lastPickupAddress, id: undefined };
    }
  }

  get DefaultDeliveryAddress(): IAddress {
    if (!this.user || !this.user.addresses || !this.user.addresses.length) return { ...this.DefaultAddress };

    const deliveryAddresses = cloneDeep(this.user.addresses)
      .filter((a) => a.type === AddressTypes.Delivery)
      .sort((a: IAddress, b: IAddress) => ArrayHelper.SortDescendingCompare(a.createdAt, b.createdAt));
    if (!deliveryAddresses || !deliveryAddresses.length) {
      return { ...this.DefaultAddress, type: AddressTypes.Delivery, id: undefined };
    } else {
      const lastDeliveryAddress = deliveryAddresses[0];
      lastDeliveryAddress.createdAt = dayjs(lastDeliveryAddress.createdAt).toDate();
      return { ...lastDeliveryAddress, id: undefined };
    }
  }

  get DefaultBillingAddress(): IAddress {
    if (!this.user || !this.user.addresses || !this.user.addresses.length) return { ...this.DefaultAddress };

    const billingAddresses = cloneDeep(this.user.addresses)
      .filter((a) => a.type === AddressTypes.Billing)
      .sort((a: IAddress, b: IAddress) => ArrayHelper.SortDescendingCompare(a.createdAt, b.createdAt));
    if (!billingAddresses || !billingAddresses.length) {
      return { ...this.DefaultAddress, type: AddressTypes.Billing, id: undefined };
    } else {
      const lastBillingAddress = billingAddresses[0];
      lastBillingAddress.createdAt = dayjs(lastBillingAddress.createdAt).toDate();
      return { ...lastBillingAddress, id: undefined };
    }
  }

  get MailProviderWebSite(): string {
    if (!this.user) return "";

    return MailHelper.HrefToMailProviderWebSite(this.user.email);
  }

  @Mutation
  UPDATE_USER_DATA(userData: IUserData): void {
    this.user = userData;
  }

  @Mutation
  UPDATE_VENDOR_DATA(vendorData: IVendor): void {
    if (!this.user) return;

    this.user.vendor = vendorData;
  }

  @Mutation
  UPDATE_VENDOR_DEFAULT_FANCIER_ID(defaultFancierId: number): void {
    if (!this.user || !this.user.vendor) return;

    this.user.vendor.defaultFancierId = defaultFancierId;
  }

  @Mutation
  UPDATE_VENDOR_DEFAULT_FANCIER_PROFILE_ID(defaultProfileId: number): void {
    if (!this.user || !this.user.vendor) return;

    this.user.vendor.defaultFancierProfileId = defaultProfileId;
  }

  @Mutation
  ADD_ADDRESS(address: IAddress): void {
    if (!this.user) return;
    this.user.addresses.push(address);
  }

  @Mutation
  UPDATE_PREFERRED_LANGUAGE(locale: string) {
    if (!this.user) return;

    this.user.preferredLanguage = locale;
  }

  @Mutation
  UPDATE_FLAG_AGREE_TO_AUCTION_CONDITIONS(agree: boolean) {
    if (!this.user) return;

    this.user.flagAgreeToAuctionConditions = agree;
  }

  @Mutation
  SET_INTERMEDIARY(intermediary: IUserData) {
    this.intermediary = intermediary;
  }

  @Mutation
  RESET(): void {
    this.user = null;
    this.intermediary = null;
  }

  @Action
  AddAddress(address: IAddress): void {
    if (!address) return;

    address.createdAt = new Date();
    this.ADD_ADDRESS(address);
    this.Store();
  }

  @Action
  InitUserData(user: IUserData) {
    if (!user) return;

    this.UPDATE_USER_DATA(user);
    this.Store();
  }

  @Action
  InitVendorData(vendor: IVendor) {
    if (!vendor || this.user?.vendor) return;

    this.UPDATE_VENDOR_DATA(vendor);
    this.Store();
  }

  @Action
  InitIntermediary(user: IUserData) {
    this.SET_INTERMEDIARY(user);
  }

  @Action
  UpdateVendorDefaultFancierId(defaultFancierId: number) {
    if (this.user?.vendor) return;

    this.UPDATE_VENDOR_DEFAULT_FANCIER_ID(defaultFancierId);
    this.Store();
  }

  @Action
  UpdateVendorDefaultFancierProfileId(defaultProfileId: number) {
    if (this.user?.vendor) return;

    this.UPDATE_VENDOR_DEFAULT_FANCIER_PROFILE_ID(defaultProfileId);
    this.Store();
  }

  @Action
  UpdateUserPreferredLanguage(locale: string) {
    if (!this.user || this.user.preferredLanguage === locale) return;

    this.UPDATE_PREFERRED_LANGUAGE(locale);
    this.Store();
  }

  @Action
  UpdateMemberFlagPolicy(agreeToAuctionConditions: boolean): void {
    if (!this.user || !agreeToAuctionConditions || this.user.flagAgreeToAuctionConditions) return;

    this.UPDATE_FLAG_AGREE_TO_AUCTION_CONDITIONS(agreeToAuctionConditions);
    this.Store();
  }

  @Action
  Reset(): void {
    this.RESET();
    this.ClearStorage();
  }

  @Action
  Store(): void {
    if (!HasFunctionalityCookieConsent()) return;

    localStorage.setItem(UserStore.STORE_KEY, JSON.stringify(this.user));
  }

  @Action
  ClearStorage(): void {
    localStorage.removeItem(UserStore.STORE_KEY);
  }

  @Action
  async Restore() {
    const userDataStored = localStorage.getItem(UserStore.STORE_KEY);

    try {
      if (userDataStored) {
        const userData = JSON.parse(userDataStored) as IUserData;
        this.UPDATE_USER_DATA(userData);
      }
      // Note: retro compatibility
      else if (localStorage.getItem(AuthStore.STORE_KEY)) {
        const sessionStored: string = localStorage.getItem(AuthStore.STORE_KEY) as string;
        const session: IAuthSession = JSON.parse(sessionStored) as IAuthSession;
        if (session.userData) this.UPDATE_USER_DATA(session.userData as IUserData);
      }
    } catch (error: any) {
      console.error(error);
      this.ClearStorage();
    }
  }
}
