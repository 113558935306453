import NavAccordionItem from "@pigeon/components/nav/NavAccordionItem";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavAccordion extends Vue {
  @Prop({ default: false })
  readonly hasAccordionBehavior: boolean;

  items: NavAccordionItem[] = [];

  mounted() {
    this.$on("expand", (itemKey: string) => {
      this.OnItemExpand(itemKey);
    });

    if (this.hasAccordionBehavior) {
      this.EnsureOnlyOnePanelIsOpened();
    }
  }

  destroyed() {
    this.$off("expand");
  }

  private EnsureOnlyOnePanelIsOpened(): void {
    let firstPanelExpandedKey: string = "";

    this.items.forEach((item) => {
      if (item.HasToggleTransitionEnded) {
        if (!firstPanelExpandedKey) firstPanelExpandedKey = item.itemKey;
        else item.Collapse();
      }
    });
  }

  public OnItemExpand(itemKey: string) {
    if (this.hasAccordionBehavior) {
      this.CloseOtherPanels(itemKey);
    }
  }

  private CloseOtherPanels(currentItemKey: string): void {
    if (!this.hasAccordionBehavior || !currentItemKey) return;

    this.items.forEach((item) => {
      if (item.itemKey !== currentItemKey && item.HasToggleTransitionEnded) {
        item.Collapse();
      }
    });
  }

  public CollapseItems(): void {
    this.items.forEach((item) => {
      item.Collapse();
    });
  }

  public ExpandItem(index: number): void {
    this.items[index].Expand();
  }
}
