import { ICreditCardManager } from "./contracts/ICreditCardManager";

export class CreditCardManager implements ICreditCardManager {
  static readonly cardBrandIconsMap: { [key: string]: string } = {
    visa: "cc-visa",
    mastercard: "cc-mastercard",
    amex: "cc-amex",
    discover: "cc-discover",
    diners: "cc-diners-club",
    jcb: "cc-jcb",
    unknown: ""
  };

  public GetCardIconName(brand: string): string {
    return CreditCardManager.cardBrandIconsMap[brand];
  }

  public GetCardIcon(brand: string): string[] {
    let icon = "";
    let collectionIcon = "fas";

    if (brand in CreditCardManager.cardBrandIconsMap) {
      icon = CreditCardManager.cardBrandIconsMap[brand];

      if (brand != "unknown") {
        collectionIcon = "fab";
      }
    }

    return [collectionIcon, icon];
  }
}
