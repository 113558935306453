import { IBid } from "@api/models/market/IBid";
import { IBiddingOrder } from "@api/models/market/IBiddingOrder";
import { IOrder } from "@api/models/market/IOrder";
import { store } from "@market/stores";
import { IAdminDashboardStore } from "@market/stores/contracts/IAdminDashboardStore";
import VendorDashboardStore from "@market/stores/modules/dashboard.vendor";
import { Action, Module } from "vuex-module-decorators";

export const STORE_TOKEN: "adminDashboard" = "adminDashboard";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class AdminDashboardStore extends VendorDashboardStore implements IAdminDashboardStore {
  // salesDataset: ISale[] = [];

  @Action
  async EnsureUpdateSalesDatasetForBids(bids: IBid[]): Promise<void> {
    const requiredSalesIds: number[] = bids.filter((b) => b.saleId).map((b) => b.saleId as number);
    if (!requiredSalesIds?.length) return;

    const missingSales = await this.FetchMissingSalesProducts(requiredSalesIds);
    this.UpdateSalesDataset(missingSales);
  }

  @Action
  async EnsureUpdateSalesDatasetForBiddingOrders(biddingOrders: IBiddingOrder[]): Promise<void> {
    const requiredSalesIds: number[] = biddingOrders.filter((bo) => bo.saleId).map((bo) => bo.saleId as number);
    if (!requiredSalesIds?.length) return;

    const missingSales = await this.FetchMissingSalesProducts(requiredSalesIds);
    this.UpdateSalesDataset(missingSales);
  }

  @Action
  async EnsureUpdateSalesDatasetForOrders(orders: IOrder[]): Promise<void> {
    const requiredSalesIds: number[] = orders.flatMap((o) =>
      o.lines.filter((ol) => ol.saleId).map((ol) => ol.saleId as number)
    );
    if (!requiredSalesIds?.length) return;

    const missingSales = await this.FetchMissingSalesProducts(requiredSalesIds);
    this.UpdateSalesDataset(missingSales);
  }
}
