import { IPollVotesService } from "@api/contracts/odata/IPollVotesService";
import { IPollVote } from "@api/models/market/IPollVote";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import odataQuery from "odata-fluent-query";

export class PollVotesService extends BaseODataService implements IPollVotesService {
  constructor() {
    super();
  }

  public Insert(pollVote: IPollVote): AxiosPromise<IPollVote> {
    return this.Client().post("PollVotes", pollVote);
  }

  public Delete(pollVoteKey: number): AxiosPromise<void> {
    return this.Client().delete(`PollVotes(${pollVoteKey})`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IPollVote>> {
    const query = odataQuery<IPollVote>().orderBy("pollAnswerId", "desc");

    return this.Client().get(`PollVotes?${query.toString()}`);
  }

  public Fetch(pollVoteKey: number): AxiosPromise<IPollVote> {
    return this.Client().get(`PollVotes(${pollVoteKey})`);
  }
}
