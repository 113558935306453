import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { Channels } from "@api/enumerations/Channels";
import { IAccountEmailVerification } from "@api/models/auth/IAccountEmailVerification";
import { IAuthSession } from "@api/models/auth/IAuthSession";
import { accountStore } from "@market/stores/App.store.modules";
import { IErrorManager } from "@pigeon/services/contracts/IErrorManager";
import { ErrorManager } from "@pigeon/services/ErrorManager";
import { Inject } from "inversify-props";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component
export default class AccountSignUpVerificationView extends Vue {
  static readonly VERIFICATION_CODE_LENGTH: number = 6;

  @Prop()
  readonly email: string;
  @Prop()
  readonly authSession: IAuthSession;

  @Ref()
  readonly refValidation: InstanceType<typeof ValidationObserver>;

  @Inject()
  private accountsService: IAccountsService;

  VERIFICATION_CODE_LENGTH = AccountSignUpVerificationView.VERIFICATION_CODE_LENGTH;
  verificationCode: string | null = null;
  verificationEmailHasBeenSent: boolean = false;
  verificationCodeHasFailed: boolean = false;
  isProcessing: boolean = false;
  errorManager: IErrorManager = new ErrorManager();

  mounted() {
    this.errorManager = new ErrorManager(this.refValidation);
  }

  public async SendNewVerificationCodeEmail() {
    if (!this.$auth.User) return;

    const verification: IAccountEmailVerification = { email: this.$auth.User.email };
    await this.accountsService.SendNewEmailVerification(Channels.Market, verification, this.$i18n.locale);
    accountStore.FlagVerificationAsResent();
    this.verificationEmailHasBeenSent = true;

    setTimeout(() => {
      this.verificationEmailHasBeenSent = false;
    }, 60 * 1000);
  }

  // email verification code form submit
  public async Submit() {
    if (!this.$auth.IsAuthenticated || !this.$auth.User?.id || !this.verificationCode) return;

    const userId: string = this.$auth.User.id;
    const securityToken: string = this.verificationCode.replaceAll(/\s/g, "");

    if (!userId || !securityToken) throw new Error("Account: Missing parameter exception");
    this.isProcessing = true;
    this.verificationCodeHasFailed = false;
    this.errorManager.Reset();

    try {
      const verificationResponse = await this.accountsService.VerifyEmail({
        userId: userId,
        securityToken: securityToken,
        channel: Channels.Market
      });
      if (verificationResponse.data == true) accountStore.FlagAccountAsVerified();
      else this.verificationCodeHasFailed = true;
    } catch (error: any) {
      this.errorManager.HandleError(error);
    } finally {
      this.isProcessing = false;
    }
  }
}
