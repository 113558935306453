import { IContributorRegistration } from "@api/models/auth/IContributorRegistration";
import { IContributorUpgradeRegistration } from "@api/models/auth/IContributorUpgradeRegistration";
import { IRegistration } from "@api/models/auth/IRegistration";
import { IVendorRegistration } from "@api/models/auth/IVendorRegistration";
import { IVendorUpgradeRegistration } from "@api/models/auth/IVendorUpgradeRegistration";
import { ContributorTypes } from "@api/models/market/constants/ContributorTypes";
import { DEFAULT_LOCALE } from "@pigeon/i18n/i18n.const";

export class RegistrationFactory {
  public static GetDefaultRegistration(): IRegistration {
    return {
      firstname: "",
      lastname: "",
      buyerAlias: "",
      countryCode: "",
      email: "",
      password: "",
      preferredLanguage: DEFAULT_LOCALE,
      terms: false,
      redirectUrl: ""
    };
  }

  public static GetDefaultVendorRegistration(): IVendorRegistration {
    const baseRegistration = RegistrationFactory.GetDefaultRegistration();

    return {
      ...baseRegistration,
      buyerAlias: "",
      shopId: "",
      iban: "",
      countryCode: "BE",
      corporation: null,
      terms: false,
      redirectUrl: ""
    };
  }

  public static GetDefaultVendorUpgradeRegistration(): IVendorUpgradeRegistration {
    return {
      shopId: "",
      iban: "",
      countryCode: "BE",
      corporation: null,
      terms: false,
      redirectUrl: ""
    };
  }

  public static GetDefaultContributorRegistration(): IContributorRegistration {
    const baseRegistration = RegistrationFactory.GetDefaultRegistration();

    return {
      ...baseRegistration,
      contributorAlias: "",
      countryCode: "",
      contributorType: ContributorTypes.Amateur,
      terms: false,
      redirectUrl: ""
    };
  }

  public static GetDefaultContributorUpgradeRegistration(): IContributorUpgradeRegistration {
    return {
      contributorAlias: "",
      countryCode: "",
      contributorType: ContributorTypes.Amateur,
      terms: false,
      redirectUrl: ""
    };
  }
}
