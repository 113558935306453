import { ISaleManager } from "@/Pigeon/services/contracts/ISaleManager";
import { ICartsService } from "@api/contracts/odata/ICartsService";
import { ISalesService } from "@api/contracts/odata/ISalesService";
import { CartItemMapper } from "@api/mappers/CartItemMapper";
import { CurrencyTypes } from "@api/models/market/constants/CurrencyTypes";
import { ICart } from "@api/models/market/ICart";
import { ICartSale } from "@api/models/market/ICartSale";
import { ISale } from "@api/models/market/ISale";
import { IODataValueResponse } from "@api/models/shared/IODataValueResponse";
import { ICartItem } from "@market/models/ICartItem";
import { store } from "@market/stores";
import { authStore, checkoutStore, userStore } from "@market/stores/App.store.modules";
import { ICartState } from "@market/stores/contracts/ICartState";
import { ICartStore } from "@market/stores/contracts/ICartStore";
import { HasFunctionalityCookieConsent } from "@pigeon/components/layout/TheBannerCookies";
import { SaleManager } from "@pigeon/services/SaleManager";
import { AxiosPromise } from "axios";
import { cid, container, Inject } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "cart" = "cart";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class CartStore extends VuexModule implements ICartStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private salesService: ISalesService;
  // @Inject()
  // private cartsService: ICartsService;

  static readonly STORE_KEY: string = "cart";
  cartId: number | null = null;
  checkoutSessionId: string | null = null;
  items: ICartItem[] = [];

  get CartCount(): number {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return this.items.length;
  }

  get CartTotal(): number {
    if (!this.items) return 0;

    let total: number = 0;
    this.items.forEach((item) => {
      total += item.total;
    });

    return total;
  }

  @Mutation
  RESET(): void {
    this.cartId = null;
    this.checkoutSessionId = null;
    this.items = [];
  }

  @Mutation
  SET_CHECKOUT_SESSION_ID(checkoutSessionId: string): void {
    if (!checkoutSessionId) return;

    this.checkoutSessionId = checkoutSessionId;
  }

  @Mutation
  SET_CART_ID(cartId: number): void {
    if (!cartId) return;

    this.cartId = cartId;
  }

  @Mutation
  SET_ITEMS(items: ICartItem[]): void {
    if (!items) return;

    this.items = items;
  }

  @Mutation
  ADD_ITEM(item: ICartItem): void {
    if (!item) return;

    this.items.push(item);
  }

  @Mutation
  REMOVE_ITEM(itemId: number): void {
    if (!itemId) return;

    this.items = this.items.filter((item) => item.id != itemId);
  }

  @Action
  Restore() {
    // Check if the store exists in storage
    const cartStored = localStorage.getItem(CartStore.STORE_KEY);
    if (!cartStored) {
      this.Reset();
      return;
    }

    try {
      const cart: ICartState = JSON.parse(cartStored);
      if (typeof cart.items === "undefined") throw new Error();

      this.SET_ITEMS(cart.items);
      if (cart.cartId) this.SET_CART_ID(cart.cartId);
      if (cart.checkoutSessionId) this.SET_CHECKOUT_SESSION_ID(cart.checkoutSessionId);
    } catch (error: any) {
      console.error(error);
      // Potentially corrupted data
      // Clear stored data
      this.ClearStorage();
    }
  }

  @Action
  StoreState(): void {
    if (!HasFunctionalityCookieConsent()) return;

    const state: ICartState = { cartId: this.cartId, checkoutSessionId: this.checkoutSessionId, items: this.items };
    localStorage.setItem(CartStore.STORE_KEY, JSON.stringify(state));
  }

  @Action
  ClearStorage(): void {
    localStorage.removeItem(CartStore.STORE_KEY);
  }

  @Action
  UpdateCheckoutSessionId(checkoutSessionId: string): void {
    if (!checkoutSessionId || checkoutSessionId == this.checkoutSessionId) return;

    this.SET_CHECKOUT_SESSION_ID(checkoutSessionId);
  }

  @Action
  AddItem(sale: ISale): AxiosPromise<IODataValueResponse<boolean>> {
    const salesService = container.get<ISalesService>(cid.SalesService);
    return salesService.GetIsAvailableForPurchase(sale.id as number).then(async (availableForPurchaseResponse) => {
      const isAvailableForPurchase = availableForPurchaseResponse.data.value;

      if (isAvailableForPurchase) {
        const item: ICartItem = CartItemMapper.Map(sale);

        if (!this.items.includes(item)) {
          this.ADD_ITEM(item);
          this.StoreState();
          await this.InsertCart();
        }
      }

      return availableForPurchaseResponse;
    });
  }

  @Action
  async RemoveItem(itemId: number): Promise<void> {
    const itemIsInCart = this.items.some((item) => item.id == itemId);

    if (itemIsInCart) {
      this.REMOVE_ITEM(itemId);
      this.StoreState();
      await this.InsertCart();
    }

    if (!this.items || !this.items.length) {
      this.Reset();
      checkoutStore.Reset();
    }
  }

  @Action
  Reset(): void {
    this.RESET();
    this.ClearStorage();
  }

  @Action
  async InsertCart(): Promise<void> {
    const cartsService = container.get<ICartsService>(cid.CartsService);
    const saleManager = container.get<ISaleManager>(cid.SaleManager);

    const cartItems: ICartSale[] = this.items.map((i) => {
      return { saleId: i.saleId } as ICartSale;
    });

    let total: number = 0;
    this.items.forEach((item) => {
      total += saleManager.CalculateSaleAmount(item.sale);
    });

    if (!cartItems || !cartItems.length || total < 0) return Promise.resolve();

    const cart: ICart = {
      cartItems: cartItems,
      checkoutSessionId: checkoutStore.session.checkoutSessionId ?? null,
      total: total,
      currency: CurrencyTypes.Euro,
      userIpAddress: undefined,
      userId: authStore.IsMember && checkoutStore.session ? checkoutStore.session.userId : undefined,
      guestId: !authStore.IsMember && checkoutStore.session ? checkoutStore.session.guestId : undefined,
      user: undefined,
      guest: undefined,
      createdUrl: window.location.href,
      createdAt: new Date().toISOString(),
      createdBy: userStore.user ? userStore.user?.userName : undefined
    };

    const { data } = await cartsService.Insert(cart);
    if (data.id) this.SET_CART_ID(data.id);
  }
}
