export enum SaleStatus {
  OnSale = "Sale",
  OnHold = "Hold",
  Sold = "Sold",
  Reserved = "Rsvd",
  Unsold = "Nsld",
  Removed = "Rmvd", // a vendor or an admin can remove a sale (= soft delete). Only an admin can delete a sale and erasing data from DB (= hard delete)
  Suspended = "Spnd", // a vendor can suspend a sale (and can restart it later)
  Stopped = "Stpd" // an admin can stop a sale (and only admin can restart it later)
}
