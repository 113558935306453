import { IPigeonsService } from "@api/contracts/odata/IPigeonsService";
import { IPigeon } from "@api/models/market/IPigeon";
import PigeonPedigree from "@pigeon/components/pigeon/PigeonPedigree.vue";
import { PedigreeDiagramOrientations } from "@pigeon/components/pigeon/PigeonPedigreeDiagram";
import PigeonPedigreePdf from "@pigeon/components/pigeon/PigeonPedigreePdf.vue";
import PigeonPedigreePicture from "@pigeon/components/pigeon/PigeonPedigreePicture.vue";
import PigeonPropertyTicketPicture from "@pigeon/components/pigeon/PigeonPropertyTicketPicture.vue";
import { nameof } from "@pigeon/extensions/nameof";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { Inject } from "inversify-props";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

export enum PedigreeViewTypes {
  Diagram = "diagram",
  Picture = "picture",
  TicketPicture = "ticket-picture",
  Pdf = "pdf"
}

@Component({
  components: {
    PigeonPedigree,
    PigeonPedigreePicture,
    PigeonPedigreePdf,
    PigeonPropertyTicketPicture
  }
})
export default class ProductPedigreeView extends Vue {
  @Prop({ default: false })
  readonly parentsLinked: boolean;
  @Prop({
    default: PedigreeViewTypes.Picture,
    validator: (value: PedigreeViewTypes) => Object.values(PedigreeViewTypes).includes(value)
  })
  readonly viewBy: PedigreeViewTypes;
  @Prop({ default: PedigreeDiagramOrientations.Horizontal })
  readonly viewByOrientation: PedigreeDiagramOrientations;
  @Prop()
  readonly pigeonKey: number;

  @Inject()
  private pigeonsService: IPigeonsService;
  @Inject()
  private pigeonManager: IPigeonManager;

  Orientations = PedigreeDiagramOrientations;
  ViewTypes = PedigreeViewTypes;
  selectedViewBy: PedigreeViewTypes = PedigreeViewTypes.Picture;
  selectedViewByOrientation: PedigreeDiagramOrientations | null = null;
  selectedPigeon: IPigeon | null = null;
  pedigree: IPigeon[] | null = null;
  pedigreeGeneration: number = 2;
  maxSelectedGeneration: number = this.pedigreeGeneration;
  isFaulty: boolean = false;
  isLoading: boolean = false;

  get HasPropertyTicketPicture(): boolean {
    if (!this.pedigree || !this.pedigree.length) return false;

    const pigeon = this.pedigree[0];
    return this.pigeonManager.HasPicturePedigree(pigeon.pictures);
  }

  get HasPedigreePicture(): boolean {
    if (!this.pedigree || !this.pedigree.length) return false;

    const pigeon = this.pedigree[0];
    return this.pigeonManager.HasPicturePedigree(pigeon.pictures);
  }

  get HasPedigreePdf(): boolean {
    if (!this.pedigree || !this.pedigree.length) return false;

    const pigeon = this.pedigree[0];
    return this.pigeonManager.HasPdfPedigree(pigeon.pictures);
  }

  get HasPedigreeDiagramData(): boolean {
    if (!this.pedigree) return false;

    return this.pedigree.length > 1;
  }

  get IsOwnerOfThisPigeon(): boolean {
    return this.pedigree?.[0].userId == this.$auth.User?.id;
  }

  mounted() {
    this.selectedViewBy = this.viewBy;
    this.selectedViewByOrientation = this.viewByOrientation;
  }

  @Watch(nameof<ProductPedigreeView>("pigeonKey"), { immediate: true })
  ObservePedigreeData() {
    if (!this.pigeonKey) return;

    this.FetchPedigree(this.pedigreeGeneration);
  }

  @Watch(nameof<ProductPedigreeView>("pedigree"), { immediate: true })
  ObserveViewByType() {
    if (!this.pedigree || !this.pedigree.length) return;

    const pigeon = this.pedigree[0];

    if (this.selectedViewBy == PedigreeViewTypes.Picture && !this.pigeonManager.HasPicturePedigree(pigeon.pictures)) {
      this.selectedViewBy = PedigreeViewTypes.Pdf;
    }

    if (this.selectedViewBy == PedigreeViewTypes.Pdf && !this.pigeonManager.HasPdfPedigree(pigeon.pictures)) {
      this.selectedViewBy = PedigreeViewTypes.Picture;
    }

    if (this.selectedViewBy == PedigreeViewTypes.Picture && !this.pigeonManager.HasPicturePedigree(pigeon.pictures)) {
      this.selectedViewBy = PedigreeViewTypes.Diagram;
    }
  }

  public FetchPedigree(generation: number): void {
    if (!this.pigeonKey) return;

    this.isLoading = true;
    this.isFaulty = false;

    this.pigeonsService
      .FetchPedigree(this.pigeonKey, generation)
      .then((response) => {
        this.isLoading = false;
        this.isFaulty = false;
        this.pedigree = response.data.value;
      })
      .catch((error) => {
        this.isLoading = false;
        this.isFaulty = true;
      });
  }

  public OnViewBy(value: PedigreeViewTypes): void {
    this.selectedViewBy = value;
  }

  public OnViewByOrientation(value: PedigreeDiagramOrientations): void {
    this.selectedViewByOrientation = value;
  }

  public OnSelectGeneration(selectedGeneration: number): void {
    // A call api is necessary only if the generation is greater than the eager load
    if (selectedGeneration <= this.maxSelectedGeneration) return;

    this.maxSelectedGeneration = selectedGeneration;
    this.FetchPedigree(selectedGeneration);
  }
}
