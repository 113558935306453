import { ISalesService } from "@api/contracts/odata/ISalesService";
import { ISale } from "@api/models/market/ISale";
import { store } from "@market/stores";
import { authStore } from "@market/stores/App.store.modules";
import { IApprovalStore } from "@market/stores/contracts/IApprovalStore";
import { cid, container, Inject } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "approval" = "approval";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class ApprovalStore extends VuexModule implements IApprovalStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private salesService: ISalesService;

  pendingApprovalSales: ISale[] = [];
  approvedSales: ISale[] = [];
  disapprovedSales: ISale[] = [];
  showPendingApprovalTask: boolean = true;

  get pendingApprovalSalesCount(): number {
    return this.pendingApprovalSales?.length ?? 0;
  }

  get approvedSalesCount(): number {
    return this.approvedSales?.length ?? 0;
  }

  get disapprovedSalesCount(): number {
    return this.disapprovedSales?.length ?? 0;
  }

  @Mutation
  SET_PENDING_APPROVAL_SALES(data: ISale[]): void {
    this.pendingApprovalSales = data;
  }

  @Mutation
  SET_APPROVED_SALES(data: ISale[]): void {
    this.approvedSales = data;
  }

  @Mutation
  SET_DISAPPROVED_SALES(data: ISale[]): void {
    this.disapprovedSales = data;
  }

  @Mutation
  SET_SHOW_PENDING_APPROVAL_TASK(show: boolean): void {
    this.showPendingApprovalTask = show;
  }

  @Action
  public DismissPendingApprovalTask() {
    this.showPendingApprovalTask = false;
  }

  @Action
  public async FetchApprovalSales(): Promise<void> {
    const salesService = container.get<ISalesService>(cid.SalesService);

    if (authStore.IsApprover) {
      salesService.FetchAllPendingApprovalSales().then((response) => {
        this.SET_PENDING_APPROVAL_SALES(response.data.value);
      });

      salesService.FetchAllApprovedSales().then((response) => {
        this.SET_APPROVED_SALES(response.data.value);
      });

      salesService.FetchAllDisapprovedSales().then((response) => {
        this.SET_DISAPPROVED_SALES(response.data.value);
      });
    }
  }

  @Action
  public async Reset(): Promise<void> {
    this.SET_PENDING_APPROVAL_SALES([]);
    this.SET_APPROVED_SALES([]);
    this.SET_DISAPPROVED_SALES([]);
  }
}
