import NavAccordion from "@pigeon/components/nav/NavAccordion.vue";
import NavAccordionItem from "@pigeon/components/nav/NavAccordionItem.vue";
import NavBreadcrumb from "@pigeon/components/nav/NavBreadcrumb.vue";
import NavDropdownList from "@pigeon/components/nav/NavDropdownList.vue";
import NavDropdownListItem from "@pigeon/components/nav/NavDropdownListItem.vue";
import NavPanel from "@pigeon/components/nav/NavPanel.vue";
import NavStepper from "@pigeon/components/nav/NavStepper.vue";
import NavStepperStep from "@pigeon/components/nav/NavStepperStep.vue";
import NavStepperVertical from "@pigeon/components/nav/NavStepperVertical.vue";
import NavStepperVerticalStep from "@pigeon/components/nav/NavStepperVerticalStep.vue";
import NavTab from "@pigeon/components/nav/NavTab.vue";
import NavTabBloc from "@pigeon/components/nav/NavTabBloc.vue";
import NavTabs from "@pigeon/components/nav/NavTabs.vue";
import Vue from "vue";

// Nav components
// TODO: import locally
Vue.component("NavBreadcrumb", NavBreadcrumb);
Vue.component("NavTabs", NavTabs);
Vue.component("NavTabBloc", NavTabBloc);
Vue.component("NavTab", NavTab);
Vue.component("NavDropdownList", NavDropdownList);
Vue.component("NavDropdownListItem", NavDropdownListItem);
Vue.component("NavAccordion", NavAccordion);
Vue.component("NavAccordionItem", NavAccordionItem);
Vue.component("NavPanel", NavPanel);
Vue.component("NavStepper", NavStepper);
Vue.component("NavStepperStep", NavStepperStep);
Vue.component("NavStepperVertical", NavStepperVertical);
Vue.component("NavStepperVerticalStep", NavStepperVerticalStep);
