import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";

export enum AlertTypes {
  Info = "info",
  Warning = "warning",
  Danger = "danger",
  Success = "success"
}

@Component
export default class AppAlert extends Vue {
  @Prop({ validator: (value: AlertTypes) => Object.values(AlertTypes).includes(value) })
  readonly type: AlertTypes;
  @Prop()
  readonly icon?: string | string[];
  @Prop({ default: true })
  readonly outline: boolean;
  @Prop({ default: false })
  readonly inline: boolean;
  @Prop()
  readonly linkLabel?: string;
  @Prop()
  readonly linkHref?: string;
  @Prop()
  readonly linkExternal?: boolean;
  @Prop()
  readonly linkTo?: Location;
  @Prop()
  readonly buttonLabel?: string;
  @Prop()
  readonly caption?: string;

  get CssClassAlertType(): string {
    let cssClasses = `alert-${this.type}`;

    if (this.inline) cssClasses += " inline-alert";

    return cssClasses;
  }

  get CssClassAlert(): string {
    let cssClasses = this.CssClassAlertType;

    if (this.outline) {
      cssClasses += " outline-alert";
    }
    if (!this.icon) {
      cssClasses += " no-icon-alert";
    }

    return cssClasses;
  }

  @Emit("action")
  public Action(): void {}
}
