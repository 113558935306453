import { IPigeonsService } from "@api/contracts/odata/IPigeonsService";
import { SaleStatus } from "@api/models/market/constants/SaleStatus";
import { IFancier } from "@api/models/market/IFancier";
import { IParentAsset } from "@api/models/market/IParentAsset";
import { IPicture } from "@api/models/market/IPicture";
import { IPigeon } from "@api/models/market/IPigeon";
import { IPrize } from "@api/models/market/IPrize";
import { IRankingAsset } from "@api/models/market/IRankingAsset";
import { ISale } from "@api/models/market/ISale";
import { IStrain } from "@api/models/market/IStrain";
import { IUser } from "@api/models/market/IUser";
import { IVideo } from "@api/models/market/IVideo";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { IODataValueResponse } from "@api/models/shared/IODataValueResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore } from "@market/stores/App.store.modules";
import { CatalogFilterTypes } from "@pigeon/enumerations/CatalogFilterTypes";
import { CatalogSortTypes } from "@pigeon/enumerations/CatalogSortTypes";
import { IPaginate } from "@pigeon/models/IPaginate";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, FilterBuilder, ODataQuery, odataQuery } from "odata-fluent-query";

type OrderDirection = "asc" | "desc" | undefined;

export class PigeonsService extends BaseODataService implements IPigeonsService {
  constructor() {
    super();
  }

  //#region Sort
  public OrderBy(query: ODataQuery<IPigeon>, sort?: string): ODataQuery<IPigeon> {
    if (!sort) return query;

    const sortParams: string[] = sort.split(";");
    const orderPropertyName: string = sortParams[0];
    const orderDirection: string = sortParams[1];

    if (orderPropertyName == CatalogSortTypes.Name) {
      query.orderBy("name", orderDirection as OrderDirection);
    } else if (orderPropertyName == CatalogSortTypes.Ring) {
      query.orderBy("ring", orderDirection as OrderDirection);
    } else if (orderPropertyName == CatalogSortTypes.SaleEndDate) {
      query.orderBy("createdAt", orderDirection as OrderDirection);
    }

    return query;
  }
  //#endregion

  //#region Filter
  public FilterBy(query: ODataQuery<IPigeon>, filters?: Map<CatalogFilterTypes, string[]>): ODataQuery<IPigeon> {
    if (!filters) return query;

    if (filters.has(CatalogFilterTypes.Contributor)) {
      query = this.FilterQueryByContributor(query, filters.get(CatalogFilterTypes.Contributor));
    }

    if (filters.has(CatalogFilterTypes.Sex)) {
      query = this.FilterQueryBySex(query, filters.get(CatalogFilterTypes.Sex));
    }

    if (filters.has(CatalogFilterTypes.Discipline)) {
      query = this.FilterQueryByDiscipline(query, filters.get(CatalogFilterTypes.Discipline));
    }

    if (filters.has(CatalogFilterTypes.Country)) {
      query = this.FilterQueryByCountry(query, filters.get(CatalogFilterTypes.Country));
    }

    if (filters.has(CatalogFilterTypes.YearMin)) {
      query = this.FilterQueryByYearMin(query, filters.get(CatalogFilterTypes.YearMin)?.shift() as string | undefined);
    }

    if (filters.has(CatalogFilterTypes.YearMax)) {
      query = this.FilterQueryByYearMax(query, filters.get(CatalogFilterTypes.YearMax)?.shift() as string | undefined);
    }

    return query;
  }

  private FilterQueryByContributor(query: ODataQuery<IPigeon>, filterValues?: string[]): ODataQuery<IPigeon> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((p) => p.userId.in(filterValues));
  }

  private FilterQueryBySex(query: ODataQuery<IPigeon>, filterValues?: string[]): ODataQuery<IPigeon> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((p) => p.sex.in(filterValues));
  }

  private FilterQueryByDiscipline(query: ODataQuery<IPigeon>, filterValues?: string[]): ODataQuery<IPigeon> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((p) => p.discipline.in(filterValues));
  }

  private FilterQueryByCountry(query: ODataQuery<IPigeon>, filterValues?: string[]): ODataQuery<IPigeon> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((p) => p.ringArea.in(filterValues));
  }

  private FilterQueryByYearMin(query: ODataQuery<IPigeon>, filterValue?: string): ODataQuery<IPigeon> {
    if (!filterValue) return query;

    const yearMinFilterValue = parseInt(filterValue);
    return query.filter((p) => p.birthYear.biggerOrEqualThan(yearMinFilterValue));
  }

  private FilterQueryByYearMax(query: ODataQuery<IPigeon>, filterValue?: string): ODataQuery<IPigeon> {
    if (!filterValue) return query;

    const yearMaxFilterValue = parseInt(filterValue);
    return query.filter((p) => p.birthYear.lessOrEqualThan(yearMaxFilterValue));
  }
  //#endregion

  //#region Search
  public SearchBy(query: ODataQuery<IPigeon>, search?: string): ODataQuery<IPigeon> {
    if (!search) return query;

    // Filter by Ring
    // Or
    // Filter by Name
    // let searchFilters = `name eq '${query}' or ring eq '${query}'`; // search by equals

    // prettier-ignore
    return query.filter((p) =>
      p.name.contains(search).or(p.ring.contains(search))
    );
  }
  //#endregion
  public Insert(pigeon: IPigeon): AxiosPromise<IPigeon> {
    return this.Client().post("Pigeons", pigeon);
  }

  public Update(pigeonKey: number, pigeon: IPigeon): AxiosPromise<void> {
    return this.Client().put(`Pigeons(${pigeonKey})`, pigeon);
  }

  public Patch(pigeonKey: number, pigeon: Partial<IPigeon>): AxiosPromise<void> {
    return this.Client().patch(`Pigeons(${pigeonKey})`, pigeon);
  }

  public Delete(pigeonKey: number): AxiosPromise<void> {
    return this.Client().delete(`Pigeons(${pigeonKey})`);
  }

  public Fetch(pigeonKey: number): AxiosPromise<IPigeon> {
    return this.Client().get(`Pigeons(${pigeonKey})`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    const query = odataQuery<IPigeon>().count();

    return this.Client().get(`Pigeons?${query.toString()}`);
  }

  public FetchAllByVendor(vendorKey: string): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    const query = odataQuery<IPigeon>()
      .filter((p) => p.userId.equals(vendorKey, { ignoreGuid: true })) // .or(p.sales.any((s: FilterBuilder<ISale>) => s.userId.equals(vendorKey, {ignoreGuid: true})))) // ignore data where pigeon has not been created by current user
      .expand("sales", (s: ExpandObjectQuery<ISale>) =>
        s.select("id", "flagApproved", "status", "startDate", "endDate")
      ) // include sale to check edit permission
      .orderBy("createdAt", "desc");

    return this.Client().get(`Pigeons?${query.toString()}`);
  }

  public FetchAllReproducersByVendor(vendorKey: string): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    const query = odataQuery<IPigeon>()
      .filter((p) => p.userId.equals(vendorKey, { ignoreGuid: true }))
      .filter((p) => p.birthYear.lessThan(new Date().getFullYear()))
      .filter((p) => p.sales.empty())
      .filter((p) => p.package.isNull());

    return this.Client().get(`Pigeons?${query.toString()}`);
  }

  public FetchAllReproducersAvailableByVendor(vendorKey: string): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    const query = odataQuery<IPigeon>()
      .filter((p) => p.userId.equals(vendorKey, { ignoreGuid: true }))
      .filter((p) => p.birthYear.lessThan(new Date().getFullYear()))
      .filter((p) => p.sales.empty().or(p.sales.any((s: FilterBuilder<ISale>) => s.status.in([SaleStatus.Unsold]))))
      .filter((p) => p.package.isNull());

    return this.Client().get(`Pigeons?${query.toString()}`);
  }

  public FetchCatalog(
    paginate?: IPaginate,
    sort?: string,
    filters?: Map<CatalogFilterTypes, string[]>,
    search?: string
  ): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    // prettier-ignore
    let query = odataQuery<IPigeon>();

    if (search) query = this.SearchBy(query, search);
    if (filters) query = this.FilterBy(query, filters);

    query = query
      .select("id", "name", "ring", "sex", "ringArea", "asset", "assetText", "flagApproved", "userId")
      .expand("user", (u: ExpandObjectQuery<IUser>) => u.select("firstname", "lastname"))
      .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f.select("id", "name"))
      .expand("strain", (s: ExpandObjectQuery<IStrain>) => s.select("id", "name"))
      .expand("pictures", (p: ExpandObjectQuery<IPicture>) => p.select("filename", "type", "url", "urlWebOptimized"))
      .expand("videos", (v: ExpandObjectQuery<IVideo>) => v.select("filename", "type", "url", "urlWebOptimized"))
      .expand("prizes", (p: ExpandObjectQuery<IPrize>) =>
        p.select("rank", "order", "distance", "participants", "area", "year", "place", "taggedAsAsset").orderBy("order")
      )
      .expand("assetRankings", (ar: ExpandObjectQuery<IRankingAsset>) => ar.select("areaType"))
      .expand("assetParents", (ap: ExpandObjectQuery<IParentAsset>) =>
        ap.select("parentId").expand("parent", (p: ExpandObjectQuery<IPigeon>) =>
          p
            .select("id", "name", "ring", "sex", "asset", "assetText")
            .expand("prizes", (p: ExpandObjectQuery<IPrize>) =>
              p
                .select("rank", "order", "distance", "participants", "area", "year", "place", "taggedAsAsset")
                .orderBy("order")
            )
            .expand("assetRankings", (ar: ExpandObjectQuery<IRankingAsset>) => ar.select("areaType"))
        )
      )
      .count();

    if (sort) query = this.OrderBy(query, sort);
    if (paginate) query = query.paginate(paginate.pageSize, paginate.page);

    return this.Client().get(`Pigeons?${query.toString()}`);
  }

  public FetchSheet(pigeonKey: number): AxiosPromise<IPigeon> {
    // prettier-ignore
    const query = odataQuery<IPigeon>()
      .expand("fancier")
      .expand("strain")
      .expand("pictures");

    return this.Client().get(`Pigeons(${pigeonKey})?${query.toString()}`);
  }

  public FetchProduct(pigeonKey: number): AxiosPromise<IPigeon> {
    // prettier-ignore
    const query = odataQuery<IPigeon>()
      .expand("sales", (s: ExpandObjectQuery<ISale>) => s
        .select("id", "flagApproved", "status", "startDate", "endDate")  // include sale to check edit permission
      )
      .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
        .select("id", "name", "createdAt", "createdBy")
      )
      .expand("strain", (s: ExpandObjectQuery<IStrain>) => s
        .select("id", "name")
      )
      .expand("pictures", (p: ExpandObjectQuery<IPicture>) => p 
        .select("id", "filename", "type", "url", "urlWebOptimized")
      )
      .expand("videos", (v: ExpandObjectQuery<IVideo>) => v 
        .select("id", "filename", "type", "url", "urlWebOptimized")
      )
      .expand("prizes", (p: ExpandObjectQuery<IPrize>) => p 
        .select("id", "rank", "order", "distance", "participants", "area", "year", "place", "taggedAsAsset")
        .orderBy("order")
      )
      .expand("father", (f: ExpandObjectQuery<IPigeon>) => f
        .select("id", "name", "ring", "sex", "userId")
      )
      .expand("mother", (m: ExpandObjectQuery<IPigeon>) => m
        .select("id", "name", "ring", "sex", "userId")
      )
      .expand("assetRankings", (ar: ExpandObjectQuery<IRankingAsset>) => ar
        .select("areaType")
      )
      .expand("assetParents", (ap: ExpandObjectQuery<IParentAsset>) => ap
        .select("parentId")
        .expand("parent", (p: ExpandObjectQuery<IPigeon>) => p
          .select("id", "name" , "ring", "sex", "asset", "assetText")
          .expand("prizes", (p: ExpandObjectQuery<IPrize>) => p
            .select("id", "rank", "order", "distance", "participants", "area", "year", "place", "taggedAsAsset")
            .orderBy("order")
          )
          .expand("assetRankings", (ar: ExpandObjectQuery<IRankingAsset>) => ar
            .select("areaType")
          )
        )
      );

    return this.Client().get(`Pigeons(${pigeonKey})?${query.toString()}`);
  }

  public FetchParentSummary(parentKey: number): AxiosPromise<IPigeon> {
    // prettier-ignore
    const query = odataQuery<IPigeon>()
      .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
        .select("id", "name")
      )
      .expand("strain", (s: ExpandObjectQuery<IStrain>) => s
        .select("id", "name")
      )
      .expand("pictures", (p: ExpandObjectQuery<IPicture>) => p 
        .select("id", "filename", "type", "url", "urlWebOptimized")
      )
      .expand("videos", (v: ExpandObjectQuery<IVideo>) => v 
        .select("id", "filename", "type", "url", "urlWebOptimized")
      )
      .expand("prizes", (p: ExpandObjectQuery<IPrize>) => p 
        .select("id", "rank", "order", "distance", "participants", "area", "year", "place", "taggedAsAsset")
        .orderBy("order")
      );

    return this.Client().get(`Pigeons(${parentKey})?${query.toString()}`);
  }

  public FetchChildren(parentKey: number): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    const query = odataQuery<IPigeon>()
      .select("id", "name", "ring")
      .filter((p) => p.fatherId.equals(parentKey).or(p.motherId.equals(parentKey)))
      .count();

    return this.Client().get(`Pigeons?${query.toString()}`);
  }

  public FetchWithParents(pigeonKey: number): AxiosPromise<IPigeon> {
    // prettier-ignore
    const query = odataQuery<IPigeon>()
      .select("id", "name", "ring", "sex", "userId")
      .expand("father", (f: ExpandObjectQuery<IPigeon>) => f
        .select("id", "name", "ring", "sex", "userId")
      )
      .expand("mother", (m: ExpandObjectQuery<IPigeon>) => m
        .select("id", "name", "ring", "sex", "userId")
      )

    return this.Client().get(`Pigeons(${pigeonKey})?${query.toString()}`);
  }

  public FetchWithPrizes(pigeonKey: number): AxiosPromise<IPigeon> {
    // prettier-ignore
    const query = odataQuery<IPigeon>()
      .select("id", "name", "ring")
      .expand("prizes", (p: ExpandObjectQuery<IPrize>) => p
        .select("rank", "order", "distance", "participants", "area", "year", "place", "taggedAsAsset")
        .orderBy("order")
      )

    return this.Client().get(`Pigeons(${pigeonKey})/?${query.toString()}`);
  }

  public FetchPedigree(pigeonKey: number, generation = 2): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    // prettier-ignore
    const query = odataQuery<IPigeon>()
      .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
        .select("name")
      )
      .expand("strain", (s: ExpandObjectQuery<IStrain>) => s
        .select("name")
      )
      .expand("pictures", (p: ExpandObjectQuery<IPicture>) => p 
        .select("filename", "type", "url", "urlWebOptimized")
      )
      .expand("prizes", (p: ExpandObjectQuery<IPrize>) => p 
        .select("rank", "order", "distance", "participants", "area", "year", "place", "taggedAsAsset")
        .orderBy("order")
      )
      .expand("father", (f: ExpandObjectQuery<IPigeon>) => f
        .select("id", "name", "ring", "sex", "userId")
      )
      .expand("mother", (m: ExpandObjectQuery<IPigeon>) => m
        .select("id", "name", "ring", "sex", "userId")
      )
      .expand("assetRankings", (ar: ExpandObjectQuery<IRankingAsset>) => ar
        .select("areaType")
      )
      .expand("assetParents", (ap: ExpandObjectQuery<IParentAsset>) => ap
        .select("parentId")
        .expand("parent", (p: ExpandObjectQuery<IPigeon>) => p
          .select("id", "name" , "ring", "sex", "asset", "assetText")
          .expand("prizes", (p: ExpandObjectQuery<IPrize>) => p
            .select("rank", "order", "distance", "participants", "area", "year", "place", "taggedAsAsset")
            .orderBy("order")
          )
          .expand("assetRankings", (ar: ExpandObjectQuery<IRankingAsset>) => ar
            .select("areaType")
          )
        )
      );

    return this.Client().get(
      `Pigeons(${pigeonKey})/MarketService.Pedigree(generation=${generation})?${query.toString()}`
    );
  }

  public FetchPedigreeIdentityOnly(pigeonKey: number, generation = 2): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    const query = `Pigeons(${pigeonKey})/MarketService.PedigreeIdentity(generation=${generation})`;

    return this.Client().get(query);
  }

  public DetermineRelationship(pigeonKey: number, parentKey: number): AxiosPromise<string> {
    return super.Client().get(`Pigeons(${pigeonKey})/MarketService.FamilyRelationship(ParentKey=${parentKey})`);
  }

  public FetchAllPendingApprovalPigeons(): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    if (!authStore.IsApprover) return Promise.reject("Not authorized");

    const query = odataQuery<IPigeon>()
      .filter((p) => p.flagApproved.isNull())
      .expand("fancier")
      .expand("user")
      .orderBy("createdAt", "desc")
      .count();

    return this.Client().get(`Pigeons?${query.toString()}`);
  }

  public FetchAllApprovedPigeons(): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    if (!authStore.IsApprover) return Promise.reject("Not authorized");

    const query = odataQuery<IPigeon>()
      .filter((p) => p.flagApproved.equals(true))
      .expand("fancier")
      .expand("user")
      .orderBy("approvedAt", "desc")
      .count();

    return this.Client().get(`Pigeons?${query.toString()}`);
  }

  public FetchAllDisapprovedPigeons(): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    if (!authStore.IsApprover) return Promise.reject("Not authorized");

    const query = odataQuery<IPigeon>()
      .filter((p) => p.flagApproved.equals(false))
      .expand("fancier")
      .expand("user")
      .orderBy("approvedAt", "desc")
      .count();

    return this.Client().get(`Pigeons?${query.toString()}`);
  }

  public GetNumberViews(): AxiosPromise<IODataValueResponse<number>> {
    return this.Client().get("Pigeons/MarketService.NumberViews");
  }

  public GetNumberPedigrees(): AxiosPromise<IODataValueResponse<number>> {
    return this.Client().get("Pigeons/MarketService.NumberPedigrees");
  }

  public GetNumberPosts(): AxiosPromise<IODataValueResponse<number>> {
    return this.Client().get("Pigeons/MarketService.NumberPosts");
  }

  public FindPigeonByRingAndByUser(
    pigeonRing: string,
    userId: string
  ): AxiosPromise<IODataCollectionResponse<IPigeon>> {
    // prettier-ignore
    const query = odataQuery<IPigeon>()
      .filter(p => p.userId.equals(userId, { ignoreGuid: true }))
      .filter(p => p.ring.equals(pigeonRing))
      .count();

    return this.Client().get(`Pigeons?${query.toString()}`);
  }
}
