import { IPigeon } from "@api/models/market/IPigeon";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { Inject } from "inversify-props";
// import pdf from "vue-pdf";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    // Pdf: pdf
  }
})
// See: https://github.com/FranckFreiburger/vue-pdf
// Note: CORS policy must be configured (on storage via CORS settings)
export default class PigeonPedigreePdf extends Vue {
  @Prop()
  readonly pigeon: IPigeon;
  @Prop({ default: 1 })
  readonly pages: number;

  @Inject()
  private pigeonManager: IPigeonManager;

  get PedigreePdfUrl(): string {
    if (!this.pigeon) {
      return this.pigeonManager.defaultPedigreePdf;
    }

    return this.pigeonManager.GetPdfPedigree(this.pigeon.pictures);
  }
}
