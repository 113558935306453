import { PictureTypes } from "@api/models/market/constants/PictureTypes";
import { IPicture } from "@api/models/market/IPicture";
import router from "@market/routers";
import { AppRoutes } from "@market/routers/App.routes";
import { IShopManager } from "@pigeon/services/contracts/IShopManager";

export class ShopManager implements IShopManager {
  readonly defaultShopPicture = require("@market/assets/images/pictures/sell-background.webp");

  public GetShopUrl(shopId: string | undefined): string {
    if (!shopId) throw new Error("Invalid parameter exception: shopId cannot be undefined");

    const route = router.resolve({ name: AppRoutes.BuyShop, params: { shopKey: shopId } });
    return `${window.location.protocol}//${window.location.host}${route.href}`;
  }

  //#region Picture
  public GetPictureRemoteUrl(relativeUrl: string | null | undefined): string {
    if (!relativeUrl) return this.defaultShopPicture;

    // test if parameter is a remote url
    if (/(http(s?)):\/\//gi.test(relativeUrl)) return relativeUrl;

    // test if parameter is a inline data image
    if (/(data):/gi.test(relativeUrl)) return relativeUrl;

    return `${process.env.VUE_APP_AZURE_STORAGE_HOST}${relativeUrl}`;
  }

  public GetShopCoverPicture(pictures?: IPicture[], useRawPicture = false): string {
    if (!pictures || !pictures.length) {
      return `${this.defaultShopPicture}`;
    }

    const shopPicture: IPicture | undefined = pictures.find((picture) => picture.type === PictureTypes.Shop);
    if (!shopPicture) return this.defaultShopPicture;

    const pictureUrl = shopPicture.urlWebOptimized && !useRawPicture ? shopPicture.urlWebOptimized : shopPicture.url;
    if (!pictureUrl) return this.defaultShopPicture;

    return this.GetPictureRemoteUrl(pictureUrl);
  }

  public HasPicture(pictures: IPicture[] | undefined, pictureType: PictureTypes): boolean {
    if (!pictures || pictures.length < 1) {
      return false;
    }

    return pictures.some((picture) => picture.type === pictureType);
  }
  //#endregion
}
