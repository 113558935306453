import { IPigeon } from "@api/models/market/IPigeon";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { Inject } from "inversify-props";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PigeonPicture extends Vue {
  @Prop()
  readonly pigeon: IPigeon;

  @Inject()
  private pigeonManager: IPigeonManager;

  forceFallbackPicture: boolean = false;

  get PigeonPicture(): string {
    if (!this.pigeon) return "";

    return this.pigeonManager.GetPictureThumbnail(this.pigeon.pictures, this.forceFallbackPicture);
  }

  public OnPictureError(): void {
    this.forceFallbackPicture = true;
  }
}
