import { ADMINISTRATOR_ID } from "@api/models/market/constants/AppRoles";
import { PaymentMethodTypes } from "@api/models/market/constants/PaymentMethodTypes";
import { SaleStatus } from "@api/models/market/constants/SaleStatus";
import { SaleTypes } from "@api/models/market/constants/SaleTypes";
import { TransferMethodTypes } from "@api/models/market/constants/TransferMethodTypes";
import { IBid } from "@api/models/market/IBid";
import { IDeposit } from "@api/models/market/IDeposit";
import { ISale } from "@api/models/market/ISale";
import { ISettlement } from "@api/models/market/ISettlement";
import { IUser } from "@api/models/market/IUser";
import i18n from "@market/i18n";
import { ITimelineEvent } from "@pigeon/models/ITimelineEvent";
import { IEventManager } from "./contracts/IEventManager";

export class EventManager implements IEventManager {
  //#region Supervise
  public buildTimelineEvents(sale: ISale, deposit?: IDeposit, settlement?: ISettlement): ITimelineEvent[] {
    const timelineEvents: (ITimelineEvent | undefined)[] = [
      this.BuildSaleCreatedEvent(sale),
      this.BuildSaleApprovedEvent(sale),
      this.BuildSaleStartEvent(sale),
      this.BuildFirstBidEvent(sale),
      this.BuildLastBidEvent(sale),
      this.BuildSaleEndEvent(sale),
      this.BuildOrderEvent(sale),
      this.BuildDepositEvent(sale, deposit),
      this.BuildSettlementEvent(sale, settlement),
      this.BuildPaymentEvent(sale),
      this.BuildTransferEvent(sale),
      this.BuildShippingEvent(sale),
      this.BuildSaleStatusModifiedEvent(sale)
    ];

    const filteredTimelineEvents: ITimelineEvent[] = timelineEvents.filter((event) => !!event) as ITimelineEvent[];
    const orderedTimelineEvents: ITimelineEvent[] = filteredTimelineEvents.sort(
      (eventA, eventB) => eventA.date.getTime() - eventB.date.getTime()
    );

    return orderedTimelineEvents;
  }

  private BuildSaleCreatedEvent(sale: ISale): ITimelineEvent | undefined {
    if (!sale.createdAt) return;

    return {
      // icon: 'plus-circle',
      date: new Date(sale.createdAt),
      title: i18n.t("event.sale_created") as string,
      description: undefined
    };
  }

  private BuildSaleApprovedEvent(sale: ISale): ITimelineEvent {
    const approvalStatus = i18n.t(`enum.flag_approved.${sale.flagApproved}`) as string;
    const title = i18n.t("event.sale_approval_by_staff_$approval-status", {
      ["approval-status"]: approvalStatus.toLowerCase()
    }) as string;
    const description = i18n.t(`event.sale_approval_${sale.flagApproved}`) as string;
    return {
      // icon: 'calendar',
      date: new Date(sale.startDate),
      title: title,
      description: description
    };
  }

  private BuildSaleStartEvent(sale: ISale): ITimelineEvent | undefined {
    if (!sale.approvedAt || !sale.flagApproved) return;

    const startDate = new Date(sale.startDate);
    const approvalDate = new Date(sale.approvedAt);
    const actualStartDate = approvalDate > startDate ? approvalDate : startDate;
    return {
      // icon: 'calendar',
      date: actualStartDate,
      title:
        sale.type === SaleTypes.Bid
          ? (i18n.t("event.auction_started") as string)
          : (i18n.t("event.sale_started") as string),
      description: undefined
    };
  }

  private BuildSaleEndEvent(sale: ISale): ITimelineEvent | undefined {
    if (!sale.endDate) return;

    const endDate = new Date(sale.endDate);
    let orderDate = undefined;
    if (sale.orderLine?.order) orderDate = new Date(sale.orderLine?.order?.orderDate);

    let actualEndDate = orderDate && orderDate < endDate ? orderDate : endDate;
    if (sale.type == SaleTypes.Bid) actualEndDate = endDate;

    return actualEndDate > new Date()
      ? undefined
      : {
          // icon: 'calendar',
          date: actualEndDate,
          title:
            sale.type === SaleTypes.Bid
              ? (i18n.t("event.auction_closed") as string)
              : (i18n.t("event.sale_ended") as string),
          description: undefined
        };
  }

  private BuildFirstBidEvent(sale: ISale): ITimelineEvent | undefined {
    if (sale.type != SaleTypes.Bid || !sale.bids?.length) return;

    const firstBid: IBid = sale.bids[sale.bids.length - 1];
    const bidder: IUser | undefined = firstBid?.user;
    let description = i18n.n(firstBid.amount, "currency");

    if (bidder) {
      description += ` ${i18n.t("event.posted_by")} ${bidder.firstname} ${bidder.lastname?.toUpperCase()}`;
      if (bidder.buyerAlias) description += ` | ${bidder?.buyerAlias}`;
    }

    return {
      // icon: 'gavel',
      date: new Date(firstBid.bidDate),
      title: i18n.t("event.first_bid") as string,
      description: description
    };
  }

  private BuildLastBidEvent(sale: ISale): ITimelineEvent | undefined {
    if (sale.type != SaleTypes.Bid || !sale.bids?.length || sale.bids.length <= 1) return;

    const lastBid: IBid = sale.bids[0];
    const bidder: IUser | undefined = lastBid?.user;
    let description = i18n.n(lastBid.amount, "currency");

    if (bidder) {
      description += ` ${i18n.t("event.posted_by")} ${bidder.firstname} ${bidder.lastname?.toUpperCase()}`;
      if (bidder.buyerAlias) description += ` | ${bidder?.buyerAlias}`;
    }

    return {
      // icon: 'gavel',
      date: new Date(lastBid.bidDate),
      title: i18n.t("event.last_bid") as string,
      description: description
    };
  }

  private BuildOrderEvent(sale: ISale): ITimelineEvent | undefined {
    if (!sale.orderLine?.order) return;

    const orderDate = new Date(sale.orderLine?.order?.orderDate);
    return {
      // icon: 'file-invoice',
      date: orderDate,
      title: i18n.t("event.registered_order") as string,
      description: undefined
    };
  }

  private BuildDepositEvent(sale: ISale, deposit?: IDeposit): ITimelineEvent | undefined {
    if (!deposit) return;

    const collectedDate = new Date(deposit.paymentDate);
    const description = sale.userId
      ? i18n.t("event.collected_by") + " " + this.BuildCollectedByDescription(sale.userId, deposit.paymentCollectedBy)
      : undefined;

    return {
      // icon: 'hand-holding-usd',
      date: collectedDate,
      title: i18n.t("event.deposit_collected") as string,
      description: description
    };
  }

  private BuildSettlementEvent(sale: ISale, settlement?: ISettlement): ITimelineEvent | undefined {
    if (!settlement) return;

    const collectedDate = new Date(settlement.paymentDate);
    const description = sale.userId
      ? i18n.t("event.collected_by") +
        " " +
        this.BuildCollectedByDescription(sale.userId, settlement.paymentCollectedBy)
      : undefined;

    return {
      // icon: 'hand-holding-usd',
      date: collectedDate,
      title: i18n.t("event.settlement_collected") as string,
      description: description
    };
  }

  private BuildCollectedByDescription(vendorId: string, paymentCollectedBy: string | undefined): string | undefined {
    if (!vendorId || !paymentCollectedBy) return;

    switch (paymentCollectedBy) {
      case ADMINISTRATOR_ID:
        return i18n.t("enum.payment_agent.platform") as string;

      case vendorId:
        return i18n.t("enum.payment_agent.vendor") as string;

      default:
        return i18n.t("enum.payment_agent.undefined") as string;
    }
  }

  private BuildPaymentEvent(sale: ISale): ITimelineEvent | undefined {
    if (!sale.orderLine?.order?.paymentDate) return;

    const paymentDate = new Date(sale.orderLine.order.paymentDate);

    const paymentStatus = i18n.t(`enum.payment_status.${sale.orderLine.order.paymentStatus}`) as string;
    const title = i18n.t("event.payment_$payment-status", {
      ["payment-status"]: paymentStatus
    }) as string;

    let paymentBy =
      sale.orderLine.order.paymentMethod == PaymentMethodTypes.Cash
        ? i18n.t("event.payment_in_label") + " "
        : i18n.t("event.payment_by_label") + " ";
    paymentBy += i18n.t(`enum.payment_method.${sale.orderLine.order.paymentMethod}`) as string;
    const paymentVia = sale.userId
      ? i18n.t("event.payment_collected_via") +
        " " +
        this.BuildCollectedByDescription(sale.userId, sale.orderLine.order.paymentCollectedBy)
      : undefined;
    const description = `${paymentBy?.toLowerCase()} ${paymentVia}`;

    return {
      // icon: 'money-check',
      date: paymentDate,
      title: title,
      description: description
    };
  }

  private BuildTransferEvent(sale: ISale): ITimelineEvent | undefined {
    if (!sale.orderLine?.order?.transferDate) return;

    const transferDate = new Date(sale.orderLine.order.transferDate);

    const transferStatus = i18n.t(`enum.transfer_status.${sale.orderLine.order.transferStatus}`) as string;
    const title = i18n.t("event.payment_$transfer-status", {
      ["transfer-status"]: transferStatus
    }) as string;

    let paymentBy =
      sale.orderLine.order.transferMethod == TransferMethodTypes.Cash
        ? i18n.t("event.payment_in_label") + " "
        : i18n.t("event.payment_by_label") + " ";
    paymentBy += i18n.t(`enum.transfer_method.${sale.orderLine.order.transferMethod}`) as string;
    const paymentVia = sale.userId
      ? i18n.t("event.payment_collected_via") +
        " " +
        this.BuildCollectedByDescription(sale.userId, sale.orderLine.order.paymentTransferredBy)
      : undefined;
    const description = `${paymentBy} ${paymentVia}`;

    return {
      // icon: 'money-check',
      date: transferDate,
      title: title,
      description: description
    };
  }

  private BuildShippingEvent(sale: ISale): ITimelineEvent | undefined {
    if (!sale.orderLine?.shippingDate) return;

    const shippingDate = new Date(sale.orderLine?.shippingDate);
    const shippingStatus = i18n.t(`enum.shipping_status.${sale.orderLine.shippingStatus}`) as string;
    const title = i18n.t("event.shipping_$shipping-status", {
      ["shipping-status"]: shippingStatus.toLowerCase()
    }) as string;

    return {
      // icon: shippingManager.GetShippingIcon(sale),
      date: shippingDate,
      title: title,
      description: undefined
    };
  }

  private BuildSaleStatusModifiedEvent(sale: ISale): ITimelineEvent | undefined {
    if (!sale.modifiedAt) return;

    let statusTitle;
    switch (sale.status) {
      case SaleStatus.OnSale:
        // this case is handled by other event
        return;

      case SaleStatus.Sold:
        // this case is handled by other event
        return;

      case SaleStatus.OnHold:
        statusTitle = "sale_onhold";
        break;

      case SaleStatus.Removed:
        statusTitle = "sale_removed";
        break;

      case SaleStatus.Suspended:
        statusTitle = "sale_suspended";
        break;

      case SaleStatus.Stopped:
        statusTitle = "sale_stopped";
        break;

      case SaleStatus.Reserved:
        statusTitle = "sale_reserved";
        break;

      case SaleStatus.Unsold:
        statusTitle = "sale_unsold";
        break;

      default:
        statusTitle = "sale_modified";
        break;
    }
    return {
      // icon: 'handshake',
      date: new Date((sale.modifiedAt || sale.createdAt) as string | Date),
      title: `${i18n.t(`event.status_updated`)}${i18n.t(`event.${statusTitle}`)}`,
      description: undefined
    };
  }
  //#endregion
}
