import { ISettlementsService } from "@api/contracts/odata/ISettlementsService";
import { IPackage } from "@api/models/market/IPackage";
import { IPigeon } from "@api/models/market/IPigeon";
import { ISale } from "@api/models/market/ISale";
import { ISettlement } from "@api/models/market/ISettlement";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, settlementStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, FilterDate, FilterString, odataQuery } from "odata-fluent-query";

export class SettlementsService extends BaseODataService implements ISettlementsService {
  constructor() {
    super();
  }

  public Insert(settlement: ISettlement): AxiosPromise<ISettlement> {
    return this.Client().post("Settlements", settlement);
  }

  public Update(settlementKey: string, settlement: ISettlement): AxiosPromise<void> {
    return this.Client().put(`Settlements('${settlementKey}')`, settlement);
  }

  public Patch(settlementKey: string, settlement: Partial<ISettlement>): AxiosPromise<void> {
    return this.Client().patch(`Settlements('${settlementKey}')`, settlement);
  }

  public PatchAsProcessed(settlementKey: string, processed = true): AxiosPromise<void> {
    settlementStore.RemovePendingSettlements(settlementKey);
    return this.Client().patch(`Settlements('${settlementKey}')`, { flagProcessed: processed } as Partial<ISettlement>);
  }

  public Delete(settlementKey: string): AxiosPromise<void> {
    return this.Client().delete(`Settlements('${settlementKey}')`);
  }

  public DeleteSoft(settlementKey: string): AxiosPromise<void> {
    return this.Client().patch(`Settlements('${settlementKey}')`, { flagSoftDeleted: true } as Partial<ISettlement>);
  }

  public Fetch(settlementKey: string): AxiosPromise<ISettlement> {
    let query = odataQuery<ISettlement>();

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));

    return this.Client().get(`Settlements('${settlementKey}')?${query.toString()}`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<ISettlement>> {
    let query = odataQuery<ISettlement>().orderBy("paymentDate", "desc");

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));

    return this.Client().get(`Settlements?${query.toString()}`);
  }

  public FetchAllSettlements(fromDate?: Date, toDate?: Date): AxiosPromise<IODataCollectionResponse<ISettlement>> {
    let query = odataQuery<ISettlement>().orderBy("paymentDate", "desc");

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));
    if (fromDate)
      query = query.filter((s) => (s.paymentDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((s) => (s.paymentDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`Settlements?${query.toString()}`);
  }

  public FetchAllPendingProcessing(): AxiosPromise<IODataCollectionResponse<ISettlement>> {
    let query = odataQuery<ISettlement>()
      .filter((s) => s.flagProcessed.isNull())
      .orderBy("paymentDate", "desc");

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));

    return this.Client().get(`Settlements?${query.toString()}`);
  }

  public FetchAllWithSaleProduct(): AxiosPromise<IODataCollectionResponse<ISettlement>> {
    let query = odataQuery<ISettlement>();

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));

    query = query.orderBy("paymentDate", "desc").expand("sale", (s: ExpandObjectQuery<ISale>) =>
      s
        .select("id", "type", "product", "price", "feesRate", "vatRate", "vatInclusive")
        .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p.select("name", "ring"))
        .expand("package", (p: ExpandObjectQuery<IPackage>) => p.select("name", "discipline"))
    );

    return this.Client().get(`Settlements?${query.toString()}`);
  }
  public FetchAllSettlementsByVendor(
    vendorKey: string,
    from?: Date,
    to?: Date
  ): AxiosPromise<IODataCollectionResponse<ISettlement>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<ISettlement>();

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));
    if (from) query = query.filter((s) => (s.paymentDate as any as FilterDate).isAfterOrEqual(from));
    if (to) query = query.filter((s) => (s.paymentDate as any as FilterDate).isBeforeOrEqual(to));

    query = query
      .filter((s) => (s.vendorId as FilterString).equals(vendorKey, { ignoreGuid: true }))
      .orderBy("paymentDate", "desc");

    return this.Client().get(`Settlements?${query.toString()}`);
  }

  public FetchAllByVendorWithSaleProduct(
    vendorKey: string,
    from?: Date,
    to?: Date
  ): AxiosPromise<IODataCollectionResponse<ISettlement>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<ISettlement>();

    if (!authStore.IsAdministrator) query = query.filter((s) => s.flagSoftDeleted.notEquals(true));
    if (from) query = query.filter((s) => (s.paymentDate as any as FilterDate).isAfterOrEqual(from));
    if (to) query = query.filter((s) => (s.paymentDate as any as FilterDate).isBeforeOrEqual(to));

    query = query
      .filter((s) => s.vendorId.equals(vendorKey, { ignoreGuid: true }))
      .orderBy("paymentDate", "desc")
      .expand("sale", (s: ExpandObjectQuery<ISale>) =>
        s
          .select("id", "type", "product", "price", "feesRate", "vatRate", "vatInclusive")
          .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p.select("name", "ring"))
          .expand("package", (p: ExpandObjectQuery<IPackage>) => p.select("name", "discipline"))
      );

    return this.Client().get(`Settlements?${query.toString()}`);
  }

  public FetchAllSummaryWithUserBySale(saleKey: number): AxiosPromise<IODataCollectionResponse<ISettlement>> {
    const query = odataQuery<ISettlement>()
      .filter((b) => b.saleId.equals(saleKey))
      .select("id", "amount", "paymentDate", "userId")
      .orderBy("paymentDate", "desc")
      .expand("user", (u) => u.select("id", "firstname", "lastname", "buyerAlias", "email", "phoneNumber"));

    return this.Client().get(`Settlements?${query.toString()}`);
  }
}
