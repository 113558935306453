var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{attrs:{"vid":_vm.validationId,"rules":_vm.validationRules,"tag":"div","name":_vm.validationName || _vm.label || _vm.Attrs.name},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"field select-wrapper",class:{
      'is-invalid': _vm.hasError || (errors && errors.length > 0),
      'floating-labels select-wrapper-labeled': _vm.label && _vm.label.length,
      'select-wrapper': !_vm.label || !_vm.label.length,
    }},[_c('select',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.selectedValue),expression:"selectedValue"}],staticClass:"input select-input",class:{ 'boxed-select-input': _vm.isBoxed },attrs:{"data-selected":_vm.HasAnOptionSelected,"id":_vm.id},on:{"blur":function($event){_vm.HasFocus = false},"focus":function($event){_vm.HasFocus = true},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},'select',_vm.Attrs,false),_vm.Listeners),[(_vm.optionsGroups)?_vm._l((Object.keys(_vm.optionsGroups)),function(groupKey){return _c('optgroup',{key:groupKey,attrs:{"label":groupKey}},_vm._l((_vm.optionsGroups[groupKey]),function(option){return _c('option',{key:Array.isArray(option.value) ? option.value.join(';') : option.value,attrs:{"disabled":option.disabled},domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])}),0)}):(_vm.options)?_vm._l((_vm.options),function(option){return _c('option',{key:Array.isArray(option.value) ? option.value.join(';') : option.value,attrs:{"disabled":option.disabled},domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])}):_vm._e()],2),(_vm.label)?_c('label',{staticClass:"label floating-label",class:{
        'is-floating': !_vm.hasFloatingLabel || (_vm.hasFloatingLabel && _vm.HasFocus)
      },attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('button',{staticClass:"btn btn-select btn-link",attrs:{"type":"button","aria-label":_vm.$t('toggle')}},[_c('Icon',{staticClass:"arrow-icon",attrs:{"icon":"angle-down"}})],1)]),(errors && errors.length)?_c('AppError',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }