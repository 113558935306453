import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { RegistrationFactory } from "@api/factories/registrationFactory";
import { IAuthSession } from "@api/models/auth/IAuthSession";
import { IVendorRegistration } from "@api/models/auth/IVendorRegistration";
import { IVendorUpgradeRegistration } from "@api/models/auth/IVendorUpgradeRegistration";
import AccountSignUpDoneView from "@market/pages/views/auth/vendor/AccountSignUpDone.vue";
import { AppRoutes } from "@market/routers/App.routes";
import { appDataStore, authStore } from "@market/stores/App.store.modules";
import { nameof } from "@pigeon/extensions/nameof";
import { DEFAULT_LOCALE } from "@pigeon/i18n/i18n.const";
import { IErrorManager } from "@pigeon/services/contracts/IErrorManager";
import { ErrorManager } from "@pigeon/services/ErrorManager";
import { Inject } from "inversify-props";
import { cloneDeep } from "lodash-es";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    SignUpDone: AccountSignUpDoneView
  }
})
export default class AccountSignUpView extends Vue {
  @Ref()
  readonly refValidation: InstanceType<typeof ValidationObserver>;

  @Inject()
  private accountsService: IAccountsService;

  AppRoutes = AppRoutes;
  registration: IVendorUpgradeRegistration | IVendorRegistration = this.$auth.IsAuthenticated
    ? RegistrationFactory.GetDefaultVendorUpgradeRegistration()
    : RegistrationFactory.GetDefaultVendorRegistration();
  isACorporation: boolean = false;
  registrationIsDone: boolean = false;
  isProcessing: boolean = false;
  errorManager: IErrorManager = new ErrorManager();

  get IsUpgradeOperation(): boolean {
    return this.$auth.IsAuthenticated ? true : false;
  }

  get CountryCodes() {
    return appDataStore.CountryOptions;
  }

  mounted() {
    this.InitCorporation();
    this.errorManager = new ErrorManager(this.refValidation);
  }

  @Watch(nameof<AccountSignUpView>("$route"), { immediate: true })
  ObserveRedirectUrl(): void {
    this.registration.redirectUrl = this.$route.path;
  }

  private InitCorporation(): void {
    if (this.$auth.User && this.$auth.User.corporation) {
      this.registration.corporation = cloneDeep(this.$auth.User.corporation);
      this.isACorporation = true;
    } else {
      this.registration.corporation = null;
      this.isACorporation = false;
    }
  }

  public OnIsACorporation(): void {
    if (this.isACorporation) {
      this.registration.corporation =
        this.$auth.User && this.$auth.User.corporation
          ? cloneDeep(this.$auth.User.corporation)
          : {
              name: "",
              vatNumber: "",
              userId: this.$auth.User ? this.$auth.User.id : ""
            };
    } else {
      this.registration.corporation = null;
    }
  }

  public async Submit(): Promise<void> {
    if (!this.registration) return;

    this.isProcessing = true;
    this.errorManager.Reset();

    if (this.IsUpgradeOperation) {
      this.accountsService
        .UpgradeAsVendor(this.registration as IVendorUpgradeRegistration)
        .then((response) => {
          const session: IAuthSession = response.data;

          this.registrationIsDone = true;
          authStore.AuthSession(session);
        })
        .catch((error: any) => {
          this.errorManager.HandleError(error);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    } else {
      // Set current UI locale as preferred language
      (this.registration as IVendorRegistration).preferredLanguage = this.$i18n.locale;

      this.accountsService
        .SignUpAsVendor(this.registration as IVendorRegistration)
        .then((response) => {
          this.registrationIsDone = true;
          authStore.AuthSession(response.data);
        })
        .catch((error: any) => {
          this.errorManager.HandleError(error);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    }
  }
}
