import { IJwt } from "@api/models/auth/IJwt";
import { IPayload } from "@api/models/auth/IPayload";

export class PayloadMapper {
  public static Map(decodedJwt: IJwt): IPayload {
    return {
      userId: decodedJwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
      userRoles: decodedJwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
      normalizedUserName: decodedJwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
      normalizedEmail: decodedJwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"]
    };
  }
}
