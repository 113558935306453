import { AppThemes } from "@api/models/market/constants/AppThemes";
import { default as NavDropdownListItem } from "@pigeon/components/nav/NavDropdownListItem";
import { Component, Prop, Vue } from "vue-property-decorator";

export enum DropdownTypes {
  Info = "info",
  Warning = "warning",
  Danger = "danger",
  Success = "success",
  Primary = "primary",
  Secondary = "secondary"
}

@Component
export default class NavDropdownList extends Vue {
  @Prop({ default: AppThemes.Light, validator: (value: AppThemes) => Object.values(AppThemes).includes(value) })
  readonly theme: AppThemes;
  @Prop({
    default: DropdownTypes.Info,
    validator: (value: DropdownTypes) => Object.values(DropdownTypes).includes(value)
  })
  readonly type: DropdownTypes;
  @Prop({ default: true })
  readonly hasButton: boolean;
  @Prop({ default: true })
  readonly autoClose: boolean;

  items: NavDropdownListItem[] = [];
  isOpen: boolean = false;

  public Toggle(): void {
    this.isOpen = !this.isOpen;
  }

  public Close(): void {
    this.isOpen = false;
  }

  public Open(): void {
    this.isOpen = true;
  }

  public OnClickItem(): void {
    if (!this.autoClose) return;

    this.Close();
  }
}
