import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavDropdownListItem extends Vue {
  @Prop()
  readonly to?: Location | string;
  @Prop()
  readonly href?: string;

  get Attrs(): Record<string, string> {
    return { ...this.$attrs };
  }
}
