export const beforeThanNow = (value: string) => {
  const now = new Date();

  return {
    valid: new Date(value).getTime() < now.getTime()
  };
};

export const afterThanToday = (value: string) => {
  let today = new Date();
  today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);

  return {
    valid: new Date(value).getTime() >= today.getTime()
  };
};
