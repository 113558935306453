import { authStore } from "@market/stores/App.store.modules";

export function isAuthorized(requiredRole?: string | string[]): boolean {
  if (!authStore.IsAuthenticated) return false;

  let isAuthorized = false;

  if (Array.isArray(requiredRole)) {
    // One of roles is required (OR operator)
    for (const role of requiredRole) {
      isAuthorized = isAuthorized || authStore.IsAuthorized(role);
    }
  } else if (typeof requiredRole === "string" && requiredRole) {
    // The role is required
    isAuthorized = authStore.IsAuthorized(requiredRole);
  } else {
    // None role is required
    isAuthorized = true;
  }

  return isAuthorized;
}
