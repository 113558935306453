import { ISalesService } from "@api/contracts/odata/ISalesService";
import { IAuction } from "@api/models/market/IAuction";
import { IPayments } from "@api/models/market/IPayments";
import { ISale } from "@api/models/market/ISale";
import { store } from "@market/stores";
import { IVendorDashboardStore } from "@market/stores/contracts/IVendorDashboardStore";
import { ISaleManager } from "@pigeon/services/contracts/ISaleManager";
import { cid, container, Inject } from "inversify-props";
import { merge } from "lodash-es";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "vendorDashboard" = "vendorDashboard";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class VendorDashboardStore extends VuexModule implements IVendorDashboardStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private salesService: ISalesService
  // @Inject()
  // private saleManager: ISaleManager

  salesDataset: ISale[] = [];

  @Mutation
  RESET_SALES_DATASET(): void {
    this.salesDataset = [];
  }

  @Mutation
  UPDATE_SALES_DATASET(salesToUpdate: ISale[]): void {
    for (const saleToUpdate of salesToUpdate) {
      const existing = this.salesDataset.find((s) => s.id == saleToUpdate.id);
      existing
        ? merge(existing, saleToUpdate) // update existing item
        : this.salesDataset.push(saleToUpdate); // insert new item
    }
  }
  // Old version
  // UPDATE_SALES_DATASET(salesToUpdate: ISale[]): void {
  //   for (const saleToUpdate of salesToUpdate) {
  //     const existingIndex = this.salesDataset.findIndex((s) => s.id == saleToUpdate.id);
  //     existingIndex > -1
  //       ? this.salesDataset.splice(existingIndex, 1, saleToUpdate) // replace existing item
  //       : this.salesDataset.push(saleToUpdate); // insert new item
  //   }
  // }

  @Action
  ResetDataset(): void {
    this.RESET_SALES_DATASET();
  }

  @Action
  UpdateSalesDataset(sales: ISale[]): void {
    if (!sales?.length) return;

    this.UPDATE_SALES_DATASET(sales);
  }

  @Action
  async EnsureUpdateSalesDatasetForAuctions(auctions: IAuction[]): Promise<void> {
    const saleManager = container.get<ISaleManager>(cid.SaleManager);
    const requiredSalesIds: number[] = auctions.flatMap((a) => a.sales.filter((s) => s.id).map((s) => s.id as number));
    if (!requiredSalesIds?.length) return;

    const missingSales = await this.FetchMissingSalesProducts(requiredSalesIds);
    await saleManager.FetchThenPopulateSalesBidsProperty(missingSales);
    this.UpdateSalesDataset(missingSales);
  }

  @Action
  async EnsureUpdateSalesDatasetForPayments(payments: IPayments): Promise<void> {
    const depositSalesIds: number[] = payments.deposits?.filter((d) => d.saleId)?.map((d) => d.saleId as number) ?? [];
    const settlementSalesIds: number[] =
      payments.settlements?.filter((s) => s.saleId)?.map((s) => s.saleId as number) ?? [];
    const stripePayoutSalesIds: number[] =
      payments.stripePayouts
        ?.filter((sp) => sp.saleIds?.length)
        ?.flatMap((sp) => sp.saleIds as string[])
        ?.filter((value) => value)
        ?.map((value) => parseInt(value)) ?? [];
    const payoutSalesIds: number[] =
      payments.payouts
        ?.filter((p) => p.saleIds?.length)
        ?.flatMap((p) => p.saleIds as string[])
        ?.filter((value) => value)
        ?.map((value) => parseInt(value)) ?? [];

    const paymentSalesIds: number[] = ([] as number[]).concat(
      depositSalesIds,
      settlementSalesIds,
      stripePayoutSalesIds,
      payoutSalesIds
    );
    const requiredSalesIds: number[] = Array.from(new Set(paymentSalesIds));

    if (requiredSalesIds?.length) {
      const missingSales = await this.FetchMissingSalesProducts(requiredSalesIds);
      this.UpdateSalesDataset(missingSales);
    }
  }

  @Action
  protected async FetchMissingSalesProducts(requiredSalesIds: number[]): Promise<ISale[]> {
    const salesService = container.get<ISalesService>(cid.SalesService);

    requiredSalesIds = Array.from(
      new Set(requiredSalesIds.filter((sid) => sid !== undefined && typeof sid !== "undefined"))
    ); // clean values
    if (!requiredSalesIds?.length) return Promise.resolve([]);

    const existingsSalesIds: number[] = this.salesDataset.filter((s) => s.id).map((s) => s.id as number);
    const missingSalesIds: number[] = requiredSalesIds.filter((id) => !existingsSalesIds.includes(id));
    if (!missingSalesIds?.length) return Promise.resolve([]);

    const { data: missingSalesWithProductData } = await salesService.FetchAllSalesWithProductByIds(missingSalesIds);

    return missingSalesWithProductData.value;
  }
}
