import { agree } from "@market/validation/rules/agree";
import { afterThanToday, beforeThanNow } from "@market/validation/rules/date";
import { isValidIBAN } from "@market/validation/rules/iban";
import {
  isUniqueBuyerName,
  isUniqueEmail,
  isUniquePigeonRing,
  isUniqueShop,
  isUniqueVendorName
} from "@market/validation/rules/unique";
import { extend } from "vee-validate";

// Custome rules
extend("unique_member_email", { validate: isUniqueEmail } as any);
extend("unique_vendor_name", { validate: isUniqueVendorName } as any);
extend("unique_shop", { validate: isUniqueShop } as any);
extend("unique_buyer_name", { validate: isUniqueBuyerName } as any);
extend("unique_pigeon_ring", { validate: isUniquePigeonRing } as any);
extend("iban", { validate: isValidIBAN } as any);
extend("agree", { validate: agree } as any);
extend("not_in_future", { validate: beforeThanNow } as any);
extend("not_in_past", { validate: afterThanToday } as any);
