import { IPigeon } from "@api/models/market/IPigeon";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { Inject } from "inversify-props";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PigeonPropertyTicketPicture extends Vue {
  @Prop()
  readonly pigeon: IPigeon;
  @Prop({ default: false })
  readonly useRawPicture: boolean;

  @Inject()
  private pigeonManager: IPigeonManager;

  forceFallbackPicture: boolean = false;

  get PropertyTicketPictureUrl(): string {
    if (!this.pigeon) {
      return "";
    }

    return this.pigeonManager.GetPicturePropertyTicket(
      this.pigeon.pictures,
      this.useRawPicture || this.forceFallbackPicture
    );
  }

  public OnPropertyTicketPictureUrl(): void {
    this.forceFallbackPicture = true;
  }
}
