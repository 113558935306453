import { IPayoutsService } from "@api/contracts/webapi/IPayoutsService";
import { IPayout } from "@api/models/market/IPayout";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";

export class PayoutsService extends BaseApiService implements IPayoutsService {
  public FetchAllPayouts(from?: Date, to?: Date): AxiosPromise<IPayout[]> {
    if (!authStore.IsVendor && !authStore.IsAdministrator) throw new Error("Unauthorized");

    let queryParam = "";
    if (from) queryParam += `/${from.toISOString()}`;
    if (to) queryParam += `/${to.toISOString()}`;

    return this.Client().get(`Payouts${queryParam}`);
  }

  public FetchAllPayoutsByVendor(vendorKey: string, from?: Date, to?: Date): AxiosPromise<IPayout[]> {
    if (!authStore.IsVendor && !authStore.IsAdministrator) throw new Error("Unauthorized");
    if (!vendorKey || (vendorKey != userStore.user?.id && !authStore.IsAdministrator)) throw new Error("Unauthorized");

    let queryParam = "";
    if (from) queryParam += `/${from.toISOString()}`;
    if (to) queryParam += `/${to.toISOString()}`;

    return this.Client().get(`Payouts/vendor/${vendorKey}${queryParam}`);
  }

  public FetchBySaleAndVendor(saleKey: number, vendorKey: string): AxiosPromise<IPayout> {
    if (!authStore.IsVendor && !authStore.IsAdministrator) throw new Error("Unauthorized");
    if (!vendorKey || (vendorKey != userStore.user?.id && !authStore.IsAdministrator)) throw new Error("Unauthorized");

    return this.Client().get(`Payout/${saleKey}/vendor/${vendorKey}`);
  }
}
