import { Component, Prop, Vue } from "vue-property-decorator";

export enum ButtonTypes {
  Primary = "primary",
  Secondary = "secondary",
  Info = "info",
  Success = "success",
  Warning = "warning",
  Danger = "danger"
}

// See: https://getbootstrap.com/docs/5.1/components/dropdowns/
// See: https://getbootstrap.com/docs/5.1/components/dropdowns/#split-button
@Component({
  inheritAttrs: false
})
export default class AppButtonDropdown extends Vue {
  @Prop()
  readonly actionLabel?: string;
  @Prop({
    default: ButtonTypes.Primary,
    validator: (value: ButtonTypes) => Object.values(ButtonTypes).includes(value)
  })
  readonly type: ButtonTypes;
  @Prop({ default: true })
  readonly outline: boolean;
  @Prop({ default: "left" })
  readonly menuAlign: "left" | "right";

  dropdownMenuIsOpen: boolean = false;

  get Attrs(): any {
    return { ...this.$attrs };
  }

  public ToggleDropdownMenu(): void {
    this.dropdownMenuIsOpen = !this.dropdownMenuIsOpen;
  }

  public OpenDropdownMenu(): void {
    this.dropdownMenuIsOpen = true;
  }

  public CloseDropdownMenu(): void {
    this.dropdownMenuIsOpen = false;
  }
}
