import { BaseThemes } from "@api/models/market/constants/BaseThemes";
import { IMenuItem } from "@pigeon/models/IMenuItem";
import mitt, { Emitter } from "mitt";
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator";

export enum DropdownDrops {
  Left = "left",
  Right = "right"
}

export enum DropdownVerticalDrops {
  Bottom = "bottom",
  Top = "top"
}

export type AppDropdownEvents = {
  ["menu-toggle"]: void;
  ["menu-open"]: void;
};

@Component
export default class AppDropdown extends Vue {
  @Model("change")
  readonly selected: IMenuItem | null;
  @Prop({ type: Array })
  readonly items: IMenuItem[];
  @Prop({
    default: DropdownDrops.Left,
    validator: (value: DropdownDrops) => Object.values(DropdownDrops).includes(value)
  })
  readonly drop: DropdownDrops;
  @Prop({
    default: DropdownVerticalDrops.Bottom,
    validator: (value: DropdownVerticalDrops) => Object.values(DropdownVerticalDrops).includes(value)
  })
  readonly dropVertical: DropdownVerticalDrops;
  @Prop({ validator: (value: BaseThemes) => Object.values(BaseThemes).includes(value) })
  readonly theme?: BaseThemes;

  @Prop({ default: () => mitt() })
  readonly menuBus: Emitter<AppDropdownEvents>;

  public ToggleMenu(): void {
    this.menuBus.emit("menu-toggle");
  }

  public ExpandMenu(): void {
    this.menuBus.emit("menu-open");
  }

  @Emit("change")
  public OnSelectedItem(selected: IMenuItem) {
    if (selected === this.selected || !selected) return;

    return selected;
  }
}
