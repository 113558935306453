import { IOfferManager } from "./contracts/IOfferManager";

export class OfferManager implements IOfferManager {
  // WARNING: OfferManager.CalculateUpStepOffer (client side) must do the same than BidService.CalculateUpStepOffer (server side)
  // TODO: consider call back end to calculate it in next version
  public CalculateUpStepOffer(offer: number): number {
    if (offer < 100) return 10;
    else if (offer < 200) return 20;
    else if (offer < 500) return 25;
    else if (offer < 1000) return 50;
    else return 100;
  }

  // WARNING: OfferManager.CalculateDownStepOffer (client side) must do the same than BidService.CalculateDownStepOffer (server side)
  // TODO: consider call back end to calculate it in next version
  public CalculateDownStepOffer(offer: number): number {
    if (offer <= 100) return 10;
    else if (offer <= 200) return 20;
    else if (offer <= 500) return 25;
    else if (offer <= 1000) return 50;
    else return 100;
  }
  //#endregion
}
