import { ISvgDocumentsService } from "@api/contracts/webapi/ISvgDocumentsService";
import { ISepaFormPayload } from "@api/models/market/ISepaFormPayload";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { AxiosPromise } from "axios";

export class SvgDocumentsService extends BaseApiService implements ISvgDocumentsService {
  public GetSepaFormInSvg(sepaFormPayload: ISepaFormPayload): AxiosPromise<string> {
    return this.Client().post(`SvgDocuments/sepa`, sepaFormPayload);
  }
}
