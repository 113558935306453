import { IPollsService } from "@api/contracts/odata/IPollsService";
import { IPoll } from "@api/models/market/IPoll";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import odataQuery from "odata-fluent-query";

export class PollsService extends BaseODataService implements IPollsService {
  constructor() {
    super();
  }

  public Insert(poll: IPoll): AxiosPromise<IPoll> {
    return this.Client().post("Polls", poll);
  }

  public Update(pollKey: number, poll: IPoll): AxiosPromise<void> {
    return this.Client().put(`Polls(${pollKey})`, poll);
  }

  public Delete(pollKey: number): AxiosPromise<void> {
    return this.Client().delete(`Polls(${pollKey})`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IPoll>> {
    const query = odataQuery<IPoll>().orderBy("createdAt", "desc");

    return this.Client().get(`Polls?${query.toString()}`);
  }

  public Fetch(pollKey: number): AxiosPromise<IPoll> {
    const query = odataQuery<IPoll>()
      .expand("translations")
      .expand("answers", (a) =>
        a
          .select("id", "answer")
          .expand("translations")
          .expand("votes", (v) => v.select("id"))
      );

    return this.Client().get(`Polls(${pollKey})?${query.toString()}`);
  }

  public FetchLastActive(): AxiosPromise<IODataCollectionResponse<IPoll>> {
    const query = odataQuery<IPoll>()
      .orderBy("createdAt", "desc")
      .filter((p) => p.isActive.equals(true))
      .expand("translations")
      .expand("answers", (a) =>
        a
          .select("id", "answer")
          .expand("translations")
          .expand("votes", (v) => v.select("id"))
      );

    return this.Client().get(`Polls?${query.toString()}`);
  }
}
