import { ISale } from "@api/models/market/ISale";
import { ISaleNotification } from "@api/models/market/ISaleNotification";
import { NOTIFICATION_DEFAULT_TIMEOUT, NOTIFICATION_LIMIT_TO_DISPLAY } from "@market/App.const";
import ToastMessage from "@market/components/toast/ToastMessage.vue";
import i18n from "@market/i18n";
import { ToastTypeMapper } from "@market/mappers/ToastTypeMapper";
import { AppRoutes } from "@market/routers/App.routes";
import { authStore, notificationStore } from "@market/stores/App.store.modules";
import { nameof } from "@pigeon/extensions/nameof";
import { INotificationManager } from "@pigeon/services/contracts/INotificationManager";
import { ISaleManager } from "@pigeon/services/contracts/ISaleManager";
import { Inject } from "inversify-props";
import { cloneDeep } from "lodash-es";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Location, RawLocation } from "vue-router";
import { ToastComponent } from "vue-toastification/dist/types/src/types";

@Component
export default class TheNotifications extends Vue {
  @Inject()
  private notificationManager: INotificationManager;
  @Inject()
  private saleManager: ISaleManager;

  get IsAuthenticated(): boolean {
    return authStore.IsAuthenticated;
  }

  get Notifications(): ISaleNotification[] {
    return notificationStore.saleNotifications;
  }

  get UnreadNotifications(): ISaleNotification[] {
    return notificationStore.saleNotificationsUnread;
  }

  @Watch(nameof<TheNotifications>("UnreadNotifications"), { immediate: true, deep: true })
  async ObserveNotifications() {
    if (!authStore.IsAuthenticated || !authStore.IsMember) return;

    await this.HandleUnreadNotifications();
  }

  private GetSaleLinkTo(saleId: number): RawLocation {
    return { name: AppRoutes.VendorSalesDetails, params: { saleKey: saleId.toString() } };
  }

  private NavigateToSale(saleId: number): void {
    if (!saleId) return;

    const saleTo = this.GetSaleLinkTo(saleId);
    const isAlreadyOnSaleRoute =
      (this.$route.name == (saleTo as Location).name || this.$route.name == AppRoutes.Sale) &&
      this.$route.params?.saleKey == saleId.toString();

    if (!isAlreadyOnSaleRoute) this.$router.push(this.GetSaleLinkTo(saleId));
  }

  // Business rules: When a notification is displayed on website (or via browser notification api), the notification should be flagged as read
  // Business rules: A danger notification should stay displayed until user close it (or click on it)
  // Business rules: Add an additional browser notification when user is not on the current tab
  private async HandleUnreadNotifications() {
    const sortedUnreadNotifications = cloneDeep(this.UnreadNotifications).sort((notifA, notifB) =>
      new Date(notifA.notificationDate).getTime() < new Date(notifB.notificationDate).getTime() ? 1 : -1
    );
    const notificationsToDisplayToUser =
      sortedUnreadNotifications.length < NOTIFICATION_LIMIT_TO_DISPLAY
        ? sortedUnreadNotifications
        : sortedUnreadNotifications.slice(0, NOTIFICATION_LIMIT_TO_DISPLAY);

    for (const notification of notificationsToDisplayToUser) {
      if (this.NotificationHasBeenAlreadySeen(notification) || notification.flagAsRead === true) continue;

      const notifID = `notif_${notification.id}`;
      const notifTitle = this.notificationManager.DetermineNotificationTitle(notification);
      const notifText = i18n.t(`notifications.sale_notifications.message.${notification.notification}`) as string;
      const notifType = this.notificationManager.GetNotificationType(notification);
      // Business rules: A danger notification should stay displayed until user close it (or click on it)
      const toastType = ToastTypeMapper.MapAlertTypeToToastType(notifType);
      const toastTimeout = NOTIFICATION_DEFAULT_TIMEOUT;

      // Display toast notification
      const toastContent: ToastComponent = {
        component: ToastMessage,
        props: {
          title: notifTitle,
          message: notifText,
          sale: notification.sale,
          hasSaleNavigate: true,
          actionLabel: this.$t("action.see")
        },
        listeners: {
          click: (sale: ISale) => {
            if (this.$route.name != AppRoutes.Sale) {
              this.$router.push(this.saleManager.BuildSaleRoute(sale));
            }
          }
        }
      };
      const toastID = this.$toast(toastContent, {
        type: toastType,
        timeout: toastTimeout
      });

      // Display Browser notification
      // Business rules: Add an additional browser notification when user is not on the current tab
      if (document.hidden) {
        const notificationPermission = await this.AskNotificationPermission();
        if (notificationPermission !== "granted") {
          this.NotifyViaTheBrowser(notifTitle, notifText, notifID, notification);
        }
      }

      // Flag notification as seen
      this.notificationManager.FlagSaleNotificationAsSeen(notification);
      // Business rules: Flag notification as read when a notification is displayed on website (or displayed via browser notification api)
      if (!authStore.IsAdministrator) await this.notificationManager.FlagSaleNotificationAsRead(notification);
    }
  }

  private NotificationHasBeenAlreadySeen(notification: ISaleNotification) {
    return notificationStore.saleNotificationsSeen.map((sns) => sns.id).includes(notification.id);
  }

  //#region BrowserApiNotifications
  private AskNotificationPermission(): Promise<NotificationPermission> {
    // Check if browser supports notifications
    if (!("Notification" in window)) {
      console.warn("This browser does not support notifications.");
      return Promise.resolve("denied");
    } else {
      return Notification.requestPermission();
    }
  }

  private NotifyViaTheBrowser(notifTitle: string, notifText: string, notifID: string, notification: ISaleNotification) {
    const notif = new Notification(notifTitle, { body: notifText, tag: notifID });
    notif.onclick = (ev: Event) => {
      if (notification.saleId) this.NavigateToSale(notification.saleId);
    };
  }
  //#endregion
}
