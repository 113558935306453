import { IReferralsService } from "@api/contracts/odata/IReferralsService";
import { IReferral } from "@api/models/market/IReferral";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { SortDirectionTypes } from "@pigeon/components/data/DataGridColumn";
import { AxiosPromise } from "axios";
import { FilterDate, odataQuery } from "odata-fluent-query";

export class ReferralsService extends BaseODataService implements IReferralsService {
  constructor() {
    super();
  }

  public Insert(referral: IReferral): AxiosPromise<IReferral> {
    return this.Client().post("Referrals", referral);
  }

  public Update(referralKey: number, referral: IReferral): AxiosPromise<void> {
    return this.Client().put(`Referrals(${referralKey})`, referral);
  }

  public Delete(referralKey: number): AxiosPromise<void> {
    return this.Client().delete(`Referrals(${referralKey})`);
  }

  public Fetch(referralKey: number): AxiosPromise<IReferral> {
    return this.Client().get(`Referrals(${referralKey})`);
  }

  public FetchAll(sort?: string, vendorKey?: string): AxiosPromise<IODataCollectionResponse<IReferral>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<IReferral>().count();

    if (sort) {
      const sortParams: string[] = sort.split(";");
      const orderPropertyName: keyof IReferral = sortParams[0] as keyof IReferral;
      const orderDirection: SortDirectionTypes = sortParams[1] as SortDirectionTypes;

      query = query.orderBy(orderPropertyName, orderDirection);
    } else {
      query = query.orderBy("lastname"); // default order by
    }

    if (vendorKey && authStore.IsAdministrator) {
      query = query.filter((r) => r.vendorId.equals(vendorKey, { ignoreGuid: true }));
    }

    return this.Client().get(`Referrals?${query.toString()}`);
  }

  public FetchAllReferrals(fromDate?: Date, toDate?: Date): AxiosPromise<IODataCollectionResponse<IReferral>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<IReferral>();

    if (fromDate)
      query = query.filter((r) => (r.recordedAt as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((r) => (r.recordedAt as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`Referrals?${query.toString()}`);
  }
}
