import { IPigeonsService } from "@api/contracts/odata/IPigeonsService";
import { IPigeon } from "@api/models/market/IPigeon";
import { IProduct } from "@market/components/sale/SaleProductAbstract";
import { ICartItem } from "@market/models/ICartItem";
import { IFilePicture } from "@pigeon/models/IFilePicture";
import { IFileVideo } from "@pigeon/models/IFileVideo";
import { IBlobManager } from "@pigeon/services/contracts/IBlobManager";
import { IProductManager } from "@pigeon/services/contracts/IProductManager";
import { Inject } from "inversify-props";
import { kebabCase } from "lodash-es";

export class ProductManager implements IProductManager {
  @Inject()
  private pigeonsService: IPigeonsService;
  @Inject()
  private blobManager: IBlobManager;

  public GetProductReferenceSlug(product: IProduct | ICartItem): string | undefined {
    if (!product || !product.reference) return;

    return product.reference.replace(/\s/g, "");
  }

  public GetProductNameSlug(product: IProduct | ICartItem): string | undefined {
    if (!product || !product.name) return;

    return kebabCase(product.name);
  }

  //#region Upload
  private async UploadPictureFilesAsync(pigeonId: number, pictureFiles: IFilePicture[]): Promise<void> {
    for (const pictureFile of pictureFiles) {
      await this.blobManager.InsertPigeonPictureAsync(pigeonId, pictureFile);
    }
  }

  private async UploadVideoFilesAsync(pigeonId: number, videoFiles: IFileVideo[]): Promise<void> {
    for (const videoFile of videoFiles) {
      await this.blobManager.InsertPigeonVideoAsync(pigeonId, videoFile);
    }
  }
  //#endregion

  //#region Insert/Update
  private SanitizePigeonGraph(pigeon: IPigeon): void {
    // Sanitize ring
    if (!pigeon.ringNumber) {
      pigeon.ring = undefined;
      pigeon.ringArea = undefined;
      pigeon.ringClub = undefined;
      pigeon.ringNumber = undefined;
    }

    // Sanitize fancier
    if (pigeon.fancier && !pigeon.fancier.name) {
      delete pigeon.fancier;
    }

    // Sanitize strain
    if (pigeon.strain && !pigeon.strain.name) {
      delete pigeon.strain;
    }
  }

  public async Insert(pigeon: IPigeon, pictureFiles: IFilePicture[], videoFiles?: IFileVideo[]): Promise<IPigeon> {
    // Sanitize graph inputs (= clean data model and remove not used child relation)
    this.SanitizePigeonGraph(pigeon);

    try {
      const { data } = await this.pigeonsService.Insert(pigeon);
      const insertedPigeon = data;
      const insertedPigeonId = insertedPigeon.id as number;

      if (pictureFiles && pictureFiles.length > 0) {
        await this.UploadPictureFilesAsync(insertedPigeonId, pictureFiles);
      }
      if (videoFiles && videoFiles.length > 0) {
        await this.UploadVideoFilesAsync(insertedPigeonId, videoFiles);
      }
      return Promise.resolve(insertedPigeon);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  public async Update(
    updatedPigeonId: number,
    pigeon: IPigeon,
    pictureFiles: IFilePicture[],
    videoFiles?: IFileVideo[]
  ): Promise<void> {
    // Sanitize graph inputs (= clean data model and remove not used child relation)
    this.SanitizePigeonGraph(pigeon);

    try {
      await this.pigeonsService.Update(updatedPigeonId, pigeon);

      if (pictureFiles && pictureFiles.length > 0) {
        await this.UploadPictureFilesAsync(updatedPigeonId, pictureFiles);
      }
      if (videoFiles && videoFiles.length > 0) {
        await this.UploadVideoFilesAsync(updatedPigeonId, videoFiles);
      }
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
  //#endregion
}
