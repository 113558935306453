import { ActionTypes } from "@api/models/market/constants/ActionTypes";
import CartTable from "@market/components/cart/CartTable.vue";
import { ICartItem } from "@market/models/ICartItem";
import AccountSignInView from "@market/pages/views/auth/AccountSignIn.vue";
import AccountSignOnView from "@market/pages/views/auth/AccountSignOn.vue";
import AccountSignUpView from "@market/pages/views/auth/AccountSignUp.vue";
import AccountVendorSignUpView from "@market/pages/views/auth/vendor/AccountSignUp.vue";
import { AppRoutes } from "@market/routers/App.routes";
import {
  cartDialogStore,
  cartStore,
  signInDialogStore,
  signOnDialogStore,
  signUpDialogStore,
  signUpVendorDialogStore
} from "@market/stores/App.store.modules";
import AppDialog from "@pigeon/components/app/AppDialog.vue";
import { Component, Vue } from "vue-property-decorator";
import { Location } from "vue-router";

@Component({
  components: {
    AppDialog,
    CartTable,
    SignUpView: AccountSignUpView,
    SignInView: AccountSignInView,
    SignOnView: AccountSignOnView,
    VendorSignUpView: AccountVendorSignUpView
  }
})
export default class TheNavBarDialogs extends Vue {
  AppRoutes = AppRoutes;
  ActionTypes = ActionTypes;
  hasForgottenPassword: boolean = false;

  //#region cartGetters
  get CartItems(): ICartItem[] {
    return cartStore.items;
  }

  get CartCount(): number {
    return cartStore.CartCount;
  }

  get CartActionLinkTo(): Location {
    if (cartStore.CartCount < 1) {
      return { name: AppRoutes.Buy };
    }

    return { name: AppRoutes.CheckoutOrder };
  }
  //#endregion

  //#region dialogGetters
  get OneDialogIsOpen(): boolean {
    return (
      cartDialogStore.dialog.isOpen ||
      signInDialogStore.dialog.isOpen ||
      signOnDialogStore.dialog.isOpen ||
      signUpDialogStore.dialog.isOpen ||
      signUpVendorDialogStore.dialog.isOpen
    );
  }
  get CartDialogIsOpen(): boolean {
    return cartDialogStore.dialog.isOpen;
  }
  get SignInDialogIsOpen(): boolean {
    return signInDialogStore.dialog.isOpen;
  }
  get SignOnDialogIsOpen(): boolean {
    return signOnDialogStore.dialog.isOpen;
  }
  get SignUpDialogIsOpen(): boolean {
    return signUpDialogStore.dialog.isOpen;
  }
  get SignUpVendorDialogIsOpen(): boolean {
    return signUpVendorDialogStore.dialog.isOpen;
  }
  //#endregion

  //#region dialogActions
  public CloseAllDialogs(): void {
    if (cartDialogStore.dialog.isOpen) cartDialogStore.Close();
    if (signInDialogStore.dialog.isOpen) signInDialogStore.Close();
    if (signOnDialogStore.dialog.isOpen) signOnDialogStore.Close();
    if (signUpDialogStore.dialog.isOpen) signUpDialogStore.Close();
    if (signUpVendorDialogStore.dialog.isOpen) signUpVendorDialogStore.Close();
  }

  public CloseCartDialog(): void {
    cartDialogStore.Close();
  }

  public CloseSignInDialog(): void {
    signInDialogStore.Close();
  }

  public CloseSignUpDialog(): void {
    signUpDialogStore.Close();
  }

  public CloseSignUpVendorDialog(): void {
    signUpVendorDialogStore.Close();
  }

  public CloseSignOnDialog(): void {
    signOnDialogStore.Close();
  }
  //#endregion

  public Recovery(value: boolean): void {
    this.hasForgottenPassword = value;
  }
}
