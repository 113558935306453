import mitt, { Emitter } from "mitt";

type OptionalHandler = void | (() => void);
export type UiBusEvents = {
  ["bingmaps-loaded"]: OptionalHandler;
  ["search-bar-toggled"]: OptionalHandler;
  ["login"]: OptionalHandler;
  ["logout"]: OptionalHandler;
};

export const uiBus: Emitter<UiBusEvents> = mitt<UiBusEvents>();
