import { IAccountData } from "@api/models/auth/IAccountData";
import { IAuthSession } from "@api/models/auth/IAuthSession";
import { store } from "@market/stores";
import { IAccountStore } from "@market/stores/contracts/IAccountStore";
import AuthStore from "@market/stores/modules/authStore";
import { HasFunctionalityCookieConsent } from "@pigeon/components/layout/TheBannerCookies";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "account" = "account";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class AccountStore extends VuexModule implements IAccountStore {
  static readonly STORE_KEY: string = "myaccount";

  pending: boolean = false;
  account: IAccountData | null = null;
  refreshPromise: Promise<void> | null;

  get AccountIsVerified(): boolean {
    if (!this.account) return false;

    return this.account.isAccountVerified;
  }

  get AccountVendorIsVerified(): boolean {
    if (!this.account) return false;

    return this.account.isConnectedAccountVerified;
  }

  get HasResentAccountVerification(): boolean {
    if (!this.account) return false;

    return this.account.hasResentAccountVerification;
  }

  @Mutation
  UPDATE_ACCOUNT_DATA(accountData: IAccountData): void {
    this.account = accountData;
  }

  @Mutation
  FLAG_ACCOUNT_RESENT_VERIFICATION(): void {
    if (this.account) {
      this.account.hasResentAccountVerification = true;
    }
  }

  @Mutation
  RESET(): void {
    this.account = null;
  }

  @Action
  FlagAccountAsVerified() {
    if (!this.account) return;

    this.UPDATE_ACCOUNT_DATA({
      ...this.account,
      isAccountVerified: true
    });
    this.Store();
  }

  @Action
  FlagConnectedAccountAsVerified() {
    if (!this.account) return;

    this.UPDATE_ACCOUNT_DATA({
      ...this.account,
      isConnectedAccountVerified: true
    });
    this.Store();
  }

  @Action
  InitAccountData(accountData: IAccountData) {
    this.UPDATE_ACCOUNT_DATA(accountData);
    this.Store();
  }

  @Action
  FlagVerificationAsResent(): void {
    this.FLAG_ACCOUNT_RESENT_VERIFICATION();
  }

  @Action
  Reset(): void {
    this.RESET();
    this.ClearStorage();
  }

  @Action
  Store(): void {
    if (!HasFunctionalityCookieConsent()) return;

    localStorage.setItem(AccountStore.STORE_KEY, JSON.stringify(this.account));
  }

  @Action
  ClearStorage(): void {
    localStorage.removeItem(AccountStore.STORE_KEY);
  }

  @Action
  async Restore() {
    const accountDataStored = localStorage.getItem(AccountStore.STORE_KEY);

    try {
      if (accountDataStored) {
        const accountData = JSON.parse(accountDataStored) as IAccountData;
        this.UPDATE_ACCOUNT_DATA(accountData);
      }
      // Note: retro compatibility
      else if (localStorage.getItem(AuthStore.STORE_KEY)) {
        const sessionStored: string = localStorage.getItem(AuthStore.STORE_KEY) as string;
        const session: IAuthSession = JSON.parse(sessionStored) as IAuthSession;
        if (session.accountData) this.UPDATE_ACCOUNT_DATA(session.accountData as IAccountData);
      }
    } catch (error: any) {
      console.error(error);
      this.ClearStorage();
    }
  }
}
