import { ValidationModes } from "@pigeon/components/formUI/FormInput";
import { nameof } from "@pigeon/extensions/nameof";
import { v4 as UUIDv4 } from "uuid";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class FormInputText extends Vue {
  @Prop({ default: () => `text-${UUIDv4()}` })
  readonly id: string;
  @Prop({ default: true })
  readonly hasFloatingLabel: boolean;
  @Prop({ default: false })
  readonly focused: boolean;
  @Prop()
  readonly label: string;
  @Prop()
  readonly value: string;
  @Prop()
  readonly validationRules?: string | Record<string, any>;
  @Prop()
  readonly validationCustomMessages?: Record<string, any>;
  @Prop({ validator: (value: ValidationModes) => Object.values(ValidationModes).includes(value) })
  readonly validationMode?: ValidationModes;
  @Prop()
  readonly validationName?: string;
  @Prop()
  readonly validationId?: string;
  @Prop()
  readonly hasError?: boolean;

  hasFocus: boolean = false;
  innerValue: any | null = null;

  @Watch(nameof<FormInputText>("value"), { immediate: true })
  ObserveValueModel() {
    this.innerValue = this.value;
  }

  @Emit("input")
  public Input(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  get Attrs(): any {
    return { ...this.$attrs, value: this.value };
  }

  get Listeners(): any {
    return { ...this.$listeners, input: this.Input };
  }

  get HasFocus(): boolean {
    return this.hasFocus;
  }

  set HasFocus(value: boolean) {
    this.hasFocus = value;
  }
}
