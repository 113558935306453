import { PLATFORM_CREATED_DATE } from "@market/App.const";
import { DateRange } from "@pigeon/components/formUI/FormInputSelectDateRangePicker";
import dayjs from "dayjs";
import { IDashboardManager } from "./contracts/IDashboardManager";

export class DashboardManager implements IDashboardManager {
  static DateRangeForLastMonth: DateRange = {
    startDate: dayjs().startOf("month").add(-1, "month").toDate(),
    endDate: dayjs().endOf("month").toDate()
  };
  DateRangeForLastMonth: DateRange = DashboardManager.DateRangeForLastMonth;

  DateRangeForAllTime(userRegisteredAt?: string | undefined): DateRange {
    const refDate = userRegisteredAt ? new Date(userRegisteredAt) : PLATFORM_CREATED_DATE;

    return {
      startDate: dayjs(refDate).startOf("year").toDate(),
      endDate: dayjs(new Date()).endOf("year").toDate()
    };
  }
}
