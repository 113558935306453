export enum CatalogFilterTypes {
  Vendor = "vendor",
  Contributor = "contributor",
  Sex = "sex",
  Age = "age",
  YearMin = "birthYearMin",
  YearMax = "birthYearMax",
  Discipline = "discipline",
  SaleType = "type",
  ProductType = "product",
  Country = "country",
  PriceMin = "priceMin",
  PriceMax = "priceMax",
  Status = "status",
  FlagSharedCatalog = "flagSharedCatalog",
  FlagArchived = "flagArchived",
  FlagApproved = "flagApproved",
  FlagVoucher = "flagVoucher",
  EndDateLowerThat = "endDateLT",
  StartDateGreaterOrEqualAt = "startDateGE",
  EndDateGreaterOrEqualAt = "endDateGE",
  StartDateLowerThat = "startDateLT",
  Auction = "auction",
  PaymentStatus = "paymentStatus",
  TransferStatus = "transferStatus",
  CreatedDateBetween = "createdDateBetween",
  Shop = "shop",
  Favorite = "favorite"
}
