import { AppRoles } from "@api/models/market/constants/AppRoles";
import { ProductTypes } from "@api/models/market/constants/ProductTypes";
import i18n from "@market/i18n";
import { DEFAULT_LOCALE } from "@market/i18n/i18n.const";
import { AppRoutes } from "@market/routers/App.routes";
import I18nLocalization from "@pigeon/components/i18n/I18nLocalization.vue";
import { GetUserLocale } from "@pigeon/i18n/i18n";
import { IBreadcrumbItem } from "@pigeon/models/IBreadcrumbItem";
import { PedigreeViewTypes } from "@pigeon/views/product/ProductPedigree";
import { cloneDeep } from "lodash-es";
import { RouteConfig } from "vue-router";

//#region Pages & Views
const HomePage = () => import(/* webpackChunkName: "HomePage" */ "@market/pages/HomePage.vue");
const FaqPage = () => import(/* webpackChunkName: "FaqPage" */ "@market/pages/FaqPage.vue");
const BuyPage = () => import(/* webpackChunkName: "BuyPage" */ "@market/pages/BuyPage.vue");
const SalePage = () => import(/* webpackChunkName: "SalePage" */ "@market/pages/SalePage.vue");
const ContactPage = () => import(/* webpackChunkName: "ContactPage" */ "@market/pages/ContactPage.vue");
const PolicyCookiesPage = () =>
  import(/* webpackChunkName: "CookiesPolicyPage" */ "@market/pages/CookiesPolicyPage.vue");
const PolicyPrivacyPage = () =>
  import(/* webpackChunkName: "PrivacyPolicyPage" */ "@market/pages/PrivacyPolicyPage.vue");
const PolicyTermsAndConditionsPage = () =>
  import(/* webpackChunkName: "TermsAndConditionsPage" */ "@market/pages/TermsAndConditionsPage.vue");
const PolicyAuctionConditionsPage = () =>
  import(/* webpackChunkName: "AuctionConditionsPage" */ "@market/pages/AuctionConditionsPage.vue");

const WhyPage = () => import(/* webpackChunkName: "WhyPage" */ "@market/pages/WhyPage.vue");
const LabPage = () => import(/* webpackChunkName: "LabPage" */ "@market/pages/LabPage.vue");
const NotFoundPage = () => import(/* webpackChunkName: "NotFoundPage" */ "@market/pages/NotFoundPage.vue");
const PigeonPage = () => import(/* webpackChunkName: "PigeonPage" */ "@market/pages/PigeonPage.vue");
const PigeonNewPage = () => import(/* webpackChunkName: "PigeonNewPage" */ "@market/pages/PigeonNewPage.vue");
const PigeonEditPage = () => import(/* webpackChunkName: "PigeonEditPage" */ "@market/pages/PigeonEditPage.vue");

const PigeonPedigreeView = () =>
  import(/* webpackChunkName: "PigeonPedigree" */ "@pigeon/views/product/ProductPedigree.vue");
const ProductBreederView = () =>
  import(/* webpackChunkName: "ProductBreeder" */ "@pigeon/views/product/ProductBreeder.vue");
const ProductPackagePigeonView = () =>
  import(/* webpackChunkName: "PackagePigeon" */ "@market/pages/views/package/PackagePigeon.vue");
const ProductStepPackageView = () =>
  import(/* webpackChunkName: "ProductStepPackage" */ "@pigeon/views/product/ProductStepPackage.vue");
const ProductStepPigeonView = () =>
  import(/* webpackChunkName: "ProductStepPigeon" */ "@pigeon/views/product/ProductStepPigeon.vue");
const ProductStepPigeonAccordionView = () =>
  import(/* webpackChunkName: "ProductStepPigeonAccordion" */ "@pigeon/views/product/ProductStepPigeonAccordion.vue");
const ProductStepPedigreeView = () =>
  import(/* webpackChunkName: "ProductStepPedigree" */ "@pigeon/views/product/ProductStepPedigree.vue");
const PigeonStepSuccessView = () =>
  import(/* webpackChunkName: "PigeonStepSuccess" */ "@market/pages/views/pigeon/PigeonStepSuccess.vue");
const ProductStepSuccessParentView = () =>
  import(/* webpackChunkName: "ProductStepSuccessParent" */ "@pigeon/views/product/ProductStepSuccessParent.vue");
const ProductStepSaleView = () =>
  import(/* webpackChunkName: "ProductStepSale" */ "@pigeon/views/product/ProductStepSale.vue");
const ProductStepSuccessSaleView = () =>
  import(/* webpackChunkName: "ProductStepSuccessSale" */ "@pigeon/views/product/ProductStepSuccessSale.vue");

const CheckoutPage = () => import(/* webpackChunkName: "CheckoutPage" */ "@market/pages/CheckoutPage.vue");
const CheckoutView = () => import(/* webpackChunkName: "CheckoutView" */ "@market/pages/views/checkout/Checkout.vue");
const CheckoutSuccessView = () =>
  import(/* webpackChunkName: "CheckoutSuccess" */ "@market/pages/views/checkout/CheckoutSuccess.vue");

const AccountPage = () => import(/* webpackChunkName: "AccountPage" */ "@market/pages/AccountPage.vue");
const AccountResetPasswordPage = () =>
  import(/* webpackChunkName: "AccountResetPasswordPage" */ "@market/pages/AccountResetPasswordPage.vue");
const AccountVerificationPage = () =>
  import(/* webpackChunkName: "AccountVerificationPage" */ "@market/pages/AccountVerificationPage.vue");
const ConnectOAuthVerificationPage = () =>
  import(/* webpackChunkName: "ConnectOAuthVerificationPage" */ "@market/pages/ConnectOAuthVerificationPage.vue");
const AccountAddressView = () =>
  import(/* webpackChunkName: "AccountAddress" */ "@market/pages/views/account/AccountAddress.vue");
const VendorAuctionView = () =>
  import(/* webpackChunkName: "AccountAuction" */ "@market/pages/views/buyer/BuyerAuction.vue");
const AccountDataView = () =>
  import(/* webpackChunkName: "AccountData" */ "@market/pages/views/account/AccountData.vue");
const AccountNotificationsCenterView = () =>
  import(
    /* webpackChunkName: "AccountNotificationsCenter" */ "@market/pages/views/account/AccountNotificationsCenter.vue"
  );

const BuyerPage = () => import(/* webpackChunkName: "BuyerPage" */ "@market/pages/BuyerPage.vue");
const BuyerDashboardView = () =>
  import(/* webpackChunkName: "BuyerDashboard" */ "@market/pages/views/buyer/BuyerDashboard.vue");
const BuyerOrdersView = () =>
  import(/* webpackChunkName: "AccountOrders" */ "@market/pages/views/buyer/BuyerOrders.vue");
const BuyerOrderDetailsView = () =>
  import(/* webpackChunkName: "AccountOrderDetails" */ "@market/pages/views/buyer/BuyerOrderDetails.vue");

const BreederPage = () => import(/* webpackChunkName: "BreederPage" */ "@market/pages/BreederPage.vue");
const BreederDashboardView = () =>
  import(/* webpackChunkName: "BreederDashboard" */ "@market/pages/views/breeder/BreederDashboard.vue");
const BreederFancierProfileView = () =>
  import(/* webpackChunkName: "BreederFancierProfile" */ "@market/pages/views/breeder/BreederFancierProfile.vue");

const VendorPage = () => import(/* webpackChunkName: "VendorPage" */ "@market/pages/VendorPage.vue");
const VendorDashboardView = () =>
  import(/* webpackChunkName: "VendorDashboard" */ "@market/pages/views/vendor/VendorDashboard.vue");
const VendorShopView = () => import(/* webpackChunkName: "VendorShop" */ "@market/pages/views/vendor/VendorShop.vue");

const VendorReferralView = () =>
  import(/* webpackChunkName: "VendorReferral" */ "@market/pages/views/vendor/VendorReferral.vue");
const VendorPaymentsView = () =>
  import(/* webpackChunkName: "VendorPayments" */ "@market/pages/views/vendor/VendorPayments.vue");
const VendorSalesView = () =>
  import(/* webpackChunkName: "VendorSales" */ "@market/pages/views/vendor/VendorSales.vue");
const VendorSuperviseView = () =>
  import(/* webpackChunkName: "VendorSupervise" */ "@market/pages/views/vendor/VendorSupervise.vue");
const SellPage = () => import(/* webpackChunkName: "SellPage" */ "@market/pages/SellPage.vue");
const SaleNewPage = () => import(/* webpackChunkName: "SaleNewPage" */ "@market/pages/SaleNewPage.vue");
const SaleEditPage = () => import(/* webpackChunkName: "SaleEditPage" */ "@market/pages/SaleEditPage.vue");

const AdminPage = () => import(/* webpackChunkName: "AdminPage" */ "@market/pages/AdminPage.vue");
const AdminDashboardView = () =>
  import(/* webpackChunkName: "AdminDashboard" */ "@market/pages/views/admin/AdminDashboard.vue");
const AdminDashboardLegacyView = () =>
  import(/* webpackChunkName: "AdminDashboard" */ "@market/pages/views/admin/AdminDashboard.legacy.vue");
const AdminDbAlertsView = () =>
  import(/* webpackChunkName: "AdminDbAlerts" */ "@market/pages/views/admin/AdminDbAlerts.vue");
const AdminApprovalSalesView = () =>
  import(/* webpackChunkName: "AdminApprovalSales" */ "@market/pages/views/admin/AdminApprovalSales.vue");
const AdminNotificationsView = () =>
  import(/* webpackChunkName: "AdminNotificationSales" */ "@market/pages/views/admin/AdminNotificationSales.vue");
const AdminBankTransferView = () =>
  import(/* webpackChunkName: "AdminBankTransfer" */ "@market/pages/views/admin/AdminBankTransfer.vue");
//#endregion

const saleStepsChildren: RouteConfig[] = [
  // Sale step
  {
    path: "sale",
    component: ProductStepSaleView,
    name: AppRoutes.AbstractSaleSale,
    meta: {
      requiresAuth: true,
      requiresAuthRole: AppRoles.Vendor,
      step: AppRoutes.AbstractSaleSale,
      nextSteps: {
        [ProductTypes.Pigeon]: AppRoutes.AbstractSalePigeon,
        [ProductTypes.Package]: AppRoutes.AbstractSalePackage
      },
      defaultStep: AppRoutes.AbstractSale
    }
  },
  // Package steps
  {
    path: "package",
    component: ProductStepPackageView,
    name: AppRoutes.AbstractSalePackage,
    props: { pigeonHasVideo: false },
    meta: {
      requiresAuth: true,
      requiresAuthRole: AppRoles.Vendor,
      step: AppRoutes.AbstractSalePackage,
      previousStep: AppRoutes.AbstractSaleSale,
      defaultStep: AppRoutes.AbstractSale
    }
  },
  // Pigeon steps
  {
    path: "pigeon",
    component: ProductStepPigeonView,
    name: AppRoutes.AbstractSalePigeon,
    props: { pigeonHasVideo: true },
    meta: {
      requiresAuth: true,
      requiresAuthRole: AppRoles.Vendor,
      step: AppRoutes.AbstractSalePigeon,
      nextStep: AppRoutes.AbstractSalePedigree,
      previousStep: AppRoutes.AbstractSaleSale,
      defaultStep: AppRoutes.AbstractSale
    }
  },
  {
    path: "pedigree",
    component: ProductStepPedigreeView,
    name: AppRoutes.AbstractSalePedigree,
    meta: {
      versionBeta: true,
      requiresAuth: true,
      requiresAuthRole: AppRoles.Vendor,
      step: AppRoutes.AbstractSalePedigree,
      previousStep: AppRoutes.AbstractSalePigeon,
      defaultStep: AppRoutes.AbstractSale
    }
  },
  // Shared steps
  {
    path: "inserted",
    component: ProductStepSuccessSaleView,
    name: AppRoutes.AbstractSaleInserted,
    meta: { requiresAuth: true, requiresAuthRole: AppRoles.Vendor, step: AppRoutes.AbstractSaleInserted },
    props: { operation: "insert" }
  },
  {
    path: "updated",
    component: ProductStepSuccessSaleView,
    name: AppRoutes.AbstractSaleUpdated,
    meta: { requiresAuth: true, requiresAuthRole: AppRoles.Vendor, step: AppRoutes.AbstractSaleUpdated },
    props: { operation: "update" }
  },
  { path: "", redirect: "sale", name: AppRoutes.AbstractSale }
];

const pigeonStepsChildren: RouteConfig[] = [
  {
    path: "pigeon",
    component: ProductStepPigeonView,
    name: AppRoutes.AbstractPigeonPigeon,
    meta: {
      requiresAuth: true,
      requiresAuthRole: AppRoles.Vendor,
      step: AppRoutes.AbstractPigeonPigeon,
      nextStep: AppRoutes.AbstractPigeonPedigree,
      defaultStep: AppRoutes.AbstractPigeon
    }
  },
  {
    path: "pedigree",
    component: ProductStepPedigreeView,
    name: AppRoutes.AbstractPigeonPedigree,
    meta: {
      versionBeta: true,
      requiresAuth: true,
      requiresAuthRole: AppRoles.Vendor,
      step: AppRoutes.AbstractPigeonPedigree,
      previousStep: AppRoutes.AbstractPigeonPigeon,
      defaultStep: AppRoutes.AbstractPigeon
    }
  },
  {
    path: "inserted",
    component: PigeonStepSuccessView,
    name: AppRoutes.AbstractPigeonInserted,
    meta: { requiresAuth: true, requiresAuthRole: AppRoles.Vendor, step: AppRoutes.AbstractPigeonInserted },
    props: { operation: "insert" }
  },
  {
    path: "updated",
    component: PigeonStepSuccessView,
    name: AppRoutes.AbstractPigeonUpdated,
    meta: { requiresAuth: true, requiresAuthRole: AppRoles.Vendor, step: AppRoutes.AbstractPigeonUpdated },
    props: { operation: "update" }
  },
  { path: "", redirect: "pigeon", name: AppRoutes.AbstractPigeon }
];

export const breadcrumb: { [key: string]: IBreadcrumbItem[] } = {
  buy: [{ text: "catalog" }],
  checkout: [
    {
      text: "catalog",
      to: { name: AppRoutes.Buy }
    },
    { text: "checkout" }
  ],
  contact: [
    {
      text: "catalog",
      to: { name: AppRoutes.Buy }
    },
    { text: "contact" }
  ],
  saleSheet: [
    {
      text: "catalog",
      to: { name: AppRoutes.Buy }
    },
    { text: "sale_sheet" }
  ]
};

export const routes: RouteConfig[] = [
  { path: "lab", component: LabPage, name: AppRoutes.Lab },
  { path: "home", component: HomePage, name: AppRoutes.Home },
  { path: "faq", component: FaqPage, name: AppRoutes.Faq },
  { path: "why", component: WhyPage, name: AppRoutes.Why },
  { path: "buy", component: BuyPage, name: AppRoutes.Buy, meta: { breadcrumb: breadcrumb.buy } },
  { path: "shop/:shopKey", component: BuyPage, name: AppRoutes.BuyShop, meta: { breadcrumb: breadcrumb.buy } },
  {
    path: "buy/:saleKey(\\d+)/:productRef?/:productName?",
    component: SalePage,
    meta: { breadcrumb: breadcrumb.saleSheet },
    children: [
      {
        path: "",
        component: PigeonPedigreeView,
        // Note: viewBy prop is pdf (if picture pdf exists), picture (if picture img exists) or diagram (fallback value)
        // See: ProductPedigree.ts / SyncViewByWithPigeonPictureAvailable() method
        props: { parentsLinked: false, viewBy: PedigreeViewTypes.Pdf },
        name: AppRoutes.Sale,
        meta: { breadcrumb: breadcrumb.saleSheet }
      },
      {
        path: "package/pigeon/:pigeonKey?",
        component: ProductPackagePigeonView,
        name: AppRoutes.SalePackagePigeon
      }
      // considered for the vnext
      // { path: "palmares", component: ProductPalmaresView, name: AppRoutes.SalePalmares },
    ]
  },
  {
    path: "pigeon/:productKey(\\d+)/:productRef?/:productName?",
    component: PigeonPage,
    children: [
      {
        path: "pedigree",
        component: PigeonPedigreeView,
        name: AppRoutes.PigeonPedigree,
        props: { parentsLinked: true, viewBy: "diagram" },
        meta: { breadcrumb: breadcrumb.productSheet }
      },
      { path: "", redirect: "pedigree", name: AppRoutes.Pigeon }
    ]
  },
  {
    path: "pigeon/new",
    component: PigeonNewPage,
    meta: {
      requiresAuth: true,
      requiresAuthRole: AppRoles.Vendor
    },
    children: cloneDeep(pigeonStepsChildren).map((child) => {
      if (child.path === "/updated") return {} as RouteConfig;

      child.name = `new.${child.name}`;
      if (child.meta) {
        if (child.meta.step) child.meta.step = `new.${child.meta.step}`;
        if (child.meta.nextStep) child.meta.nextStep = `new.${child.meta.nextStep}`;
        if (child.meta.previousStep) child.meta.previousStep = `new.${child.meta.previousStep}`;
        if (child.meta.defaultStep) child.meta.defaultStep = `new.${child.meta.defaultStep}`;
      }
      return child;
    })
  },
  {
    path: "pigeon/edit/:productKey(\\d+)",
    component: PigeonEditPage,
    meta: {
      requiresAuth: true,
      requiresAuthRole: AppRoles.Vendor
    },
    children: cloneDeep(pigeonStepsChildren).map((child) => {
      if (child.path === "/inserted") return {} as RouteConfig;

      child.name = `edit.${child.name}`;
      if (child.meta) {
        if (child.meta.step) child.meta.step = `edit.${child.meta.step}`;
        if (child.meta.nextStep) child.meta.nextStep = `edit.${child.meta.nextStep}`;
        if (child.meta.previousStep) child.meta.previousStep = `edit.${child.meta.previousStep}`;
        if (child.meta.defaultStep) child.meta.defaultStep = `edit.${child.meta.defaultStep}`;
      }
      return child;
    })
  },
  {
    path: "buy/checkout",
    component: CheckoutPage,
    meta: {
      breadcrumb: breadcrumb.checkout
    },
    children: [
      {
        path: "order",
        component: CheckoutView,
        name: AppRoutes.CheckoutOrder
      },
      {
        path: "success/:orderId",
        component: CheckoutSuccessView,
        props: true,
        name: AppRoutes.CheckoutSuccess
      },
      { path: "", redirect: "order", name: AppRoutes.Checkout }
    ]
  },
  { path: "sell", component: SellPage, name: AppRoutes.Sell },
  {
    path: "sell/new",
    component: SaleNewPage,
    meta: {
      requiresAuth: true,
      requiresAuthRole: AppRoles.Vendor
    },
    children: cloneDeep(saleStepsChildren).map((child) => {
      if (child.path === "/updated") return {} as RouteConfig;

      child.name = `new.${child.name}`;
      if (child.meta) {
        if (child.meta.step) child.meta.step = `new.${child.meta.step}`;
        if (child.meta.nextStep) child.meta.nextStep = `new.${child.meta.nextStep}`;
        if (child.meta.nextSteps) {
          const nextSteps = child.meta.nextSteps;
          for (const keyNextStep in nextSteps) {
            // eslint-disable-next-line no-prototype-builtins
            if (nextSteps.hasOwnProperty(keyNextStep)) {
              nextSteps[keyNextStep] = `new.${nextSteps[keyNextStep]}`;
            }
          }
        }
        if (child.meta.previousStep) child.meta.previousStep = `new.${child.meta.previousStep}`;
        if (child.meta.defaultStep) child.meta.defaultStep = `new.${child.meta.defaultStep}`;
      }
      return child;
    })
  },
  {
    path: "sell/edit/:saleKey(\\d+)",
    component: SaleEditPage,
    meta: {
      requiresAuth: true,
      requiresAuthRole: AppRoles.Vendor
    },
    children: cloneDeep(saleStepsChildren).map((child) => {
      if (child.path === "/inserted") return {} as RouteConfig;

      child.name = `edit.${child.name}`;
      if (child.meta) {
        if (child.meta.step) child.meta.step = `edit.${child.meta.step}`;
        if (child.meta.nextStep) child.meta.nextStep = `edit.${child.meta.nextStep}`;
        if (child.meta.nextSteps) {
          const nextSteps = child.meta.nextSteps;
          for (const keyNextStep in nextSteps) {
            // eslint-disable-next-line no-prototype-builtins
            if (nextSteps.hasOwnProperty(keyNextStep)) {
              nextSteps[keyNextStep] = `edit.${nextSteps[keyNextStep]}`;
            }
          }
        }
        if (child.meta.previousStep) child.meta.previousStep = `edit.${child.meta.previousStep}`;
        if (child.meta.defaultStep) child.meta.defaultStep = `edit.${child.meta.defaultStep}`;
      }
      return child;
    })
  },
  {
    path: "account/reset-password", // a securityToken is passed as query param
    component: AccountResetPasswordPage,
    name: AppRoutes.AccountResetPassword
  },
  {
    path: "account/verification/:userId", // a securityToken is passed as query param
    component: AccountVerificationPage,
    name: AppRoutes.AccountVerification
  },
  {
    path: "account",
    component: AccountPage,
    meta: { requiresAuth: true, requiresAuthRole: AppRoles.Member },
    children: [
      {
        path: "data",
        component: AccountDataView,
        name: AppRoutes.AccountData
      },
      {
        path: "address",
        component: AccountAddressView,
        name: AppRoutes.AccountAddress
      },
      {
        path: "notifications",
        component: AccountNotificationsCenterView,
        name: AppRoutes.AccountNotifications
      }
    ]
  },
  {
    path: "buyer",
    component: BuyerPage,
    meta: { requiresAuth: true, requiresAuthRole: AppRoles.Member },
    children: [
      {
        path: "dashboard",
        component: BuyerDashboardView,
        name: AppRoutes.BuyerDashboard
      },
      {
        path: "orders",
        component: BuyerOrdersView,
        name: AppRoutes.BuyerOrders
      },
      {
        path: "orders/:orderKey",
        component: BuyerOrderDetailsView,
        name: AppRoutes.BuyerOrdersDetails,
        props: true
      }
    ]
  },
  {
    path: "breeder",
    component: BreederPage,
    meta: { requiresAuth: true, requiresAuthRole: AppRoles.Vendor },
    children: [
      {
        path: "dashboard",
        component: BreederDashboardView,
        name: AppRoutes.BreederDashboard,
        meta: {
          versionBeta: true
        }
      },
      {
        path: "fancier-profile/:profileKey?",
        component: BreederFancierProfileView,
        name: AppRoutes.BreederFancierProfile,
        props: true,
        meta: {
          versionBeta: true
        }
      }
    ]
  },
  {
    path: "vendor",
    component: VendorPage,
    meta: { requiresAuth: true, requiresAuthRole: AppRoles.Vendor },
    children: [
      {
        path: "dashboard",
        component: VendorDashboardView,
        name: AppRoutes.VendorDashboard,
        meta: {
          versionBeta: true
        }
      },
      {
        path: "auctions/:auctionKey?",
        component: VendorAuctionView,
        name: AppRoutes.VendorAuction,
        props: true
      },
      {
        path: "payments",
        component: VendorPaymentsView,
        name: AppRoutes.VendorPayments
      },
      {
        path: "sales",
        component: VendorSalesView,
        name: AppRoutes.VendorSales
      },
      {
        path: "sales/:saleKey",
        name: AppRoutes.VendorSalesDetails,
        redirect: { name: AppRoutes.Sale }
      },
      {
        path: "supervise/:saleKey",
        component: VendorSuperviseView,
        name: AppRoutes.VendorSuperviseDetails,
        props: true
      },
      {
        path: "shop",
        component: VendorShopView,
        name: AppRoutes.VendorShop,
        meta: {
          versionBeta: true
        }
      },
      {
        path: "referral",
        component: VendorReferralView,
        name: AppRoutes.VendorReferral,
        meta: {
          versionBeta: true
        }
      },
      {
        path: "pigeons/:productKey",
        name: AppRoutes.VendorPigeonsDetails,
        redirect: { name: AppRoutes.AbstractPigeon }
      }
    ]
  },
  {
    path: "admin",
    component: AdminPage,
    name: AppRoutes.Admin,
    meta: { requiresAuth: true, requiresAuthRoles: [AppRoles.Approver, AppRoles.Administrator] },
    children: [
      {
        path: "dashboard",
        component: AdminDashboardView,
        name: AppRoutes.AdminDashboard,
        meta: {
          requiresAuth: true,
          requiresAuthRole: AppRoles.Approver
        }
      },
      {
        path: "dashboard-legacy",
        component: AdminDashboardLegacyView,
        name: AppRoutes.AdminDashboardLegacy,
        meta: {
          requiresAuth: true,
          requiresAuthRole: AppRoles.Approver
        }
      },
      {
        path: "sales-approval",
        component: AdminApprovalSalesView,
        name: AppRoutes.AdminApprovalSales,
        meta: {
          requiresAuth: true,
          requiresAuthRole: AppRoles.Approver
        }
      },
      {
        path: "alerts",
        component: AdminDbAlertsView,
        name: AppRoutes.AdminAlerts,
        meta: {
          requiresAuth: true,
          requiresAuthRole: AppRoles.Administrator
        }
      },
      {
        path: "sales-notifications",
        component: AdminNotificationsView,
        name: AppRoutes.AdminNotifications,
        meta: {
          requiresAuth: true,
          requiresAuthRole: AppRoles.Administrator
        }
      },
      {
        path: "bank-transfer",
        component: AdminBankTransferView,
        name: AppRoutes.AdminBankTransfer,
        meta: {
          requiresAuth: true,
          requiresAuthRole: AppRoles.Administrator
        }
      }
    ]
  },
  {
    path: "contact",
    component: ContactPage,
    name: AppRoutes.Contact,
    meta: { breadcrumb: breadcrumb.contact }
  },
  {
    path: "terms-and-conditions",
    component: PolicyTermsAndConditionsPage,
    name: AppRoutes.TermsAndConditions
  },
  {
    path: "auction-conditions",
    component: PolicyAuctionConditionsPage,
    name: AppRoutes.AuctionConditions
  },
  { path: "privacy-policy", component: PolicyPrivacyPage, name: AppRoutes.PrivacyPolicy },
  { path: "cookies-policy", component: PolicyCookiesPage, name: AppRoutes.CookiesPolicy },
  // Redirects to PigeonCommunity
  {
    path: "?redirect=https://www.pigeoncommunity.be/en/explore",
    name: AppRoutes.CommunityPigeon,
    beforeEnter(to) {
      if (to.params && to.params.productKey && to.params.productRef && to.params.productName)
        window.location.replace(
          `${process.env.VUE_APP_PIGEON_COMMUNITY}/${i18n.locale}/explore/${to.params.productKey}/${to.params.productRef}/${to.params.productName}`
        );
      else if (to.params && to.params.productKey)
        window.location.replace(
          `${process.env.VUE_APP_PIGEON_COMMUNITY}/${i18n.locale}/explore/${to.params.productKey}`
        );
      else window.location.replace(`${process.env.VUE_APP_PIGEON_COMMUNITY}/${i18n.locale}/explore`);
    }
  },
  // Default fallback
  { path: "", redirect: { name: AppRoutes.Home, params: { locale: GetUserLocale() } }, name: AppRoutes.Default }, // Workaround: See https://github.com/vuejs/vue-router/issues/1762
  { path: "*", name: AppRoutes.Page404, component: NotFoundPage } // In Vue 3 path: '/:pathMatch(.*)' See: https://next.router.vuejs.org/guide/migration/#removed-star-or-catch-all-routes
];

const localizedRoutes: RouteConfig[] = [
  {
    path: "/",
    redirect: `/${DEFAULT_LOCALE}`
  },
  {
    path: "/oauth/connect",
    component: ConnectOAuthVerificationPage
  },
  {
    path: "/:locale([A-Za-z]{2})",
    component: I18nLocalization,
    children: routes
  }
];

export default localizedRoutes;
