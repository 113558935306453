import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { IPigeonsService } from "@api/contracts/odata/IPigeonsService";
import { userStore } from "@market/stores/App.store.modules";
import { cid, container } from "inversify-props";

export const isUniquePigeonRing = async (value: string) => {
  if (!value) return;
  const pigeonsService = container.get<IPigeonsService>(cid.PigeonsService);

  if (!userStore.user) return { valid: true };

  return pigeonsService.FindPigeonByRingAndByUser(value, userStore.user.id).then((response) => {
    if (response.data["@odata.count"] && response.data["@odata.count"] > 0) {
      return {
        valid: false
      };
    } else {
      return {
        valid: true
      };
    }
  });
};

export const isUniqueEmail = (value: string) => {
  if (!value) return;

  const accountsService = container.get<IAccountsService>(cid.AccountsService);
  return accountsService
    .IsMemberEmailUnique(value)
    .then((response) => {
      return {
        valid: response.data
      };
    })
    .catch((error) => {
      return {
        valid: true
      };
    });
};

export const isUniqueVendorName = (value: string) => {
  if (!value) return;

  const accountsService = container.get<IAccountsService>(cid.AccountsService);
  return accountsService
    .IsVendorNameUnique(value)
    .then((response) => {
      return {
        valid: response.data
      };
    })
    .catch((error) => {
      return {
        valid: true
      };
    });
};

export const isUniqueShop = (value: string) => {
  if (!value) return;

  const accountsService = container.get<IAccountsService>(cid.AccountsService);
  return accountsService
    .IsShopUnique(value)
    .then((response) => {
      return {
        valid: response.data
      };
    })
    .catch((error) => {
      return {
        valid: true
      };
    });
};

export const isUniqueBuyerName = (value: string) => {
  if (!value) return;

  const accountsService = container.get<IAccountsService>(cid.AccountsService);
  return accountsService
    .IsBuyerNameUnique(value)
    .then((response) => {
      return {
        valid: response.data
      };
    })
    .catch((error) => {
      return {
        valid: true
      };
    });
};

export const isUniqueContributorName = (value: string) => {
  if (!value) return;

  const accountsService = container.get<IAccountsService>(cid.AccountsService);
  return accountsService
    .IsContributorNameUnique(value)
    .then((response) => {
      return {
        valid: response.data
      };
    })
    .catch((error) => {
      return {
        valid: true
      };
    });
};
