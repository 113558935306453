import { IGuestsService } from "@api/contracts/odata/IGuestsService";
import { ICart } from "@api/models/market/ICart";
import { IGuest } from "@api/models/market/IGuest";
import { IOrder } from "@api/models/market/IOrder";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, odataQuery } from "odata-fluent-query";

export class GuestsService extends BaseODataService implements IGuestsService {
  constructor() {
    super();
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IGuest>> {
    const query = odataQuery<IGuest>().orderBy("firstname", "asc");

    return this.Client().get(`Guests?${query.toString()}`);
  }

  public FetchAllIdentitiesWith(): AxiosPromise<IODataCollectionResponse<IGuest>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<IGuest>().select("id", "firstname", "lastname").orderBy("firstname", "asc");
    return this.Client().get(`Guests?${query.toString()}`);
  }

  public Fetch(guestKey: number): AxiosPromise<IGuest> {
    return this.Client().get(`Guests(${guestKey})`);
  }

  public FetchAllOverview(): AxiosPromise<IODataCollectionResponse<IGuest>> {
    const query = odataQuery<IGuest>()
      .select("id", "firstname", "lastname", "email", "phoneNumber", "preferredLanguage", "stripeCustomerId")
      .orderBy("firstname", "asc")
      .expand("carts", (q: ExpandObjectQuery<ICart>) => q.select("id"))
      .expand("orders", (q: ExpandObjectQuery<IOrder>) => q.select("id"));

    return this.Client().get(`Guests?${query.toString()}`);
  }

  public GetGuestsIdentities(): AxiosPromise<IODataCollectionResponse<Partial<IGuest>>> {
    return this.Client().get(`Guests/MarketService.GetGuestsIdentities`);
  }
}
