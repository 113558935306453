import { IUserData } from "@api/models/market/IUserData";
import { appDataStore } from "@market/stores/App.store.modules";
import { nameof } from "@pigeon/extensions/nameof";
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from "@pigeon/i18n/i18n.const";
import { IMenuItem } from "@pigeon/models/IMenuItem";
import { II18nManager } from "@pigeon/services/contracts/II18nManager";
import { uiBus } from "@pigeon/Ui.bus";
import { Inject } from "inversify-props";
import mitt, { Emitter } from "mitt";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

export enum DropdownDirections {
  Bottom = "bottom",
  Top = "top"
}

export type I18nLanguageDropdownEvents = {
  ["menu-close"]: void;
};

@Component
export default class I18nLanguageDropdown extends Vue {
  @Prop({
    validator: (value: DropdownDirections) => Object.values(DropdownDirections).includes(value),
    default: DropdownDirections.Bottom
  })
  readonly dropVertical: DropdownDirections;

  @Inject()
  private i18nManager: II18nManager;

  supportedLanguages: { [key: string]: IMenuItem } = SUPPORTED_LOCALES;
  selectedLanguage: IMenuItem | null = null;
  bus: Emitter<I18nLanguageDropdownEvents> = mitt<I18nLanguageDropdownEvents>();

  get SupportedLanguages(): IMenuItem[] {
    const languages: IMenuItem[] = [];

    Object.keys(this.supportedLanguages).map((kl) => languages.push(this.supportedLanguages[kl]));
    return languages;
  }

  get CurrentLocale(): string {
    return this.$route.params.locale || this.$i18n.locale || DEFAULT_LOCALE;
  }

  mounted() {
    const uiLocale = this.$auth.User ? this.$auth.User.preferredLanguage : this.CurrentLocale;
    this.UpdateUILocale(uiLocale);
    this.i18nManager.UpdateAspNetCoreCookieRequestCulture(this.$cookies, uiLocale);
    uiBus.on("login", () => this.OnLogin);
  }

  destroyed() {
    uiBus.off("login", () => this.OnLogin);
  }

  @Watch(nameof<I18nLanguageDropdown>("$route"))
  ObserveUIWithRouteLocale() {
    if (this.selectedLanguage && this.$route.params.locale == this.selectedLanguage.value) return;

    this.UpdateUILocale(this.$route.params.locale);
  }

  private OnLogin(user: IUserData): void {
    this.i18nManager.UpdateClientUrl(this.$i18n, user.preferredLanguage);
    this.UpdateUILocale(user.preferredLanguage);
    this.i18nManager.UpdateAspNetCoreCookieRequestCulture(this.$cookies, user.preferredLanguage);
  }

  public UpdateUILocale(locale: string): void {
    if (!locale) return;

    this.i18nManager.UpdateUILocale(this.$i18n, locale);
    this.selectedLanguage = this.supportedLanguages[locale];
  }

  public SetAppLocal(): void {
    this.bus.emit("menu-close");
    if (!this.selectedLanguage || (this.selectedLanguage && this.$i18n.locale === this.selectedLanguage.value)) return;

    const locale = this.selectedLanguage.value as string;
    this.i18nManager.UpdateClientUrl(this.$i18n, locale);
    this.UpdateUILocale(locale);
    this.i18nManager.UpdateUserPreferredLocale(locale);
    this.i18nManager.UpdateAspNetCoreCookieRequestCulture(this.$cookies, locale);

    appDataStore.InitializeCountries();
  }
}
