import { IBlobsService } from "@api/contracts/webapi/IBlobsService";
import { PictureTypes } from "@api/models/market/constants/PictureTypes";
import { VideoTypes } from "@api/models/market/constants/VideoTypes";
import { IChunkEntry } from "@api/models/market/IChunkEntry";
import { VIDEO_CHUNK_SIZE } from "@market/App.const";
import { IFilePicture } from "@pigeon/models/IFilePicture";
import { IFileVideo } from "@pigeon/models/IFileVideo";
import { IBlobManager } from "@pigeon/services/contracts/IBlobManager";
import { IFileManager } from "@pigeon/services/contracts/IFileManager";
import { Inject } from "inversify-props";

export const BLOB_CONTAINER_PICTURES = "pictures";
export const BLOB_CONTAINER_VIDEOS = "videos";
export const BLOB_DIRECTORY_PICTURES_PIGEONS = "pigeons";
export const BLOB_DIRECTORY_PICTURES_FANCIERS = "fanciers";
export const BLOB_DIRECTORY_PICTURES_AUCTIONS = "auctions";
export const BLOB_DIRECTORY_PICTURES_SHOPS = "shops";

export class BlobManager implements IBlobManager {
  @Inject()
  private blobsService: IBlobsService;
  @Inject()
  private fileManager: IFileManager;

  private BuildPigeonPictureBlobPath(pigeonId: number, filePicture: IFilePicture): string {
    return `${BLOB_DIRECTORY_PICTURES_PIGEONS}/${pigeonId}/${this.BuildPictureName(
      filePicture.pictureType,
      filePicture.file
    )}`;
  }

  private BuildFancierPictureBlobPath(fancierProfileId: number, filePicture: IFilePicture): string {
    return `${BLOB_DIRECTORY_PICTURES_FANCIERS}/${fancierProfileId}/${this.BuildPictureName(
      filePicture.pictureType,
      filePicture.file
    )}`;
  }

  private BuildAuctionPictureBlobPath(auctionId: number, filePicture: IFilePicture): string {
    return `${BLOB_DIRECTORY_PICTURES_AUCTIONS}/${auctionId}/${this.BuildPictureName(
      filePicture.pictureType,
      filePicture.file
    )}`;
  }

  private BuildShopPictureBlobPath(shopId: string, filePicture: IFilePicture): string {
    return `${BLOB_DIRECTORY_PICTURES_SHOPS}/${shopId}/${this.BuildPictureName(
      filePicture.pictureType,
      filePicture.file
    )}`;
  }

  private BuildPigeonVideoBlobPath(pigeonId: number, fileVideo: IFileVideo): string {
    return `${BLOB_DIRECTORY_PICTURES_PIGEONS}/${pigeonId}/${this.BuildVideoName(fileVideo.videoType, fileVideo.file)}`;
  }

  public BuildPictureName(pictureType: PictureTypes, file: File): string {
    let pictureName = "";

    switch (pictureType) {
      case PictureTypes.Pigeon:
        pictureName = "pigeon";
        break;
      case PictureTypes.Eye:
        pictureName = "eye";
        break;
      case PictureTypes.PigeonAndEye:
        pictureName = "pigeonAndEye";
        break;
      case PictureTypes.Pedigree:
        pictureName = "pedigree";
        break;
      case PictureTypes.Wing:
        pictureName = "wing";
        break;
      case PictureTypes.Fancier:
        pictureName = "fancier";
        break;

      case PictureTypes.Auction:
        pictureName = "auction";
        break;

      case PictureTypes.Shop:
        pictureName = "shop";
        break;
      case PictureTypes.Loft:
        pictureName = "loft";
        break;
      case PictureTypes.Network:
        pictureName = "network";
        break;

      case PictureTypes.Other:
      default:
        pictureName = "other";
        break;
    }

    return `${pictureName}.${this.fileManager.GetFileExtension(file)}`;
  }

  public BuildVideoName(videoType: VideoTypes, file: File): string {
    let pictureName = "";

    switch (videoType) {
      case VideoTypes.Pigeon:
        pictureName = "pigeon";
        break;
      case VideoTypes.Flight:
        pictureName = "flight";
        break;

      default:
        pictureName = "video";
        break;
    }

    return `${pictureName}.${this.fileManager.GetFileExtension(file)}`;
  }

  public ValidPictureMimeType({ type }: File): boolean {
    switch (type) {
      case "image/jpeg":
      case "image/png":
      case "image/svg+xml":
      case "image/gif":
      case "image/webp":
      case "application/pdf":
        return true;

      default:
        return false;
    }
  }

  public ValidVideoMimeType({ type }: File): boolean {
    switch (type) {
      case "video/3gpp":
      case "video/3gpp2":
      case "video/mp4":
      case "video/mpeg":
      case "video/ogg":
      case "video/quicktime":
      case "video/x-ms-wmv":
      case "video/x-msvideo":
      case "video/x-flv":
        return true;

      default:
        return false;
    }
  }

  public async InsertPigeonPictureAsync(pigeonId: number, filePicture: IFilePicture): Promise<void> {
    if (!filePicture || !filePicture.file || !this.ValidPictureMimeType(filePicture.file))
      Promise.reject("Invalid argument exception");

    try {
      const blobPath = this.BuildPigeonPictureBlobPath(pigeonId, filePicture);
      await this.blobsService.UploadAssetPicture(filePicture.file, BLOB_DIRECTORY_PICTURES_PIGEONS, pigeonId, blobPath);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  public async InsertPigeonVideoAsync(pigeonId: number, fileVideo: IFileVideo): Promise<void> {
    if (!fileVideo || !fileVideo.file || !this.ValidVideoMimeType(fileVideo.file))
      Promise.reject("Invalid argument exception");

    try {
      const blobChunks: Blob[] = this.SliceBlobIntoChunks(fileVideo.file);
      const blobFileName = this.BuildVideoName(VideoTypes.Pigeon, fileVideo.file);

      for (let index = 0; index < blobChunks.length; index++) {
        const chunk = blobChunks[index];
        const chunkEntry: IChunkEntry = {
          chunk: chunk,
          pigeonKey: pigeonId,
          blobName: blobFileName, //fileVideo.file.name,
          index: index
        };

        await this.blobsService.UploadChunk(chunkEntry);
      }

      await this.blobsService.CommitChunks(pigeonId, {
        fileName: blobFileName, //fileVideo.file.name,
        fileSize: fileVideo.file.size,
        fileType: fileVideo.file.type
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  private SliceBlobIntoChunks(fileBlob: Blob): Blob[] {
    const chunks = [];
    const chunkSize = VIDEO_CHUNK_SIZE * 1024 * 1024; // 10 MB
    const chunksAmount = Math.ceil(fileBlob.size / chunkSize);

    for (let i = 0; i < chunksAmount; i++) {
      const start = chunkSize * i;
      const end = chunkSize * (i + 1);

      const chunk = fileBlob.slice(start, end, fileBlob.type);
      chunks.push(chunk);
    }

    return chunks;
  }

  public async InsertFancierPictureAsync(fancierProfileId: number, filePicture: IFilePicture): Promise<void> {
    if (!filePicture || !filePicture.file || !this.ValidPictureMimeType(filePicture.file))
      Promise.reject("Invalid argument exception");

    const blobPath = this.BuildFancierPictureBlobPath(fancierProfileId, filePicture);
    try {
      await this.blobsService.UploadAssetPicture(
        filePicture.file,
        BLOB_DIRECTORY_PICTURES_FANCIERS,
        fancierProfileId,
        blobPath
      );
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  public async InsertAuctionPictureAsync(auctionId: number, filePicture: IFilePicture): Promise<void> {
    if (!filePicture || !filePicture.file || !this.ValidPictureMimeType(filePicture.file))
      Promise.reject("Invalid argument exception");

    const blobPath = this.BuildAuctionPictureBlobPath(auctionId, filePicture);
    try {
      await this.blobsService.UploadAssetPicture(
        filePicture.file,
        BLOB_DIRECTORY_PICTURES_AUCTIONS,
        auctionId,
        blobPath
      );
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  public async InsertShopPictureAsync(shopId: string, filePicture: IFilePicture): Promise<void> {
    if (!filePicture || !filePicture.file || !this.ValidPictureMimeType(filePicture.file))
      Promise.reject("Invalid argument exception");

    const blobPath = this.BuildShopPictureBlobPath(shopId, filePicture);
    try {
      await this.blobsService.UploadAssetPicture(filePicture.file, BLOB_DIRECTORY_PICTURES_SHOPS, shopId, blobPath);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}
