import { SexTypes } from "@api/models/market/constants/SexTypes";
import { IProductDropdownListItem } from "@pigeon/models/IProductDropdownListItem";

export class ProductItemFactory {
  public static GetUnknowItem(): IProductDropdownListItem {
    return {
      id: -1,
      relationship: "unknown",
      name: "unknown",
      ring: "XX 00-0000000",
      sex: SexTypes.Unknown
    };
  }
}
