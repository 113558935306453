import Vue from "vue";
import { BreakpointService } from "./breakpoint/BreakpointService";

export const breakpointService = new BreakpointService({
  breakpoint: {
    thresholds: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1600,
      xxl: undefined
    }
  }
});

// See https://fr.vuejs.org/v2/guide/plugins.html
const breakpointPlugin: any = {
  install(Vue: Vue) {
    (Vue as any).prototype.$breakpoint = (Vue as any).observable(breakpointService);
  }
};

export default breakpointPlugin;
