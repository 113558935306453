import { SaleTypes } from "@api/models/market/constants/SaleTypes";
import { ISale } from "@api/models/market/ISale";
import i18n from "@market/i18n";
import { IPackageCartItem } from "@market/models/IPackageCartItem";
import { IPackageManager } from "@pigeon/services/contracts/IPackageManager";
import { ISaleManager } from "@pigeon/services/contracts/ISaleManager";
import { cid, container } from "inversify-props";

export class PackageCartItemMapper {
  // Warning: Cannot use DI with static with property injection
  // @Inject()
  // private static saleManager: ISaleManager;
  // @Inject()
  // private static packageManager: IPackageManager;

  public static Map(sale: ISale): IPackageCartItem {
    if (!sale || !sale.id || !sale.package || !sale.packageId || (sale.type === SaleTypes.Bid && !sale.bids?.length))
      throw new Error("Argument exception");

    const saleManager = container.get<ISaleManager>(cid.SaleManager);
    const packageManager = container.get<IPackageManager>(cid.PackageManager);

    const salePackageItem: IPackageCartItem = {
      id: sale.id,
      product: sale.product,
      saleId: sale.id,
      sale: sale,
      reference: (i18n.t("app.package") + " " + i18n.t(`enum.discipline.${sale.package.discipline}`)) as string,
      name: sale.package.name,
      pictures: packageManager.GetPictures(sale.package),
      taxRate: saleManager.CalculateVatRate(sale),
      taxAmount: saleManager.CalculateVatAmount(sale),
      taxInclusive: sale.vatInclusive,
      price: saleManager.CalculateSaleAmountExcludingVat(sale),
      total: saleManager.CalculateSaleAmountIncludingVat(sale),
      currency: sale.currency,
      endDate: sale.endDate ? new Date(sale.endDate) : undefined,
      pickUpAddress: sale.pickUpAddress,
      pigeonsCount: packageManager.GetPigeonsCount(sale.package),
      malesCount: packageManager.GetPigeonsMaleCount(sale.package),
      femalesCount: packageManager.GetPigeonsFemaleCount(sale.package),
      genderlessesCount: packageManager.GetPigeonsGenderlessCount(sale.package),
      juniorsCount: packageManager.GetPigeonsJuniorCount(sale.package),
      yearlingsCount: packageManager.GetPigeonsYearlingCount(sale.package),
      seniorsCount: packageManager.GetPigeonsSeniorCount(sale.package),
      fancierName: sale.package.fancier ? sale.package.fancier.name : ""
    };

    return salePackageItem;
  }
}
