import i18n from "@market/i18n";
import { configure, extend, ValidationObserver, ValidationProvider } from "vee-validate";
import validationMessagesEn from "vee-validate/dist/locale/en.json";
import validationMessageFr from "vee-validate/dist/locale/fr.json";
import validationMessageNl from "vee-validate/dist/locale/nl.json";
import validationMessagePl from "vee-validate/dist/locale/pl.json";
import validationMessagePt from "vee-validate/dist/locale/pt_PT.json";
import {
  alpha,
  alpha_dash,
  confirmed,
  email,
  digits,
  max,
  max_value,
  min,
  min_value,
  regex,
  required
} from "vee-validate/dist/rules";
import Vue from "vue";
import customValidationMessagesEn from "./messages/en.json";
import customValidationMessagesFr from "./messages/fr.json";
import customValidationMessagesNl from "./messages/nl.json";
import customValidationMessagesPl from "./messages/pl.json";
import customValidationMessagesPt from "./messages/pt.json";

// See https://logaretm.github.io/vee-validate/guide/localization.html#i18n
// Localization
i18n.mergeLocaleMessage("en", {
  validations: { ...validationMessagesEn.messages, ...customValidationMessagesEn.messages }
});
i18n.mergeLocaleMessage("fr", {
  validations: { ...validationMessageFr.messages, ...customValidationMessagesFr.messages }
});
i18n.mergeLocaleMessage("nl", {
  validations: { ...validationMessageNl.messages, ...customValidationMessagesNl.messages }
});
i18n.mergeLocaleMessage("pl", {
  validations: { ...validationMessagePl.messages, ...customValidationMessagesPl.messages }
});
i18n.mergeLocaleMessage("pt", {
  validations: { ...validationMessagePt.messages, ...customValidationMessagesPt.messages }
});

configure({
  // this will be used to generate messages.
  defaultMessage: (_, values: any) => i18n.t(`validations.${values._rule_}`, values) as string
});

// Default rules
extend("required", required);
extend("regex", regex);
extend("email", email);
extend("digits", digits);
extend("confirmed", confirmed);
extend("alpha", alpha);
extend("alpha_dash", alpha_dash);
extend("min", min);
extend("max", max);
extend("min_value", min_value);
extend("max_value", max_value);

// Register validation components globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
