export class MathHelper {
  public static RoundTo2decimal(number: number): number {
    // See: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  public static RoundToInteger(number: number): number {
    return Math.round(number);
  }
}
