import { IRankingAssetsService } from "@api/contracts/odata/IRankingAssetsService";
import { IRankingAsset } from "@api/models/market/IRankingAsset";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { odataQuery } from "odata-fluent-query";

export class RankingAssetsService extends BaseODataService implements IRankingAssetsService {
  constructor() {
    super();
  }

  public FetchAllByPigeon(pigeonKey: number, top?: number): AxiosPromise<IODataCollectionResponse<IRankingAsset>> {
    let query = odataQuery<IRankingAsset>()
      .filter((ar) => ar.pigeonId.equals(pigeonKey))
      .count();

    if (top) query = query.paginate(top);

    return this.Client().get(`RankingAssets?${query.toString()}`);
  }
}
