import { IFavoritesService } from "@api/contracts/odata/IFavoritesService";
import { IFavorite } from "@api/models/market/IFavorite";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import odataQuery from "odata-fluent-query";

export class FavoritesService extends BaseODataService implements IFavoritesService {
  constructor() {
    super();
  }

  public Insert(favorite: IFavorite): AxiosPromise<IFavorite> {
    return this.Client().post("Favorites", favorite);
  }

  public Delete(favoriteKey: number): AxiosPromise<void> {
    return this.Client().delete(`Favorites(${favoriteKey})`);
  }

  public FetchAllByUser(userKey?: string): AxiosPromise<IODataCollectionResponse<IFavorite>> {
    if (!userKey) return Promise.reject(new Error("Argument exception: userKey is missing"));

    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<IFavorite>()
      .filter((f) => f.userId.equals(userKey, { ignoreGuid: true }))
      .orderBy("recordedDate", "desc");

    return this.Client().get(`Favorites?${query.toString()}`);
  }

  public FetchAllWithSaleProductByUser(userKey?: string): AxiosPromise<IODataCollectionResponse<IFavorite>> {
    if (!userKey) return Promise.reject(new Error("Argument exception: userKey is missing"));

    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<IFavorite>()
      .filter((f) => f.userId.equals(userKey, { ignoreGuid: true }))
      .orderBy("recordedDate", "desc")
      .expand("sale", (s) =>
        s
          .select("id", "product", "type")
          .expand("pigeon", (p) => p.select("id", "name", "ring"))
          .expand("package", (p) => p.select("id", "name", "discipline"))
      );

    return this.Client().get(`Favorites?${query.toString()}`);
  }

  public Fetch(favoriteKey: number): AxiosPromise<IFavorite> {
    return this.Client().get(`Favorites(${favoriteKey})`);
  }
}
