import { IContactsService } from "@api/contracts/webapi/IContactsService";
import { Channels } from "@api/enumerations/Channels";
import { IContactMessage } from "@api/models/auth/IContact";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { AxiosPromise } from "axios";

export class ContactsService extends BaseApiService implements IContactsService {
  constructor() {
    super();
  }

  Message(contact: IContactMessage): AxiosPromise<void> {
    return this.Client().post(`Contacts/${Channels.Market}`, contact);
  }
}
