import { AppRoutes } from "@market/routers/App.routes";
import { appStore } from "@market/stores/App.store.modules";
import { authGuard } from "@pigeon/guards/authGuard";
import { authRoleGuard } from "@pigeon/guards/authRoleGuard";
import { GetUserLocale } from "@pigeon/i18n/i18n";
import { uiBus } from "@pigeon/Ui.bus";
import NProgress from "nprogress";
import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

// Nprogress
NProgress.configure({ showSpinner: false, speed: 600 });
// router
Vue.use(VueRouter);

const router: VueRouter = new VueRouter({
  routes: routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      const lastFrom = from.matched[from.matched.length - 1];
      const lastTo = to.matched[to.matched.length - 1];

      if (lastFrom && lastTo && lastFrom.name?.startsWith("sale.") && lastTo.name?.startsWith("sale.")) return;
      else return { x: 0, y: 0 };
    }
  }
});

// Global guards
router.beforeEach((to, from, next) => {
  const redirectToDefault = () => next({ name: AppRoutes.Default, params: { locale: GetUserLocale() } });

  if (to.path || to.name) {
    NProgress.start();
  }

  if (authGuard(to) && authRoleGuard(to)) next();
  else redirectToDefault();
});

// After hooks
router.afterEach((to, from) => {
  NProgress.set(0.99); // 100%
  window.setTimeout(() => {
    NProgress.done();
  }, 500);

  // referral code
  if (to.query?.["referralCode"]) {
    appStore.UpdateReferralCode(to.query["referralCode"] as string);
  }
});

uiBus.on("logout", () => {
  if (router.currentRoute?.meta?.requiresAuth) {
    router.push({ name: AppRoutes.Default, params: { locale: GetUserLocale() } });
  }
});

export default router;
