import { IShippingServicesService } from "@api/contracts/odata/IShippingServicesService";
import { IShippingRate } from "@api/models/market/IShippingRate";
import { IShippingRateZipRange } from "@api/models/market/IShippingRateZipRange";
import { IShippingService } from "@api/models/market/IShippingService";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, FilterBuilder, odataQuery } from "odata-fluent-query";

export class ShippingServicesService extends BaseODataService implements IShippingServicesService {
  constructor() {
    super();
  }

  public Fetch(shippingServiceKey: number): AxiosPromise<IShippingService> {
    return this.Client().get(`ShippingServices(${shippingServiceKey})`);
  }

  public FetchAllByFromAndByTo(
    from: string, // country iso letters
    to: string, // country iso letters
    fromZipCode?: number,
    toZipCode?: number
  ): AxiosPromise<IODataCollectionResponse<IShippingService>> {
    // prettier-ignore
    const query = odataQuery<IShippingService>()
      .filter((ss) => ss.from.equals(from))
      .filter((ss) => ss.to.equals(to))
      .expand("provider")
      .expand("rates", (sr: ExpandObjectQuery<IShippingRate>) => sr
        .select("id", "fromNth", "unitPrice", "currency")
        .expand("fromShippingRateZipRanges", fromZipCode 
          ? (fromZipRanges) => fromZipRanges
            .filter((fromZipRanges: FilterBuilder<IShippingRateZipRange>) => fromZipRanges.zipRange.startZipCode.biggerThan(fromZipCode) || fromZipRanges.zipRange.startZipCode.equals(fromZipCode))
            .filter((fromZipRanges: FilterBuilder<IShippingRateZipRange>) => fromZipRanges.zipRange.endZipCode.lessThan(fromZipCode) || fromZipRanges.zipRange.endZipCode.equals(fromZipCode))
            .expand("zipRange")
          : undefined
        )
        .expand("toShippingRateZipRanges", toZipCode 
          ? toZipRanges => toZipRanges
            .filter(toZipRanges => toZipRanges.zipRange.startZipCode.biggerThan(toZipCode) || toZipRanges.zipRange.startZipCode.equals(toZipCode))
            .filter(toZipRanges => toZipRanges.zipRange.endZipCode.lessThan(toZipCode) || toZipRanges.zipRange.endZipCode.equals(toZipCode))
            .expand("zipRange")
          : undefined
        )
      );

    return this.Client().get(`ShippingServices?${query.toString()}`);
  }
}
