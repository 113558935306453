import { Channels } from "@api/enumerations/Channels";
import { IAuth, IAuthResponse } from "@api/models/auth/IAuth";
import { IAuthentication } from "@api/models/auth/IAuthentication";
import { IAuthSession } from "@api/models/auth/IAuthSession";
import { IContributorRegistration } from "@api/models/auth/IContributorRegistration";
import { IRecovery } from "@api/models/auth/IRecovery";
import { IRegistration } from "@api/models/auth/IRegistration";
import { IReset } from "@api/models/auth/IReset";
import { ISignUpBuyerRegistration } from "@api/models/auth/ISignUpBuyerRegistration";
import { ISignUpContributorRegistration } from "@api/models/auth/ISignUpContributorRegistration";
import { ISignUpRegistration } from "@api/models/auth/ISignUpRegistration";
import { ISignUpVendorRegistration } from "@api/models/auth/ISignUpVendorRegistration";
import { IVendorRegistration } from "@api/models/auth/IVendorRegistration";
import { IGuest } from "@api/models/market/IGuest";
import { BaseAuthServiceAnonymous } from "@api/services/auth/base/BaseAuthService.anonymous";
import { AxiosPromise } from "axios";

export class AccountsServiceAnonymous extends BaseAuthServiceAnonymous {
  //#region Token
  RefreshAuth(expiredAuth: IAuth): AxiosPromise<IAuthResponse> {
    if (!expiredAuth.accessToken || !expiredAuth.refreshToken) {
      return Promise.reject("Invalid RefreshAuth arguments");
    }

    return this.Client().post("Accounts/RefreshAuth", expiredAuth);
  }
  //#endregion

  //#region Account
  SignIn(authentication: IAuthentication): AxiosPromise<IAuthSession> {
    return this.Client().post("Accounts/SignIn", authentication);
  }

  SignUp(channel: Channels, registration: IRegistration): AxiosPromise<IAuthSession> {
    return this.Client().post(`Accounts/SignUp/${channel}`, registration);
  }

  SignUpAsVendor(registration: IVendorRegistration): AxiosPromise<IAuthSession> {
    return this.Client().post("Accounts/Vendor/SignUp", registration);
  }

  SignUpAsContributor(registration: IContributorRegistration): AxiosPromise<IAuthSession> {
    return this.Client().post("Accounts/Contributor/SignUp", registration);
  }

  Register(guest: IGuest): AxiosPromise<IGuest> {
    return this.Client().post("Accounts/Register/Guest", guest);
  }

  RecoverAccount(channel: Channels, recovery: IRecovery, locale: string): AxiosPromise<void> {
    const clientRedirectionSlug = `account/reset-password`;
    recovery.redirectUrl = `${window.location.origin}/${locale}/${clientRedirectionSlug}`;
    return this.Client().post(`Accounts/RecoverAccount/${channel}`, recovery);
  }

  ResetPassword(reset: IReset): AxiosPromise<void> {
    return this.Client().post("Accounts/ResetPassword", reset);
  }
  //#endregion

  //#region IsUnique
  IsMemberEmailUnique(email: string): AxiosPromise<boolean> {
    const signUpRegistration: ISignUpRegistration = { email: email };

    return this.Client().post<boolean>("Accounts/IsUnique", signUpRegistration);
  }

  IsVendorNameUnique(name: string): AxiosPromise<boolean> {
    const signUpRegistration: ISignUpVendorRegistration = { alias: name };

    return this.Client().post<boolean>("Accounts/Vendor/IsUnique", signUpRegistration);
  }

  IsShopUnique(shop: string): AxiosPromise<boolean> {
    const signUpRegistration: ISignUpVendorRegistration = { shopId: shop };

    return this.Client().post<boolean>("Accounts/Shop/IsUnique", signUpRegistration);
  }

  IsBuyerNameUnique(name: string): AxiosPromise<boolean> {
    const signUpRegistration: ISignUpBuyerRegistration = { alias: name };

    return this.Client().post<boolean>("Accounts/Buyer/IsUnique", signUpRegistration);
  }

  IsContributorNameUnique(name: string): AxiosPromise<boolean> {
    const signUpRegistration: ISignUpContributorRegistration = { alias: name };

    return this.Client().post<boolean>("Accounts/Contributor/IsUnique", signUpRegistration);
  }
  //#endregion

  //#region Guest
  FindGuest(email: string, lastname: string, firstname: string): AxiosPromise<IGuest> {
    return this.Client().get<IGuest>(`Accounts/Find/Guest/${email}/${lastname}/${firstname}`);
  }
  //#endregion
}
