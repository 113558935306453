import { PictureTypes } from "@api/models/market/constants/PictureTypes";
import { SexTypes } from "@api/models/market/constants/SexTypes";
import { IPackage } from "@api/models/market/IPackage";
import { IPicture } from "@api/models/market/IPicture";
import { IPrize } from "@api/models/market/IPrize";
import { IPrizesRanking } from "@api/models/market/IPrizesRanking";
import { IRankingAsset } from "@api/models/market/IRankingAsset";
import { IPackageManager } from "@pigeon/services/contracts/IPackageManager";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { Inject } from "inversify-props";
import { kebabCase } from "lodash-es";

export class PackageManager implements IPackageManager {
  @Inject()
  private pigeonManager: IPigeonManager;

  readonly defaultPackagePicture = require("@pigeon/assets/images/pictures/package-generic.webp");
  readonly defaultPedigreePicture = "";
  readonly defaultPedigreePdf = "";

  //#region Sheet
  public IsRinged(pack: IPackage): boolean {
    if (pack.withRings) return pack.withRings;

    if (!pack || !pack.pigeons || !pack.pigeons.length) return false;
    return pack.pigeons.some((p) => (p.ringNumber || p.ring ? true : false));
  }

  public HasPedigree(pack: IPackage): boolean {
    if (pack.withPedigrees) return pack.withPedigrees;

    if (!pack || !pack.pigeons || !pack.pigeons.length) return false;
    return pack.pigeons.some(
      (p) => this.pigeonManager.HasPicturePedigree(p.pictures) || this.pigeonManager.HasPdfPedigree(p.pictures)
    );
  }

  //#endregion

  //#region Count
  public GetPigeonsCount(pack: IPackage): number {
    if (pack.pigeonItemsNumber) return pack.pigeonItemsNumber;

    if (!pack || !pack.pigeons || !pack.pigeons.length) return 0;
    return pack.pigeons.length;
  }

  public GetPigeonsMaleCount(pack: IPackage): number {
    if (pack.pigeonMaleItemsNumber) return pack.pigeonMaleItemsNumber;

    if (!pack || !pack.pigeons || !pack.pigeons.length) return 0;
    return pack.pigeons.filter((p) => p.sex === SexTypes.Male).length;
  }

  public GetPigeonsFemaleCount(pack: IPackage): number {
    if (pack.pigeonFemaleItemsNumber) return pack.pigeonFemaleItemsNumber;

    if (!pack || !pack.pigeons || !pack.pigeons.length) return 0;
    return pack.pigeons.filter((p) => p.sex === SexTypes.Female).length;
  }

  public GetPigeonsGenderlessCount(pack: IPackage): number {
    if (!pack || !pack.pigeons || !pack.pigeons.length) return 0;

    return pack.pigeons.filter((p) => p.sex === SexTypes.Unknown).length;
  }

  public GetPigeonsJuniorCount(pack: IPackage): number {
    if (!pack || !pack.pigeons || !pack.pigeons.length) return 0;

    return pack.pigeons.filter((p) => this.pigeonManager.GetAgeCategory(p) === "Junior").length;
  }

  public GetPigeonsYearlingCount(pack: IPackage): number {
    if (!pack || !pack.pigeons || !pack.pigeons.length) return 0;

    return pack.pigeons.filter((p) => this.pigeonManager.GetAgeCategory(p) === "Yearling").length;
  }

  public GetPigeonsSeniorCount(pack: IPackage): number {
    if (!pack || !pack.pigeons || !pack.pigeons.length) return 0;

    return pack.pigeons.filter((p) => this.pigeonManager.GetAgeCategory(p) === "Old").length;
  }

  public GetReproducersCount(pack: IPackage): number {
    if (pack.reproducerItemsNumber) return pack.reproducerItemsNumber;

    if (!pack || !pack.reproducerParents || !pack.reproducerParents.length) return 0;
    return pack.reproducerParents.length;
  }

  public GetReproducersMaleCount(pack: IPackage): number {
    if (pack.reproducerMaleItemsNumber) return pack.reproducerMaleItemsNumber;

    if (!pack || !pack.reproducerParents || !pack.reproducerParents.length) return 0;
    return pack.reproducerParents.map((rp) => rp.parent).filter((p) => p?.sex === SexTypes.Male).length;
  }

  public GetReproducersFemaleCount(pack: IPackage): number {
    if (pack.reproducerFemaleItemsNumber) return pack.reproducerFemaleItemsNumber;

    if (!pack || !pack.reproducerParents || !pack.reproducerParents.length) return 0;
    return pack.reproducerParents.map((rp) => rp.parent).filter((p) => p?.sex === SexTypes.Female).length;
  }
  //#endregion

  //#region Slug
  public GetNameSlug(pack: IPackage | undefined): string | undefined {
    if (!pack || !pack.name) return;

    return kebabCase(pack.name);
  }
  //#endregion

  //#region Picture/Video
  public GetPictureRemoteUrl(relativeUrl: string | null | undefined): string {
    if (!relativeUrl) return this.defaultPackagePicture;

    // test if parameter is a remote url
    if (/(http(s?)):\/\//gi.test(relativeUrl)) return relativeUrl;

    // test if parameter is a inline data image
    if (/(data):/gi.test(relativeUrl)) return relativeUrl;

    return `${process.env.VUE_APP_AZURE_STORAGE_HOST}${relativeUrl}`;
  }

  public GetVideoRemoteUrl(relativeUrl: string | null | undefined): string {
    if (!relativeUrl) return this.defaultPackagePicture;

    // test if parameter is a remote url
    if (/(http(s?)):\/\//gi.test(relativeUrl)) return relativeUrl;

    // test if parameter is a inline data image
    if (/(data):/gi.test(relativeUrl)) return relativeUrl;

    return `${process.env.VUE_APP_AZURE_STORAGE_HOST}${relativeUrl}`;
  }

  public GetPictures(
    pack: IPackage,
    pictureTypes: PictureTypes[] = [
      PictureTypes.Package,
      PictureTypes.Pigeon,
      PictureTypes.PigeonAndEye,
      PictureTypes.Eye,
      PictureTypes.Wing
    ]
  ): IPicture[] {
    if (!pack || !pack.pigeons) {
      return [];
    }

    let pictures: IPicture[] = [];

    if (pack.pictures && pictureTypes.includes(PictureTypes.Package)) {
      const packagePicture: IPicture | undefined = pack.pictures.find(
        (picture) => picture.type === PictureTypes.Package
      );
      if (packagePicture) pictures.push(packagePicture);
    }

    pack.pigeons.forEach((pigeon) => {
      const pigeonPictures = pigeon.pictures?.filter((picture) => pictureTypes.includes(picture.type));

      if (pigeonPictures && pigeonPictures.length) {
        pictures = [...pictures, ...pigeonPictures];
      }
    });

    return pictures;
  }

  public GetPicturesUrl(
    pack: IPackage,
    useRawPicture = false,
    pictureTypes: PictureTypes[] = [
      PictureTypes.Package,
      PictureTypes.Pigeon,
      PictureTypes.PigeonAndEye,
      PictureTypes.Eye,
      PictureTypes.Wing
    ]
  ): string[] {
    const picturesUrl: string[] = [];
    const pictures = this.GetPictures(pack, pictureTypes);

    pictures.forEach((picture) => {
      let pictureUrl = picture.urlWebOptimized && !useRawPicture ? picture.urlWebOptimized : picture.url;
      if (!pictureUrl) pictureUrl = this.defaultPackagePicture;

      const pictureRemoteUrl = this.GetPictureRemoteUrl(pictureUrl);
      if (pictureRemoteUrl) picturesUrl.push(pictureRemoteUrl);
    });

    return picturesUrl;
  }

  public GetPicturesThumbnail(pictures: IPicture[], useRawPicture = false): string[] {
    const picturesUrl: string[] = [];

    pictures = pictures.filter((pic) => pic.type === PictureTypes.Pigeon || pic.type === PictureTypes.PigeonAndEye);
    pictures.forEach((picture) => {
      let pictureUrl = picture.urlWebOptimized && !useRawPicture ? picture.urlWebOptimized : picture.url;
      if (!pictureUrl) pictureUrl = this.defaultPackagePicture;

      const pictureRemoteUrl = this.GetPictureRemoteUrl(pictureUrl);
      if (pictureRemoteUrl) picturesUrl.push(pictureRemoteUrl);
    });

    return picturesUrl;
  }

  public GetPicturePackage(pictures?: IPicture[], useRawPicture = false): string {
    if (!pictures || !pictures.length) {
      return `${this.defaultPackagePicture}`;
    }

    const picture: IPicture | undefined = pictures.find((picture) => picture.type === PictureTypes.Package);
    if (!picture) return this.defaultPackagePicture;

    const pictureUrl = picture.urlWebOptimized && !useRawPicture ? picture.urlWebOptimized : picture.url;
    if (!pictureUrl) return this.defaultPackagePicture;

    return this.GetPictureRemoteUrl(pictureUrl);
  }

  public GetThumbnailPackage(pictures?: IPicture[], useRawPicture = false): string {
    const picture = this.GetPicturePackage(pictures, useRawPicture);
    if (!picture) return "";

    try {
      const thumbnailUrl = new URL(picture);
      if (!useRawPicture) {
        const thumbnailPathSegments = thumbnailUrl.pathname.split("/");

        if (thumbnailPathSegments.length > 0) {
          thumbnailPathSegments[thumbnailPathSegments.length - 1] = "thumbnail.webp";
          const webOptimizedThumbnailPath = thumbnailPathSegments.join("/");
          thumbnailUrl.pathname = webOptimizedThumbnailPath;
        }
      }

      return thumbnailUrl.href;
    } catch (error: any) {
      console.error(`Invalid URL picture : '${picture}'`);
      return "";
    }
  }

  public HasPicturePedigree(pictures: IPicture[] | undefined): boolean {
    if (!pictures || pictures.length < 1) {
      return false;
    }

    return pictures.some((picture) => picture.type === PictureTypes.Pedigree);
  }

  public GetPicturePedigree(pictures?: IPicture[], useRawPicture = false): string {
    if (!this.HasPicturePedigree(pictures) || !pictures) {
      //return `${this.defaultPedigreePicture}`;
      return "";
    }

    const pedigreePicture: IPicture | undefined = pictures.find((picture) => picture.type === PictureTypes.Pedigree);
    //if (!pedigreePicture) return this.defaultPedigreePicture;
    if (!pedigreePicture) return "";

    const pedigreePictureUrl =
      pedigreePicture.urlWebOptimized && !useRawPicture ? pedigreePicture.urlWebOptimized : pedigreePicture.url;
    if (!pedigreePictureUrl) return "";

    return this.GetPictureRemoteUrl(pedigreePictureUrl);
  }

  public HasPdfPedigree(pictures: IPicture[] | undefined): boolean {
    if (!pictures || pictures.length < 1) {
      return false;
    }

    return pictures.some((picture) => picture.type === PictureTypes.PedigreePdf);
  }

  public GetPdfPedigree(pictures: IPicture[] | undefined): string {
    if (!this.HasPdfPedigree(pictures) || !pictures) {
      //return `${location.origin}${this.defaultPedigreePdf}`;
      return "";
    }

    const pedigreePdf: IPicture | undefined = pictures.find((picture) => picture.type === PictureTypes.PedigreePdf);
    //if (!pedigreePdf) return this.defaultPedigreePdf;
    if (!pedigreePdf) return "";

    const pedigreePdfUrl = pedigreePdf.url;
    return this.GetPictureRemoteUrl(pedigreePdfUrl);
  }
  //#endregion

  //#region Asset
  // Business Rules : Take the best top that covers at least 80% of number of prizes or at default take the top 1000
  // Business Rules : Take max 3 items
  public CalculateAssetPrizeRankings(
    prizes: IPrize[] | undefined,
    assetRankings: IRankingAsset[] | undefined
  ): IPrizesRanking[] {
    // Note: prize must have ranking navigation property to access area property
    if (!prizes || !prizes.length || !assetRankings || !assetRankings.length) return [];
    const taggedAssetPrizes = prizes.filter((p) => p.taggedAsAsset === true);

    if (!taggedAssetPrizes.length) return [];
    const assetRankingsAreas: string[] = assetRankings.map((ar) => ar.areaType);
    const areas: string[] = taggedAssetPrizes.filter((ap) => assetRankingsAreas.includes(ap.area)).map((p) => p.area);
    const setAreas: Set<string> = new Set(areas);

    const prizesRankings: IPrizesRanking[] = [];
    for (const area of setAreas) {
      const prizesFilteredByArea: IPrize[] = taggedAssetPrizes.filter((p) => p.area == area);
      const prizesRanking = this.CalculateAssetPrizeRankingsForArea(prizesFilteredByArea, area);
      prizesRankings.push(prizesRanking);
    }

    return prizesRankings.length > 3 ? prizesRankings.slice(0, 3) : prizesRankings;
  }

  private CalculateAssetPrizeRankingsForArea(prizesFilteredByArea: IPrize[], area: string): IPrizesRanking {
    const count: number = prizesFilteredByArea.length;
    const countTop10: number = prizesFilteredByArea.filter((pra) => pra.rank <= 10).length;
    const countTop100: number = prizesFilteredByArea.filter((pra) => pra.rank <= 100).length;
    const countTop200: number = prizesFilteredByArea.filter((pra) => pra.rank <= 200).length;
    const countTop1000: number = prizesFilteredByArea.filter((pra) => pra.rank <= 1000).length;

    if (countTop10 >= 0.8 * count) {
      return { count: countTop10, area: area, top: 10 };
    } else if (countTop100 >= 0.8 * count) {
      return { count: countTop100, area: area, top: 100 };
    } else if (countTop200 >= 0.8 * count) {
      return { count: countTop200, area: area, top: 200 };
    } else {
      return { count: countTop1000, area: area, top: 1000 };
    }
  }

  public HasAsset(pack: IPackage): boolean {
    if (!pack) return false;

    return true;
  }
  //#endregion
}
