import { IParentAssetsService } from "@api/contracts/odata/IParentAssetsService";
import { IParentAsset } from "@api/models/market/IParentAsset";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { odataQuery } from "odata-fluent-query";

export class ParentAssetsService extends BaseODataService implements IParentAssetsService {
  constructor() {
    super();
  }

  public FetchAllByPigeon(pigeonKey: number, top?: number): AxiosPromise<IODataCollectionResponse<IParentAsset>> {
    let query = odataQuery<IParentAsset>()
      .select("parentId")
      .expand("parent", (q) => q.select("id", "name", "ring", "sex", "asset", "assetText"))
      .filter((ap) => ap.pigeonId.equals(pigeonKey))
      .count();

    if (top) query = query.paginate(top);

    return this.Client().get(`ParentAssets?${query.toString()}`);
  }
}
