import { IFileManager } from "./contracts/IFileManager";

export class FileManager implements IFileManager {
  public GetFileExtension({ name: filename }: File): string {
    if (!filename) return "";

    const extension = filename.split(".").pop();
    return extension ? extension.toLowerCase() : "";
  }
}
