import { IReproducerParentsService } from "@api/contracts/odata/IReproducerParentsService";
import { SaleStatus } from "@api/models/market/constants/SaleStatus";
import { IReproducerParent } from "@api/models/market/IReproducerParent";
import { ISale } from "@api/models/market/ISale";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import odataQuery, { FilterBuilder } from "odata-fluent-query";

export class ReproducerParentsService extends BaseODataService implements IReproducerParentsService {
  constructor() {
    super();
  }

  public FetchAll(userKey?: string): AxiosPromise<IODataCollectionResponse<IReproducerParent>> {
    let query = odataQuery<IReproducerParent>().expand("parent");

    if (userKey) {
      query = query.filter((rp) => rp.parent.userId.equals(userKey, { ignoreGuid: true }));
    }

    return this.Client().get(`ReproducerParents?${query.toString()}`);
  }

  public FetchAllAvailable(userKey?: string): AxiosPromise<IODataCollectionResponse<IReproducerParent>> {
    let query = odataQuery<IReproducerParent>()
      .expand("parent")
      .filter((rp) =>
        rp.parent.sales.empty().or(rp.parent.sales.any((s: FilterBuilder<ISale>) => s.status.in([SaleStatus.Unsold])))
      );

    if (userKey) {
      query = query.filter((rp) => rp.parent.userId.equals(userKey, { ignoreGuid: true }));
    }

    return this.Client().get(`ReproducerParents?${query.toString()}`);
  }
}
