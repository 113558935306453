import { IPrizesRanking } from "@api/models/market/IPrizesRanking";
import PigeonAssetRanking from "@pigeon/components/pigeon/PigeonAssetRanking.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    AssetRanking: PigeonAssetRanking
  }
})
export default class PigeonAssetRankings extends Vue {
  @Prop({ default: () => [] })
  readonly rankings: IPrizesRanking[];

  get OrderedRankings(): IPrizesRanking[] {
    const orderedRankings: IPrizesRanking[] = [];

    for (let index = 0, count = this.rankings.length; index < count; index++) {
      const orderedRanking = this.rankings[index];
      orderedRanking.order = index + 1;
      orderedRankings.push(orderedRanking);
    }

    return orderedRankings;
  }
}
