import TheAlerts from "@market/components/layout/TheAlerts.vue";
import TheBetaRibbon from "@market/components/layout/TheBetaRibbon.vue";
import TheFooter from "@market/components/layout/TheFooter.vue";
import TheNavBar from "@market/components/layout/TheNavBar.vue";
import TheNotifications from "@market/components/layout/TheNotifications.vue";
import TheSearchBar from "@market/components/layout/TheSearchBar.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheNavBar,
    TheFooter,
    TheAlerts,
    TheBetaRibbon,
    TheNotifications,
    TheSearchBar
  }
})
export default class TheLayout extends Vue {}
