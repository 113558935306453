import { appDataStore } from "@market/stores/App.store.modules";
import "famfamfam-flags/dist/sprite/famfamfam-flags.min.css";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProductCountryFlag extends Vue {
  @Prop()
  readonly countryCode: string;
  @Prop()
  readonly countryName: string;
  @Prop({ default: false })
  readonly showLabel: boolean;

  get CountryFlag(): string {
    const code = this.countryName
      ? appDataStore.Countries.find((c) => c.label.toUpperCase() == this.countryName.toUpperCase())?.code
      : this.countryCode?.toLowerCase() ?? "";

    return code?.toLowerCase() ?? "";
  }

  get CountryLabel(): string | undefined {
    if (this.countryName) return this.countryName;

    if (this.countryCode) {
      const label = appDataStore.Countries.find((c) => c.code.toUpperCase() == this.countryCode.toUpperCase())?.label;
      return label ? label : this.countryCode;
    }
  }
}
