import { ISaleDisapprovalReasonsService } from "@api/contracts/odata/ISaleDisapprovalReasonsService";
import { ISaleDisapprovalReason } from "@api/models/market/ISaleDisapprovalReason";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { odataQuery } from "odata-fluent-query";

export class SaleDisapprovalReasonsService extends BaseODataService implements ISaleDisapprovalReasonsService {
  constructor() {
    super();
  }

  public Insert(saleDisapprovalReason: ISaleDisapprovalReason): AxiosPromise<ISaleDisapprovalReason> {
    return this.Client().post("SaleDisapprovalReasons", saleDisapprovalReason);
  }

  public Delete(saleKey: number, reasonType: string): AxiosPromise<void> {
    return this.Client().delete(`SaleDisapprovalReasons(keySale=${saleKey},keyDisapproval='${reasonType}')`);
  }

  public FetchAllReasonsBySale(saleKey: number): AxiosPromise<IODataCollectionResponse<ISaleDisapprovalReason>> {
    const query = odataQuery<ISaleDisapprovalReason>()
      .filter((sdr) => sdr.saleId.equals(saleKey))
      .count();

    return this.Client().get(`SaleDisapprovalReasons?${query.toString()}`);
  }
}
