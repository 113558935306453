import { AppThemes } from "@api/models/market/constants/AppThemes";
import { IPigeon } from "@api/models/market/IPigeon";
import PigeonDropdownlistItem from "@pigeon/components/pigeon/PigeonDropdownlistItem.vue";
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    PigeonDropdownlistItem
  }
})
export default class PigeonDropdownlist extends Vue {
  @Model("change")
  readonly selected: IPigeon | null;
  @Prop({ type: Array })
  readonly pigeons: IPigeon[];
  @Prop({ default: false })
  readonly isLoading: boolean;
  @Prop({ default: AppThemes.Light, validator: (value: AppThemes) => Object.values(AppThemes).includes(value) })
  readonly theme: AppThemes;

  isExpanded: boolean = false;

  @Emit("expand")
  public ToggleExpand(): void {
    if (this.isLoading) return;
    this.isExpanded = !this.isExpanded;
  }

  @Emit("expand")
  public Expand(): void {
    if (this.isLoading) return;
    this.isExpanded = true;
  }

  public Collapse(): void {
    if (this.isLoading) return;
    this.isExpanded = false;
  }

  @Emit("change")
  public SelectItem(selected: IPigeon) {
    this.isExpanded = false;

    if (selected !== this.selected && selected) {
      return selected;
    }
  }
}
