import NavStepperStepInstance from "@pigeon/components/nav/NavStepperStep";
import { nameof } from "@pigeon/extensions/nameof";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Location } from "vue-router";

export enum StepperBehaviorModes {
  RouteName = "routeName",
  RouteMeta = "routeMeta",
  Parent = "parent"
}

@Component
export default class NavStepper extends Vue {
  steps: string[] = [];

  @Prop()
  readonly step?: string;
  @Prop({
    default: StepperBehaviorModes.RouteName,
    validator: (value: StepperBehaviorModes) => Object.values(StepperBehaviorModes).includes(value)
  })
  readonly behaviorMode: StepperBehaviorModes;

  currentStepId: string | null = null;

  get Steps(): string[] {
    return this.steps;
  }

  get HasPreviousSteps() {
    if (!this.currentStepId) return false;

    const stepIndex = this.steps.indexOf(this.currentStepId);
    return stepIndex > 0 && this.steps.length > 1;
  }

  get HasNextSteps() {
    if (!this.currentStepId) return false;

    const stepIndex = this.steps.indexOf(this.currentStepId);
    return stepIndex >= 0 && this.steps.length > 1 && stepIndex < this.steps.length - 1;
  }

  mounted() {
    this.ObserveCurrentStepId();
  }

  @Watch(nameof<NavStepper>("$route"), { immediate: true })
  @Watch(nameof<NavStepper>("step"), { immediate: true })
  ObserveCurrentStepId() {
    this.currentStepId = this.CurrentStepId();
  }

  public CurrentStepId(): string | null {
    if (!this.steps || !this.steps.length) return null;

    if (this.behaviorMode === StepperBehaviorModes.RouteName) {
      return this.$route?.name ?? null;
    } else if (this.behaviorMode === StepperBehaviorModes.RouteMeta) {
      return this.$route?.meta?.step;
    } else if (this.behaviorMode === StepperBehaviorModes.Parent) {
      return this.step ?? null;
    } else {
      return null;
    }
  }

  Register(stepId: string) {
    if (!this.steps.includes(stepId)) {
      this.steps.push(stepId);
    }
  }

  Unregister(stepId: string) {
    const index = this.steps.indexOf(stepId);

    if (index >= 0) {
      this.steps.splice(index, 1);
    }
  }

  private GetChildStep(childStepId: string): NavStepperStepInstance | null {
    if (!childStepId) return null;

    let childStep = null;

    for (let index: number = 0; index < this.$children.length; index++) {
      const element: NavStepperStepInstance = this.$children[index] as NavStepperStepInstance;

      if (element.stepId && element.stepId === childStepId) {
        childStep = element;
        break;
      }
    }

    return childStep;
  }

  private GetPreviousStep(): NavStepperStepInstance | null {
    if (!this.steps || !this.steps.length || !this.currentStepId) return null;

    const currentStepIndex = this.steps.indexOf(this.currentStepId);
    const previousStepIndex = currentStepIndex > 1 ? currentStepIndex - 1 : 0;
    const previousStepId = this.steps[previousStepIndex];
    return this.GetChildStep(previousStepId);
  }

  private GetNextStep(): NavStepperStepInstance | null {
    if (!this.steps || !this.steps.length || !this.currentStepId) return null;

    const currentStepIndex = this.steps.indexOf(this.currentStepId);
    const nextStepIndex = currentStepIndex < this.steps.length - 1 ? currentStepIndex + 1 : this.steps.length;
    const nextStepId = this.steps[nextStepIndex];
    return this.GetChildStep(nextStepId);
  }

  public GetPreviousStepLocation(): Location | undefined {
    if (this.behaviorMode !== StepperBehaviorModes.RouteMeta) return undefined;

    const previousStep: NavStepperStepInstance | null = this.GetPreviousStep();
    return previousStep ? previousStep.to : undefined;
  }

  public GetNextStepLocation(): Location | undefined {
    if (this.behaviorMode !== StepperBehaviorModes.RouteMeta) return undefined;

    const nextStep: NavStepperStepInstance | null = this.GetNextStep();
    return nextStep ? nextStep.to : undefined;
  }

  public GoToPreviousStep(): void {
    if (this.behaviorMode !== StepperBehaviorModes.Parent) return;

    const previousStep: NavStepperStepInstance | null = this.GetPreviousStep();
    if (previousStep && previousStep.stepId) {
      this.currentStepId = previousStep.stepId;
    }
  }

  public GoToNextStep(): void {
    if (this.behaviorMode !== StepperBehaviorModes.Parent) return;

    const nextStep: NavStepperStepInstance | null = this.GetNextStep();
    if (nextStep && nextStep.stepId) {
      this.currentStepId = nextStep.stepId;
    }
  }
}
