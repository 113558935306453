import { IQrCodesService } from "@/Api/contracts/webapi/IQrCodesService";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { AxiosPromise } from "axios";
import { IQrCodeTextPayload, IQrCodeGiroPayload, IQrCodeSmsPayload } from "@api/models/market/IQrCodePayload";

export class QrCodesService extends BaseApiService implements IQrCodesService {
  public GetQrCodeForTextInImage(payload: IQrCodeTextPayload): AxiosPromise<File> {
    return this.Client().post(`QRCodes/text-in-image`, payload, { responseType: "blob" });
  }
  public GetQrCodeForTextInBase64String(payload: IQrCodeTextPayload): AxiosPromise<string> {
    return this.Client().post(`QRCodes/text-in-base64String`, payload);
  }
  public GetQrCodeForTextInSvg(payload: IQrCodeTextPayload): AxiosPromise<string> {
    return this.Client().post(`QRCodes/text-in-svg`, payload);
  }

  public GetQrCodeForGiroInImage(payload: IQrCodeGiroPayload): AxiosPromise<File> {
    return this.Client().post(`QRCodes/giro-in-image`, payload, { responseType: "blob" });
  }
  public GetQrCodeForGiroInBase64String(payload: IQrCodeGiroPayload): AxiosPromise<string> {
    return this.Client().post(`QRCodes/giro-in-base64String`, payload);
  }
  public GetQrCodeForGiroInSvg(payload: IQrCodeGiroPayload): AxiosPromise<string> {
    return this.Client().post(`QRCodes/giro-in-svg`, payload);
  }

  public GetQrCodeForSmsInImage(payload: IQrCodeSmsPayload): AxiosPromise<File> {
    return this.Client().post(`QRCodes/sms-in-image`, payload, { responseType: "blob" });
  }
  public GetQrCodeForSmsInBase64String(payload: IQrCodeSmsPayload): AxiosPromise<string> {
    return this.Client().post(`QRCodes/sms-in-base64String`, payload);
  }
  public GetQrCodeForSmsInSvg(payload: IQrCodeSmsPayload): AxiosPromise<string> {
    return this.Client().post(`QRCodes/sms-in-svg`, payload);
  }
}
