export enum AppRoutes {
  AccountAddress = "account.address",
  AccountData = "account.data",
  AccountMessages = "account.messages",
  AccountNotifications = "account.notifications",
  AccountOverview = "account.overview",
  AccountResetPassword = "account.reset-password",
  AccountVerification = "account.verification",
  BuyerDashboard = "buyer.dashboard",
  BuyerOrders = "buyer.orders",
  BuyerOrdersDetails = "buyer.orders.details",
  BreederDashboard = "breeder.dashboard",
  BreederFancierProfile = "breeder.fancier-profile",
  VendorAuction = "vendor.auction",
  VendorDashboard = "vendor.dashboard",
  VendorPayments = "vendor.payments",
  VendorPigeonsDetails = "admin.pigeons.details",
  VendorReferral = "vendor.referral",
  VendorSales = "vendor.sales",
  VendorSalesDetails = "vendor.sales.details",
  VendorSuperviseDetails = "vendor.supervise.details",
  VendorShop = "vendor.shop",
  Admin = "admin",
  AdminAlerts = "admin.alerts",
  AdminApprovalSales = "admin.approval.sales",
  AdminNotifications = "admin.notifications",
  AdminBankTransfer = "admin.bank-transfer",
  AdminDashboard = "admin.dashboard",
  AdminDashboardLegacy = "admin.dashboard-legacy",
  Buy = "buy",
  BuyShop = "buy.shop",
  CheckoutOrder = "checkout.order",
  CheckoutSuccess = "checkout.success",
  Checkout = "checkout.default",
  Contact = "contact",
  CookiesPolicy = "cookies-policy",
  Default = "default",
  Faq = "faq",
  Home = "home",
  Lab = "lab",
  Page404 = "404",
  PrivacyPolicy = "privacy-policy",
  CommunityPigeon = "COMMUNITY@pigeon", // See: CommunityAppRoutes.ProductSheet
  Pigeon = "pigeon",
  PigeonPedigree = "pigeon.pedigree",
  Sale = "sale",
  SalePedigree = "sale.pedigree",
  SalePackagePigeon = "sale.package.pigeon",
  SalePalmares = "sale.palmares",
  Sell = "sell",
  AuctionConditions = "auction-conditions",
  TermsAndConditions = "terms-and-conditions",
  Why = "why",

  //#region SaleManagement
  AbstractSaleSale = "sale.sale",
  AbstractSalePigeon = "sale.pigeon",
  AbstractSalePedigree = "sale.pedigree",
  AbstractSalePackage = "sale.package",
  AbstractSaleInserted = "sale.inserted",
  AbstractSaleUpdated = "sale.updated",
  AbstractSale = "sale.default", // default step route
  //#region NewSale
  NewSaleSale = "new.sale.sale",
  NewSalePigeon = "new.sale.pigeon",
  NewSalePedigree = "new.sale.pedigree",
  NewSalePackage = "new.sale.package",
  NewSaleInserted = "new.sale.inserted",
  NewSale = "new.sale.default", // default step route
  //#endregion
  //#region EditSale
  EditSaleSale = "edit.sale.sale",
  EditSalePigeon = "edit.sale.pigeon",
  EditSalePedigree = "edit.sale.pedigree",
  EditSalePackage = "edit.sale.package",
  EditSaleUpdated = "edit.sale.updated",
  EditSale = "edit.sale.default", // default step route
  //#endregion
  //#endregion

  //#region PigeonManagement
  AbstractPigeonPigeon = "pigeon.pigeon",
  AbstractPigeonPedigree = "pigeon.pedigree",
  AbstractPigeonInserted = "pigeon.inserted",
  AbstractPigeonUpdated = "pigeon.updated",
  AbstractPigeon = "pigeon.default",
  //#region NewPigeon
  NewPigeonPigeon = "new.pigeon.pigeon",
  NewPigeonPedigree = "new.pigeon.pedigree",
  NewPigeonInserted = "new.pigeon.inserted",
  NewPigeon = "new.pigeon.default",
  //#endregion
  //#region EditPigeon
  EditPigeonPigeon = "edit.pigeon.pigeon",
  EditPigeonPedigree = "edit.pigeon.pedigree",
  EditPigeonUpdated = "edit.pigeon.updated",
  EditPigeon = "edit.pigeon.default"
  //#endregion
  //#endregion
}
