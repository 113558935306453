import TheNavBar from "@market/components/layout/TheNavBar";
import { notificationStore } from "@market/stores/App.store.modules";
import I18nLanguageDropdown from "@pigeon/components/i18n/I18nLanguageDropdown.vue";
import { Component, Emit } from "vue-property-decorator";

@Component({
  components: {
    I18nLanguageDropdown
  }
})
export default class TheNavBarMenu extends TheNavBar {
  @Emit("trigger-menu-item")
  public Trigger(): void {}

  get UnreadPMarketNotificationsCount(): number {
    return notificationStore.UnreadPMarketNotificationsCount;
  }
}
