import { ActionTypes } from "@api/models/market/constants/ActionTypes";
import { ILocation } from "@market/models/ILocation";
import { dialogsStore } from "@market/stores/App.store.modules";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppDialog extends Vue {
  @Prop()
  readonly title: string;
  @Prop()
  readonly secondaryTitle?: string;
  @Prop()
  readonly headerIcon?: Array<string> | string;
  @Prop()
  readonly actionLabel: string;
  @Prop()
  readonly actionLinkTo: ILocation;
  @Prop({ default: ActionTypes.PrimaryAction })
  readonly actionType?: ActionTypes;
  @Prop()
  readonly actionLoading?: boolean;
  @Prop({ default: false })
  readonly isOpened: boolean;

  ActionTypes = ActionTypes;

  @Emit("dialog-action")
  public Action() {
    dialogsStore.CloseDialogs();
  }

  @Emit("close")
  public Close() {}
}
