import { nameof } from "@pigeon/extensions/nameof";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import smoothReflow from "vue-smooth-reflow";

@Component({
  mixins: [smoothReflow]
})
export default class NavPanel extends Vue {
  @Prop()
  readonly itemKey: string;
  @Prop({ default: true })
  readonly opened: boolean;
  @Prop({ default: true })
  readonly canExpand: boolean;
  @Prop({ default: true })
  readonly isBordered: boolean;
  
  isOpened: boolean = true;
  HasToggleTransitionEnded: boolean = false; // used only for the transition css class

  mounted() {
    (this as any).$smoothReflow({
      el: ".panel_item-content",
      property: ["height"],
      transition: "height .25s ease-in-out"
    });
  }

  @Watch(nameof<NavPanel>("opened"), { immediate: true })
  ObserveCollapse(): void {
    this.isOpened = this.opened;
  }

  // used only for the transition css class
  @Watch(nameof<NavPanel>("isOpened"), { immediate: true })
  ObserveTransition() {
      this.HasToggleTransitionEnded = false;
      setTimeout(() => {
        this.HasToggleTransitionEnded = true;
      }, 0); // used for css transition
  }

  @Emit("click")
  public OnClick(): void {}

  public Toggle(): void {
    if (!this.canExpand) return;

    this.isOpened = !this.isOpened;
  }

  public Collapse(): void {
    if (!this.canExpand) return;

    this.isOpened = false;
  }

  public Expand(): void {
    if (!this.canExpand) return;

    this.isOpened = true;
  }
}
