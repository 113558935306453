import { IAmountManager } from "./contracts/IAmountManager";

export class AmountManager implements IAmountManager {
  public CalculateVatAmount(amount: number, vatRate: number | null, isVatInclusive: boolean | null): number | null {
    if (!vatRate) return null;

    return isVatInclusive
      ? Math.round((amount - amount / (1 + vatRate)) * 100) / 100
      : Math.round(amount * vatRate * 100) / 100;
  }

  public CalculateAmountIncludingVat(amount: number, vatAmount: number | null, isVatInclusive: boolean | null): number {
    if (!vatAmount) vatAmount = 0;

    const amountIncludingVat = isVatInclusive ? amount : amount + vatAmount;
    return Math.round(amountIncludingVat * 100) / 100;
  }

  public CalculateAmountExcludingVat(amount: number, vatAmount: number | null, isVatInclusive: boolean | null): number {
    if (!vatAmount) vatAmount = 0;

    const amountExcludingVat = isVatInclusive ? amount - vatAmount : amount;
    return Math.round(amountExcludingVat * 100) / 100;
  }
  //#endregion
}
