import { IStepItem } from "@pigeon/models/IStepItem";
import { IStepManager } from "@pigeon/services/contracts/IStepManager";
import { Route } from "vue-router";

export class StepManager implements IStepManager {
  private stepItems: IStepItem[];

  constructor(stepItems: IStepItem[]) {
    this.stepItems = stepItems;
  }

  public SetCurrentStep(route: Route): void {
    // Reset state
    this.stepItems.forEach((stepItem) => {
      stepItem.isActive = false;
      stepItem.isCompleted = false;
    });

    for (const stepItem of this.stepItems) {
      if (route && route.meta) {
        const hasStepMatch: boolean = stepItem.stepId == route.meta.step;

        if (hasStepMatch) {
          stepItem.isActive = true;
          break;
        }

        // Set previous steps as completed
        stepItem.isCompleted = true;
      }
    }
  }
}
