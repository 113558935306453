import { IPurchasesService } from "@api/contracts/webapi/IPurchasesService";
import { IPaymentRequest } from "@api/models/market/IPaymentRequest";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { AxiosPromise } from "axios";

export class PurchasesService extends BaseApiService implements IPurchasesService {
  public CreatePayment(paymentRequest: IPaymentRequest): AxiosPromise {
    return this.Client().post("Purchases/pay", paymentRequest);
  }

  // public FlagCashAsCollected(saleKey: number): AxiosPromise<any> {
  //   const cashCollectedRequest: ICashCollectedRequest = { saleKey: saleKey };
  //   return this.Client().post(`Purchases/cash-collected`, cashCollectedRequest);
  // }
}
