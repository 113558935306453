import { ITaxRatesService } from "@api/contracts/odata/ITaxRatesService";
import { ITaxRate } from "@api/models/market/ITaxRate";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { IODataValueResponse } from "@api/models/shared/IODataValueResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";

export class TaxRatesService extends BaseODataService implements ITaxRatesService {
  constructor() {
    super();
  }

  public Fetch(taxRateKey: number): AxiosPromise<ITaxRate> {
    return this.Client().get(`TaxRates(${taxRateKey})`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<ITaxRate>> {
    return this.Client().get(`TaxRates`);
  }

  public GetVatRate(countryCode: string): AxiosPromise<IODataValueResponse<number>> {
    return this.Client().get(`TaxRates/MarketService.GetVatRate(countryCode='${countryCode}')`);
  }
}
