import { BaseThemes } from "@api/models/market/constants/BaseThemes";
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator";

@Component
export default class FormPaneRadio extends Vue {
  @Model("change", { type: [String, Number, Boolean, Object, Array] })
  readonly model: any;
  @Prop({ type: [String, Number, Boolean, Object] })
  readonly value?: string | number | boolean | object;
  @Prop()
  readonly validationRules?: string | Record<string, any>;
  @Prop()
  readonly isInvalid: boolean;
  @Prop()
  readonly icon?: string | string[];
  @Prop({ validator: (value: BaseThemes) => Object.values(BaseThemes).includes(value) })
  readonly theme?: BaseThemes;

  get IsSelected(): boolean {
    return this.model === this.value;
  }

  @Emit("select")
  public OnClick(): string | number | boolean | object | undefined {
    return this.value;
  }
}
