import { IBidsService } from "@api/contracts/odata/IBidsService";
import { IBid } from "@api/models/market/IBid";
import { IUser } from "@api/models/market/IUser";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, FilterDate, odataQuery } from "odata-fluent-query";

export class BidsService extends BaseODataService implements IBidsService {
  constructor() {
    super();
  }

  public Insert(bid: IBid): AxiosPromise<IBid> {
    return this.Client().post("Bids", bid);
  }

  public Fetch(key: number): AxiosPromise<IBid> {
    return this.Client().get(`Bids(${key})`);
  }

  public FetchWithUserName(key: number): AxiosPromise<IBid> {
    const query = odataQuery<IBid>().expand("user", (q: ExpandObjectQuery<IUser>) => q.select("firstname", "lastname"));

    return this.Client().get(`Bids(${key})?${query.toString()}`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IBid>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    // Note: the collection is sorted by the server
    const query = odataQuery<IBid>()
      .select("id", "biddingOrderId", "amount", "bidDate", "userId", "saleId")
      .orderBy((b) => b.amount.desc())
      .orderBy((b) => b.biddingOrderId.asc())
      .orderBy((b) => b.bidDate.asc());

    return this.Client().get(`Bids?${query.toString()}`);
  }

  public FetchAllBidsWithBidder(fromDate?: Date, toDate?: Date): AxiosPromise<IODataCollectionResponse<IBid>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    // Note: the collection is sorted by the server
    let query = odataQuery<IBid>()
      .select("id", "biddingOrderId", "amount", "bidDate", "userId", "saleId")
      .expand("user", (u: ExpandObjectQuery<IUser>) =>
        u.select(
          "id",
          "firstname",
          "lastname",
          "buyerAlias",
          "countryCode",
          "email",
          "phoneNumber",
          "stripeCustomerId",
          "registeredAt"
        )
      )
      .orderBy((b) => b.amount.desc())
      .orderBy((b) => b.biddingOrderId.asc())
      .orderBy((b) => b.bidDate.asc());

    if (fromDate) query = query.filter((a) => (a.bidDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((a) => (a.bidDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`Bids?${query.toString()}`);
  }

  public FetchAllByUser(userKey: string): AxiosPromise<IODataCollectionResponse<IBid>> {
    if (!userKey) return Promise.reject(new Error("Argument exception: userKey is missing"));

    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    // Note: the collection is sorted by the server
    const query = odataQuery<IBid>()
      .filter((b) => b.userId.equals(userKey, { ignoreGuid: true }))
      .select("id", "biddingOrderId", "amount", "bidDate", "userId", "saleId")
      .orderBy((b) => b.amount.desc())
      .orderBy((b) => b.biddingOrderId.asc())
      .orderBy((b) => b.bidDate.asc());

    return this.Client().get(`Bids?${query.toString()}`);
  }

  public FetchAllBidsWithUserBySale(saleKey: number): AxiosPromise<IODataCollectionResponse<IBid>> {
    // Note: the collection is sorted by the server
    const query = odataQuery<IBid>()
      .filter((b) => b.saleId.equals(saleKey))
      .select("id", "biddingOrderId", "amount", "bidDate", "userId")
      .orderBy((b) => b.amount.desc())
      .orderBy((b) => b.biddingOrderId.asc())
      .orderBy((b) => b.bidDate.asc())
      .expand("user", (u: ExpandObjectQuery<IUser>) =>
        u.select("id", "firstname", "lastname", "buyerAlias", "countryCode", "email", "phoneNumber", "stripeCustomerId", "registeredAt")
      );

    return this.Client().get(`Bids?${query.toString()}`);
  }

  public FetchAllBidsWithUserBySales(saleKeys: number[]): AxiosPromise<IODataCollectionResponse<IBid>> {
    // Note: the collection is sorted by the server
    const query = odataQuery<IBid>()
      .filter((b) => b.saleId.in(saleKeys))
      .select("id", "biddingOrderId", "amount", "bidDate", "userId", "saleId")
      .orderBy((b) => b.amount.desc())
      .orderBy((b) => b.biddingOrderId.asc())
      .orderBy((b) => b.bidDate.asc())
      .expand("user", (u: ExpandObjectQuery<IUser>) =>
        u.select(
          "id",
          "firstname",
          "lastname",
          "buyerAlias",
          "countryCode",
          "email",
          "phoneNumber",
          "stripeCustomerId",
          "registeredAt"
        )
      );

    return this.Client().get(`Bids?${query.toString()}`);
  }
}
