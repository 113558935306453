import { IAuthentication } from "@api/models/auth/IAuthentication";
import AccountSignInRecoveryView from "@market/pages/views/auth/AccountSignInRecovery.vue";
import { authStore, signInDialogStore, signUpDialogStore } from "@market/stores/App.store.modules";
import { IErrorManager } from "@pigeon/services/contracts/IErrorManager";
import { ErrorManager } from "@pigeon/services/ErrorManager";
import { ValidationObserver } from "vee-validate";
import { Component, Ref, Vue } from "vue-property-decorator";

@Component({
  components: {
    SignInRecoveryView: AccountSignInRecoveryView
  }
})
export default class AccountSignInView extends Vue {
  @Ref()
  readonly refValidation: InstanceType<typeof ValidationObserver>;

  errorManager: IErrorManager = new ErrorManager();
  authentication: IAuthentication = {
    email: "",
    password: ""
  };
  hasForgottenPassword: boolean = false;
  isProcessing: boolean = false;

  mounted() {
    this.errorManager = new ErrorManager(this.refValidation);
  }

  public SwitchToRecovery() {
    this.hasForgottenPassword = true;
  }

  public SwitchToSignIn() {
    this.hasForgottenPassword = false;
  }

  public SwitchToSignUpDialog() {
    signInDialogStore.Close();
    signUpDialogStore.Open();
  }

  public async Login(authentication: IAuthentication): Promise<void> {
    try {
      await authStore.Login(authentication);
      signInDialogStore.Close();
    } catch (error: any) {
      this.errorManager.HandleError(error);
    }
  }

  public async Submit() {
    // Reset errors
    this.errorManager.Reset();
    this.isProcessing = true;

    // Log user here
    this.Login(this.authentication).finally(() => {
      this.isProcessing = false;
    });
  }
}
