import TheNavBarDialogs from "@market/components/layout/TheNavBarDialogs.vue";
import TheNavBarMenu from "@market/components/layout/TheNavBarMenu.vue";
import { AppRoutes } from "@market/routers/App.routes";
import {
  approvalStore,
  cartDialogStore,
  cartStore,
  depositStore,
  notificationStore,
  searchStore,
  settlementStore,
  signInDialogStore,
  signOnDialogStore,
  signUpDialogStore
} from "@market/stores/App.store.modules";
import { BadgeLevels } from "@pigeon/components/app/AppBadge";
import AppsBar from "@pigeon/components/apps/AppsBar.vue";
import AppsLauncher from "@pigeon/components/apps/AppsLauncher.vue";
import I18nLanguageDropdown from "@pigeon/components/i18n/I18nLanguageDropdown.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    AppsLauncher,
    AppsBar,
    TheNavBarMenu,
    TheNavBarDialogs,
    I18nLanguageDropdown
  }
})
export default class TheNavBar extends Vue {
  AppRoutes = AppRoutes;
  BadgeLevels = BadgeLevels;
  isAddItemActive: boolean = false;
  menuIsOpen: boolean = false;

  //#region DialogGetters
  get CartCount(): number {
    return cartStore.CartCount;
  }
  get CartDialogIsOpen(): boolean {
    return cartDialogStore.dialog.isOpen;
  }
  get SignInDialogIsOpen(): boolean {
    return signInDialogStore.dialog.isOpen;
  }
  get SignOnDialogIsOpen(): boolean {
    return signOnDialogStore.dialog.isOpen;
  }
  get SignUpDialogIsOpen(): boolean {
    return signUpDialogStore.dialog.isOpen;
  }
  //#endregion

  get SearchBarIsOpen(): boolean {
    return searchStore.searchBarIsOpen;
  }

  //#region Notifications
  get UnreadNotificationsCount(): number {
    return notificationStore.UnreadPMarketNotificationsCount;
  }

  get AwaitingSettlementsCount(): number {
    return settlementStore.pendingSettlementsCount;
  }

  get AwaitingDepositsCount(): number {
    return depositStore.pendingDepositsCount;
  }

  get PendingApprovalSalesCount(): number {
    return approvalStore.pendingApprovalSalesCount;
  }
  //#endregion

  get LocatedOnCheckoutPage(): boolean {
    return this.$route.name && this.$route.name.startsWith("checkout") ? true : false;
  }

  get HasEmptyCart(): boolean {
    return cartStore.CartCount == 0;
  }

  public SetAddItemActive(isActive: boolean): void {
    this.isAddItemActive = isActive;
  }

  public NavigationLinkIsActive(routeName: string | string[]): boolean {
    if (Array.isArray(routeName) && this.$route.name) {
      return (routeName as string[]).includes(this.$route.name);
    } else {
      return this.$route.name === routeName;
    }
  }

  public CloseMenu(): void {
    this.menuIsOpen = false;
  }

  public ToggleMenu(): void {
    this.menuIsOpen = !this.menuIsOpen;
  }

  public ToggleSearchBar(): void {
    if (!this.SearchBarIsOpen) {
      this.GoToBuyPage();
    }

    searchStore.ToggleSearchBar();
  }

  //#region DialogActions
  public ToggleCartDialog(): void {
    cartDialogStore.Toggle();
  }
  public ToggleSignInDialog(): void {
    signInDialogStore.Toggle();
  }
  public ToggleSignOnDialog(): void {
    signOnDialogStore.Toggle();
  }
  public ToggleSignUpDialog(): void {
    signUpDialogStore.Toggle();
  }
  //#endregion

  public GoToBuyPage(): void {
    if (this.$route.name == AppRoutes.Buy) return;

    this.$router.push({ name: AppRoutes.Buy });
  }
}
