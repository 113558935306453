import { ProductMapper } from "@api/mappers/ProductMapper";
import { SaleStatus } from "@api/models/market/constants/SaleStatus";
import { IBid } from "@api/models/market/IBid";
import { ISale } from "@api/models/market/ISale";
import BidDoubleCheckHighestBidAbstract from "@market/components/bid/BidDoubleCheckHighestBidAbstract";
import { IProduct } from "@market/components/sale/SaleProductAbstract";
import { ICartItem } from "@market/models/ICartItem";
import { AppRoutes } from "@market/routers/App.routes";
import { authStore, cartStore, userStore } from "@market/stores/App.store.modules";
import { Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class BidAlertCongratulations extends BidDoubleCheckHighestBidAbstract {
  @Prop()
  readonly sales: ISale[]; // all sales won
  @Prop()
  declare readonly sale: ISale; // the current sale won alert
  @Prop()
  readonly bid: IBid;

  @Prop()
  readonly isDismissible: boolean;
  @Prop()
  readonly hasSnooze: boolean;

  isDismissed: boolean = false;

  get HasWinAuction(): boolean {
    if (!this.sale || !authStore.IsAuthenticated || !authStore.IsMember || !userStore?.user?.id) return false;

    const highestBid = this.saleManager.GetHighestBid(this.sale);
    return highestBid?.userId === userStore.user.id;
  }

  get Product(): IProduct | null {
    return ProductMapper.MapToProduct(this.sale);
  }

  get CartItems(): ICartItem[] {
    return cartStore.items;
  }

  get LastItemBeforeCheckout(): boolean {
    if (!this.sales || this.sales.length == 1) return true;

    return this.sales?.every((sa) => this.CartItems.some((ci) => ci.saleId == sa.id || sa.id == this.sale.id));
  }

  public OnDismissAlert() {
    this.isDismissed = true;
  }

  @Emit("snooze")
  public Snooze() {
    this.OnDismissAlert();
  }

  public async Checkout(): Promise<void> {
    if (this.sale.status !== SaleStatus.OnSale) {
      console.error("Operation exception: a user can checkout only a for sale sale.");
      return;
    }
    if (!this.HasWinAuction) {
      console.error("Operation exception: the current user has not win the auction.");
      return;
    }

    const itemIsInCart = this.CartItems.find((i) => i.saleId === this.sale.id);
    if (!itemIsInCart) {
      await cartStore.AddItem(this.sale);
    }

    const isCartItemsContainsAllWonSales = this.sales?.every((sa) => this.CartItems.some((ci) => ci.saleId == sa.id));
    if (!this.sales || (this.sales && isCartItemsContainsAllWonSales))
      this.$router.push({ name: AppRoutes.CheckoutOrder });
  }
}
