export enum NotificationTypes {
  SaleSold = "saleSold",
  SalePendingApproval = "saleAprvl",
  SaleApproved = "saleAprvd",
  SaleDisapproved = "saleDprvd",
  PigeonPendingApproval = "pgnAprvl",
  PigeonApproved = "pgnAprvd",
  PigeonDisapproved = "pgnDprvd",
  AuctionNewBidOnAFollowedSale = "actBid",
  AuctionNewBidOnYourSale = "actNewBid",
  AuctionBidAccepted = "actAccBid",
  AuctionOutBid = "actOutBid"
}
