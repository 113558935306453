import FormAsync from "@pigeon/components/formUI/FormAsync.vue";
import FormFields from "@pigeon/components/formUI/FormFields.vue";
import FormInput from "@pigeon/components/formUI/FormInput.vue";
import FormInputCheck from "@pigeon/components/formUI/FormInputCheck.vue";
import FormInputPicture from "@pigeon/components/formUI/FormInputPicture.vue";
import FormInputRadio from "@pigeon/components/formUI/FormInputRadio.vue";
import FormInputSelect from "@pigeon/components/formUI/FormInputSelect.vue";
import FormInputText from "@pigeon/components/formUI/FormInputText.vue";
import FormPaneCheck from "@pigeon/components/formUI/FormPaneCheck.vue";
import FormPaneRadio from "@pigeon/components/formUI/FormPaneRadio.vue";
import Vue from "vue";

// Form components
Vue.component("FormAsync", FormAsync);
Vue.component("FormFields", FormFields);
Vue.component("FormInput", FormInput);
Vue.component("FormInputText", FormInputText);
Vue.component("FormInputCheck", FormInputCheck);
Vue.component("FormPaneCheck", FormPaneCheck);
Vue.component("FormInputRadio", FormInputRadio);
Vue.component("FormPaneRadio", FormPaneRadio);
Vue.component("FormInputSelect", FormInputSelect);
Vue.component("FormInputPicture", FormInputPicture);
