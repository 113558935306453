import { IPigeonsService } from "@api/contracts/odata/IPigeonsService";
import { IReproducerParentsService } from "@api/contracts/odata/IReproducerParentsService";
import { ISalesService } from "@api/contracts/odata/ISalesService";
import { AssetTypes } from "@api/models/market/constants/AssetTypes";
import { PictureTypes } from "@api/models/market/constants/PictureTypes";
import { ProductTypes } from "@api/models/market/constants/ProductTypes";
import { SexTypes } from "@api/models/market/constants/SexTypes";
import { VideoTypes } from "@api/models/market/constants/VideoTypes";
import { IPackage } from "@api/models/market/IPackage";
import { IPicture } from "@api/models/market/IPicture";
import { IPigeon } from "@api/models/market/IPigeon";
import { IPrize } from "@api/models/market/IPrize";
import { IPrizesRanking } from "@api/models/market/IPrizesRanking";
import { IRankingAsset } from "@api/models/market/IRankingAsset";
import { IVideo } from "@api/models/market/IVideo";
import { IPigeonCartItem } from "@market/models/IPigeonCartItem";
import dayjs from "@pigeon/i18n/dayjs";
import { IProductDropdownListItem } from "@pigeon/models/IProductDropdownListItem";
import { Inject } from "inversify-props";
import { kebabCase, uniqWith } from "lodash-es";
import { IPigeonManager } from "./contracts/IPigeonManager";

export class PigeonManager implements IPigeonManager {
  @Inject()
  private pigeonsService: IPigeonsService;
  @Inject()
  private salesService: ISalesService;
  @Inject()
  private reproducerParentsService: IReproducerParentsService;

  readonly defaultPigeonPicture = require("@pigeon/assets/images/pictures/pigeon-generic.webp");
  readonly defaultPedigreePicture = "";
  readonly defaultPedigreePdf = "";

  //#region Info
  public GetSexLabel(pigeon: IPigeon | IPigeonCartItem): string {
    if (!pigeon || !pigeon.sex) return "Unknown";

    switch (pigeon.sex) {
      case SexTypes.Male:
        return "Male";

      case SexTypes.Female:
        return "Female";

      default:
        return "Unknown";
    }
  }

  public GetSexIcon(pigeon: IPigeon | IPigeonCartItem | IProductDropdownListItem): string {
    if (!pigeon || !pigeon.sex) return "";

    switch (pigeon.sex) {
      case SexTypes.Male:
        return "mars";

      case SexTypes.Female:
        return "venus";

      case SexTypes.Unknown:
        return "genderless";

      default:
        return "";
    }
  }

  public GetAgeCategory(pigeon: IPigeon | IPigeonCartItem): string {
    if (!pigeon || !pigeon.birthYear) {
      return "";
    }

    const diff = dayjs().year() - pigeon.birthYear;

    if (diff == 0) {
      return "Junior"; // Youngsters
    } else if (diff <= 1) {
      return "Yearling";
    } else {
      return "Old";
    }
  }
  //#endregion

  //#region Slug
  public GetNameSlug(pigeon: IPigeon | IPigeonCartItem | undefined): string | undefined {
    if (!pigeon || !pigeon.name) return;

    return kebabCase(pigeon.name);
  }

  public GetRingSlug(pigeon: IPigeon | undefined): string | undefined {
    if (!pigeon || !pigeon.ring) return;

    return pigeon.ring.replace(/\s/g, "");
  }

  public GetReferenceSlug(cartItem: IPigeonCartItem | undefined): string | undefined {
    if (!cartItem || !cartItem.reference) return;

    return cartItem.reference.replace(/\s/g, "");
  }
  //#endregion

  //#region Picture/Video
  public GetPictureRemoteUrl(relativeUrl: string | null | undefined): string {
    if (!relativeUrl) return this.defaultPigeonPicture;

    // test if parameter is a remote url
    if (/(http(s?)):\/\//gi.test(relativeUrl)) return relativeUrl;

    // test if parameter is a inline data image
    if (/(data):/gi.test(relativeUrl)) return relativeUrl;

    return `${process.env.VUE_APP_AZURE_STORAGE_HOST}${relativeUrl}`;
  }

  public GetVideoRemoteUrl(relativeUrl: string | undefined): string {
    if (!relativeUrl) return this.defaultPigeonPicture;

    // test if parameter is a remote url
    if (/(http(s?)):\/\//gi.test(relativeUrl)) return relativeUrl;

    // test if parameter is a inline data image
    if (/(data):/gi.test(relativeUrl)) return relativeUrl;

    return `${process.env.VUE_APP_AZURE_STORAGE_HOST}${relativeUrl}`;
  }

  public GetPicturePigeon(pictures?: IPicture[], useRawPicture = false): string {
    if (!pictures || !pictures.length) {
      return `${this.defaultPigeonPicture}`;
    }

    const pigeonPicture: IPicture | undefined = pictures.find((picture) => picture.type === PictureTypes.Pigeon);
    if (!pigeonPicture) return this.defaultPigeonPicture;

    const pictureUrl =
      pigeonPicture.urlWebOptimized && !useRawPicture ? pigeonPicture.urlWebOptimized : pigeonPicture.url;
    if (!pictureUrl) return this.defaultPigeonPicture;

    return this.GetPictureRemoteUrl(pictureUrl);
  }

  public GetPictureThumbnail(pictures?: IPicture[], useRawPicture = false): string {
    const picturePigeon = this.GetPicturePigeon(pictures, useRawPicture);
    if (!picturePigeon) return "";

    try {
      const thumbnailPigeonUrl = new URL(picturePigeon);
      if (!useRawPicture) {
        const thumbnailPathSegments = thumbnailPigeonUrl.pathname.split("/");

        if (thumbnailPathSegments.length > 0) {
          thumbnailPathSegments[thumbnailPathSegments.length - 1] = "thumbnail.webp";
          const webOptimizedThumbnailPath = thumbnailPathSegments.join("/");
          thumbnailPigeonUrl.pathname = webOptimizedThumbnailPath;
        }
      }

      return thumbnailPigeonUrl.href;
    } catch (error: any) {
      console.error(`Invalid URL picture : '${picturePigeon}'`);
      return "";
    }
  }

  public HasPicture(pictures: IPicture[] | undefined, pictureType: PictureTypes): boolean {
    if (!pictures || pictures.length < 1) {
      return false;
    }

    return pictures.some((picture) => picture.type === pictureType);
  }

  public HasPicturePedigree(pictures: IPicture[] | undefined): boolean {
    return this.HasPicture(pictures, PictureTypes.Pedigree);
  }

  public GetPicturePedigree(pictures?: IPicture[], useRawPicture = false): string {
    if (!this.HasPicturePedigree(pictures) || !pictures) {
      //return `${this.defaultPedigreePicture}`;
      return "";
    }

    const pedigreePicture: IPicture | undefined = pictures.find((picture) => picture.type === PictureTypes.Pedigree);
    //if (!pedigreePicture) return this.defaultPedigreePicture;
    if (!pedigreePicture) return "";

    const pedigreePictureUrl =
      pedigreePicture.urlWebOptimized && !useRawPicture ? pedigreePicture.urlWebOptimized : pedigreePicture.url;
    if (!pedigreePictureUrl) return "";

    return this.GetPictureRemoteUrl(pedigreePictureUrl);
  }

  public HasPdfPedigree(pictures: IPicture[] | undefined): boolean {
    return this.HasPicture(pictures, PictureTypes.PedigreePdf);
  }

  public GetPdfPedigree(pictures: IPicture[] | undefined): string {
    if (!this.HasPdfPedigree(pictures) || !pictures) {
      //return `${location.origin}${this.defaultPedigreePdf}`;
      return "";
    }

    const pedigreePdf: IPicture | undefined = pictures.find((picture) => picture.type === PictureTypes.PedigreePdf);
    //if (!pedigreePdf) return this.defaultPedigreePdf;
    if (!pedigreePdf) return "";

    const pedigreePdfUrl = pedigreePdf.url;
    return this.GetPictureRemoteUrl(pedigreePdfUrl);
  }

  public HasPicturePropertyTicket(pictures: IPicture[] | undefined): boolean {
    return this.HasPicture(pictures, PictureTypes.PropertyTicket);
  }

  public GetPicturePropertyTicket(pictures?: IPicture[], useRawPicture = false): string {
    if (!this.HasPicturePropertyTicket(pictures) || !pictures) {
      return "";
    }

    const propertyTicketPicture: IPicture | undefined = pictures.find(
      (picture) => picture.type === PictureTypes.PropertyTicket
    );
    if (!propertyTicketPicture) return "";

    const propertyTicketPictureUrl =
      propertyTicketPicture.urlWebOptimized && !useRawPicture
        ? propertyTicketPicture.urlWebOptimized
        : propertyTicketPicture.url;
    if (!propertyTicketPictureUrl) return "";

    return this.GetPictureRemoteUrl(propertyTicketPictureUrl);
  }

  public HasVideo(videos: IVideo[] | undefined, videoType: VideoTypes): boolean {
    if (!videos || videos.length < 1) {
      return false;
    }

    return videos.some((video) => video.type === videoType);
  }
  //#endregion

  //#region Asset
  // Business Rules : Take the best top that covers at least 80% of number of prizes or at default take the top 1000
  // Business Rules : Take max 3 items
  public CalculateAssetPrizeRankings(
    prizes: IPrize[] | undefined,
    assetRankings: IRankingAsset[] | undefined
  ): IPrizesRanking[] {
    // Note: prize must have ranking navigation property to access area property
    if (!prizes || !prizes.length || !assetRankings || !assetRankings.length) return [];
    const taggedAssetPrizes = prizes.filter((p) => p.taggedAsAsset === true);

    if (!taggedAssetPrizes.length) return [];
    const assetRankingsAreas: string[] = assetRankings.map((ar) => ar.areaType);
    const areas: string[] = taggedAssetPrizes.filter((ap) => assetRankingsAreas.includes(ap.area)).map((p) => p.area);
    const setAreas: Set<string> = new Set(areas);

    const prizesRankings: IPrizesRanking[] = [];
    for (const area of setAreas) {
      const prizesFilteredByArea: IPrize[] = taggedAssetPrizes.filter((p) => p.area == area);
      const prizesRanking = this.CalculateAssetPrizeRankingsForArea(prizesFilteredByArea, area);
      prizesRankings.push(prizesRanking);
    }

    return prizesRankings.length > 3 ? prizesRankings.slice(0, 3) : prizesRankings;
  }

  private CalculateAssetPrizeRankingsForArea(prizesFilteredByArea: IPrize[], area: string): IPrizesRanking {
    const count: number = prizesFilteredByArea.length;
    const countTop10: number = prizesFilteredByArea.filter((pra) => pra.rank <= 10).length;
    const countTop100: number = prizesFilteredByArea.filter((pra) => pra.rank <= 100).length;
    const countTop200: number = prizesFilteredByArea.filter((pra) => pra.rank <= 200).length;
    const countTop1000: number = prizesFilteredByArea.filter((pra) => pra.rank <= 1000).length;

    if (countTop10 >= 0.8 * count) {
      return { count: countTop10, area: area, top: 10 };
    } else if (countTop100 >= 0.8 * count) {
      return { count: countTop100, area: area, top: 100 };
    } else if (countTop200 >= 0.8 * count) {
      return { count: countTop200, area: area, top: 200 };
    } else {
      return { count: countTop1000, area: area, top: 1000 };
    }
  }

  public HasAsset(pigeon: IPigeon): boolean {
    if (!pigeon || !pigeon.asset) return false;

    if (pigeon.asset === AssetTypes.Text) {
      return pigeon.assetText ? true : false;
    } else if (pigeon.asset === AssetTypes.Prize) {
      return pigeon.prizes && pigeon.prizes.length && pigeon.prizes.some((p) => p.taggedAsAsset === true)
        ? true
        : false;
    } else if (pigeon.asset === AssetTypes.Ranking) {
      return pigeon.assetRankings && pigeon.assetRankings.length ? true : false;
    } else if (pigeon.asset === AssetTypes.Parent) {
      return pigeon.assetParents && pigeon.assetParents.length ? true : false;
    } else {
      console.error("Operation exception: unsupported asset type");
      return false;
    }
  }

  public HasAssetType(pigeon: IPigeon): boolean {
    if (!pigeon) return false;

    return pigeon.asset ? true : false;
  }

  public HasCharacteristics(pigeon: IPigeon): boolean {
    return pigeon?.characteristics?.length ? true : false;
  }
  //#endregion

  //#region Fetch
  public async FetchAllPigeonsByVendor(currentVendorId: string): Promise<IPigeon[]> {
    const { data: pigeonsData } = await this.pigeonsService.FetchAllByVendor(currentVendorId);
    const pigeons = pigeonsData.value;

    return pigeons;
  }

  public async FetchAllReproducersByVendor(currentVendorId: string): Promise<IPigeon[]> {
    const { data: dataReproducerParents } = await this.reproducerParentsService.FetchAll(currentVendorId);
    const reproducerParents = dataReproducerParents.value.filter((rp) => rp.parent).map((rp) => rp.parent as IPigeon);

    const { data: dataReproducers } = await this.pigeonsService.FetchAllReproducersByVendor(currentVendorId);
    const reproducerPigeons = dataReproducers.value;

    return uniqWith(
      ([] as IPigeon[]).concat(reproducerParents, reproducerPigeons),
      (value: IPigeon, other: IPigeon) => value.id === other.id
    );
  }

  public async FetchAllReproducersAvailableByVendor(currentVendorId: string): Promise<IPigeon[]> {
    const { data: dataReproducerParents } = await this.reproducerParentsService.FetchAllAvailable(currentVendorId);
    const reproducerParents = dataReproducerParents.value.filter((rp) => rp.parent).map((rp) => rp.parent as IPigeon);

    const { data: dataReproducers } = await this.pigeonsService.FetchAllReproducersAvailableByVendor(currentVendorId);
    const reproducerPigeons = dataReproducers.value;

    return uniqWith(
      ([] as IPigeon[]).concat(reproducerParents, reproducerPigeons),
      (value: IPigeon, other: IPigeon) => value.id === other.id
    );
  }

  public async FetchUnsoldPigeonsByVendor(currentVendorId: string): Promise<IPigeon[]> {
    const { data: dataUnsoldSales } = await this.salesService.FetchAllUnsoldSalesWithProductByVendor(currentVendorId);
    const unsoldPigeons: IPigeon[] = dataUnsoldSales.value
      .filter((s) => s.product == ProductTypes.Pigeon)
      .map((s) => s.pigeon as IPigeon);
    const unsoldPigeonsFromPack: IPigeon[] = dataUnsoldSales.value
      .filter((s) => s.product == ProductTypes.Package)
      .flatMap((s) => (s.package as IPackage).pigeons as IPigeon[]);

    return uniqWith(
      ([] as IPigeon[]).concat(unsoldPigeons, unsoldPigeonsFromPack),
      (value: IPigeon, other: IPigeon) => value.id === other.id
    );
  }
  //#endregion
}
