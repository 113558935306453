import { IValidationServerError } from "@pigeon/models/IValidationServerError";
import { IValidationServerErrorDetail } from "@pigeon/models/IValidationServerErrorDetail";
import { IErrorManager } from "@pigeon/services/contracts/IErrorManager";
import { AxiosError } from "axios";
import { camelCase } from "lodash-es";
import { ValidationObserver } from "vee-validate";

export interface IApiErrorDataResponse {
  value?: any;
  Message?: any;
  error?: IValidationServerError;
}

export class ErrorManager implements IErrorManager {
  private errorMessages: string[];
  private errorBag: InstanceType<typeof ValidationObserver> | null;

  // errorBag not used for now (wait for https://github.com/logaretm/vee-validate/issues/2307 )
  constructor(errorBag?: InstanceType<typeof ValidationObserver>) {
    this.errorMessages = [];
    this.errorBag = errorBag ? errorBag : null;
  }

  get ErrorMessages(): string[] {
    return this.errorMessages;
  }

  get HasErrors(): boolean {
    return this.errorMessages.length ? true : false;
  }

  public Reset(): void {
    this.errorMessages = [];
  }

  public HandleError(error: Error): void {
    if (error instanceof Error) {
      const axiosError: AxiosError = error as AxiosError;
      if (typeof axiosError.isAxiosError !== "undefined" && axiosError.isAxiosError) {
        this.HandleAxiosError(axiosError);
      } else {
        this.HandleMessageError(error.message);
      }
    } else if (typeof error == "string") {
      this.HandleMessageError(error);
    }

    console.error(error?.message ?? error);
  }

  // See https://github.com/axios/axios#handling-errors
  public HandleAxiosError(error: AxiosError): void {
    // Service Error
    if (error.response) {
      // The request was made and the server responded with a status code
      this.HandleServiceError(error);
    } else if (error.request) {
      // The request was made but no response was received
      this.HandleRaisedError(error);
    }
    // Raised Error
    else if (error.message) {
      this.HandleRaisedError(error);
    }
    // Unexpected Error
    else {
      this.HandleUnexpectedError();
    }
  }

  public HandleRaisedError(error: Error) {
    if (!error || !error.message) return;

    this.errorMessages.push(error.message);
  }

  public HandleServiceError(error: AxiosError): void {
    if (!error.response || !error.response.data) return;

    // Bad request
    const httpStatusCode: number = error.response.status;
    const isUnexpectedError: boolean = httpStatusCode >= 500;
    const isBadRequestError: boolean = httpStatusCode >= 400 && httpStatusCode < 500;

    if (isUnexpectedError) {
      this.HandleUnexpectedError();
    } else if (isBadRequestError) {
      if (error.response && error.response.data) {
        const errorResponseData: IApiErrorDataResponse = error.response.data as any as IApiErrorDataResponse;

        if (errorResponseData.value) {
          this.errorMessages.push(errorResponseData.value);
        } else if (errorResponseData.Message) {
          const errorMessage = errorResponseData.Message;
          if (Array.isArray(errorMessage)) {
            errorMessage.forEach((message) => {
              this.HandleMessageError(message);
            });
          } else {
            this.HandleMessageError(errorMessage);
          }
        } else if (typeof error.response.data === "string") {
          this.HandleMessageError(error.response.data);
        } else if (error.response.data) {
          this.HandleModelValidationError(error);
        }
      } else {
        this.HandleUnexpectedError();
      }
    }
  }

  public HandleModelValidationError(error: AxiosError): void {
    if (!error.response) return;
    if (!error.response.data) return;

    const errorResponse: IApiErrorDataResponse = error.response.data as any as IApiErrorDataResponse;
    if (errorResponse.error) {
      this.HandleModelValidationErrorDetails(errorResponse.error);
    }
  }

  public HandleModelValidationErrorDetails(validationServerError: IValidationServerError): void {
    for (const key in validationServerError.details) {
      const hasKey = Object.prototype.hasOwnProperty.call(validationServerError.details, key);
      if (hasKey) {
        const detail: IValidationServerErrorDetail = validationServerError.details[key];
        // Inject error in vee validate
        if (this.errorBag) this.errorBag.setErrors({ [camelCase(detail.target)]: [detail.message] });

        // Add a error message
        // Note: Both inject error and a error message are required because sometimes some validated fields (validate on server side)
        // are not on the current view (on client side)
        this.errorMessages.push(detail.message);
      }
    }
  }

  public HandleMessageError(error: string): void {
    this.errorMessages.push(error);
  }

  public HandleUnexpectedError(): void {
    this.errorMessages.push(`An unexpected error has occured.
    <br /> 
    We are working hard to try fix it.  
    <br />
    Please retry, later...`);
  }
}
