import { v4 as UUIDv4 } from "uuid";
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class FormInputCheck extends Vue {
  @Model("change", { type: [String, Number, Boolean, Object, Array] })
  readonly model: any;
  @Prop({ default: undefined, type: [String, Number, Boolean, Object] })
  readonly value?: string | number | boolean | object;
  @Prop({ type: [Boolean, String], default: true })
  readonly trueValue: boolean | string;
  @Prop({ type: [Boolean, String], default: false })
  readonly falseValue: boolean | string;
  @Prop({ type: String, default: () => `check-${UUIDv4()}` })
  readonly id: string;
  // @Prop()
  // readonly validationRules?: string | Object;
  // @Prop()
  // readonly validationCustomMessages?: Object;
  // @Prop()
  // readonly validationName?: string;

  hovered: boolean = false;

  get Attrs(): any {
    return { ...this.$attrs, value: this.value };
  }

  get Listeners() {
    return { ...this.$listeners, change: this.OnChange };
  }

  get IsChecked() {
    if (Array.isArray(this.model)) {
      return this.model.includes(this.value);
    }

    if (this.HasValue) {
      return this.model === this.value || this.model === this.trueValue;
    }

    return this.model === this.trueValue;
  }

  private get HasValue() {
    if (typeof this.$options.propsData === "undefined") return;

    return Object.prototype.hasOwnProperty.call(this.$options.propsData, "value");
  }

  @Emit("change")
  private HandleArrayCheckbox() {
    const newModel = this.model;

    if (!this.IsChecked) {
      newModel.push(this.value);
    } else {
      this.RemoveItemFromModel(newModel);
    }

    return newModel;
  }

  private RemoveItemFromModel(newModel: any) {
    const index = newModel.indexOf(this.value);

    if (index !== -1) {
      newModel.splice(index, 1);
    }
  }

  @Emit("change")
  private HandleSingleSelectCheckbox() {
    return this.IsChecked ? null : this.value;
  }

  @Emit("change")
  private HandleSimpleCheckbox() {
    return this.IsChecked ? this.falseValue : this.trueValue;
  }

  public OnChange() {
    if (this.$attrs.disabled || this.$attrs.readonly) return;

    this.ToggleCheck();
  }

  public ToggleCheck() {
    if (Array.isArray(this.model)) {
      this.HandleArrayCheckbox();
    } else if (this.HasValue) {
      this.HandleSingleSelectCheckbox();
    } else {
      this.HandleSimpleCheckbox();
    }
  }

  public Highlight(state: boolean) {
    this.hovered = state;
  }
}
