import { IPrizesService } from "@api/contracts/odata/IPrizesService";
import { IPrize } from "@api/models/market/IPrize";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";

export class PrizesService extends BaseODataService implements IPrizesService {
  constructor() {
    super();
  }

  public Fetch(prizeKey: number): AxiosPromise<IPrize> {
    return this.Client().get(`Prizes(${prizeKey})`);
  }
}
