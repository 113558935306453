export class ArrayHelper {
  public static SortAscendingCompare<T>(a: T, b: T) {
    if (a > b) return +1;
    if (a < b) return -1;
    return 0;
  }

  public static SortDescendingCompare<T>(a: T, b: T) {
    if (a < b) return +1;
    if (a > b) return -1;
    return 0;
  }
}
