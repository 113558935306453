import { appStore } from "@market/stores/App.store.modules";
import Vue from "vue";
import { bootstrap } from "vue-gtag";
import { Component, Watch } from "vue-property-decorator";

export interface IConsent {
  "strictly-necessary": boolean;
  functionality: boolean;
  tracking: boolean;
  targeting: boolean;
}

const COOKIES_CONSENT_USER = "cookie_consent_user_accepted";
const COOKIES_CONSENT_LEVELS = "cookie_consent_level";
// TODO: Move to Cookies(Service|Manager)
export function HasFunctionalityCookieConsent(): boolean {
  if (!Vue.cookies) return false;

  const cookiesUserConsent = Vue.cookies.get(COOKIES_CONSENT_USER);
  const cookiesConsentLevels = Vue.cookies.get(COOKIES_CONSENT_LEVELS);

  const userHasAcceptedFunctionalityCookie =
    cookiesUserConsent && cookiesConsentLevels && (cookiesConsentLevels as IConsent).functionality ? true : false;

  if (!userHasAcceptedFunctionalityCookie)
    console.warn("User has not accept functionality cookies.  It impacts data stored on browser storage.");

  return userHasAcceptedFunctionalityCookie;
}

@Component
export default class TheBannerCookies extends Vue {
  created() {
    // get user consents from cookies
    const userHasAcceptedCookiesConsent = Vue.cookies.get(COOKIES_CONSENT_USER);

    if (Vue.cookies.isKey(COOKIES_CONSENT_LEVELS)) {
      this.HandleCookies();
    }

    if (!userHasAcceptedCookiesConsent) {
      this.RunCookiesConsent();
      this.ListenOnCookieChangePrefences();
    }
  }

  @Watch("$i18n.locale", { immediate: false })
  async ObserveCookiesLocal() {
    // Remove previous cookies banner (translated in other locale)
    document.querySelectorAll(".cc_dialog").forEach((element) => {
      element.remove();
    });

    appStore.UpdateCookiesBanner(false);
    await this.RunCookiesConsent();
  }

  // Note: cookie consent loaded statically from index.html
  private async RunCookiesConsent() {
    if (appStore.cookiesBanner) return;

    if (!(window as any).cookieconsent) return;
    appStore.UpdateCookiesBanner(true);

    const locale =
      window.location.pathname && window.location.pathname.length >= 3
        ? window.location.pathname.substring(1, 3)
        : "en";

    (window as any).cookieconsent.run({
      notice_banner_type: "headline",
      consent_type: "express",
      palette: "light",
      language: locale,
      website_name: "PigeonMarket",
      change_preferences_selector: "#cookiesPreferences",
      cookies_policy_url: "https://www.pigeonmarket.be/en/cookies-policy"
    });
  }

  private ListenOnCookieChangePrefences() {
    const elementChangePreferences: HTMLButtonElement = document.querySelector(".cc_b_cp") as HTMLButtonElement;
    if (elementChangePreferences) {
      elementChangePreferences.onclick = this.ListenOnCookieSavePreferences;
    }
  }

  private ListenOnCookieSavePreferences() {
    const elementSavePreferences: HTMLButtonElement = document.querySelector(
      ".cc_cp_f_save button"
    ) as HTMLButtonElement;
    if (elementSavePreferences) {
      elementSavePreferences.onclick = this.HandleCookies;
    }
  }

  private HandleCookies() {
    // get user consents from cookies
    const cookiesConsent = Vue.cookies.get(COOKIES_CONSENT_LEVELS);

    if (cookiesConsent) {
      const consentLevel: IConsent = cookiesConsent as IConsent;

      this.HandleFunctionalityCookies(consentLevel.functionality);
      this.HandleTrackingCookies(consentLevel.tracking);
      this.HandleTargetingCookies(consentLevel.targeting);
    }
  }

  private HandleFunctionalityCookies(hasConsent: boolean) {
    if (!hasConsent) {
      localStorage.clear();
    }
  }

  private async HandleTrackingCookies(hasConsent: boolean) {
    const userHasAcceptedCookiesConsent = Vue.cookies.get(COOKIES_CONSENT_USER);

    if (process.env.NODE_ENV === "production" && userHasAcceptedCookiesConsent && hasConsent) {
      await bootstrap();
    }
  }

  private HandleTargetingCookies(hasConsent: boolean) {}
}
