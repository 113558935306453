import { authStore } from "@market/stores/App.store.modules";
import { Route } from "vue-router";

export function authGuard(to: Route): boolean {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // prettier-ignore
  return !requiresAuth || (requiresAuth && authStore.IsAuthenticated)
    ? true
    : false;
}
