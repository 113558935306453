import { Component, Emit, Prop, Vue } from "vue-property-decorator";

// See: https://medium.com/@dobromir_hristov/reacting-to-promises-from-event-listeners-in-vue-js-8959b6d03f52
@Component
export default class AppButtonLoader extends Vue {
  @Prop({ default: 4500 })
  readonly notificationTime: number;
  @Prop({ default: "btn-danger" })
  readonly errorClass: string;
  @Prop({ default: "btn-success" })
  readonly successClass: string;

  isLoading: boolean = false;
  hasSuccess: boolean = false;
  hasError: boolean = false;

  get Listeners(): Record<string, Function | Function[]> {
    return { ...this.$listeners, click: this.HandleClick };
  }

  get ComputedClasses(): { [key: string]: boolean } {
    return {
      [this.errorClass]: this.hasError,
      [this.successClass]: this.hasSuccess,
      "is-loading": this.isLoading
    };
  }

  get LoaderColor(): string | null {
    if (this.$el.classList.contains("btn-primary")) {
      return "#FFFFFF";
    }

    return null;
  }

  private Reset(): void {
    setTimeout(() => {
      this.hasError = false;
      this.hasSuccess = false;
    }, this.notificationTime);
  }

  @Emit("done")
  private Done(response: any): void {
    this.hasSuccess = true;

    return response;
  }

  @Emit("fail")
  private Fail(error: any): void {
    this.hasError = true;

    return error;
  }

  public async HandleClick($event: any) {
    try {
      this.isLoading = true;

      const response = await (this.$listeners as any).click($event);
      this.Done(response);
    } catch (error: any) {
      this.Fail(error);
    } finally {
      this.isLoading = false;
      this.Reset();
    }
  }
}
