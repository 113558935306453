import { IParentAssetsService } from "@api/contracts/odata/IParentAssetsService";
import { IPrizeAssetsService } from "@api/contracts/odata/IPrizeAssetsService";
import { IRankingAssetsService } from "@api/contracts/odata/IRankingAssetsService";
import { AppThemes } from "@api/models/market/constants/AppThemes";
import { AssetTypes } from "@api/models/market/constants/AssetTypes";
import { IParentAsset } from "@api/models/market/IParentAsset";
import { IPigeon } from "@api/models/market/IPigeon";
import { IPrize } from "@api/models/market/IPrize";
import { IPrizesRanking } from "@api/models/market/IPrizesRanking";
import { IRankingAsset } from "@api/models/market/IRankingAsset";
import PigeonAssetText from "@pigeon/components/pigeon/PigeonAssetText.vue";
import PigeonAssetParents from "@pigeon/components/pigeon/PigeonAssetParents.vue";
import PigeonAssetPrizes from "@pigeon/components/pigeon/PigeonAssetPrizes.vue";
import PigeonAssetRankings from "@pigeon/components/pigeon/PigeonAssetRankings.vue";
import { PigeonAssetModes } from "@pigeon/enumerations/PigeonAssetModes";
import { nameof } from "@pigeon/extensions/nameof";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { Inject } from "inversify-props";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    AssetRankings: PigeonAssetRankings,
    AssetPrizes: PigeonAssetPrizes,
    AssetParents: PigeonAssetParents,
    AssetText: PigeonAssetText
  }
})
export default class PigeonAsset extends Vue {
  @Prop()
  readonly pigeon: IPigeon;
  @Prop({
    default: PigeonAssetModes.Alone,
    validator: (value: PigeonAssetModes) => [PigeonAssetModes.Alone, PigeonAssetModes.Combined].includes(value)
  })
  readonly mode: string;
  @Prop({ validator: (value: AppThemes) => Object.values(AppThemes).includes(value) })
  readonly theme?: AppThemes;

  @Inject()
  private prizeAssetsService: IPrizeAssetsService;
  @Inject()
  private rankingAssetsService: IRankingAssetsService;
  @Inject()
  private parentAssetsService: IParentAssetsService;
  @Inject()
  private pigeonManager: IPigeonManager;

  AssetTypes = AssetTypes;
  PigeonAssetModes = PigeonAssetModes;
  // Note: used asset variables to handle async values
  assetPrizes: IPrize[] | null = null;
  assetPrizesRankings: IRankingAsset[] | null = null;
  assetParents: IParentAsset[] | null = null;

  get AssetText(): string | undefined {
    return this.pigeon?.assetText;
  }

  get AssetPrizes(): IPrize[] {
    if (!this.pigeon || !this.assetPrizes) return [];

    return this.assetPrizes.filter((prz) => prz.taggedAsAsset);
  }

  get AssetPrizesRankings(): IPrizesRanking[] {
    if (!this.pigeon || !this.assetPrizes || !this.assetPrizesRankings) return [];

    return this.pigeonManager.CalculateAssetPrizeRankings(this.assetPrizes, this.assetPrizesRankings);
  }

  get AssetParents(): IPigeon[] {
    if (!this.pigeon || !this.assetParents) return [];

    return this.assetParents.map((assetParent: IParentAsset) => assetParent.parent);
  }

  get Asset(): AssetTypes | undefined {
    return this.pigeon.asset;
  }

  // WARNING: use data var to handle async values
  @Watch(nameof<PigeonAsset>("Asset"), { immediate: true })
  async ObserveAsset() {
    await this.FetchAsset();
  }

  public async FetchAsset() {
    if (!this.pigeon.id || !this.pigeon.asset) return;

    // Asset text
    if (this.pigeon.asset === AssetTypes.Text) {
      return; // nothing to fetch (use pigeon.assetText)
    }
    // Asset prizes
    else if (this.pigeon.asset === AssetTypes.Prize) {
      await this.FetchAssetPrizes(this.pigeon.id);
    }
    // Asset rankings
    else if (this.pigeon.asset === AssetTypes.Ranking) {
      await this.FetchAssetPrizesRankings(this.pigeon.id);
    }
    // Asset parents
    else if (this.pigeon.asset === AssetTypes.Parent) {
      await this.FetchAssetParents(this.pigeon.id);
    }
  }

  private async FetchAssetPrizes(pigeonId?: number) {
    if (!pigeonId) return;

    const { data } = await this.prizeAssetsService.FetchAllByPigeon(pigeonId);
    this.assetPrizes = data.value;
  }

  private async FetchAssetPrizesRankings(pigeonId?: number) {
    if (!pigeonId) return;

    await this.FetchAssetPrizes(pigeonId);
    const { data } = await this.rankingAssetsService.FetchAllByPigeon(pigeonId);
    this.assetPrizesRankings = data.value;
  }

  private async FetchAssetParents(pigeonId?: number) {
    if (!pigeonId) return;

    const { data } = await this.parentAssetsService.FetchAllByPigeon(pigeonId);
    this.assetParents = data.value;
  }
}
