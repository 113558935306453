export type SubtaskTypes = ApprovalTaskTypes | SaleTaskTypes | SettlementTaskTypes | ShippingTaskTypes;

export enum SettlementTaskTypes {
  CheckSettlementCollected = "ChkSettlementCollected",
  CheckTransferDone = "ChkTransferToPlatform"
}

export enum SaleTaskTypes {
  CheckExchangeBetweenBuyerAndSeller = "ChkBuyerVendorExchange",
  CheckBuyerInterest = "ChkBuyerInterest",
  SelectPurchaseAbortReason = "SlcPurchaseAbortReason"
}

export enum PurchaseAbortReasons {
  Transport = "transport",
  Price = "price",
  Taxes = "taxes",
  Delay = "delay",
  Pigeon = "pigeon",
  Other = "other"
}

export enum ApprovalTaskTypes {
  CheckDisapprovalCorrection = "ChkDisapprovalCorrection"
}

export enum ShippingTaskTypes {
  CheckShippingStatus = "ChkShippingStatus",
  CheckTransportMethod = "ChkTransportMethod",
  CheckTransportCarrier = "ChkTransportCarrier"
}
