import { IBlobsService } from "@api/contracts/webapi/IBlobsService";
import { IChunkEntry } from "@api/models/market/IChunkEntry";
import { IFileMetadata } from "@api/models/market/IFileMetadata";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { authStore } from "@market/stores/App.store.modules";
import { BLOB_CONTAINER_PICTURES, BLOB_CONTAINER_VIDEOS } from "@pigeon/services/BlobManager";
import { AxiosPromise } from "axios";

export class BlobsService extends BaseApiService implements IBlobsService {
  constructor() {
    super();
  }

  /** @deprecated This method doesn t check the read/write permissions for current Vendor/Contributor for the current ObjectAsset */
  UploadPicture(file: File, blobPath = ""): AxiosPromise<void> {
    if (!authStore.IsVendor && !authStore.IsContributor)
      return Promise.reject("unauthorized: The operation is not authorized.");

    const blobFullPath: string = blobPath ? blobPath : file.name.toLowerCase();

    const formData = new FormData();
    formData.append("file", file, blobFullPath);

    return this.Client().post(`Blobs/${BLOB_CONTAINER_PICTURES}`, formData, {
      headers: {
        accept: "application/json",
        contentType: "multipart/form-data;"
      }
    });
  }

  UploadAssetPicture(
    file: File,
    assetDirectory: string,
    assetObjectKey: number | string,
    blobPath: string
  ): AxiosPromise<void> {
    if (!authStore.IsVendor && !authStore.IsContributor)
      return Promise.reject("unauthorized: The operation is not authorized.");

    const formData = new FormData();
    formData.append("file", file, blobPath);

    return this.Client().post(`Blobs/${BLOB_CONTAINER_PICTURES}/${assetDirectory}/${assetObjectKey}`, formData, {
      headers: {
        accept: "application/json",
        contentType: "multipart/form-data;"
      }
    });
  }

  UploadVideo(file: File, blobPath = ""): AxiosPromise<void> {
    if (!authStore.IsVendor && !authStore.IsContributor)
      return Promise.reject("unauthorized: The operation is not authorized.");

    const blobFullPath: string = blobPath ? blobPath : file.name.toLowerCase();
    const formData = new FormData();
    formData.append("file", file, blobFullPath);

    return this.Client().post(`Blobs/${BLOB_CONTAINER_VIDEOS}`, formData, {
      headers: {
        accept: "application/json",
        contentType: "multipart/form-data;"
      }
    });
  }

  UploadAssetVideo(
    file: File,
    assetDirectory: string,
    assetObjectKey: number | string,
    blobPath: string
  ): AxiosPromise<void> {
    if (!authStore.IsVendor && !authStore.IsContributor)
      return Promise.reject("unauthorized: The operation is not authorized.");

    const formData = new FormData();
    formData.append("file", file, blobPath);

    return this.Client().post(`Blobs/${BLOB_CONTAINER_VIDEOS}/${assetDirectory}/${assetObjectKey}`, formData, {
      headers: {
        accept: "application/json",
        contentType: "multipart/form-data;"
      }
    });
  }

  UploadChunk(chunkEntry: IChunkEntry): AxiosPromise<void> {
    if (!authStore.IsVendor && !authStore.IsContributor)
      return Promise.reject("unauthorized: The operation is not authorized.");

    return this.Client().post(
      `Blobs/${BLOB_CONTAINER_VIDEOS}/chunk/${chunkEntry.pigeonKey}/${chunkEntry.blobName}/${chunkEntry.index}`,
      chunkEntry.chunk,
      {
        headers: {
          "Content-Type": "application/octet-stream"
          // "Content-Length": chunkEntry.chunk.size
        }
      }
    );
  }

  CommitChunks(pigeonKey: number, fileMetadata: IFileMetadata): AxiosPromise<void> {
    if (!authStore.IsVendor && !authStore.IsContributor)
      return Promise.reject("unauthorized: The operation is not authorized.");

    const blobName = fileMetadata.fileName;
    return this.Client().post(`Blobs/${BLOB_CONTAINER_VIDEOS}/chunks/${pigeonKey}/${blobName}`, fileMetadata);
  }

  DeletePicture(
    assetContainer: string,
    assetDirectory: string,
    assetObjectKey: number,
    blobPath: string
  ): AxiosPromise<void> {
    if (!authStore.IsVendor && !authStore.IsContributor)
      return Promise.reject("unauthorized: The operation is not authorized.");

    return this.Client().delete(`Blobs/${assetContainer}/${assetDirectory}/${assetObjectKey}/${blobPath}`);
  }
}
