import { ITwitterService } from "@api/contracts/webapi/ITwitterService";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { AxiosPromise } from "axios";
import { Status as Tweet } from "twitter-d";

// See https://developer.twitter.com/en/docs/twitter-api
export class TwitterService extends BaseApiService implements ITwitterService {
  public FetchLatestTweet(): AxiosPromise<Tweet> {
    return this.Client().get("Twitter/latest-tweet");
  }

  public GetNumberFollowers(): AxiosPromise<number> {
    return this.Client().get("Twitter/number-followers");
  }
}
