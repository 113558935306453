import { store } from "@market/stores";
import { IAppStore } from "@market/stores/contracts/IAppStore";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

// TODO: [Single respnsability]: Merge this store with (futur) userStore
export const STORE_TOKEN: "app" = "app";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class AppStore extends VuexModule implements IAppStore {
  cookiesBanner: boolean = false;
  isAutomaticTranslationDismissed: boolean = false;
  snoozedDateEndVendorConnectedAccount: Date | null = null;
  referralCode: string | null = null;

  @Mutation
  UPDATE_COOKIES_BANNER(hasDisplayed: boolean): void {
    this.cookiesBanner = hasDisplayed;
  }

  @Mutation
  UPDATE_AUTOMATIC_TRANSLATION_ALERT(isDismissed: boolean): void {
    this.isAutomaticTranslationDismissed = isDismissed;
  }

  @Mutation
  UPDATE_SNOOZED_END_DATE_VENDOR_CONNECTED_ACCOUNT(snoozeEndDate: Date) {
    this.snoozedDateEndVendorConnectedAccount = snoozeEndDate;
  }

  @Mutation
  UPDATE_REFERRAL_CODE(referralCode: string) {
    this.referralCode = referralCode;
  }

  @Action
  UpdateCookiesBanner(hasDisplayed: boolean): void {
    this.UPDATE_COOKIES_BANNER(hasDisplayed);
  }

  @Action
  UpdateAutomaticTranslationAlert(isDismissed: boolean): void {
    this.UPDATE_AUTOMATIC_TRANSLATION_ALERT(isDismissed);
  }

  @Action
  UpdateSnoozedEndDateVendorConnectedAccount(snoozeEndDate: Date): void {
    this.UPDATE_SNOOZED_END_DATE_VENDOR_CONNECTED_ACCOUNT(snoozeEndDate);
  }

  @Action
  UpdateReferralCode(referralCode: string): void {
    this.UPDATE_REFERRAL_CODE(referralCode);
  }
}
