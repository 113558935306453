import { store } from "@market/stores";
import { authStore } from "@market/stores/App.store.modules";
import { ISalesCatalogStore } from "@market/stores/contracts/ISalesCatalogStore";
import { CatalogFilterTypes } from "@pigeon/enumerations/CatalogFilterTypes";
import { CatalogSortDirectionTypes } from "@pigeon/enumerations/CatalogSortDirectionTypes";
import { CatalogSortTypes } from "@pigeon/enumerations/CatalogSortTypes";
import { CatalogViewTypes } from "@pigeon/enumerations/CatalogViewTypes";
import { ISalesCatalog } from "@pigeon/models/ICatalog";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "salesCatalog" = "salesCatalog";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class SalesCatalogStore extends VuexModule implements ISalesCatalogStore {
  DEFAULT_MINIMUM_PRICE: number = 0;
  DEFAULT_MAXIMUM_PRICE: number = 10_000;
  DEFAULT_STEP_PRICE: number = 100;

  catalog: ISalesCatalog = {
    id: "sales",
    page: 1,
    pageSize: 4,
    sortType: `${CatalogSortTypes.SaleEndDate};${CatalogSortDirectionTypes.Ascending}`,
    viewType: CatalogViewTypes.Pane,
    filters: new Map(),
    selectedFavoriteSales: [],
    selectedVendorFilter: [],
    selectedFlagApprovedFilter: [],
    selectedFlagVoucherFilter: [],
    selectedStatusFilter: [],
    selectedStatusFilterForPublicSales: [],
    selectedStatusFilterForMySales: [],
    selectedCurrentDateFilter: [],
    selectedCurrentDateFilterForPublicSales: [],
    selectedStartDateFilter: [],
    selectedEndDateFilter: [],
    selectedCreatedDateFilter: [],
    selectedSexFilter: [],
    selectedAgeFilter: [],
    selectedDisciplineFilter: [],
    selectedSaleTypeFilter: [],
    selectedProductTypeFilter: [],
    selectedCountryFilter: [],
    selectedAuctionFilter: [],
    selectedPaymentFilter: [],
    selectedTransferFilter: [],
    selectedPriceMininimumFilter: this.DEFAULT_MINIMUM_PRICE,
    selectedPriceMaximumFilter: this.DEFAULT_MAXIMUM_PRICE,
    selectedShopFilter: [],
    selectedFlagSharedCatalogFilter: [],
    selectedFlagArchivedCatalogFilter: []
  };

  get HasSomeFilters(): boolean {
    return (
      this.catalog.selectedFavoriteSales?.length > 0 ||
      this.catalog.selectedVendorFilter?.length > 0 ||
      this.catalog.selectedFlagApprovedFilter?.length > 0 ||
      this.catalog.selectedFlagVoucherFilter?.length > 0 ||
      this.catalog.selectedStatusFilter?.length > 0 ||
      this.catalog.selectedStatusFilterForPublicSales?.length > 0 ||
      this.catalog.selectedStatusFilterForMySales?.length > 0 ||
      this.catalog.selectedCurrentDateFilter?.length > 0 ||
      this.catalog.selectedCurrentDateFilterForPublicSales?.length > 0 ||
      this.catalog.selectedStartDateFilter?.length > 0 ||
      this.catalog.selectedEndDateFilter?.length > 0 ||
      this.catalog.selectedCreatedDateFilter?.length > 0 ||
      this.catalog.selectedSexFilter?.length > 0 ||
      this.catalog.selectedAgeFilter?.length > 0 ||
      this.catalog.selectedDisciplineFilter?.length > 0 ||
      this.catalog.selectedSaleTypeFilter?.length > 0 ||
      this.catalog.selectedProductTypeFilter?.length > 0 ||
      this.catalog.selectedCountryFilter?.length > 0 ||
      this.catalog.selectedAuctionFilter?.length > 0 ||
      this.catalog.selectedPaymentFilter?.length > 0 ||
      this.catalog.selectedTransferFilter?.length > 0 ||
      this.catalog.selectedShopFilter?.length > 0 ||
      this.catalog.selectedFlagSharedCatalogFilter?.length > 0 ||
      this.catalog.selectedFlagArchivedCatalogFilter?.length > 0 ||
      this.catalog.selectedPriceMininimumFilter != this.DEFAULT_MINIMUM_PRICE ||
      this.catalog.selectedPriceMaximumFilter != this.DEFAULT_MAXIMUM_PRICE
    );
  }

  @Mutation
  public GO_TO_PAGE(pageIndex: number) {
    this.catalog.page = pageIndex;
  }

  @Mutation
  public SELECT_PAGE_SIZE(pageSize: number) {
    this.catalog.pageSize = pageSize;
  }

  @Mutation
  public SELECT_SORT_TYPE(sortType: string) {
    this.catalog.sortType = sortType;
  }

  @Mutation
  public SELECT_VIEW_TYPE(viewType: CatalogViewTypes) {
    this.catalog.viewType = viewType;
  }

  @Mutation
  public SET_FILTERS(filters: Map<CatalogFilterTypes, string[] | boolean[]>) {
    this.catalog.filters = filters;
  }

  @Mutation
  public CLEAR_FILTERS() {
    this.catalog.selectedFavoriteSales = [];
    this.catalog.selectedVendorFilter = [];
    this.catalog.selectedFlagApprovedFilter = [];
    this.catalog.selectedFlagVoucherFilter = [];
    this.catalog.selectedStatusFilter = [];
    this.catalog.selectedStatusFilterForPublicSales = [];
    this.catalog.selectedStatusFilterForMySales = [];
    this.catalog.selectedSexFilter = [];
    this.catalog.selectedAgeFilter = [];
    this.catalog.selectedCurrentDateFilter = [];
    this.catalog.selectedCurrentDateFilterForPublicSales = [];
    this.catalog.selectedStartDateFilter = [];
    this.catalog.selectedEndDateFilter = [];
    this.catalog.selectedCreatedDateFilter = [];
    this.catalog.selectedDisciplineFilter = [];
    this.catalog.selectedSaleTypeFilter = [];
    this.catalog.selectedProductTypeFilter = [];
    this.catalog.selectedCountryFilter = [];
    this.catalog.selectedAuctionFilter = [];
    this.catalog.selectedPaymentFilter = [];
    this.catalog.selectedTransferFilter = [];
    this.catalog.selectedPriceMininimumFilter = this.DEFAULT_MINIMUM_PRICE;
    this.catalog.selectedPriceMaximumFilter = this.DEFAULT_MAXIMUM_PRICE;
    this.catalog.selectedShopFilter = [];
  }

  @Action
  public GoToPage(pageIndex: number) {
    if (pageIndex <= 0) return;
    if (this.catalog.page === pageIndex) return;

    this.GO_TO_PAGE(pageIndex);
  }

  @Action
  public SelectPageSize(pageSize: number) {
    if (this.catalog.pageSize === pageSize) return;

    this.SELECT_PAGE_SIZE(pageSize);
  }

  @Action
  public SelectSortType(sortType: string) {
    if (this.catalog.sortType === sortType) return;

    this.SELECT_SORT_TYPE(sortType);
  }

  @Action
  public SelectViewType(viewType: CatalogViewTypes) {
    if (this.catalog.viewType === viewType) return;

    this.SELECT_VIEW_TYPE(viewType);
  }

  @Action
  public Filter() {
    const filters: Map<CatalogFilterTypes, string[] | boolean[]> = new Map();
    // Business rule: Can filter by status only for admin
    // Business rule: Can filter by status for vendor but only for their sales
    // Business rule: Can filter by status for member but only where they have put a bid / bought the sales
    if (authStore.IsAdministrator || authStore.IsApprover) {
      if (this.catalog.selectedStatusFilter.length)
        filters.set(CatalogFilterTypes.Status, this.catalog.selectedStatusFilter);
    } else {
      if (authStore.IsVendor) {
        if (this.catalog.selectedStatusFilterForMySales.length)
          filters.set(CatalogFilterTypes.Status, this.catalog.selectedStatusFilterForMySales);
      }
      if (authStore.IsMember) {
        if (this.catalog.selectedStatusFilterForPublicSales.length)
          filters.set(CatalogFilterTypes.Status, this.catalog.selectedStatusFilterForPublicSales);
      }
    }

    // Business rule: Can filter by flag approved only for admin
    // Business Rule: Can filter by expired / scheduled only for admin
    // Business rule: Can filter by flag approved for vendor but only for their sales
    // Business Rule: Can filter by expired / scheduled for vendor but only for their sales
    if (authStore.IsAdministrator || authStore.IsApprover || authStore.IsVendor) {
      if (this.catalog.selectedFlagApprovedFilter.length)
        filters.set(CatalogFilterTypes.FlagApproved, this.catalog.selectedFlagApprovedFilter);
      if (this.catalog.selectedPaymentFilter.length)
        filters.set(CatalogFilterTypes.PaymentStatus, this.catalog.selectedPaymentFilter);
      if (this.catalog.selectedTransferFilter.length)
        filters.set(CatalogFilterTypes.TransferStatus, this.catalog.selectedTransferFilter);
      if (this.catalog.selectedCurrentDateFilter.length) {
        filters.set(CatalogFilterTypes.StartDateLowerThat, [new Date().toISOString()]);
        filters.set(CatalogFilterTypes.EndDateGreaterOrEqualAt, [new Date().toISOString()]);
      }
      if (this.catalog.selectedStartDateFilter.length)
        filters.set(CatalogFilterTypes.StartDateGreaterOrEqualAt, this.catalog.selectedStartDateFilter);
      if (this.catalog.selectedEndDateFilter.length)
        filters.set(CatalogFilterTypes.EndDateLowerThat, this.catalog.selectedEndDateFilter);

      if (this.catalog.selectedCreatedDateFilter.length) {
        filters.set(CatalogFilterTypes.CreatedDateBetween, this.catalog.selectedCreatedDateFilter);
      }
    }

    if (authStore.IsVendor && !(authStore.IsAdministrator || authStore.IsApprover)) {
      if (this.catalog.selectedVendorFilter.length)
        filters.set(CatalogFilterTypes.Vendor, this.catalog.selectedVendorFilter);
    }

    if (this.catalog.selectedCurrentDateFilterForPublicSales.length) {
      filters.set(CatalogFilterTypes.StartDateLowerThat, [new Date().toISOString()]);
      filters.set(CatalogFilterTypes.EndDateGreaterOrEqualAt, [new Date().toISOString()]);
    }
    if (this.catalog.selectedFavoriteSales.length)
      filters.set(CatalogFilterTypes.Favorite, this.catalog.selectedFavoriteSales);
    if (this.catalog.selectedSexFilter.length) filters.set(CatalogFilterTypes.Sex, this.catalog.selectedSexFilter);
    if (this.catalog.selectedAgeFilter.length) filters.set(CatalogFilterTypes.Age, this.catalog.selectedAgeFilter);
    if (this.catalog.selectedDisciplineFilter.length)
      filters.set(CatalogFilterTypes.Discipline, this.catalog.selectedDisciplineFilter);
    if (this.catalog.selectedFlagVoucherFilter.length)
      filters.set(CatalogFilterTypes.FlagVoucher, this.catalog.selectedFlagVoucherFilter);
    if (this.catalog.selectedSaleTypeFilter.length)
      filters.set(CatalogFilterTypes.SaleType, this.catalog.selectedSaleTypeFilter);
    if (this.catalog.selectedProductTypeFilter.length)
      filters.set(CatalogFilterTypes.ProductType, this.catalog.selectedProductTypeFilter);
    if (this.catalog.selectedCountryFilter.length)
      filters.set(CatalogFilterTypes.Country, this.catalog.selectedCountryFilter);
    if (this.catalog.selectedAuctionFilter.length)
      filters.set(CatalogFilterTypes.Auction, this.catalog.selectedAuctionFilter);
    if (this.catalog.selectedShopFilter.length) filters.set(CatalogFilterTypes.Shop, this.catalog.selectedShopFilter);
    if (this.catalog.selectedFlagSharedCatalogFilter.length)
      filters.set(CatalogFilterTypes.FlagSharedCatalog, this.catalog.selectedFlagSharedCatalogFilter);
    if (this.catalog.selectedFlagArchivedCatalogFilter.length)
      filters.set(CatalogFilterTypes.FlagArchived, this.catalog.selectedFlagArchivedCatalogFilter);

    if (
      this.catalog.selectedPriceMininimumFilter &&
      this.catalog.selectedPriceMininimumFilter > this.DEFAULT_MINIMUM_PRICE
    )
      filters.set(CatalogFilterTypes.PriceMin, [this.catalog.selectedPriceMininimumFilter.toString()]);
    if (this.catalog.selectedPriceMaximumFilter && this.catalog.selectedPriceMaximumFilter < this.DEFAULT_MAXIMUM_PRICE)
      filters.set(CatalogFilterTypes.PriceMax, [this.catalog.selectedPriceMaximumFilter.toString()]);

    this.SET_FILTERS(filters);
  }

  @Action
  public ClearFilters() {
    this.CLEAR_FILTERS();
  }
}
