import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { IAlertsService } from "@api/contracts/odata/IAlertsService";
import { ISalesService } from "@api/contracts/odata/ISalesService";
import { DbAlertTypes } from "@api/models/market/constants/DbAlertTypes";
import { SaleStatus } from "@api/models/market/constants/SaleStatus";
import { IAlert } from "@api/models/market/IAlert";
import { IBid } from "@api/models/market/IBid";
import { ISale } from "@api/models/market/ISale";
import BidAlertCongratulations from "@market/components/bid/BidAlertCongratulations.vue";
import { ICartItem } from "@market/models/ICartItem";
import { AppRoutes } from "@market/routers/App.routes";
import {
  accountStore,
  appStore,
  authStore,
  cartStore,
  signUpDialogStore,
  userStore
} from "@market/stores/App.store.modules";
import { AlertTypes } from "@pigeon/components/app/AppAlert";
import ModuleStripeConnect from "@pigeon/components/module/ModuleStripeConnect.vue";
import { nameof } from "@pigeon/extensions/nameof";
import { DEFAULT_LOCALE } from "@pigeon/i18n/i18n.const";
import { II18nManager } from "@pigeon/services/contracts/II18nManager";
import { Inject } from "inversify-props";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    ModuleStripeConnect,
    BidAlertCongratulations
  }
})
export default class TheAlerts extends Vue {
  @Inject()
  private alertsService: IAlertsService;
  @Inject()
  private salesService: ISalesService;
  @Inject()
  private i18nManager: II18nManager;

  static readonly SnoozeTimeHours: number = 1;
  DEFAULT_LOCALE = DEFAULT_LOCALE;
  AppRoutes = AppRoutes;
  alerts: IAlert[] = [];
  winningSales: ISale[] = [];
  salesCountForCurrentVendor: number = 0;
  holdSalesCountForCurrentVendor: number = 0;
  soldSalesCountForCurrentVendor: number = 0;

  get AccountIsVerified(): boolean {
    return accountStore.AccountIsVerified;
  }

  get AccountVendorIsVerified(): boolean {
    return accountStore.AccountVendorIsVerified;
  }

  get AccountVendorVerificationLevel(): AlertTypes {
    if (!authStore.IsVendor || this.salesCountForCurrentVendor == 0) return AlertTypes.Info;

    let level = AlertTypes.Info;

    // BusinessRule: If vendor has added a sale (first sale) then the level is warning
    if (this.salesCountForCurrentVendor > 0) {
      level = AlertTypes.Warning;

      // BusinessRule: If vendor has sold a sale (first sale) then the level is danger
      if (this.holdSalesCountForCurrentVendor > 0 || this.soldSalesCountForCurrentVendor > 0) {
        level = AlertTypes.Danger;
      }
    }

    return level;
  }

  get HasResentAccountVerification(): boolean {
    return accountStore.HasResentAccountVerification;
  }

  get SnoozedDateEndVendorConnectedAccount(): Date | null {
    return appStore.snoozedDateEndVendorConnectedAccount;
  }

  get IsAuthenticated(): boolean {
    return authStore?.IsAuthenticated;
  }

  get IsSignUpDialogOpen(): boolean {
    return signUpDialogStore.dialog.isOpen;
  }

  // Business rules: alerts should not be displayed on checkout page
  // Business rules: alerts should not be displayed on winning product page
  get ShouldHideAlertsOnCurrentRoute(): boolean {
    if (this.$route.name?.startsWith("checkout.")) return true;
    if (
      this.$route.name?.startsWith("sale.") &&
      this.$route.params["saleKey"] &&
      this.winningSales.some((s) => s.id?.toString() == this.$route.params["saleKey"])
    )
      return true;

    return false;
  }

  get isAutomaticTranslationDismissed(): boolean {
    return appStore.isAutomaticTranslationDismissed;
  }

  get CartItems(): ICartItem[] {
    return cartStore.items;
  }

  get AWinningSale(): ISale | undefined {
    if (!this.winningSales?.length) return;

    let itemIndex: number = 0;
    let item = this.winningSales[itemIndex];
    const cartContainsTheItem: boolean =
      this.CartItems?.length && this.CartItems.some((ci) => ci.saleId == item.id) ? true : false;

    while (cartContainsTheItem && itemIndex < this.winningSales?.length) {
      itemIndex++;
      item = this.winningSales?.[itemIndex - 1];
    }

    return item;
  }

  @Watch(nameof<TheAlerts>("IsAuthenticated"), { immediate: true })
  async ObserveAuth() {
    if (!this.IsAuthenticated) return Promise.resolve();
    if (this.ShouldHideAlertsOnCurrentRoute) return Promise.resolve();

    try {
      if (authStore.IsVendor) await this.FetchForVendor();
      if (authStore.IsMember) await this.FetchForMember();
    } catch (error) {
      console.error(error);
    }
  }

  public GetAlertIcon(alertType: DbAlertTypes): string {
    if (!alertType) return "info";

    switch (alertType.toLowerCase()) {
      case DbAlertTypes.Danger:
      case DbAlertTypes.Warning:
        return "exclamation";

      case DbAlertTypes.Success:
        return "check";

      case DbAlertTypes.Info:
      default:
        return "info";
    }
  }

  public ConvertAlertType(alertType: DbAlertTypes): AlertTypes {
    switch (alertType) {
      case DbAlertTypes.Info:
        return AlertTypes.Info;
      case DbAlertTypes.Success:
        return AlertTypes.Success;
      case DbAlertTypes.Danger:
        return AlertTypes.Danger;
      case DbAlertTypes.Warning:
        return AlertTypes.Warning;

      default:
        console.warn("Warning: db alert type not supported");
        return AlertTypes.Info;
    }
  }

  public GetHighestBidByUser(sale: ISale): IBid | undefined {
    if (!sale || !sale.bids || !sale.bids.length) return undefined;

    const highestBidAmount = Math.max(...sale.bids.map((b) => b.amount), 0);
    return sale.bids.find((b) => b.amount === highestBidAmount && b.userId === this.$auth.User?.id);
  }

  public IsUserOnTheSalePage(sale: ISale): boolean {
    return (
      this.$route.name == AppRoutes.Sale ||
      this.$route.name == AppRoutes.SalePackagePigeon ||
      this.$route.name == AppRoutes.SalePedigree
    );
  }

  public async FetchForMember() {
    // Business rules: alerts should not be displayed on checkout page
    // Business rules: alerts should not be displayed on winning product page
    if (this.ShouldHideAlertsOnCurrentRoute) return;

    // Business rules: Auction alerts should not be displayed on product page if auth member has done an offer
    if (this.$auth.IsAuthenticated && this.$auth.User) {
      let auctionAlertsShouldBeDisplayed: boolean = true;

      if (this.$route.name?.startsWith("sale.") && this.$route.params["saleKey"]) {
        const { data } = await this.salesService.FetchAllBids(parseInt(this.$route.params["saleKey"]));
        const memberHasDoneAnBid = data.value.some((b) => b.userId == this.$auth.User?.id);
        auctionAlertsShouldBeDisplayed = !memberHasDoneAnBid;
      }

      if (auctionAlertsShouldBeDisplayed) {
        // Note: Winning sales are used here to displayed auction alert
        const { data } = await this.salesService.GetAllWinningSalesInPendingByUser(this.$auth.User?.id);
        this.winningSales = data.value;
      }
    } else {
      this.winningSales = [];
    }

    const { data } = await this.alertsService.FetchAllByUser(this.$auth.User?.id);
    this.alerts = data.value;
  }

  public async FetchForVendor() {
    if (!authStore.IsVendor || !userStore.user) return Promise.resolve();

    const [{ data: countSalesData }, { data: countHoldSalesData }, { data: countSoldSalesData }] = await Promise.all([
      this.salesService.CountSalesByVendorId(userStore.user.id),
      this.salesService.CountSalesByVendorIdAndBySaleStatus(userStore.user.id, SaleStatus.OnHold),
      this.salesService.CountSalesByVendorIdAndBySaleStatus(userStore.user.id, SaleStatus.Sold)
    ]);

    this.salesCountForCurrentVendor = countSalesData["@odata.count"] ?? 0;
    this.holdSalesCountForCurrentVendor = countHoldSalesData["@odata.count"] ?? 0;
    this.soldSalesCountForCurrentVendor = countSoldSalesData["@odata.count"] ?? 0;
  }

  public OnDismissAlert(alertId: number): void {
    const indexToRemove = this.alerts.findIndex((a) => a.id == alertId);

    if (indexToRemove != -1) {
      const alertsRemoved = this.alerts.splice(indexToRemove, 1);
      const alertRemoved = alertsRemoved[0];

      if (alertRemoved && alertRemoved.userId)
        if (alertRemoved.isDismissible) {
          this.alertsService.Patch(alertRemoved.id, { flagAsRead: true, flaggedDate: new Date().toISOString() });
        } else {
          this.alertsService.Patch(alertRemoved.id, { flaggedDate: new Date().toISOString() });
        }
    }
  }

  public OnDismissAutomaticTranslationAlert(): void {
    appStore.UpdateAutomaticTranslationAlert(true);
  }

  public OnSnoozeAuctionAlert(saleId: number | undefined): void {
    if (!this.$auth.IsMember || !saleId) return;

    const snoozedDate: Date = new Date();
    snoozedDate.setHours(new Date().getHours() + TheAlerts.SnoozeTimeHours);
    this.salesService.Patch(saleId, { snoozeDate: snoozedDate.toISOString() });
  }

  public OnSnoozeVendorConnectedAccountAlert(): void {
    const snoozedDate: Date = new Date();
    snoozedDate.setHours(new Date().getHours() + TheAlerts.SnoozeTimeHours);

    appStore.UpdateSnoozedEndDateVendorConnectedAccount(snoozedDate);
  }

  public OpenSignUpDialog(): void {
    signUpDialogStore.Open();
  }

  public SwitchToDefaultLocale(): void {
    this.i18nManager.UpdateUILocale(this.$i18n, DEFAULT_LOCALE);

    if (this.$route.name) {
      this.$router.push({ name: this.$route.name, params: { locale: DEFAULT_LOCALE } });

      if (this.$auth.IsAuthenticated) {
        this.i18nManager.UpdateUserPreferredLocale(DEFAULT_LOCALE);
        this.i18nManager.UpdateAspNetCoreCookieRequestCulture(this.$cookies, DEFAULT_LOCALE);
      } else {
        window.location.reload();
      }
    }
  }
}
