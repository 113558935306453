import { default as Stepper } from "@pigeon/components/nav/NavStepperVertical";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavStepperVerticalStep extends Vue {
  @Prop()
  readonly title: string;
  @Prop()
  readonly icon: string;
  @Prop()
  readonly description: string;
  @Prop()
  readonly status: string;

  index = -1;
  internalStatus: string = "";
  stepper: Stepper;

  get IsStepActive() {
    return this.stepper.activeStep === this.index;
  }

  get CurrentStatus() {
    return this.status || this.internalStatus;
  }

  get IsLast() {
    return this.stepper.steps[this.stepper.steps.length - 1] === this;
  }

  get PrevStatus() {
    const prevStep = this.stepper.steps[this.index - 1];
    return prevStep ? prevStep.CurrentStatus : "wait";
  }

  get StepsCount() {
    return this.stepper.steps.length;
  }

  beforeCreate() {
    this.stepper = this.$parent as Stepper;
    this.stepper.steps.push(this);
  }

  beforeDestroy() {
    const steps = this.stepper.steps;
    const index = steps.indexOf(this);

    if (index >= 0) {
      steps.splice(index, 1);
    }
  }

  mounted() {
    const unwatch = this.$watch("index", (val) => {
      this.$watch("$parent.activeStep", this.UpdateStatus, { immediate: true });
      unwatch();
    });
  }

  UpdateStatus() {
    const activeIndex: number = this.stepper.activeStep;

    if (activeIndex > this.index) {
      this.internalStatus = "success";
    } else if (activeIndex === this.index && this.PrevStatus !== "error") {
      this.internalStatus = "process";
    } else {
      this.internalStatus = "wait";
    }
  }

  BackTo(): void {
    const activeIndex: number = this.stepper.activeStep;

    if (activeIndex > this.index) {
      if (!this.$parent) return;

      this.$parent.$emit("back-to-step", this.index);
    }
  }
}
