import { default as BaseAccordion } from "@pigeon/components/nav/NavAccordion";
import { nameof } from "@pigeon/extensions/nameof";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import smoothReflow from "vue-smooth-reflow";

const parentAccordionComponentName = "NavAccordion"; // Warning: must match with accordion registered name
@Component({
  mixins: [smoothReflow]
})
export default class NavAccordionItem extends Vue {
  @Prop()
  readonly itemKey: string;
  @Prop({ default: false })
  readonly opened: boolean;
  @Prop({ default: true })
  readonly canExpand: boolean;
  @Prop({ default: false })
  readonly isBordered: boolean;
  @Prop({ default: false })
  readonly isSeparated: boolean;

  isOpened: boolean = false;
  HasToggleTransitionEnded: boolean = false; // used only for the transition css class

  get BaseAccordion(): BaseAccordion {
    let name: string = "";
    let parent: any = null;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let instance: any = this;

    do {
      parent = instance.$parent;
      if (!parent) console.warn("Operation exception: base accordion not found");

      name = (parent as any).$options._componentTag;
      instance = parent;
    } while (name.toUpperCase() !== parentAccordionComponentName.toUpperCase());

    return parent as BaseAccordion;
  }

  beforeMount() {
    this.BaseAccordion.items.push(this);
  }

  beforeDestroy() {
    const items = this.BaseAccordion.items;
    const index = items.findIndex((item) => item.itemKey === this.itemKey);

    if (index > -1) {
      items.splice(index, 1);
    }
  }

  mounted() {
    (this as any).$smoothReflow({
      el: ".accordion_item-content",
      property: ["height"],
      transition: "height .25s ease-in-out"
    });
  }

  @Watch(nameof<NavAccordionItem>("opened"), { immediate: true })
  ObserveCollapse(): void {
    this.isOpened = this.opened;
  }

  // used only for the transition css class
  @Watch(nameof<NavAccordionItem>("isOpened"), { immediate: true })
  ObserveTransition() {
      this.HasToggleTransitionEnded = false;
      setTimeout(() => {
        this.HasToggleTransitionEnded = true;
      }, 0); // used for css transition
  }

  get HasAccordionBehavior() {
    return this.BaseAccordion.hasAccordionBehavior;
  }

  public EmitExpandOnParentAccordion() {
    if (!this.isOpened) return;

    const itemIndex = this.BaseAccordion.items.findIndex((i) => i.itemKey === this.itemKey);
    this.BaseAccordion.$emit("expand", this.itemKey);
    this.BaseAccordion.$emit("expand-index", itemIndex);
  }

  @Emit("click")
  public OnClick(): void {}

  public Toggle(): void {
    if (!this.canExpand) return;

    this.isOpened = !this.isOpened;
    if(this.isOpened) this.EmitExpandOnParentAccordion();
  }

  public Collapse(): void {
    if (!this.canExpand) return;

    this.isOpened = false;
  }

  public Expand(): void {
    if (!this.canExpand) return;

    this.isOpened = true;
    this.EmitExpandOnParentAccordion();
  }
}
