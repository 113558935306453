import { IRingsService } from "@api/contracts/odata/IRingsService";
import { IRing } from "@api/models/market/IRing";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { memoizeAsync } from "utils-decorators";

export class RingsService extends BaseODataService implements IRingsService {
  constructor() {
    super();
  }

  @memoizeAsync()
  FetchAll(): AxiosPromise<IODataCollectionResponse<IRing>> {
    return this.Client().get("Rings");
  }
}
