import { IPackageManager } from "@/Pigeon/services/contracts/IPackageManager";
import { IPigeonManager } from "@/Pigeon/services/contracts/IPigeonManager";
import { ProductTypes } from "@api/models/market/constants/ProductTypes";
import { ISale } from "@api/models/market/ISale";
import { IProduct } from "@market/components/sale/SaleProductAbstract";
import i18n from "@market/i18n";
import { AppRoutes } from "@market/routers/App.routes";
import { cid, container } from "inversify-props";
import { Location } from "vue-router";

export class ProductMapper {
  // Warning: Cannot use DI with static with property injection
  // @Inject()
  // private static pigeonManager: IPigeonManager;
  // @Inject()
  // private static packageManager: IPackageManager;

  public static MapPigeon(sale: ISale): IProduct | null {
    if (!sale || !sale.pigeon) {
      console.error("Invalid parameter exception: sale or pigeon is undefined");
      return null;
    }

    const pigeonManager = container.get<IPigeonManager>(cid.PigeonManager);
    return {
      saleId: sale.id,
      type: sale.product,
      name: sale.pigeon.name,
      reference: sale.pigeon.ring ?? (i18n.t("common.undefined") as string),
      description: "",
      to: {
        name: AppRoutes.Sale,
        params: sale.pigeon.name
          ? {
              saleKey: sale.id?.toString(),
              productRef: pigeonManager.GetRingSlug(sale.pigeon),
              productName: pigeonManager.GetNameSlug(sale.pigeon)
            }
          : { saleKey: sale.id?.toString(), productRef: pigeonManager.GetRingSlug(sale.pigeon) }
      } as Location
    };
  }

  public static MapPackage(sale: ISale, useRawPicture = false): IProduct | null {
    if (!sale || !sale.package) {
      console.error("Invalid parameter exception: sale or package is undefined");
      return null;
    }

    const packageManager = container.get<IPackageManager>(cid.PackageManager);
    return {
      saleId: sale.id,
      type: sale.product,
      name: sale.package.name,
      reference: (i18n.t("app.package") + " " + i18n.t(`enum.discipline.${sale.package.discipline}`)) as string,
      description: sale.package.assetText,
      to: {
        name: AppRoutes.Sale,
        params: {
          saleKey: sale.id?.toString(),
          productName: packageManager.GetNameSlug(sale.package)
        }
      } as Location
    };
  }

  public static MapToProduct(sale: ISale): IProduct | null {
    if (!sale) throw new Error("Null Argument exception");

    if (sale.product === ProductTypes.Pigeon) {
      return ProductMapper.MapPigeon(sale);
    } else if (sale.product === ProductTypes.Package) {
      return ProductMapper.MapPackage(sale);
    } else {
      console.error("Unsupported product type");
      return null;
    }
  }
}
