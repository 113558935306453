import { IPigeon } from "@api/models/market/IPigeon";
import PigeonDropdownlist from "@pigeon/components/pigeon/PigeonDropdownlist.vue";
import PigeonSheet from "@pigeon/components/pigeon/PigeonSheet.vue";
import { ProductDisplayModes } from "@pigeon/enumerations/ProductDisplayModes";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { Inject } from "inversify-props";
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    PigeonDropdownlist,
    PigeonSheet
  }
})
export default class PigeonPedigreeSheet extends Vue {
  @Model("select")
  readonly selected: IPigeon | null;
  @Prop({ type: Array, default: () => [] })
  readonly pedigree: IPigeon[];
  @Prop({ default: false })
  readonly useRawPicture: boolean;

  @Inject()
  private pigeonManager: IPigeonManager;

  DisplayModes = ProductDisplayModes;
  isMinimized: boolean = false;
  forceFallbackPicture: boolean = false;

  private get CurrentSelectedIndex(): number {
    if (!this.pedigree) {
      return -1;
    }

    return this.pedigree.findIndex((pigeon) => this.selected == pigeon);
  }

  private get PreviousSelectedIndex(): number {
    return this.CurrentSelectedIndex - 1 >= 0 ? this.CurrentSelectedIndex - 1 : 0;
  }

  private get NextSelectedIndex(): number {
    return this.CurrentSelectedIndex + 1 <= this.MaxSelectedIndex
      ? this.CurrentSelectedIndex + 1
      : this.MaxSelectedIndex;
  }

  private get MaxSelectedIndex(): number {
    if (!this.pedigree) {
      return 0;
    }

    return this.pedigree.length - 1;
  }

  get SelectedPigeonPictureUrl(): string {
    if (!this.selected) {
      return this.pigeonManager.defaultPigeonPicture;
    }

    return this.pigeonManager.GetPicturePigeon(this.selected.pictures, this.useRawPicture || this.forceFallbackPicture);
  }

  public GoToPreviousPigeon(): void {
    this.GoTo(this.PreviousSelectedIndex);
  }

  public GoToNextPigeon(): void {
    this.GoTo(this.NextSelectedIndex);
  }

  @Emit("select")
  public GoTo(index: number) {
    if (!this.pedigree || index < 0 || index > this.MaxSelectedIndex) {
      return;
    }

    return this.pedigree[index];
    // this.selected = this.pedigree[index];
  }

  public OnPigeonPictureError(): void {
    this.forceFallbackPicture = true;
  }
}
