import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { Channels } from "@api/enumerations/Channels";
import { IRecovery } from "@api/models/auth/IRecovery";
import AccountSignInRecoveryDoneView from "@market/pages/views/auth/AccountSignInRecoveryDone.vue";
import { IErrorManager } from "@pigeon/services/contracts/IErrorManager";
import { ErrorManager } from "@pigeon/services/ErrorManager";
import { Inject } from "inversify-props";
import { ValidationObserver } from "vee-validate";
import { Component, Emit, Ref, Vue } from "vue-property-decorator";

@Component({
  components: {
    SignInRecoveryDone: AccountSignInRecoveryDoneView
  }
})
export default class AccountSignInRecoveryView extends Vue {
  @Ref()
  readonly refValidation: InstanceType<typeof ValidationObserver>;

  @Inject()
  private accountsService: IAccountsService;

  recovery: IRecovery = {} as IRecovery;
  recoveryIsDone: boolean = false;
  errorManager: IErrorManager = new ErrorManager();
  isProcessing: boolean = false;

  mounted() {
    this.errorManager = new ErrorManager(this.refValidation);
  }

  @Emit("sign-in")
  public SignIn() {}

  public Submit(): void {
    if (!this.recovery || !this.recovery.email) return;

    // Reset errors
    this.errorManager.Reset();
    this.isProcessing = true;
    this.accountsService
      .RecoverAccount(Channels.Market, this.recovery, this.$i18n.locale)
      .then((response) => {
        this.recoveryIsDone = true;
      })
      .catch((error: any) => {
        this.errorManager.HandleError(error);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }
}
