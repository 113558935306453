import { IUsersService } from "@api/contracts/odata/IUsersService";
import { SearchTypes } from "@api/models/market/constants/SearchTypes";
import { IBid } from "@api/models/market/IBid";
import { ICart } from "@api/models/market/ICart";
import { IGuest } from "@api/models/market/IGuest";
import { IOrder } from "@api/models/market/IOrder";
import { IOrderLine } from "@api/models/market/IOrderLine";
import { IPackage } from "@api/models/market/IPackage";
import { IPigeon } from "@api/models/market/IPigeon";
import { ISale } from "@api/models/market/ISale";
import { IUser } from "@api/models/market/IUser";
import { IVendor } from "@api/models/market/IVendor";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { CatalogFilterTypes } from "@pigeon/enumerations/CatalogFilterTypes";
import { IPaginate } from "@pigeon/models/IPaginate";
import { ISalesQueryManager } from "@pigeon/services/contracts/ISalesQueryManager";
import { AxiosPromise } from "axios";
import { Inject } from "inversify-props";
import { ExpandObjectQuery, FilterDate, ODataQuery, odataQuery } from "odata-fluent-query";

export class UsersService extends BaseODataService implements IUsersService {
  @Inject()
  private salesQueryManager: ISalesQueryManager;

  //#region Queries
  private QueryUserAllSales(
    paginate?: IPaginate,
    sort?: string,
    filters?: Map<CatalogFilterTypes, string[] | boolean[]>,
    search?: string,
    searchType?: SearchTypes
  ) {
    const query = odataQuery<IUser>().expand("sales", (s) => {
      let saleSubquery: ODataQuery<ISale> = s as ODataQuery<ISale>;

      if (search && searchType) saleSubquery = this.salesQueryManager.SearchBy(saleSubquery, search, searchType);
      if (filters) saleSubquery = this.salesQueryManager.FilterBy(saleSubquery, filters);

      saleSubquery = saleSubquery
        .expand("orderLine", (ol: ExpandObjectQuery<IOrderLine>) =>
          ol
            .select("shippingStatus")
            .expand("order", (o: ExpandObjectQuery<IOrder>) => o.select("id", "paymentStatus", "transferStatus"))
        )
        .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p.select("name", "ring"))
        .expand("package", (p: ExpandObjectQuery<IPackage>) => p.select("name", "discipline"))
        .expand("bids", (b: ExpandObjectQuery<IBid>) =>
          b
            .select("amount", "bidDate", "biddingOrderId")
            .orderBy("amount", "desc")
            .orderBy("biddingOrderId", "asc")
            .orderBy("bidDate", "asc")
        );

      if (sort) saleSubquery = this.salesQueryManager.OrderBy(saleSubquery, sort) as ODataQuery<ISale>;
      else saleSubquery = saleSubquery.orderBy("saleDate", "desc").orderBy("startDate", "desc"); // default order by

      if (paginate) saleSubquery = saleSubquery.paginate(paginate.pageSize, paginate.page);

      return saleSubquery;
    });

    return query;
  }
  //#endregion

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IUser>> {
    const query = odataQuery<IUser>().orderBy("firstname", "asc");

    return this.Client().get(`Users?${query.toString()}`);
  }

  public FetchAllIdentities(): AxiosPromise<IODataCollectionResponse<IUser>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<IUser>()
      .select("id", "firstname", "lastname")
      .expand("bids", b => b.select("id")) // add property to check if active
      .expand("orders", o => o.select("id")) // add property to check if active
      .expand("carts", o => o.select("id")) // add property to check if active
      .orderBy("firstname", "asc");

    return this.Client().get(`Users?${query.toString()}`);
  }

  public FetchAllIdentitiesExtended(): AxiosPromise<IODataCollectionResponse<IUser>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<IUser>()
      .select("id", "firstname", "lastname", "stripeCustomerId", "stripeConnectedAccountId")
      .orderBy("firstname", "asc");

    return this.Client().get(`Users?${query.toString()}`);
  }

  public FetchAllIdentitiesWith(): AxiosPromise<IODataCollectionResponse<IUser>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<IUser>().select("id", "firstname", "lastname")
      .orderBy("firstname", "asc")
      .expand("addresses")
      .expand("corporation");
    return this.Client().get(`Users?${query.toString()}`);
  }

  public Fetch(userKey: string): AxiosPromise<IUser> {
    if (userStore.user && userStore.user.id !== userKey && !authStore.IsAdministrator)
      return Promise.reject(new Error("unauthorized"));

    return this.Client().get(`Users('${userKey}')`);
  }

  public FetchWithVendor(userKey: string): AxiosPromise<IUser> {
    const query = odataQuery<IUser>()
      .select("id", "firstname", "lastname", "email", "stripeConnectedAccountId", "countryCode", "registeredAt")
      .expand("corporation", (c) => c.select("name", "vatNumber"))
      .expand("vendor", (v) => v.select("id", "referralCode"));

    return this.Client().get(`Users('${userKey}')?${query.toString()}`);
  }

  public FetchBuyerDashboard(userKey: string, fromDate?: Date, toDate?: Date): AxiosPromise<IUser> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<IUser>()
      .expand("alerts", a => { 
        let alertsSubquery = a;
  
        if(fromDate)
          alertsSubquery = alertsSubquery.filter(a => (a.fromDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()))
        if(toDate)
          alertsSubquery = alertsSubquery.filter(a => (a.toDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()))

        alertsSubquery = alertsSubquery.orderBy("fromDate", "asc");
        return alertsSubquery;
      })
      .expand("bids", b => {
        let bidsSubquery = b;

        if(fromDate)
          bidsSubquery = bidsSubquery.filter(b => (b.bidDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
        if(toDate)
          bidsSubquery = bidsSubquery.filter(b => (b.bidDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

        bidsSubquery = bidsSubquery
          .expand("sale", s => s
            .expand("pigeon")
            .expand("package")
            // Do not use anymore because bids orders is incorrect and subquery should be done via a split query
            // .expand("bids", b => b
            //   .select("id", "biddingOrderId", "amount", "bidDate", "biddingOrderId")
            //   .orderBy("amount", "desc").orderBy("biddingOrderId", "asc").orderBy("bidDate", "asc")
            // )
          )
          .orderBy("bidDate", "desc");

        return bidsSubquery;
      })
      .expand("biddingOrders", bo => {
        let biddingOrdersSubquery = bo;

        if(fromDate)
          biddingOrdersSubquery = biddingOrdersSubquery.filter(b => (b.orderDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
        if(toDate)
          biddingOrdersSubquery = biddingOrdersSubquery.filter(b => (b.orderDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

        biddingOrdersSubquery = biddingOrdersSubquery
          .expand("bids")
          .expand("sale", s => s
            .expand("pigeon")
            .expand("package")
          )
          // Do not use anymore because bids orders is incorrect and subquery should be done via a split query
          // .expand("bids", b => b
          //   .select("id", "biddingOrderId", "amount", "bidDate", "biddingOrderId")
          //   .orderBy("amount", "desc").orderBy("biddingOrderId", "asc").orderBy("bidDate", "asc")
          // )
          .orderBy("orderDate", "desc");

        return biddingOrdersSubquery;
      })
      .expand("orders", o => {
        let ordersSubquery = o;

        if(fromDate)
          ordersSubquery = ordersSubquery.filter(o => (o.createdAt as FilterDate).isAfterOrEqual(fromDate.toISOString()));
        if(toDate)
          ordersSubquery = ordersSubquery.filter(o => (o.createdAt as FilterDate).isBeforeOrEqual(toDate.toISOString()));

        ordersSubquery = ordersSubquery
          .select("id", "orderDate", "total", "currency", "status", "paymentStatus", "shippingMethod", "userId")
          .expand("lines", (ol: ExpandObjectQuery<IOrderLine>) => ol
            .expand("sale", (s: ExpandObjectQuery<ISale>) => s
              .expand("pickUpAddress")
              .expand("pigeon")
              .expand("package")
            )
          )
          .expand("deliveryAddress")
          .orderBy("orderDate", "desc");

        return ordersSubquery;
      })
      .expand("favorites", o => {
        let favoritesSubquery = o;

        if(fromDate)
          favoritesSubquery = favoritesSubquery.filter(o => (o.recordedDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
        if(toDate)
          favoritesSubquery = favoritesSubquery.filter(o => (o.recordedDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

        favoritesSubquery = favoritesSubquery
          .select("id", "recordedDate", "saleId", "userId")
            .expand("sale", (s: ExpandObjectQuery<ISale>) => s
              .expand("pigeon")
              .expand("package")
            )
          .orderBy("recordedDate", "desc");

        return favoritesSubquery;
      });

    return this.Client().get(`Users('${userKey}')?${query.toString()}`);
  }

  public FetchBreederDashboard(userKey: string): AxiosPromise<IUser> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<IUser>()
      .expand("vendor", (v: ExpandObjectQuery<IVendor>) => (v as ODataQuery<IVendor>));

    return this.Client().get(`Users('${userKey}')?${query.toString()}`);
  }

  /** @deprecated DO NOT USE because this query reach Max DTU (S3) on Azure  */
  public FetchVendorDashboard(userKey: string, fromDate?: Date, toDate?: Date): AxiosPromise<IUser> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<IUser>()
      .expand("sales", s => {
        let salesSubquery = s;

        if(fromDate)
          salesSubquery = salesSubquery.filter(s => (s.createdAt as FilterDate).isAfterOrEqual(fromDate.toISOString()));
        if(toDate)
          salesSubquery = salesSubquery.filter(s => (s.createdAt as FilterDate).isBeforeOrEqual(toDate.toISOString()));

        salesSubquery = salesSubquery
          .orderBy("saleDate", "desc")
          .orderBy("startDate", "desc")
          .expand("bids", (b: ExpandObjectQuery<IBid>) => b
            .select("amount", "bidDate", "biddingOrderId", "userId")
            .orderBy("amount", "desc").orderBy("biddingOrderId", "asc").orderBy("bidDate", "asc")
            .expand("user", (u: ExpandObjectQuery<IUser>) => u
              .select("countryCode")
            )
          )
          .expand("orderLine", (ol: ExpandObjectQuery<IOrderLine>) => ol
            .expand("order", (o:ExpandObjectQuery<IOrder>) => o
              .expand("deliveryAddress")
              .expand("user", (u:ExpandObjectQuery<IUser>) => u
                .select("firstname", "lastname", "email", "phoneNumber", "countryCode")
              )
              .expand("guest", (g:ExpandObjectQuery<IGuest>) => g
                .select("firstname", "lastname", "email", "phoneNumber")
              )
            )
          )
          .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
            .select("name", "ring", "sex", "flagApproved")
          )
          .expand("package", (p: ExpandObjectQuery<IPackage>) => p
            .select("name", "discipline")
          )
          return salesSubquery;
        }
      )
      .expand("vendor", (v: ExpandObjectQuery<IVendor>) => v
        .expand("pictures") // shop pictures
        // .expand("referrals", (r: ExpandObjectQuery<IReferral>) => (r as ODataQuery<IReferral>))  
      )
      .expand("auctions", a => {
        let auctionsSubquery = a;

        if(fromDate)
          auctionsSubquery = auctionsSubquery.filter(a => (a.createdAt as FilterDate).isAfterOrEqual(fromDate.toISOString()));
        if(toDate)
          auctionsSubquery = auctionsSubquery.filter(a => (a.createdAt as FilterDate).isBeforeOrEqual(toDate.toISOString()));

        auctionsSubquery = auctionsSubquery
          .select("id", "userId", "startDate", "endDate")
          .expand("sales", as => as.select("id"));

        return auctionsSubquery;
      });

    return this.Client().get(`Users('${userKey}')?${query.toString()}`);
  }

  public FetchUserWithVendorAndShop(userKey: string): AxiosPromise<IUser> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<IUser>()
      .expand("vendor", (v: ExpandObjectQuery<IVendor>) => v
        .expand("pictures") // shop pictures
      );

    return this.Client().get(`Users('${userKey}')?${query.toString()}`);
  }

  public FetchAllOrders(userKey: string): AxiosPromise<IUser> {
    if (userStore.user && userStore.user.id !== userKey) return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<IUser>()
      .expand("orders", (o: ExpandObjectQuery<IOrder>) => (o as ODataQuery<IOrder>)
        .orderBy(o => o.orderDate)
      );

    return this.Client().get(`Users('${userKey}')?${query.toString()}`);
  }

  public FetchMySales(
    userKey: string,
    paginate?: IPaginate,
    sort?: string,
    filters?: Map<CatalogFilterTypes, string[] | boolean[]>,
    search?: string,
    searchType?: SearchTypes
  ): AxiosPromise<IUser> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    const query = this.QueryUserAllSales(paginate, sort, filters, search, searchType);

    return this.Client().get(`Users('${userKey}')?${query.toString()}`);
  }

  public FetchAllSales(
    sort?: string,
    filters?: Map<CatalogFilterTypes, string[] | boolean[]>,
    search?: string,
    searchType?: SearchTypes
  ): AxiosPromise<IODataCollectionResponse<IUser>> {
    const query = this.QueryUserAllSales(undefined, sort, filters, search, searchType);

    return this.Client().get(`Users?${query.toString()}`);
  }

  public FetchAllPigeons(userKey: string): AxiosPromise<IUser> {
    if (userStore.user && userStore.user.id !== userKey) return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<IUser>()
      .expand("pigeons", p => p
        .expand("fancier")
        .orderBy("createdAt")
      );

    return this.Client().get(`Users('${userKey}')?${query.toString()}`);
  }

  public FetchAllPigeonsIdentity(userKey: string): AxiosPromise<IUser> {
    if (userStore.user && userStore.user.id !== userKey) return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<IUser>()
      .expand("pigeons", p => p
        .select("id", "name", "ring", "sex", "fatherId", "motherId", "userId")
        .expand("father", (f: ExpandObjectQuery<IPigeon>) => f
          .select("id", "name", "ring", "sex", "fatherId", "motherId", "userId")
        )
        .expand("mother", (m: ExpandObjectQuery<IPigeon>) => m
          .select("id", "name", "ring", "sex", "fatherId", "motherId", "userId")
        )
      );

    return this.Client().get(`Users('${userKey}')?${query.toString()}`);
  }

  public FetchAllOverview(): AxiosPromise<IODataCollectionResponse<IUser>> {
    // prettier-ignore
    const query = odataQuery<IUser>()
      .select("id", "firstname", "lastname", "buyerAlias", "email", "emailConfirmed", "phoneNumber", "phoneNumberConfirmed", "preferredLanguage", "countryCode", "stripeCustomerId", "stripeConnectedAccountId", "registeredAt")
      .expand("carts", (c: ExpandObjectQuery<ICart>) => c
        .select("id")
      )
      .expand("orders", (o: ExpandObjectQuery<IOrder>) => o
        .select("id")
      );

    return this.Client().get(`Users?${query.toString()}`);
  }

  public FetchAllUsersWith(fromDate?: Date, toDate?: Date): AxiosPromise<IODataCollectionResponse<IUser>> {
    let query = odataQuery<IUser>()
      .orderBy("firstname", "asc")
      .expand("orders") // used for buyers
      .expand("bids") // used for bidders
      .expand("sales"); // used for vendors

    // prettier-ignore
    if (fromDate) query = query.filter((u) => (u.registeredAt as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate)
      query = query.filter((u) => (u.registeredAt as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`Users?${query.toString()}`);
  }

  public FetchAllUsersWithVendorAndShop(fromDate?: Date, toDate?: Date): AxiosPromise<IODataCollectionResponse<IUser>> {
    let query = odataQuery<IUser>().orderBy("firstname", "asc").expand("vendor");

    // prettier-ignore
    if (fromDate) query = query.filter((u) => (u.registeredAt as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate)
      query = query.filter((u) => (u.registeredAt as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`Users?${query.toString()}`);
  }

  public GetUsersIdentities(): AxiosPromise<IODataCollectionResponse<Partial<IUser>>> {
    return this.Client().get(`Users/MarketService.GetUsersIdentities`);
  }
}
