// Stores
import { IStoreModules } from "@market/stores/contracts/IStoreModules";
import Vue from "vue";
import Vuex from "vuex";
import { config } from "vuex-module-decorators";

Vue.use(Vuex);

// Store
export const store = new Vuex.Store<IStoreModules>({});
// Set rawError to true by default on all @Action decorators
config.rawError = true;
export default store;
