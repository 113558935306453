import { IPrizeAssetsService } from "@api/contracts/odata/IPrizeAssetsService";
import { IPrize } from "@api/models/market/IPrize";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { odataQuery } from "odata-fluent-query";

export class PrizeAssetsService extends BaseODataService implements IPrizeAssetsService {
  constructor() {
    super();
  }

  public FetchAllByPigeon(pigeonKey: number, top?: number): AxiosPromise<IODataCollectionResponse<IPrize>> {
    // Note: Remove $select query segment because it occurs bug (see: https://github.com/OData/odata.net/issues/633)
    // $select=id,rank,order,distance,participants,area,year,place,taggedAsAsset
    let query = odataQuery<IPrize>()
      .filter((p) => p.pigeonId.equals(pigeonKey))
      .filter((p) => p.taggedAsAsset.equals(true))
      .orderBy("order", "desc")
      .count();

    if (top) query = query.paginate(top);

    return this.Client().get(`Prizes?${query.toString()}`);
  }
}
