import { ICartsService } from "@api/contracts/odata/ICartsService";
import { ICart } from "@api/models/market/ICart";
import { ISale } from "@api/models/market/ISale";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, odataQuery } from "odata-fluent-query";

export class CartsService extends BaseODataService implements ICartsService {
  constructor() {
    super();
  }

  public Insert(cart: ICart): AxiosPromise<ICart> {
    if (!cart.cartItems || !cart.cartItems.length || cart.total < 0) return Promise.reject("Cart invalid");

    return this.Client().post("Carts", cart);
  }

  // Business rule: a cart should not be updated (but patched instead)
  // public Update(cartKey: number, cart: ICart): AxiosPromise<void> {
  //   if (!cart.cartItems || !cart.cartItems.length || cart.total < 0) return Promise.reject("Cart invalid");

  //   return this.Client().put(`Carts(${cartKey})`, cart);
  // }

  public Patch(cartKey: number, cart: Partial<ICart>): AxiosPromise<void> {
    return this.Client().patch(`Carts(${cartKey})`, cart);
  }

  public Delete(cartKey: number): AxiosPromise<void> {
    return this.Client().delete(`Carts(${cartKey})`);
  }

  public Fetch(cartKey: number): AxiosPromise<ICart> {
    return this.Client().get(`Carts(${cartKey})`);
  }

  public FetchAllOverview(): AxiosPromise<IODataCollectionResponse<ICart>> {
    if (!authStore.IsAdministrator) return Promise.reject("Not authorized");

    // prettier-ignore
    const query = odataQuery<ICart>()
      .select("id", "createdAt", "createdUrl", "checkoutSessionId", "total")
      .expand("cartItems", (q) => 
        q.expand("sale", (q: ExpandObjectQuery<ISale>) => 
          q.select("id", "price")
        )
      );

    return this.Client().get(`Carts?${query.toString()}`);
  }

  public FetchAllBySale(saleKey: number): AxiosPromise<IODataCollectionResponse<ICart>> {
    const query = odataQuery<ICart>()
      .expand("cartItems")
      .filter((c) => c.cartItems.any((ci) => ci.saleId.equals(saleKey)))
      .orderBy("createdAt", "desc");

    return this.Client().get(`Carts?${query.toString()}`);
  }
}
