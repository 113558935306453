import { IAddress } from "@api/models/market/IAddress";
import { ICustomer } from "@api/models/market/ICustomer";
import { IUser } from "@api/models/market/IUser";
import { IVendor } from "@api/models/market/IVendor";
import i18n from "@market/i18n";
import { IListOption } from "@pigeon/models/IListOption";
import { IUserManager } from "./contracts/IUserManager";

export class UserManager implements IUserManager {
  public IsEqualAddress(address: IAddress | undefined | null, addressToCompare: IAddress | undefined | null): boolean {
    if (!address || !addressToCompare) return false;

    return (
      address.addressLine == addressToCompare.addressLine &&
      address.locality == addressToCompare.locality &&
      address.postalCode == addressToCompare.postalCode &&
      address.district == addressToCompare.district &&
      address.country == addressToCompare.country &&
      address.countryTwoIsoLetters == addressToCompare.countryTwoIsoLetters
    );
  }

  public IsEqualUser(user: IUser, userToCompare: IUser): boolean {
    if (!user || !userToCompare) return false;

    return (
      user.firstname === userToCompare.firstname &&
      user.lastname === userToCompare.lastname &&
      user.email === userToCompare.email &&
      user.phoneNumber === userToCompare.phoneNumber
    );
  }

  public CompareUsersById(user1: IUser, user2: IUser): boolean {
    return user1.id === user2.id;
  }

  public CompareCustomersById(customer1: ICustomer, customer2: ICustomer): boolean {
    return customer1.id === customer2.id;
  }

  public FormatAddress(address: IAddress): string {
    if (!address) return "";

    const formattedAddress: string[] = [];

    if (address.addressLine) formattedAddress.push(address.addressLine);
    if (address.postalCode && address.locality) formattedAddress.push(`${address.postalCode} ${address.locality}`);
    if (address.country) formattedAddress.push(address.country);

    if (formattedAddress.length < 1) return "";
    else return formattedAddress.join(", ");
  }

  private MapVendorToOption(v: IVendor): IListOption {
    return { value: v?.user?.id, label: `${v?.user?.firstname} ${v?.user?.lastname?.toUpperCase()}` } as IListOption;
  }

  public BuildVendorsOptionsGroups(vendors: IVendor[]): { [key: string]: IListOption[] } {
    if (!vendors || !vendors.length) return {};

    const activeVendors = vendors.filter((v) => v.user.stripeConnectedAccountId);
    const activeVendorsOptions = activeVendors.map((v) => this.MapVendorToOption(v));

    const passiveVendors = vendors.filter((v) => !activeVendors.some((av) => av.id == v.id));
    const passiveVendorsOptions = passiveVendors.map((v) => this.MapVendorToOption(v));

    const activeVendorTranslate = i18n.t("options.active_vendors") as string;
    const passiveVendorTranslate = i18n.t("options.passive_vendors") as string;

    return {
      [activeVendorTranslate]: activeVendorsOptions,
      [passiveVendorTranslate]: passiveVendorsOptions
    };
  }

  public BuildVendorsOptions(vendors: IVendor[]): IListOption[] {
    if (!vendors || !vendors.length) return [];

    return vendors.map((v) => {
      return { value: v?.user?.id, label: `${v?.user?.firstname} ${v?.user?.lastname?.toUpperCase()}` } as IListOption;
    });
  }

  private MapMemberToOption(u: Partial<IUser>): IListOption {
    return { value: u?.id, label: `${u?.firstname} ${u?.lastname?.toUpperCase()}` } as IListOption;
  }

  public BuildMembersOptionsGroups(members: Partial<IUser>[]): { [key: string]: IListOption[] } {
    if (!members || !members.length) return {};

    const activeMembers = members.filter((m) => m.bids?.length || m.orders?.length || m.carts?.length);
    const activeMembersOptions = activeMembers.map((m) => this.MapMemberToOption(m));

    const passiveMembers = members.filter((v) => !activeMembers.some((am) => am.id == v.id));
    const passiveMembersOptions = passiveMembers.map((m) => this.MapMemberToOption(m));

    const activeMemberTranslate = i18n.t("options.active_members") as string;
    const passiveMemberTranslate = i18n.t("options.passive_members") as string;

    return {
      [activeMemberTranslate]: activeMembersOptions,
      [passiveMemberTranslate]: passiveMembersOptions
    };
  }

  public BuildMembersOptions(members: Partial<IUser>[]): IListOption[] {
    if (!members || !members.length) return [];

    return members.map((u) => {
      return { value: u?.id, label: `${u?.firstname} ${u?.lastname?.toUpperCase()}` } as IListOption;
    });
  }
}
