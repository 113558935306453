/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Breakpoints } from "./Breakpoints";
import { IBreakpoint } from "./IBreakpoint";
import { IBreakpointThresholds } from "./IBreakpointThresholds";
import { IPreset } from "./IPreset";

// See https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/services/breakpoint/index.ts
// Docs https://vuetifyjs.com/en/customization/breakpoints/
export class BreakpointService implements IBreakpoint {
  // public
  xs = false;
  sm = false;
  md = false;
  lg = false;
  xl = false;
  xxl = false;

  xsOnly = false;
  smOnly = false;
  smAndDown = false;
  smAndUp = false;
  mdOnly = false;
  mdAndDown = false;
  mdAndUp = false;
  lgOnly = false;
  lgAndDown = false;
  lgAndUp = false;
  xlOnly = false;
  xlAndDown = false;
  xlAndUp = false;
  xxlOnly = false;

  name: Breakpoints = Breakpoints.ExtraSmall; // mobile first
  height = 0;
  width = 0;
  thresholds: IBreakpointThresholds;

  // private
  private resizeTimeout = 0;

  constructor(preset: IPreset) {
    const { thresholds } = preset.breakpoint;
    this.thresholds = thresholds;

    this.Init();
  }

  public Init() {
    if (typeof window === "undefined") return;

    window.addEventListener("resize", this.OnResize.bind(this), { passive: true });

    this.Update();
  }

  private OnResize() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = window.setTimeout(this.Update.bind(this), 100);
  }

  /* eslint-disable-next-line max-statements */
  private Update() {
    const height = this.GetClientHeight();
    const width = this.GetClientWidth();

    const xs = width < this.thresholds.xs;
    const sm = width < this.thresholds.sm && !xs;
    const md = width < this.thresholds.md && !(sm || xs);
    const lg = width < this.thresholds.lg && !(md || sm || xs);
    const xl = width < this.thresholds.xl && !(lg || md || sm || xs);
    const xxl = width >= this.thresholds.xl;

    this.height = height;
    this.width = width;

    this.xs = xs;
    this.sm = sm;
    this.md = md;
    this.lg = lg;
    this.xl = xl;
    this.xxl = xxl;

    this.xsOnly = xs;
    this.smOnly = sm;
    this.smAndDown = (xs || sm) && !(md || lg || xl || xxl);
    this.smAndUp = !xs && (sm || md || lg || xl || xxl);
    this.mdOnly = md;
    this.mdAndDown = (xs || sm || md) && !(lg || xl || xxl);
    this.mdAndUp = !(xs || sm) && (md || lg || xl || xxl);
    this.lgOnly = lg;
    this.lgAndDown = (xs || sm || md || lg) && !(xl || xxl);
    this.lgAndUp = !(xs || sm || md) && (lg || xl || xxl);
    this.xlOnly = xl;
    this.xlAndDown = (xs || sm || md || lg || xl) && !xxl;
    this.xlAndUp = !(xs || sm || md || lg) && (xl || xxl);
    this.xxlOnly = xxl;

    switch (true) {
      case xs:
        this.name = Breakpoints.ExtraSmall;
        break;
      case sm:
        this.name = Breakpoints.Small;
        break;
      case md:
        this.name = Breakpoints.Medium;
        break;
      case lg:
        this.name = Breakpoints.Large;
        break;
      case xl:
        this.name = Breakpoints.ExtraLarge;
        break;

      default:
        this.name = Breakpoints.ExtraExtraLarge;
        break;
    }
  }

  // Cross-browser support as described in:
  // https://stackoverflow.com/questions/1248081
  private GetClientWidth() {
    /* istanbul ignore if */
    if (typeof document === "undefined") return 0; // SSR
    return Math.max(document.documentElement!.clientWidth, window.innerWidth || 0);
  }

  private GetClientHeight() {
    /* istanbul ignore if */
    if (typeof document === "undefined") return 0; // SSR
    return Math.max(document.documentElement!.clientHeight, window.innerHeight || 0);
  }
}
