import { render, staticRenderFns } from "./PigeonPedigreeSheet.vue?vue&type=template&id=76f0aca0&scoped=true&"
import script from "./PigeonPedigreeSheet.ts?vue&type=script&lang=ts&"
export * from "./PigeonPedigreeSheet.ts?vue&type=script&lang=ts&"
import style0 from "./PigeonPedigreeSheet.vue?vue&type=style&index=0&id=76f0aca0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76f0aca0",
  null
  
)

export default component.exports