import { ISaleNotificationsService } from "@api/contracts/odata/ISaleNotificationsService";
import { IPackage } from "@api/models/market/IPackage";
import { IPigeon } from "@api/models/market/IPigeon";
import { ISale } from "@api/models/market/ISale";
import { ISaleNotification } from "@api/models/market/ISaleNotification";
import { IUser } from "@api/models/market/IUser";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { userStore } from "@market/stores/App.store.modules";
import { NotificationsFilterTypes } from "@pigeon/enumerations/NotificationsFilterTypes";
import { AxiosPromise } from "axios";
import dayjs from "dayjs";
import { ExpandObjectQuery, FilterDate, ODataQuery, odataQuery } from "odata-fluent-query";

export class SaleNotificationsService extends BaseODataService implements ISaleNotificationsService {
  constructor() {
    super();
  }

  //#region Filters
  private FilterBy(
    query: ODataQuery<ISaleNotification>,
    filters: Map<NotificationsFilterTypes, string[]>
  ): ODataQuery<ISaleNotification> {
    if (!filters) return query;

    if (filters.has(NotificationsFilterTypes.FromDate)) {
      query = this.FilterQueryFromDate(query, filters.get(NotificationsFilterTypes.FromDate)?.shift());
    }

    if (filters.has(NotificationsFilterTypes.FromDaysAgo)) {
      query = this.FilterQueryFromDaysAgo(query, filters.get(NotificationsFilterTypes.FromDaysAgo)?.shift());
    }

    if (filters.has(NotificationsFilterTypes.Type)) {
      query = this.FilterQueryByNotificationType(query, filters.get(NotificationsFilterTypes.Type));
    }

    return query;
  }

  private FilterQueryByNotificationType(
    query: ODataQuery<ISaleNotification>,
    filterValues?: string[]
  ): ODataQuery<ISaleNotification> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((sn) => sn.notification.in(filterValues));
  }

  private FilterQueryFromDate(
    query: ODataQuery<ISaleNotification>,
    filterValue?: string
  ): ODataQuery<ISaleNotification> {
    if (!filterValue) return query;

    const fromDate = filterValue;
    const dateFilter = dayjs(fromDate);

    return query.filter((sn) => (sn.notificationDate as any as FilterDate).isAfter(dateFilter.toISOString()));
  }

  private FilterQueryFromDaysAgo(
    query: ODataQuery<ISaleNotification>,
    filterValue?: string
  ): ODataQuery<ISaleNotification> {
    if (!filterValue) return query;

    const daysToFilter = parseInt(filterValue);
    const dateFilter = dayjs().add(-daysToFilter, "day");

    return query.filter((sn) => (sn.notificationDate as any as FilterDate).isAfterOrEqual(dateFilter.toISOString()));
  }
  //#endregion

  FetchAllWithSaleByUser(
    filters?: Map<NotificationsFilterTypes, string[]>,
    top?: number
  ): AxiosPromise<IODataCollectionResponse<ISaleNotification>> {
    if (!userStore.user) return Promise.reject("Not authorized");

    let query = odataQuery<ISaleNotification>().filter((sn) =>
      sn.userId.equals((userStore.user as IUser).id, { ignoreGuid: true })
    );

    if (filters) query = this.FilterBy(query, filters);

    // Note: Comment $select query segment because it occurs bug (see: https://github.com/OData/odata.net/issues/633)
    // prettier-ignore
    query = query
      .expand("sale", (s: ExpandObjectQuery<ISale>) => s
        // .select("id", "product")
        .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
          .select("id", "name", "ring")
        )
        .expand("package", (p: ExpandObjectQuery<IPackage>) => p
          .select("id", "name", "discipline")
        )
        .expand("user", (u: ExpandObjectQuery<IUser>) => u.
          select("firstname", "lastname")
        )
      )
      .orderBy("notificationDate", "desc")
      .count();

    if (top) query = query.paginate(top);

    return this.Client().get(`SaleNotifications?${query.toString()}`);
  }

  FetchAllWithSale(
    filters?: Map<NotificationsFilterTypes, string[]>,
    top?: number
  ): AxiosPromise<IODataCollectionResponse<ISaleNotification>> {
    let query = odataQuery<ISaleNotification>();

    if (filters) query = this.FilterBy(query, filters);

    // prettier-ignore
    query = query
      .expand("sale", (s: ExpandObjectQuery<ISale>) => s
        .select("id", "product")
        .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
          .select("id", "name", "ring")
        )
        .expand("package", (p: ExpandObjectQuery<IPackage>) => p
          .select("id", "name", "discipline")
        )
        .expand("user", (u: ExpandObjectQuery<IUser>) => u.
          select("firstname", "lastname")
        )
      )
      .orderBy("notificationDate", "desc")
      .count();

    if (top) query = query.paginate(top);

    return this.Client().get(`SaleNotifications?${query.toString()}`);
  }

  PatchFlagAsRead(saleNotification: ISaleNotification): AxiosPromise<void> {
    const notificationFlaggedAsRead: Partial<ISaleNotification> = {
      flagAsRead: true,
      flaggedDate: new Date().toISOString()
    };

    return this.Client().patch(`SaleNotifications(${saleNotification.id})`, notificationFlaggedAsRead);
  }

  PatchFlagAsUnread(saleNotification: ISaleNotification): AxiosPromise<void> {
    const notificationFlaggedAsRead: Partial<ISaleNotification> = {
      flagAsRead: false,
      flaggedDate: new Date().toISOString()
    };

    return this.Client().patch(`SaleNotifications(${saleNotification.id})`, notificationFlaggedAsRead);
  }

  public FlagAllAsRead(): AxiosPromise<any> {
    return this.Client().post(`SaleNotifications/MarketService.FlagAllAsRead`);
  }
}
