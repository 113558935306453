import { IBaseAuthService } from "@api/contracts/auth/base/IBaseAuthService";
import Axios, { AxiosInstance } from "axios";

export class BaseAuthServiceAnonymous implements IBaseAuthService {
  protected client: AxiosInstance;

  constructor() {
    this.client = Axios.create({
      baseURL: process.env.VUE_APP_AUTH_BASE_URL,
      timeout: Number(process.env.VUE_APP_AUTH_TIME_OUT),
      withCredentials: true // pass cookies, headers ...
    });
  }

  public Client(): AxiosInstance {
    return this.client;
  }
}
