import { ISaleNotificationsService } from "@api/contracts/odata/ISaleNotificationsService";
import { NotificationTypes } from "@api/models/market/constants/NotificationTypes";
import { ProductTypes } from "@api/models/market/constants/ProductTypes";
import { ISaleNotification } from "@api/models/market/ISaleNotification";
import { authStore, notificationStore, userStore } from "@market/stores/App.store.modules";
import { AlertTypes } from "@pigeon/components/app/AppAlert";
import { Inject } from "inversify-props";
import { INotificationManager } from "./contracts/INotificationManager";

export class NotificationManager implements INotificationManager {
  @Inject()
  private saleNotificationsService: ISaleNotificationsService;

  public DetermineNotificationTitle(notification: ISaleNotification): string {
    if (!notification || !notification.sale) return "";
    const { sale } = notification;

    if (sale.product === ProductTypes.Pigeon && sale.pigeon) return `${sale.pigeon.name} [${sale.pigeon.ring}]`;
    else if (sale.product === ProductTypes.Package && sale.package)
      return `${sale.package.name} [${sale.package.discipline}]`;
    else {
      console.error("Not supported product type");
      return "";
    }
  }

  public GetNotificationType(saleNotification: ISaleNotification): AlertTypes {
    switch (saleNotification.notification) {
      case NotificationTypes.SalePendingApproval:
        return AlertTypes.Info;

      case NotificationTypes.SaleApproved:
        return AlertTypes.Success;

      case NotificationTypes.SaleDisapproved:
        return AlertTypes.Danger;

      case NotificationTypes.AuctionNewBidOnAFollowedSale:
        return AlertTypes.Info;

      case NotificationTypes.AuctionBidAccepted:
        return AlertTypes.Success;

      case NotificationTypes.AuctionNewBidOnYourSale:
        return AlertTypes.Success;

      case NotificationTypes.AuctionOutBid:
        return AlertTypes.Danger;

      case NotificationTypes.SaleSold:
        return AlertTypes.Success;

      default:
        return AlertTypes.Info;
    }
  }

  public async FlagSaleNotificationAsRead(saleNotification: ISaleNotification): Promise<void> {
    if (authStore.IsAdministrator) return Promise.reject("Only user can flag a notification as read.");
    if (saleNotification.flagAsRead === true) return Promise.resolve();

    notificationStore.FlagNotificationAsRead(saleNotification);
    await this.saleNotificationsService.PatchFlagAsRead(saleNotification);
  }

  public async FlagSaleNotificationAsSeen(saleNotification: ISaleNotification): Promise<void> {
    notificationStore.FlagNotificationAsSeen(saleNotification);
  }
}
