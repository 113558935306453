import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { Channels } from "@api/enumerations/Channels";
import { IAccountEmailVerification } from "@api/models/auth/IAccountEmailVerification";
import { AppRoutes } from "@market/routers/App.routes";
import { accountStore } from "@market/stores/App.store.modules";
import { Inject } from "inversify-props";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AccountSignUpDoneView extends Vue {
  @Inject()
  private accountsService: IAccountsService;

  AppRoutes = AppRoutes;

  public SendVerification(): void {
    if (!this.$auth.User) return;

    const verification: IAccountEmailVerification = { email: this.$auth.User.email };
    this.accountsService.SendNewEmailVerification(Channels.Market, verification, this.$i18n.locale);
    accountStore.FlagVerificationAsResent();
  }
}
