import { IPrizesRanking } from "@api/models/market/IPrizesRanking";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PigeonAssetRanking extends Vue {
  @Prop({ type: Object, default: () => {} })
  readonly ranking: IPrizesRanking;

  get HasRankImage(): boolean {
    return typeof this.ranking.top !== "undefined";
  }

  public GetRankImageType(order: number): string {
    if (order <= 1) {
      return "svg-gold";
    } else if (order <= 2) {
      return "svg-silver";
    } else {
      return "svg-green";
    }
  }
}
