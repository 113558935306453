import { PaymentMethodTypes } from "@api/models/market/constants/PaymentMethodTypes";
import { IAddress } from "@api/models/market/IAddress";
import { ICheckoutSessionCustomer } from "@api/models/market/ICheckoutSessionCustomer";
import { ICheckoutSessionPayment } from "@api/models/market/ICheckoutSessionPayment";
import { ICheckoutSessionShipping } from "@api/models/market/ICheckoutSessionShipping";
import {
  BANK_ACCOUNT_HOLDER_ADDRESS,
  BANK_ACCOUNT_HOLDER_NAME,
  BANK_BIC,
  BANK_IBAN,
  BANK_NAME,
  BASE_DEPOSIT_RATE,
  SEPA_COUNTRIES
} from "@market/App.const";
import { ICartItem } from "@market/models/ICartItem";
import { IPersonBankData } from "@pigeon/components/module/ModuleTransferFormSEPA";
import { IBillingDetails } from "@pigeon/models/IBillingDetails";
import { ICheckoutManager } from "@pigeon/services/contracts/ICheckoutManager";

export class CheckoutManager implements ICheckoutManager {
  public HasACartVoucherItem(cartItems: ICartItem[]): boolean {
    return cartItems.some((ci) => ci.sale.flagVoucher);
  }

  public IsCustomerInSepaArea(customer: ICheckoutSessionCustomer | null): boolean {
    if (!customer || !customer.countryCode) return false;

    return SEPA_COUNTRIES.includes(customer.countryCode);
  }

  private IsCustomerInBenelux(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return ["BE", "NL", "LU"].includes(customer.countryCode);
  }

  private IsCustomerInBelgium(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return ["BE"].includes(customer.countryCode);
  }

  private IsCustomerInNetherlands(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return ["NL"].includes(customer.countryCode);
  }

  private IsCustomerInPoland(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return ["PL"].includes(customer.countryCode);
  }

  private IsCustomerInGermany(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return ["DE"].includes(customer.countryCode);
  }

  private IsCustomerInFrance(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return ["FR"].includes(customer.countryCode);
  }

  // Note: Cartes Bancaires is done via the stripe card elements
  public IsCartesBancairesAvailableForCustomer(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return this.IsCustomerInFrance(customer);
  }

  // Note: Cartes Bancaires payment is not a bank redirect payement (it s a card payment)
  public IsBankRedirectPaymentAvailableForCustomer(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return (
      this.IsBancontactPaymentAvailableForCustomer(customer) ||
      this.IsiDealPaymentAvailableForCustomer(customer) ||
      this.IsP24PaymentAvailableForCustomer(customer) ||
      this.IsGiropayAvailableForCustomer(customer)
    );
  }

  public IsBankRedirectPaymentsAvailablesForCustomer(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    let counter = 0;
    if (this.IsBancontactPaymentAvailableForCustomer(customer)) counter++;
    if (this.IsiDealPaymentAvailableForCustomer(customer)) counter++;
    if (this.IsP24PaymentAvailableForCustomer(customer)) counter++;
    if (this.IsGiropayAvailableForCustomer(customer)) counter++;

    return counter > 1;
  }

  public IsBancontactPaymentAvailableForCustomer(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return this.IsCustomerInBelgium(customer);
  }

  public IsPayconicPaymentAvailableForCustomer(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return this.IsCustomerInBenelux(customer);
  }

  public IsiDealPaymentAvailableForCustomer(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return this.IsCustomerInNetherlands(customer);
  }

  public IsP24PaymentAvailableForCustomer(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return this.IsCustomerInPoland(customer);
  }

  public IsGiropayAvailableForCustomer(customer: ICheckoutSessionCustomer): boolean {
    if (!customer || !customer.countryCode) return false;

    return this.IsCustomerInGermany(customer);
  }

  public GetBankRedirectPaymentSubmethodNameForCustomer(customer: ICheckoutSessionCustomer): string | undefined {
    if (!this.IsBankRedirectPaymentAvailableForCustomer(customer)) return;

    if (this.IsBancontactPaymentAvailableForCustomer(customer)) return "Bancontact";
    else if (this.IsiDealPaymentAvailableForCustomer(customer)) return "iDeal";
    else if (this.IsP24PaymentAvailableForCustomer(customer)) return "Przelewy24";
    else if (this.IsGiropayAvailableForCustomer(customer)) return "Giropay";
    else return;
  }

  public IsValidAddress(address: IAddress | null | undefined): boolean {
    if (!address) return false;

    return address.addressLine && (address.country || address.countryTwoIsoLetters) ? true : false;
  }

  public IsADeposit(payment: ICheckoutSessionPayment | null, cartItems: ICartItem[]): boolean {
    return payment?.method === PaymentMethodTypes.Cash && this.HasACartVoucherItem(cartItems);
  }

  public CalculateTotalAmount(
    payment: ICheckoutSessionPayment | null,
    cartItems: ICartItem[],
    currentTaxRate?: number
  ): number {
    return this.IsADeposit(payment, cartItems)
      ? this.CalculateTotalAmountForDeposit(cartItems)
      : this.CalculateTotalAmountForOrder(cartItems, currentTaxRate);
  }

  public CalculateTotalAmountForDeposit(cartItems: ICartItem[]): number {
    return this.CalculateTotalAmountForOrder(cartItems) * BASE_DEPOSIT_RATE;
  }

  public CalculateTotalAmountForOrder(cartItems: ICartItem[], currentTaxRate?: number): number {
    let total = 0;
    cartItems.forEach((ci) => (total += ci.total));

    return total;
  }

  public BuildCustomerFullName(customer: ICheckoutSessionCustomer): string {
    return `${customer?.firstname} ${customer?.lastname}`;
  }

  public BuildSepaCreditTransferPlatformBeneficiary(minimal = false): IPersonBankData {
    return {
      name: BANK_ACCOUNT_HOLDER_NAME,
      address: minimal ? undefined : BANK_ACCOUNT_HOLDER_ADDRESS,
      iban: BANK_IBAN,
      bic: minimal ? undefined : BANK_BIC,
      bankName: minimal ? undefined : BANK_NAME
    };
  }

  public BuildSepaRemittanceInformation(cartItems: ICartItem[]): string {
    let salesIds: string = "";
    for (const ci of cartItems) {
      salesIds += ci.saleId + " ";
    }
    salesIds = salesIds.substring(0, salesIds.length - 1);

    return `SALES ${salesIds}`;
  }

  public BuildBillingDetails(
    customer: ICheckoutSessionCustomer,
    shipping: ICheckoutSessionShipping,
    payment: ICheckoutSessionPayment
  ): IBillingDetails {
    return {
      name: this.BuildCustomerFullName(customer),
      address: (payment?.address || shipping?.deliveryAddress) ?? null,
      email: customer?.email ?? null,
      phone: customer?.phoneNumber ?? null
    };
  }
}
