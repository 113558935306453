import { BaseThemes } from "@api/models/market/constants/BaseThemes";
import { Component, Prop, Vue } from "vue-property-decorator";

export enum LoaderTypes {
  Half = "half",
  Full = "full"
}

@Component
export default class AppLoader extends Vue {
  @Prop({ validator: (value: BaseThemes) => Object.values(BaseThemes).includes(value) })
  readonly theme?: BaseThemes;
  @Prop({
    default: LoaderTypes.Half,
    validator: (value: LoaderTypes) => Object.values(LoaderTypes).includes(value)
  })
  readonly type: LoaderTypes;
  @Prop({ default: false })
  readonly iconOnly: boolean;
  @Prop({ default: false })
  readonly inline: boolean;
  @Prop()
  readonly color?: string;
}
