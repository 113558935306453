import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppOverlay extends Vue {
  @Prop({ type: Number, default: 100 })
  readonly zIndex: number;

  @Emit("click")
  public HandleClick(event: MouseEvent): void {}
}
