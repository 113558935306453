import { IOrdersService } from "@api/contracts/odata/IOrdersService";
import { ShippingMethodTypes } from "@api/models/market/constants/ShippingMethodTypes";
import { IGuest } from "@api/models/market/IGuest";
import { IOrder } from "@api/models/market/IOrder";
import { IOrderAmounts } from "@api/models/market/IOrderAmounts";
import { IPackage } from "@api/models/market/IPackage";
import { IPigeon } from "@api/models/market/IPigeon";
import { ISale } from "@api/models/market/ISale";
import { IShipping } from "@api/models/market/IShipping";
import { IShippingService } from "@api/models/market/IShippingService";
import { IUser } from "@api/models/market/IUser";
import { IVendor } from "@api/models/market/IVendor";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { cloneDeep } from "lodash-es";
import { ExpandObjectQuery, FilterDate, odataQuery } from "odata-fluent-query";

export class OrdersService extends BaseODataService implements IOrdersService {
  constructor() {
    super();
  }

  public Fetch(orderKey: string): AxiosPromise<IOrder> {
    return this.Client().get(`Orders('${orderKey}')`);
  }

  public FetchWithLines(orderKey: string): AxiosPromise<IOrder> {
    const query = odataQuery<IOrder>()
      .expand("user")
      .expand("guest")
      .expand("deliveryAddress")
      .expand("lines", (l) =>
        l.expand("sale", (s: ExpandObjectQuery<ISale>) =>
          s
            .expand("pickUpAddress")
            .expand("pigeon")
            .expand("package", (p: ExpandObjectQuery<IPackage>) => p.expand("pigeons"))
        )
      );

    return this.Client().get(`Orders('${orderKey}')?${query.toString()}`);
  }

  public FetchSummaryWithBuyer(orderKey: string): AxiosPromise<IOrder> {
    const query = odataQuery<IOrder>()
      .select("id", "orderDate", "paymentDate", "paymentMethod", "total", "userId", "guestId")
      .expand("user", (u: ExpandObjectQuery<IUser>) =>
        u.select("firstname", "lastname", "email", "phoneNumber", "preferredLanguage")
      )
      .expand("guest", (g: ExpandObjectQuery<IGuest>) =>
        g.select("firstname", "lastname", "email", "phoneNumber", "preferredLanguage")
      );

    return this.Client().get(`Orders('${orderKey}')?${query.toString()}`);
  }

  public FetchSheet(orderKey: string): AxiosPromise<IOrder> {
    // prettier-ignore
    const query = odataQuery<IOrder>()
      .expand("user", (u: ExpandObjectQuery<IUser>) => u
        .expand("vendor", (v: ExpandObjectQuery<IVendor>) => v
          .select("referralCode")
        )
      )
      .expand("guest")
      .expand("deliveryAddress")
      .expand("billingAddress")
      .expand("billingCorporation")
      .expand("lines", l => l
        .expand("delivery", (d: ExpandObjectQuery<IShipping>) => d
          .expand("shippingRate")
          .expand("shippingService", (ss: ExpandObjectQuery<IShippingService>) => ss
            .expand("provider")
          )
        )
        .expand("sale", (s: ExpandObjectQuery<ISale>) => s
          .expand("pickUpAddress")
          .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
            .expand("fancier")
            .expand("pictures")
          )
          .expand("package", (p: ExpandObjectQuery<IPackage>) => p
            .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) => p
              .expand("pictures")
            )
            .expand("fancier")
          )
        )
      )

    return this.Client().get(`Orders('${orderKey}')?${query.toString()}`);
  }

  public FetchAllOverview(): AxiosPromise<IODataCollectionResponse<IOrder>> {
    if (!authStore.IsAdministrator) return Promise.reject("Not authorized");

    const query = odataQuery<IOrder>()
      .select("id", "subtotal", "total", "currency", "orderDate", "shippingMethod", "paymentMethod", "status")
      .expand("user")
      .expand("guest")
      .expand("lines", (l) => l.select("saleId"));

    return this.Client().get(`Orders?${query.toString()}`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IOrder>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<IOrder>()
      .orderBy("orderDate", "desc")
      .expand("deliveryAddress")
      .expand("user", (u: ExpandObjectQuery<IUser>) =>
        u.select("id", "firstname", "lastname", "email", "phoneNumber", "countryCode")
      )
      .expand("guest", (g: ExpandObjectQuery<IGuest>) => g.select("firstname", "lastname", "email", "phoneNumber"));

    return this.Client().get(`Orders?${query.toString()}`);
  }

  public FetchAllOrdersWithLinesAndCustomer(
    fromDate?: Date,
    toDate?: Date
  ): AxiosPromise<IODataCollectionResponse<IOrder>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<IOrder>()
      .orderBy("orderDate", "desc")
      .expand("lines")
      .expand("deliveryAddress")
      .expand("user", (u: ExpandObjectQuery<IUser>) =>
        u.select("id", "firstname", "lastname", "email", "phoneNumber", "countryCode")
      )
      .expand("guest", (g: ExpandObjectQuery<IGuest>) => g.select("firstname", "lastname", "email", "phoneNumber"));

    if (fromDate)
      query = query.filter((a) => (a.orderDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((a) => (a.orderDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`Orders?${query.toString()}`);
  }

  public FetchAllByUser(userKey?: string): AxiosPromise<IODataCollectionResponse<IOrder>> {
    if (!userKey) return Promise.reject(new Error("Argument exception: userKey is missing"));

    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<IOrder>()
      .filter((o) => o.userId.equals(userKey, { ignoreGuid: true }).or(o.guestId.equals(userKey, { ignoreGuid: true })))
      .orderBy("orderDate", "desc")
      .expand("deliveryAddress")
      .expand("user", (u: ExpandObjectQuery<IUser>) =>
        u.select("id", "firstname", "lastname", "email", "phoneNumber", "countryCode")
      )
      .expand("guest", (g: ExpandObjectQuery<IGuest>) => g.select("firstname", "lastname", "email", "phoneNumber"));

    return this.Client().get(`Orders?${query.toString()}`);
  }

  public FetchAllWithCustomerAndDeliveryAddressByIds(
    orderKeys: string[]
  ): AxiosPromise<IODataCollectionResponse<IOrder>> {
    const query = odataQuery<IOrder>()
      .filter((o) => o.id.in(orderKeys))
      .orderBy("orderDate", "desc")
      .expand("deliveryAddress")
      .expand("user", (u: ExpandObjectQuery<IUser>) =>
        u.select("id", "firstname", "lastname", "buyerAlias", "email", "phoneNumber", "countryCode", "registeredAt")
      )
      .expand("guest", (g: ExpandObjectQuery<IGuest>) =>
        g.select("firstname", "lastname", "email", "phoneNumber", "registeredAt")
      );

    return this.Client().get(`Orders?${query.toString()}`);
  }

  public Insert(order: IOrder): AxiosPromise<IOrder> {
    // Sale graph is not neccessary for insert action
    const orderToInsert: IOrder = cloneDeep(order);

    for (const line of orderToInsert.lines) {
      delete line.sale;
      delete line.delivery?.shippingService;
    }

    return this.Client().post(`Orders`, orderToInsert);
  }

  public Update(orderKey: string, order: IOrder): AxiosPromise<void> {
    // Sale graph is not neccessary for insert action
    const orderToUpdate: IOrder = cloneDeep(order);

    for (const line of orderToUpdate.lines) {
      delete line.sale;
      delete line.delivery?.shippingService;
    }

    return this.Client().put(`Orders('${orderKey}')`, orderToUpdate);
  }

  public Patch(orderKey: string, patch: Partial<IOrder>): AxiosPromise<void> {
    return this.Client().patch(`Orders('${orderKey}')`, patch);
  }

  public FlagOrderAsNonPaid(orderKey: string): AxiosPromise<void> {
    return this.Client().post(`Orders('${orderKey}')/MarketService.FlagOrderAsNonPaid`);
  }

  public CalculateAmounts(
    salesId: number[],
    shippingMethod: ShippingMethodTypes,
    buyerTwoIsoLettersCountry?: string
  ): AxiosPromise<IOrderAmounts> {
    const shippingMethodParam = shippingMethod ? `'${shippingMethod}'` : null;
    const buyerCountryParam = buyerTwoIsoLettersCountry ? `'${buyerTwoIsoLettersCountry}'` : null;

    return this.Client().get(
      `Orders/MarketService.CalculateAmounts(salesId=${JSON.stringify(
        salesId
      )},shippingMethod=${shippingMethodParam},buyerCountry=${buyerCountryParam})`
    );
  }

  public AbandonOrder(orderId: string, checkoutSessionId: string): AxiosPromise<void> {
    return this.Client().post(`Orders('${orderId}')/MarketService.AbandonOrder`, {
      checkoutSessionId: checkoutSessionId
    });
  }
}
