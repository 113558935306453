import { PictureTypes } from "@api/models/market/constants/PictureTypes";
import { IPicture } from "@api/models/market/IPicture";
import { IFilePicture } from "@pigeon/models/IFilePicture";
import { IBlobManager } from "@pigeon/services/contracts/IBlobManager";
import { Inject } from "inversify-props";
import { v4 as UUIDv4 } from "uuid";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

export enum FilePreviewModes {
  Image = "image",
  Iframe = "iframe"
}

@Component
export default class FormInputPicture extends Vue {
  @Prop()
  readonly id: string;
  @Prop({ default: () => UUIDv4() })
  readonly keyIndex: string;
  @Prop({ default: "image/*, blob" })
  readonly accept: string;
  @Prop({ default: "jpg,jpeg,gif,png,webp" })
  readonly extension: string;

  @Prop({ default: () => [] })
  readonly pictures: IPicture[];
  @Prop()
  readonly pictureType: PictureTypes;
  @Prop({ default: true })
  readonly hasPreview: boolean;
  @Prop({
    default: FilePreviewModes.Image,
    validator: (value: FilePreviewModes) => Object.values(FilePreviewModes).includes(value)
  })
  readonly previewMode: FilePreviewModes;

  @Inject()
  private blobManager: IBlobManager;

  PreviewModes = FilePreviewModes;
  fileList: FileList | null = null;
  thumb: string = "";

  get InputId(): string {
    return `app-input-picture_${this.pictureType}_${this.keyIndex}`;
  }

  private BuildThumbUrl(fileList: FileList | null): string {
    if (!fileList || !fileList.item(0)) return "";

    const file = fileList.item(0);
    if (!file) throw Error("Null exception: file is undefined.");

    let thumbUrl: string = "";
    const URL = window.URL || (window as any).webkitURL;

    if (URL && URL.createObjectURL) {
      thumbUrl = URL.createObjectURL(file);
    }

    return thumbUrl;
  }

  public OnReset(): void {
    this.fileList = null;
    this.thumb = "";
  }

  @Emit("click")
  public OnClick(): void {}

  //#region Upload
  public OnRemovePicture(): void {
    if (!this.pictures || !this.pictures.length) return;

    this.OnReset();
    const picturesToRemove = this.pictures.filter((p) => p?.type === this.pictureType);
    if (picturesToRemove) {
      picturesToRemove.forEach((pic) => this.Remove(pic));
    }
  }

  @Emit("remove")
  public Remove(pictureToRemove: IPicture): IPicture {
    return pictureToRemove;
  }

  public OnUploadInput(event: any): void {
    const files: FileList = event.target.files;

    if (!files.length) return;
    if (!this.pictures) return;

    this.fileList = files;
    this.thumb = this.BuildThumbUrl(this.fileList);

    for (const file of files) {
      this.SetPicture(file);
      this.StoreFile(file);
    }
  }

  @Emit("store-file-picture")
  public StoreFile(file: File): IFilePicture {
    return {
      pictureType: this.pictureType,
      file: file
    };
  }

  public SetPicture(fileUploaded: File): void {
    const existingPicture: IPicture | undefined = this.pictures?.find((p) => p?.type === this.pictureType);
    if (existingPicture) {
      // case update (existing picture)
      existingPicture.filename = this.blobManager.BuildPictureName(this.pictureType, fileUploaded);
      existingPicture.url = this.thumb;
    } else {
      // Case insert (new picture)
      this.pictures.push({
        type: this.pictureType,
        filename: this.blobManager.BuildPictureName(this.pictureType, fileUploaded),
        url: this.thumb
      });
    }
  }
  //#endregion
}
