import { render, staticRenderFns } from "./PigeonAssetRanking.vue?vue&type=template&id=78614ecc&scoped=true&"
import script from "./PigeonAssetRanking.ts?vue&type=script&lang=ts&"
export * from "./PigeonAssetRanking.ts?vue&type=script&lang=ts&"
import style0 from "./PigeonAssetRanking.vue?vue&type=style&index=0&id=78614ecc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78614ecc",
  null
  
)

/* custom blocks */
import block0 from "./PigeonAssetRanking.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports