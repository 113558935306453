import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TheHeader extends Vue {
  @Prop({ type: String })
  readonly title?: string;
  @Prop({ type: String })
  readonly titleSecondary?: string;
  @Prop({ type: String })
  readonly backgroundImageUrl?: string;

  get Style(): string | null {
    if (!this.backgroundImageUrl) {
      return null;
    }

    return `background-image: url('${this.backgroundImageUrl}');`;
  }
}
