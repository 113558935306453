import { IPollAnswersService } from "@api/contracts/odata/IPollAnswersService";
import { IPollAnswer } from "@api/models/market/IPollAnswer";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import odataQuery from "odata-fluent-query";

export class PollAnswersService extends BaseODataService implements IPollAnswersService {
  constructor() {
    super();
  }

  public Insert(pollAnswer: IPollAnswer): AxiosPromise<IPollAnswer> {
    return this.Client().post("PollAnswers", pollAnswer);
  }

  public Update(pollAnswerKey: number, pollAnswer: IPollAnswer): AxiosPromise<void> {
    return this.Client().put(`PollAnswers(${pollAnswerKey})`, pollAnswer);
  }

  public Delete(pollAnswerKey: number): AxiosPromise<void> {
    return this.Client().delete(`PollAnswers(${pollAnswerKey})`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IPollAnswer>> {
    const query = odataQuery<IPollAnswer>().orderBy("pollId", "desc");

    return this.Client().get(`PollAnswers?${query.toString()}`);
  }

  public Fetch(pollAnswerKey: number): AxiosPromise<IPollAnswer> {
    return this.Client().get(`PollAnswers(${pollAnswerKey})`);
  }
}
