// import 'reflect-metadata'; // Import only once
import { IQrCodesService } from "@/Api/contracts/webapi/IQrCodesService";
import { ISvgDocumentsService } from "@/Api/contracts/webapi/ISvgDocumentsService";
import { QrCodesService } from "@/Api/services/webapi/QrCodesService";
import { SvgDocumentsService } from "@/Api/services/webapi/SvgDocumentsService";
import { AmountManager } from "@/Pigeon/services/AmountManager";
import { IAmountManager } from "@/Pigeon/services/contracts/IAmountManager";
import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { IBingMapsRestService } from "@api/contracts/external/IBingMapsRestService";
import { IBingMapsWebControlService } from "@api/contracts/external/IBingMapsWebControlService";
import { IStripeClientService } from "@api/contracts/external/IStripeClientService";
import { IWorldAtlasService } from "@api/contracts/external/IWorldAtlasService";
import { IAddressesService } from "@api/contracts/odata/IAddressesService";
import { IAlertsService } from "@api/contracts/odata/IAlertsService";
import { IAuctionsService } from "@api/contracts/odata/IAuctionsService";
import { IBiddingOrdersService } from "@api/contracts/odata/IBiddingOrdersService";
import { IBidsService } from "@api/contracts/odata/IBidsService";
import { ICartsService } from "@api/contracts/odata/ICartsService";
import { ICheckoutSessionsService } from "@api/contracts/odata/ICheckoutSessionsService";
import { IDepositsService } from "@api/contracts/odata/IDepositsService";
import { IFancierProfilesService } from "@api/contracts/odata/IFancierProfilesService";
import { IFanciersService } from "@api/contracts/odata/IFanciersService";
import { IFavoritesService } from "@api/contracts/odata/IFavoritesService";
import { IGuestsService } from "@api/contracts/odata/IGuestsService";
import { INewslettersService } from "@api/contracts/odata/INewslettersService";
import { IOrderLinesService } from "@api/contracts/odata/IOrderLinesService";
import { IOrdersService } from "@api/contracts/odata/IOrdersService";
import { IPackagesService } from "@api/contracts/odata/IPackagesService";
import { IParentAssetsService } from "@api/contracts/odata/IParentAssetsService";
import { IPigeonsService } from "@api/contracts/odata/IPigeonsService";
import { IPlacesService } from "@api/contracts/odata/IPlacesService";
import { IPollAnswersService } from "@api/contracts/odata/IPollAnswersService";
import { IPollsService } from "@api/contracts/odata/IPollsService";
import { IPollVotesService } from "@api/contracts/odata/IPollVotesService";
import { IPrizeAssetsService } from "@api/contracts/odata/IPrizeAssetsService";
import { IPrizesService } from "@api/contracts/odata/IPrizesService";
import { IRankingAssetsService } from "@api/contracts/odata/IRankingAssetsService";
import { IReferralsService } from "@api/contracts/odata/IReferralsService";
import { IReproducerParentsService } from "@api/contracts/odata/IReproducerParentsService";
import { IRingsService } from "@api/contracts/odata/IRingsService";
import { ISaleDisapprovalReasonsService } from "@api/contracts/odata/ISaleDisapprovalReasonsService";
import { ISaleNotificationsService } from "@api/contracts/odata/ISaleNotificationsService";
import { ISalesService } from "@api/contracts/odata/ISalesService";
import { ISettlementsService } from "@api/contracts/odata/ISettlementsService";
import { IShippingServicesService } from "@api/contracts/odata/IShippingServicesService";
import { IStrainsService } from "@api/contracts/odata/IStrainsService";
import { ITaskTrackingsService } from "@api/contracts/odata/ITaskTrackingsService";
import { ITaxRatesService } from "@api/contracts/odata/ITaxRatesService";
import { IUsersService } from "@api/contracts/odata/IUsersService";
import { IVendorsService } from "@api/contracts/odata/IVendorsService";
import { IBlobsService } from "@api/contracts/webapi/IBlobsService";
import { IConnectedAccountsService } from "@api/contracts/webapi/IConnectedAccountsService";
import { IContactsService } from "@api/contracts/webapi/IContactsService";
import { ICountriesService } from "@api/contracts/webapi/ICountriesService";
import { IFacebookService } from "@api/contracts/webapi/IFacebookService";
import { IInvoicesService } from "@api/contracts/webapi/IInvoicesService";
import { IPaymentIntentsService } from "@api/contracts/webapi/IPaymentIntentsService";
import { IPayoutsService } from "@api/contracts/webapi/IPayoutsService";
import { IPurchasesService } from "@api/contracts/webapi/IPurchasesService";
import { IServersService } from "@api/contracts/webapi/IServersService";
import { IStripePayoutsService } from "@api/contracts/webapi/IStripePayoutsService";
import { ITwitterService } from "@api/contracts/webapi/ITwitterService";
import { AccountsService } from "@api/services/auth/AccountsService";
import { BingMapsRestService } from "@api/services/external/BingMapsRestService";
import { BingMapsWebControlService } from "@api/services/external/BingMapsWebControlService";
import { StripeClientService } from "@api/services/external/StripeClientService";
import { WorldAtlasService } from "@api/services/external/WorldAtlasService";
import { AddressesService } from "@api/services/odata/AddressesService";
import { AlertsService } from "@api/services/odata/AlertsService";
import { AuctionsService } from "@api/services/odata/AuctionsService";
import { BiddingOrdersService } from "@api/services/odata/BiddingOrdersService";
import { BidsService } from "@api/services/odata/BidsService";
import { CartsService } from "@api/services/odata/CartsService";
import { CheckoutSessionsService } from "@api/services/odata/CheckoutSessionsService";
import { DepositsService } from "@api/services/odata/DepositsService";
import { FancierProfilesService } from "@api/services/odata/FancierProfilesService";
import { FanciersService } from "@api/services/odata/FanciersService";
import { FavoritesService } from "@api/services/odata/FavoritesService";
import { GuestsService } from "@api/services/odata/GuestsService";
import { NewslettersService } from "@api/services/odata/NewslettersService";
import { OrderLinesService } from "@api/services/odata/OrderLinesService";
import { OrdersService } from "@api/services/odata/OrdersService";
import { PackagesService } from "@api/services/odata/PackagesService";
import { ParentAssetsService } from "@api/services/odata/ParentAssetsService";
import { PigeonsService } from "@api/services/odata/PigeonsService";
import { PlacesService } from "@api/services/odata/PlacesService";
import { PollAnswersService } from "@api/services/odata/PollAnswersService";
import { PollsService } from "@api/services/odata/PollsService";
import { PollVotesService } from "@api/services/odata/PollVotesService";
import { PrizeAssetsService } from "@api/services/odata/PrizeAssetsService";
import { PrizesService } from "@api/services/odata/PrizesService";
import { RankingAssetsService } from "@api/services/odata/RankingAssetsService";
import { ReferralsService } from "@api/services/odata/ReferralsService";
import { ReproducerParentsService } from "@api/services/odata/ReproducerParentsService";
import { RingsService } from "@api/services/odata/RingsService";
import { SaleDisapprovalReasonsService } from "@api/services/odata/SaleDisapprovalReasonsService";
import { SaleNotificationsService } from "@api/services/odata/SaleNotificationsService";
import { SalesService } from "@api/services/odata/SalesService";
import { SettlementsService } from "@api/services/odata/SettlementsService";
import { ShippingServicesService } from "@api/services/odata/ShippingServicesService";
import { StrainsService } from "@api/services/odata/StrainsService";
import { TaskTrackingsService } from "@api/services/odata/TaskTrackingsService";
import { TaxRatesService } from "@api/services/odata/TaxRatesService";
import { UsersService } from "@api/services/odata/UsersService";
import { VendorsService } from "@api/services/odata/VendorsService";
import { BlobsService } from "@api/services/webapi/BlobsService";
import { ConnectedAccountsService } from "@api/services/webapi/ConnectedAccountsService";
import { ContactsService } from "@api/services/webapi/ContactsService";
import { CountriesService } from "@api/services/webapi/CountriesService";
import { FacebookService } from "@api/services/webapi/FacebookService";
import { InvoicesService } from "@api/services/webapi/InvoicesService";
import { PaymentIntentsService } from "@api/services/webapi/PaymentIntentsService";
import { PayoutsService } from "@api/services/webapi/PayoutsService";
import { PurchasesService } from "@api/services/webapi/PurchasesService";
import { ServersService } from "@api/services/webapi/ServersService";
import { StripePayoutsService } from "@api/services/webapi/StripePayoutsService";
import { TwitterService } from "@api/services/webapi/TwitterService";
import { AuctionManager } from "@pigeon/services/AuctionManager";
import { BlobManager } from "@pigeon/services/BlobManager";
import { CheckoutManager } from "@pigeon/services/CheckoutManager";
import { IAuctionManager } from "@pigeon/services/contracts/IAuctionManager";
import { IBlobManager } from "@pigeon/services/contracts/IBlobManager";
import { ICheckoutManager } from "@pigeon/services/contracts/ICheckoutManager";
import { ICountryManager } from "@pigeon/services/contracts/ICountryManager";
import { ICreditCardManager } from "@pigeon/services/contracts/ICreditCardManager";
import { IDashboardManager } from "@pigeon/services/contracts/IDashboardManager";
import { IErrorManager } from "@pigeon/services/contracts/IErrorManager";
import { IEventManager } from "@pigeon/services/contracts/IEventManager";
import { IFancierProfileManager } from "@pigeon/services/contracts/IFancierProfileManager";
import { IFileManager } from "@pigeon/services/contracts/IFileManager";
import { II18nManager } from "@pigeon/services/contracts/II18nManager";
import { INotificationManager } from "@pigeon/services/contracts/INotificationManager";
import { IOfferManager } from "@pigeon/services/contracts/IOfferManager";
import { IPackageManager } from "@pigeon/services/contracts/IPackageManager";
import { IPaymentManager } from "@pigeon/services/contracts/IPaymentManager";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { IProductManager } from "@pigeon/services/contracts/IProductManager";
import { IRingManager } from "@pigeon/services/contracts/IRingManager";
import { ISaleManager } from "@pigeon/services/contracts/ISaleManager";
import { ISalesQueryManager } from "@pigeon/services/contracts/ISalesQueryManager";
import { IShippingManager } from "@pigeon/services/contracts/IShippingManager";
import { IShopManager } from "@pigeon/services/contracts/IShopManager";
import { IStepManager } from "@pigeon/services/contracts/IStepManager";
import { ITaskManager } from "@pigeon/services/contracts/ITaskManager";
import { IUserManager } from "@pigeon/services/contracts/IUserManager";
import { CountryManager } from "@pigeon/services/CountryManager";
import { CreditCardManager } from "@pigeon/services/CreditCardManager";
import { DashboardManager } from "@pigeon/services/DashboardManager";
import { ErrorManager } from "@pigeon/services/ErrorManager";
import { EventManager } from "@pigeon/services/EventManager";
import { FancierProfileManager } from "@pigeon/services/FancierProfileManager";
import { FileManager } from "@pigeon/services/FileManager";
import { I18nManager } from "@pigeon/services/I18nManager";
import { NotificationManager } from "@pigeon/services/NotificationManager";
import { OfferManager } from "@pigeon/services/OfferManager";
import { PackageManager } from "@pigeon/services/PackageManager";
import { PaymentManager } from "@pigeon/services/PaymentManager";
import { PigeonManager } from "@pigeon/services/PigeonManager";
import { ProductManager } from "@pigeon/services/ProductManager";
import { RingManager } from "@pigeon/services/RingManager";
import { SaleManager } from "@pigeon/services/SaleManager";
import { SalesQueryManager } from "@pigeon/services/SalesQueryManager";
import { ShippingManager } from "@pigeon/services/ShippingManager";
import { ShopManager } from "@pigeon/services/ShopManager";
import { StepManager } from "@pigeon/services/StepManager";
import { TaskManager } from "@pigeon/services/TaskManager";
import { UserManager } from "@pigeon/services/UserManager";
import { container } from "inversify-props";

// See: https://github.com/CKGrafico/inversify-props
// Warning: The name of the property must be the interface name (= id auto generated)
// Example: @Inject nameServiceA: INameServiceA
export function BuildContainer(): void {
  // Api services
  container.addSingleton<IAccountsService>(AccountsService);
  container.addSingleton<IAddressesService>(AddressesService);
  container.addSingleton<IAlertsService>(AlertsService);
  container.addSingleton<IAuctionsService>(AuctionsService);
  container.addSingleton<IBidsService>(BidsService);
  container.addSingleton<IBiddingOrdersService>(BiddingOrdersService);
  container.addSingleton<IBlobsService>(BlobsService);
  container.addSingleton<ICartsService>(CartsService);
  container.addSingleton<ICheckoutSessionsService>(CheckoutSessionsService);
  container.addSingleton<IConnectedAccountsService>(ConnectedAccountsService);
  container.addSingleton<IContactsService>(ContactsService);
  container.addSingleton<ICountriesService>(CountriesService);
  container.addSingleton<IDepositsService>(DepositsService);
  container.addSingleton<IFancierProfilesService>(FancierProfilesService);
  container.addSingleton<IFanciersService>(FanciersService);
  container.addSingleton<IFavoritesService>(FavoritesService);
  container.addSingleton<IGuestsService>(GuestsService);
  container.addSingleton<IInvoicesService>(InvoicesService);
  container.addSingleton<INewslettersService>(NewslettersService);
  container.addSingleton<IOrdersService>(OrdersService);
  container.addSingleton<IOrderLinesService>(OrderLinesService);
  container.addSingleton<IPackagesService>(PackagesService);
  container.addSingleton<IParentAssetsService>(ParentAssetsService);
  container.addSingleton<IPaymentIntentsService>(PaymentIntentsService);
  container.addSingleton<IStripePayoutsService>(StripePayoutsService);
  container.addSingleton<IPigeonsService>(PigeonsService);
  container.addSingleton<IPlacesService>(PlacesService);
  container.addSingleton<IPollsService>(PollsService);
  container.addSingleton<IPollAnswersService>(PollAnswersService);
  container.addSingleton<IPollVotesService>(PollVotesService);
  container.addSingleton<IPrizeAssetsService>(PrizeAssetsService);
  container.addSingleton<IPrizesService>(PrizesService);
  container.addSingleton<IPurchasesService>(PurchasesService);
  container.addSingleton<IQrCodesService>(QrCodesService);
  container.addSingleton<IRankingAssetsService>(RankingAssetsService);
  container.addSingleton<IReferralsService>(ReferralsService);
  container.addSingleton<IReproducerParentsService>(ReproducerParentsService);
  container.addSingleton<IRingsService>(RingsService);
  container.addSingleton<ISaleDisapprovalReasonsService>(SaleDisapprovalReasonsService);
  container.addSingleton<ISaleNotificationsService>(SaleNotificationsService);
  container.addSingleton<ISalesService>(SalesService);
  container.addSingleton<IServersService>(ServersService);
  container.addSingleton<ISettlementsService>(SettlementsService);
  container.addSingleton<IShippingServicesService>(ShippingServicesService);
  container.addSingleton<IStrainsService>(StrainsService);
  container.addSingleton<ISvgDocumentsService>(SvgDocumentsService);
  container.addSingleton<ITaskTrackingsService>(TaskTrackingsService);
  container.addSingleton<ITaxRatesService>(TaxRatesService);
  container.addSingleton<IPayoutsService>(PayoutsService);
  container.addSingleton<IUsersService>(UsersService);
  container.addSingleton<IVendorsService>(VendorsService);
  // External services
  container.addSingleton<IBingMapsRestService>(BingMapsRestService);
  container.addSingleton<IBingMapsWebControlService>(BingMapsWebControlService);
  container.addSingleton<IFacebookService>(FacebookService);
  container.addSingleton<IStripeClientService>(StripeClientService);
  container.addSingleton<ITwitterService>(TwitterService);
  container.addSingleton<IWorldAtlasService>(WorldAtlasService);

  // App services
  container.addSingleton<IAmountManager>(AmountManager);
  container.addSingleton<IAuctionManager>(AuctionManager);
  container.addSingleton<IBlobManager>(BlobManager);
  container.addSingleton<ICreditCardManager>(CreditCardManager);
  container.addSingleton<ICheckoutManager>(CheckoutManager);
  container.addSingleton<ICountryManager>(CountryManager);
  container.addSingleton<IEventManager>(EventManager);
  container.addSingleton<IFileManager>(FileManager);
  container.addSingleton<II18nManager>(I18nManager);
  container.addSingleton<INotificationManager>(NotificationManager);
  container.addSingleton<IOfferManager>(OfferManager);
  container.addSingleton<IPaymentManager>(PaymentManager);
  container.addSingleton<IPackageManager>(PackageManager);
  container.addSingleton<IPigeonManager>(PigeonManager);
  container.addSingleton<IProductManager>(ProductManager);
  container.addSingleton<IRingManager>(RingManager);
  container.addSingleton<ISaleManager>(SaleManager);
  container.addSingleton<ISalesQueryManager>(SalesQueryManager);
  container.addSingleton<IShippingManager>(ShippingManager);
  container.addSingleton<IShopManager>(ShopManager);
  container.addSingleton<ITaskManager>(TaskManager);
  container.addSingleton<IUserManager>(UserManager);
  container.addSingleton<IFancierProfileManager>(FancierProfileManager);
  container.addSingleton<IDashboardManager>(DashboardManager);
  container.addTransient<IErrorManager>(ErrorManager);
  container.addTransient<IStepManager>(StepManager);
}
