import { ICountriesService } from "@api/contracts/webapi/ICountriesService";
import { ICountry } from "@api/models/shared/ICountry";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { AxiosPromise } from "axios";
import { memoizeAsync } from "utils-decorators";

export class CountriesService extends BaseApiService implements ICountriesService {
  constructor() {
    super();
  }

  @memoizeAsync()
  FetchAll(locale: string): AxiosPromise<ICountry[]> {
    return this.Client().get(`Countries?locale=${locale}`);
  }
}
