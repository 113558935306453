import { IRingsService } from "@api/contracts/odata/IRingsService";
import { ICountriesService } from "@api/contracts/webapi/ICountriesService";
import { IRing } from "@api/models/market/IRing";
import { ICountry } from "@api/models/shared/ICountry";
import i18n from "@market/i18n";
import { store } from "@market/stores";
import { IAppDataStore } from "@market/stores/contracts/IAppDataStore";
import { IListOption } from "@pigeon/models/IListOption";
import { ICountryManager } from "@pigeon/services/contracts/ICountryManager";
import { IRingManager } from "@pigeon/services/contracts/IRingManager";
import { cid, container, Inject } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "data" = "data";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class AppDataStore extends VuexModule implements IAppDataStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private countriesService: ICountriesService;
  // @Inject()
  // private countryManager: ICountryManager;
  // @Inject()
  // private ringsService: IRingsService;
  // @Inject()
  // private ringManager: IRingManager;

  countries: ICountry[] = [];
  ringAreas: IRing[] = [];

  get Countries(): ICountry[] {
    return this.countries;
  }

  get CountryOptions(): IListOption[] {
    if (!this.Countries || !this.Countries.length) return [];

    return this.Countries.map((c) => {
      return { label: c.label, value: c.code } as IListOption;
    });
  }

  get RingAreas(): IRing[] {
    return this.ringAreas;
  }

  get RingAreasGroupOptions(): { [key: string]: IListOption[] } {
    const ringManager = container.get<IRingManager>(cid.RingManager);
    return ringManager.BuildRingAreasGroupOptions(this.ringAreas);
  }

  @Mutation
  SET_COUNTRIES_DATA(countriesData: ICountry[]): void {
    this.countries = countriesData;
  }

  @Mutation
  SET_RING_AREAS_DATA(ringAreasData: IRing[]): void {
    this.ringAreas = ringAreasData;
  }

  @Action
  async InitializeCountries(): Promise<void> {
    const countriesService = container.get<ICountriesService>(cid.CountriesService);
    const countryManager = container.get<ICountryManager>(cid.CountryManager);

    try {
      const { data } = await countriesService.FetchAll(i18n.locale);
      this.SET_COUNTRIES_DATA(data);
    } catch (error: any) {
      console.error(error);
      const data = countryManager.CountriesHardcoded;
      this.SET_COUNTRIES_DATA(data);
    }
  }

  @Action
  async InitializeRingAreas(): Promise<void> {
    const ringsService = container.get<IRingsService>(cid.RingsService);
    const ringManager = container.get<IRingManager>(cid.RingManager);

    try {
      const { data } = await ringsService.FetchAll();
      this.SET_RING_AREAS_DATA(data.value);
    } catch (error: any) {
      console.error(error);
      const data = ringManager.RingAreasHardcoded;
      this.SET_RING_AREAS_DATA(data);
    }
  }
}
