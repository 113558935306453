import { DisciplineTypes } from "@api/models/market/constants/DisciplineTypes";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProductDiscipline extends Vue {
  @Prop({
    default: DisciplineTypes.MediumDistance,
    validator: (value: DisciplineTypes) => Object.values(DisciplineTypes).includes(value)
  })
  readonly type: DisciplineTypes;
  @Prop({ default: true, type: Boolean })
  readonly hasLabel: boolean;

  DisciplineTypes = DisciplineTypes;

  get DistanceCssClass(): string {
    let cssClass: string = "";

    if (this.type === DisciplineTypes.ShortDistance) {
      cssClass = "short";
    } else if (this.type === DisciplineTypes.MediumDistance) {
      cssClass = "middle";
    } else if (this.type === DisciplineTypes.LongDistance) {
      cssClass = "long";
    } else if (this.type === DisciplineTypes.ExtremeLongDistance) {
      cssClass = "very-long";
    }

    return cssClass;
  }
}
