import { IBiddingOrdersService } from "@api/contracts/odata/IBiddingOrdersService";
import { IBid } from "@api/models/market/IBid";
import { IBiddingOrder } from "@api/models/market/IBiddingOrder";
import { IUser } from "@api/models/market/IUser";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, FilterDate, odataQuery } from "odata-fluent-query";

export class BiddingOrdersService extends BaseODataService implements IBiddingOrdersService {
  constructor() {
    super();
  }

  public Insert(biddingOrder: IBiddingOrder): AxiosPromise<IBiddingOrder> {
    return this.Client().post("BiddingOrders", biddingOrder);
  }

  public Fetch(biddingOrderKey: number): AxiosPromise<IBiddingOrder> {
    return this.Client().get(`BiddingOrders(${biddingOrderKey})`);
  }

  public FetchWithBidsAndBidder(biddingOrderKey: number): AxiosPromise<IBiddingOrder> {
    const query = odataQuery<IBiddingOrder>().expand("bids", (b: ExpandObjectQuery<IBid>) =>
      b
        .select("id", "biddingOrderId", "amount", "currency", "bidDate", "saleId", "userId")
        .orderBy("bidDate", "asc")
        .expand("user", (q: ExpandObjectQuery<IUser>) => q.select("id", "firstname", "lastname", "stripeCustomerId"))
    );
    return this.Client().get(`BiddingOrders(${biddingOrderKey})?${query.toString()}`);
  }

  public FetchAllBiddingOrdersWithUserBySale(saleKey: number): AxiosPromise<IODataCollectionResponse<IBiddingOrder>> {
    const query = odataQuery<IBiddingOrder>()
      .filter((b) => b.saleId.equals(saleKey))
      .select("limitAmount", "orderDate", "userId", "saleId")
      .orderBy("limitAmount", "desc")
      .expand("user", (u: ExpandObjectQuery<IUser>) =>
        u.select("id", "firstname", "lastname", "buyerAlias", "countryCode", "email", "phoneNumber", "stripeCustomerId")
      );

    return this.Client().get(`BiddingOrders?${query.toString()}`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IBiddingOrder>> {
    return this.Client().get(`BiddingOrders`);
  }

  public FetchAllBiddingOrdersWithBidderAndBids(
    fromDate?: Date,
    toDate?: Date
  ): AxiosPromise<IODataCollectionResponse<IBiddingOrder>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<IBiddingOrder>()
      .select("limitAmount", "orderDate", "userId", "saleId")
      .expand("bids")
      .expand("user", (u: ExpandObjectQuery<IUser>) =>
        u.select(
          "id",
          "firstname",
          "lastname",
          "buyerAlias",
          "countryCode",
          "email",
          "phoneNumber",
          "stripeCustomerId",
          "registeredAt"
        )
      )
      .orderBy("limitAmount", "desc");

    if (fromDate)
      query = query.filter((a) => (a.orderDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((a) => (a.orderDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`BiddingOrders?${query.toString()}`);
  }

  public FetchAllByUser(userKey: string): AxiosPromise<IODataCollectionResponse<IBiddingOrder>> {
    if (!userKey) return Promise.reject(new Error("Argument exception: userKey is missing"));

    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<IBiddingOrder>()
      .filter((bo) => bo.userId.equals(userKey, { ignoreGuid: true }))
      .select("limitAmount", "orderDate", "userId", "saleId")
      .orderBy("limitAmount", "desc");

    return this.Client().get(`BiddingOrders?${query.toString()}`);
  }

  public FetchMyHighestBiddingOrderBySale(saleKey: number): AxiosPromise<IODataCollectionResponse<IBiddingOrder>> {
    const query = odataQuery<IBiddingOrder>()
      .filter((b) => b.saleId.equals(saleKey))
      .select("limitAmount", "orderDate", "userId", "saleId")
      .orderBy("limitAmount", "desc")
      .paginate(1);

    return this.Client().get(`BiddingOrders?${query.toString()}`);
  }
}
