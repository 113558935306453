import { ISalesService } from "@api/contracts/odata/ISalesService";
import { PaymentStatus } from "@api/models/market/constants/PaymentStatus";
import { ProductTypes } from "@api/models/market/constants/ProductTypes";
import { SaleStatus } from "@api/models/market/constants/SaleStatus";
import { SaleTypes } from "@api/models/market/constants/SaleTypes";
import { SearchTypes } from "@api/models/market/constants/SearchTypes";
import { TransferStatus } from "@api/models/market/constants/TransferStatus";
import { IBid } from "@api/models/market/IBid";
import { IDeposit } from "@api/models/market/IDeposit";
import { IFancier } from "@api/models/market/IFancier";
import { IGuest } from "@api/models/market/IGuest";
import { IOrder } from "@api/models/market/IOrder";
import { IOrderLine } from "@api/models/market/IOrderLine";
import { IPackage } from "@api/models/market/IPackage";
import { IPicture } from "@api/models/market/IPicture";
import { IPigeon } from "@api/models/market/IPigeon";
import { IPigeonIdentity } from "@api/models/market/IPigeonIdentity";
import { IReproducerParent } from "@api/models/market/IReproducerParent";
import { ISale } from "@api/models/market/ISale";
import { ISaleDateRange } from "@api/models/market/ISaleDateRange";
import { ISaleDisapprovalReason } from "@api/models/market/ISaleDisapprovalReason";
import { ISettlement } from "@api/models/market/ISettlement";
import { IStrain } from "@api/models/market/IStrain";
import { IUser } from "@api/models/market/IUser";
import { IVideo } from "@api/models/market/IVideo";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { IODataValueResponse } from "@api/models/shared/IODataValueResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { CatalogFilterTypes } from "@pigeon/enumerations/CatalogFilterTypes";
import { IPaginate } from "@pigeon/models/IPaginate";
import { ISalesQueryManager } from "@pigeon/services/contracts/ISalesQueryManager";
import { AxiosPromise } from "axios";
import { Inject } from "inversify-props";
import { ExpandObjectQuery, ExpandQueryComplex, FilterDate, ODataQuery, odataQuery } from "odata-fluent-query";
import { ISaleTimeSlots } from "../../models/market/ISaleTimeSlots";

export class SalesService extends BaseODataService implements ISalesService {
  @Inject()
  private salesQueryManager: ISalesQueryManager;

  public Insert(sale: ISale): AxiosPromise<ISale> {
    return this.Client().post("Sales", sale);
  }

  public Update(saleKey: number, sale: ISale): AxiosPromise<void> {
    return this.Client().put(`Sales(${saleKey})`, sale);
  }

  public Patch(saleKey: number, sale: Partial<ISale>): AxiosPromise<void> {
    return this.Client().patch(`Sales(${saleKey})`, sale);
  }

  public Delete(saleKey: number): AxiosPromise<void> {
    return this.Client().delete(`Sales(${saleKey})`);
  }

  public Remove(saleKey: number): AxiosPromise<void> {
    const patch: Partial<ISale> = {
      status: SaleStatus.Removed
    };
    return this.Client().patch(`Sales(${saleKey})`, patch);
  }

  public Resume(saleKey: number, resumedStartDate?: string, resumedEndDate?: string): AxiosPromise<any> {
    const resumedTimeSlots: ISaleTimeSlots = {
      startDate: resumedStartDate,
      endDate: resumedEndDate
    };

    return this.Client().post(`Sales(${saleKey})/MarketService.ResumeSale`, resumedTimeSlots);
  }

  public Suspend(saleKey: number): AxiosPromise<any> {
    return this.Client().post(`Sales(${saleKey})/MarketService.SuspendSale`);
  }

  public Stop(saleKey: number): AxiosPromise<any> {
    return this.Client().post(`Sales(${saleKey})/MarketService.StopSale`);
  }

  public FlagSaleAsUnsold(saleKey: number): AxiosPromise<void> {
    return this.Client().post(`Sales(${saleKey})/MarketService.FlagSaleAsUnsold`);
  }

  //#region Query
  private QuerySupervise(): ODataQuery<ISale> {
    return (
      odataQuery<ISale>()
        //  Issue MAX DTU = split the query => call IBidService.FetchAllBidsWithUserBySale(saleKey)
        // .expand("bids", this.SubQueryBidsWithBidder())
        // .expand("user", this.SubQueryUserVendor()) // Issue MAX DTU = split the query => call IUserService.FetchWithVendor(vendorKey)
        .expand("orderLine") // Issue MAX DTU on expand order = split the query => call IOrderService.FetchSheet(orderKey)
        .expand("orderLinesAbandoned")
        .expand("pickUpAddress", (puAddress) =>
          puAddress.select("addressLine", "district", "locality", "postalCode", "country", "countryTwoIsoLetters")
        )
        .expand("settlement", (s) =>
          s
            .select(
              "id",
              "amount",
              "currency",
              "vat",
              "vatRate",
              "flagProcessed",
              "paymentCollectedBy",
              "paymentMethod",
              "paymentDate",
              "userId",
              "vendorId"
            )
            .expand("user", (u) => u.select("id", "firstname", "lastname", "buyerAlias", "email", "phoneNumber"))
        )
        .expand("deposit", (d) =>
          d
            .select(
              "id",
              "amount",
              "currency",
              "vat",
              "vatRate",
              "rateOfTotal",
              "flagProcessed",
              "paymentCollectedBy",
              "paymentMethod",
              "paymentDate",
              "userId",
              "vendorId"
            )
            .expand("user", (u) => u.select("id", "firstname", "lastname", "buyerAlias", "email", "phoneNumber"))
        )
    );
  }
  //#endregion

  //#region SubQuery
  private SubQueryProductPigeon(toEdit?: boolean): (p: ExpandQueryComplex<IPigeon>) => ExpandQueryComplex<IPigeon> {
    // prettier-ignore
    return (p) => p
      .expand("father", (f: ExpandObjectQuery<IPigeon>) => f
        .select("id", "name", "ring", "sex", "fatherId", "motherId", "userId")
      )
      .expand("mother", (m: ExpandObjectQuery<IPigeon>) => m
        .select("id", "name", "ring", "sex", "fatherId", "motherId", "userId")
      )
      .expand("fancier", (m: ExpandObjectQuery<IFancier>) => m
        .select("id", "name")
      )
      .expand("strain", (s: ExpandObjectQuery<IStrain>) => s
        .select("id", "name")
      )
      .expand("characteristics")
      .expand("pictures", (p: ExpandObjectQuery<IPicture>) => {
        return (toEdit) 
          ? p.select("id", "pigeonId", "filename", "type", "url") 
          : p.select("id", "pigeonId", "filename", "type", "url", "urlWebOptimized");
      })
      .expand("videos", (v: ExpandObjectQuery<IVideo>) => { 
        return (toEdit) 
          ? v.select("id", "pigeonId", "filename", "type", "url")
          : v.select("id", "pigeonId", "filename", "type", "url", "urlWebOptimized"); 
      })
  }

  private SubQueryProductPackage(toEdit?: boolean): (p: ExpandQueryComplex<IPackage>) => ExpandQueryComplex<IPackage> {
    // prettier-ignore
    return (p) => p
      .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
        .select("id", "name")
      )
      .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) => p
        .expand("characteristics")
        .expand("pictures", (p: ExpandObjectQuery<IPicture>) => { 
          return (toEdit) 
            ? p.select("id", "pigeonId", "filename", "type", "url")
            : p.select("id", "pigeonId", "filename", "type", "url", "urlWebOptimized");
        })
        .expand("videos", (v: ExpandObjectQuery<IVideo>) => { 
          return (toEdit) 
            ? v.select("id", "pigeonId", "filename", "type", "url")
            : v.select("id", "pigeonId", "filename", "type", "url", "urlWebOptimized"); 
        })
      )
      .expand("pictures", (p: ExpandObjectQuery<IPicture>) => {
        return (toEdit)
          ? p.select("id", "packageId", "filename", "type", "url")
          : p.select("id", "packageId", "filename", "type", "url", "urlWebOptimized");
      })
      // .expand("videos", (v: ExpandObjectQuery<IVideo>) => { 
      //   return (toEdit)
      //     ? v.select("id", "packageId", "filename", "type", "url")
      //     : v.select("id", "packageId", "filename", "type", "url", "urlWebOptimized");
      // })
      .expand("reproducerParents", (rp: ExpandObjectQuery<IReproducerParent>) => rp
        .expand("parent", (p: ExpandObjectQuery<IPigeon>) => p
          .select("id", "idClient", "name", "ring", "ringArea", "ringClub", "ringNumber", "birthYear", "sex", "discipline", "trackingState") // select id AND idClient for insert/edit process (to handle media file)
        )
      );
  }

  private SubQueryProductPigeonCatalog(): (p: ExpandQueryComplex<IPigeon>) => ExpandQueryComplex<IPigeon> {
    // prettier-ignore
    return (p) => p
      .expand("fancier", (m: ExpandObjectQuery<IFancier>) => m
        .select("id", "name", "profiles")
      )
      .expand("strain", (s: ExpandObjectQuery<IStrain>) => s
        .select("id", "name")
      )
      .expand("pictures", (p: ExpandObjectQuery<IPicture>) => p
        .select("id", "filename", "type", "url", "urlWebOptimized")
      );
  }

  private SubQueryProductPackageCatalog(): (p: ExpandQueryComplex<IPackage>) => ExpandQueryComplex<IPackage> {
    // prettier-ignore
    return (p) => p
      .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
        .select("id", "name")
      )
      .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) => p
        .select("ring", "sex", "pictures")
        .expand("pictures", (p: ExpandObjectQuery<IPicture>) => p
          .select("filename", "type", "url", "urlWebOptimized")
        )
      );
  }

  /** Warning: this call may reduce performance.
   *  To increase performance, do a split call to load bids collection */
  private SubQueryBidsWithBidder(): (s: ExpandQueryComplex<IBid>) => ExpandQueryComplex<IBid> {
    // prettier-ignore
    return (b) => b
      .select("id", "biddingOrderId", "amount", "bidDate", "biddingOrderId", "userId")
      .orderBy(b => b.amount.desc())
      .orderBy(b => b.biddingOrderId.asc())
      .orderBy(b => b.bidDate.asc())
      .expand("user", (u) => u.select("firstname", "lastname", "buyerAlias", "countryCode", "email", "phoneNumber"));
  }

  /** Warning: this call may reduce performance.
   *  To increase performance, do a split call to load bids collection */
  private SubQueryBids(): (s: ExpandQueryComplex<IBid>) => ExpandQueryComplex<IBid> {
    // prettier-ignore
    return (b) => b
      .select("id", "biddingOrderId", "amount", "bidDate", "biddingOrderId", "userId")
      .orderBy(b => b.amount.desc())
      .orderBy(b => b.biddingOrderId.asc())
      .orderBy(b => b.bidDate.asc());
  }

  private SubQueryWithOrderSummary(): (ol: ExpandQueryComplex<IOrderLine>) => ExpandQueryComplex<IOrderLine> {
    // prettier-ignore
    return (ol) => ol
      .select("orderId", "lineTotal")
      .expand("order", (o: ExpandObjectQuery<IOrder>) => o
        .select("id", "orderDate", "paymentDate", "paymentMethod", "total", "userId", "guestId")
        .expand("user", (u: ExpandObjectQuery<IUser>) => u
          .select("firstname", "lastname", "email", "phoneNumber", "preferredLanguage")
        )
        .expand("guest", (g: ExpandObjectQuery<IGuest>) => g
          .select("firstname", "lastname", "email", "phoneNumber", "preferredLanguage")
        )
      );
  }

  private SubQueryWitSettlementSummary(): (ol: ExpandQueryComplex<ISettlement>) => ExpandQueryComplex<ISettlement> {
    // prettier-ignore
    return (s) => s
      .select("id", "amount", "paymentDate", "userId")
      .expand("user", (u) => u.select("id", "firstname", "lastname", "buyerAlias", "email", "phoneNumber"));
  }

  private SubQueryWitDepositSummary(): (ol: ExpandQueryComplex<IDeposit>) => ExpandQueryComplex<IDeposit> {
    // prettier-ignore
    return (d) => d
      .select("id", "amount", "rateOfTotal", "paymentDate", "userId")
      .expand("user", (u) => u.select("id", "firstname", "lastname", "buyerAlias", "email", "phoneNumber"));
  }

  // Issue MAX DTU = split the query => call IUserService.FetchWithVendor(vendorKey)
  private SubQueryUserVendorDetails(): (u: ExpandObjectQuery<IUser>) => ExpandObjectQuery<IUser> {
    return (u) =>
      u
        .select("firstname", "lastname", "email", "stripeConnectedAccountId", "countryCode")
        .expand("corporation", (c) => c.select("name", "vatNumber"))
        .expand("vendor", (v) => v.select("referralCode"));
  }

  private SubQueryUserVendorSummaryWithContactData(): (u: ExpandObjectQuery<IUser>) => ExpandObjectQuery<IUser> {
    return (u) => u.select("firstname", "lastname", "email", "phoneNumber");
  }

  private SubQuerySummaryPigeon(): (p: ExpandQueryComplex<IPigeon>) => ExpandQueryComplex<IPigeon> {
    // prettier-ignore
    return (p) => p
      .select("id", "name", "ring")
      .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
        .select("name")
      );
  }

  private SubQuerySummaryPackage(): (p: ExpandQueryComplex<IPackage>) => ExpandQueryComplex<IPackage> {
    // prettier-ignore
    return (p) => p
      .select("id", "name")
      .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
        .select("name")
      )
      .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) => p
        .select("id", "name", "ring")
      );
  }

  private SubQuerySummaryVendor(): (u: ExpandQueryComplex<IUser>) => ExpandQueryComplex<IUser> {
    return (u) => u.select("firstname", "lastname");
  }
  //#endregion

  public Fetch(saleKey: number): AxiosPromise<ISale> {
    return this.Client().get(`Sales(${saleKey})`);
  }

  public FetchWithProduct(saleKey: number): AxiosPromise<ISale> {
    // prettier-ignore
    const query = odataQuery<ISale>()
      .expand("pigeon")
      .expand("package");

    return this.Client().get(`Sales(${saleKey})?${query.toString()}`);
  }

  public FetchSaleType(saleKey: number): AxiosPromise<SaleTypes> {
    return this.Client().get(`Sales(${saleKey})/type/$value`);
  }

  public FetchProductType(saleKey: number): AxiosPromise<ProductTypes> {
    return this.Client().get(`Sales(${saleKey})/product/$value`);
  }

  public FetchProductPigeon(saleKey: number): AxiosPromise<ISale> {
    const query = odataQuery<ISale>()
      .expand("pigeon", this.SubQueryProductPigeon())
      .expand("user", this.SubQueryUserVendorSummaryWithContactData())
      .expand("pickUpAddress");

    return this.Client().get(`Sales(${saleKey})?${query.toString()}`);
  }

  public FetchProductPigeonToEdit(saleKey: number): AxiosPromise<ISale> {
    const query = odataQuery<ISale>()
      .expand("pigeon", this.SubQueryProductPigeon(true))
      .expand("user", this.SubQueryUserVendorSummaryWithContactData())
      .expand("pickUpAddress");

    return this.Client().get(`Sales(${saleKey})?${query.toString()}`);
  }

  public FetchProductPigeonWithPaymentSummary(saleKey: number): AxiosPromise<ISale> {
    const query = odataQuery<ISale>()
      .expand("pigeon", this.SubQueryProductPigeon())
      // .expand("orderLine", this.SubQueryWithOrderSummary()) // Issue MAX DTU on expand order = split the query => call IOrderService.FetchSheet(orderKey)
      .expand("orderLine", (ol) => ol.select("id", "orderId", "lineTotal")) // Issue MAX DTU on expand order = split the query => call IOrderService.FetchSheet(orderKey)
      // .expand("deposit", this.SubQueryWitDepositSummary()) // Issue MAX DTU = split the query => call IDepositService.FetchAllSummaryWithUserBySale(saleKey)
      // .expand("settlement", this.SubQueryWitSettlementSummary()) // Issue MAX DTU = split the query => call ISettlementService.FetchAllSummaryWithUserBySale(saleKey)
      .expand("user", this.SubQueryUserVendorDetails())
      .expand("pickUpAddress");

    return this.Client().get(`Sales(${saleKey})?${query.toString()}`);
  }

  public FetchProductPackage(saleKey: number): AxiosPromise<ISale> {
    const query = odataQuery<ISale>()
      .expand("package", this.SubQueryProductPackage())
      .expand("user", this.SubQueryUserVendorSummaryWithContactData())
      .expand("pickUpAddress");

    return this.Client().get(`Sales(${saleKey})?${query.toString()}`);
  }

  public FetchProductPackageToEdit(saleKey: number): AxiosPromise<ISale> {
    const query = odataQuery<ISale>()
      .expand("package", this.SubQueryProductPackage(true))
      .expand("user", this.SubQueryUserVendorSummaryWithContactData())
      .expand("pickUpAddress");

    return this.Client().get(`Sales(${saleKey})?${query.toString()}`);
  }

  public FetchProductPackageWithPaymentSummary(saleKey: number): AxiosPromise<ISale> {
    // prettier-ignore
    const query = odataQuery<ISale>()
      .expand("package", this.SubQueryProductPackage())
       // .expand("orderLine", this.SubQueryWithOrderSummary()) // Issue MAX DTU on expand order = split the query => call IOrderService.FetchSheet(orderKey)
       .expand("orderLine", (ol => ol.select("id", "orderId", "lineTotal"))) // Issue MAX DTU on expand order = split the query => call IOrderService.FetchSheet(orderKey)
      // .expand("deposit", this.SubQueryWitDepositSummary()) // Issue MAX DTU = split the query => call IDepositService.FetchAllSummaryWithUserBySale(saleKey)
      // .expand("settlement", this.SubQueryWitSettlementSummary()) // Issue MAX DTU = split the query => call ISettlementService.FetchAllSummaryWithUserBySale(saleKey)
      .expand("user", this.SubQueryUserVendorDetails())
      .expand("pickUpAddress");

    return this.Client().get(`Sales(${saleKey})?${query.toString()}`);
  }

  public FetchSupervisedPigeon(saleKey: number): AxiosPromise<ISale> {
    // prettier-ignore
    const query = this.QuerySupervise()
      .expand("pigeon", this.SubQueryProductPigeon());

    return this.Client().get(`Sales(${saleKey})?${query.toString()}`);
  }

  public FetchSupervisedPackage(saleKey: number): AxiosPromise<ISale> {
    // prettier-ignore
    const query = this.QuerySupervise()
      .expand("package", this.SubQueryProductPackage());

    return this.Client().get(`Sales(${saleKey})?${query.toString()}`);
  }

  public FetchSalesCatalog(
    paginate?: IPaginate,
    sort?: string,
    filters?: Map<CatalogFilterTypes, string[] | boolean[]>,
    search?: string,
    searchType?: SearchTypes
  ): AxiosPromise<IODataCollectionResponse<ISale>> {
    let query = odataQuery<ISale>();

    if (search && searchType) query = this.salesQueryManager.SearchBy(query, search, searchType);
    if (filters) query = this.salesQueryManager.FilterBy(query, filters);

    query = query
      .expand("bids", this.SubQueryBidsWithBidder())
      .expand("pigeon", this.SubQueryProductPigeonCatalog())
      .expand("package", this.SubQueryProductPackageCatalog())
      .expand("user", (u: ExpandObjectQuery<IUser>) => u.select("firstname", "lastname"))
      .count();

    if (sort) query = this.salesQueryManager.OrderBy(query, sort);
    if (paginate) query = query.paginate(paginate.pageSize, paginate.page);

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchMySalesElligibleForSettlement(userKey: string): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!userKey) return Promise.reject(new Error("ArgumentException"));
    if (userStore.user?.id != userKey && !authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<ISale>()
      .filter((s) => s.userId.equals(userKey, { ignoreGuid: true }))
      .filter((s) => s.status.notEquals(SaleStatus.Removed))
      .filter((s) =>
        s.type
          .equals(SaleTypes.Fix)
          .or(s.type.equals(SaleTypes.Bid).and((s.endDate as any as FilterDate).isBefore(new Date().toISOString())))
      )
      .filter((s) => s.orderLine.order.paymentStatus.notEquals(PaymentStatus.PaymentSuccess))
      .expand("orderLine", (ol: ExpandObjectQuery<IOrderLine>) =>
        ol.expand("order", (o: ExpandObjectQuery<IOrder>) =>
          o
            .select("id", "paymentStatus", "orderDate", "paymentDate")
            .expand("user", (u: ExpandObjectQuery<IUser>) => u.select("id", "firstname", "lastname", "buyerAlias"))
            .expand("guest", (u: ExpandObjectQuery<IGuest>) => u.select("id", "firstname", "lastname"))
        )
      )
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p.select("name", "ring"))
      .expand("package", (p: ExpandObjectQuery<IPackage>) => p.select("name", "discipline"))
      .expand("bids", this.SubQueryBidsWithBidder())
      .expand("deposit", (d) => d.select("amount", "vat", "vatRate"));

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllPendingApprovalSales(): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsApprover) return Promise.reject("Not authorized");

    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => s.status.equals(SaleStatus.OnSale))
      .filter(s => s.flagApproved.isNull())
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
        .select("id", "name", "ring")
        .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
          .select("name")
        )
      )
      .expand("package", (p: ExpandObjectQuery<IPackage>) => p
        .select("id", "name")
        .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
          .select("name")
        )
        .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) => p
          .select("id", "name", "ring")
        )
      )
      .expand("user", (u: ExpandObjectQuery<IUser>) => u
        .select("firstname", "lastname")
      )
      .orderBy("approvedAt", "desc")
      .count();

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllApprovedSales(): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsApprover) return Promise.reject("Not authorized");

    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => s.status.equals(SaleStatus.OnSale))
      .filter(s => s.flagApproved.equals(true))
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
        .select("id", "name", "ring")
        .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
          .select("name")
        )
      )
      .expand("package", (p: ExpandObjectQuery<IPackage>) => p
        .select("id", "name")
        .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
          .select("name")
        )
        .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) => p
          .select("id", "name", "ring")
        )
      )
      .expand("user", (u: ExpandObjectQuery<IUser>) => u
        .select("firstname", "lastname")
      )
      .orderBy("approvedAt", "desc")
      .count();

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllNotYetPaidEndedSales(): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsAdministrator) return Promise.reject("Not authorized");

    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => s.status.notEquals(SaleStatus.Sold))
      .filter(s => s.status.notEquals(SaleStatus.Removed))
      .filter(s => s.flagApproved.equals(true))
      .filter(s => (s.startDate as any as FilterDate).isBefore(new Date().toISOString()))
      .filter(s => (s.endDate as any as FilterDate).isBefore(new Date().toISOString()))
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
        .select("id", "name", "ring")
      )
      .expand("package", (p: ExpandObjectQuery<IPackage>) => p
        .select("id", "name")
        .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) => p
          .select("id", "name", "ring")
        )
      )
      // .expand("bids", this.SubQueryBidsWithBidder()) // not used
      .expand("user", (u: ExpandObjectQuery<IUser>) => u
        .select("firstname", "lastname")
      )
      .expand("orderLine", this.SubQueryWithOrderSummary())
      .orderBy("endDate", "desc");

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllDisapprovedSales(): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsApprover) return Promise.reject("Not authorized");

    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => s.status.equals(SaleStatus.OnSale))
      .filter(s => s.flagApproved.equals(false))
      .expand("pigeon", this.SubQuerySummaryPigeon())
      .expand("package", this.SubQuerySummaryPackage())
      .expand("user", this.SubQuerySummaryVendor())
      .orderBy("approvedAt", "desc")
      .count();

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllDisapprovedSalesWithReasonsByVendor(vendorKey: string): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => s.status.equals(SaleStatus.OnSale))
      .filter(s => s.flagApproved.equals(false))
      .filter(s => s.userId.equals(vendorKey, { ignoreGuid: true }))
      .expand("pigeon", this.SubQuerySummaryPigeon())
      .expand("package", this.SubQuerySummaryPackage())
      .expand("user", this.SubQuerySummaryVendor())
      .expand("disapprovalReasons", (drs: ExpandObjectQuery<ISaleDisapprovalReason>) => drs.select("disapprovalReason"))
      .expand("taskTrackings")
      .orderBy("approvedAt", "desc")
      .count();

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllSoldSalesToCollectOrDeliverByVendor(vendorKey: string): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => s.status.in([SaleStatus.Sold, SaleStatus.Reserved]))
      .filter((s) => s.orderLine.shippingStatus.isNull())
      .filter(s => s.userId.equals(vendorKey, { ignoreGuid: true }))
      .expand("pigeon", this.SubQuerySummaryPigeon())
      .expand("package", this.SubQuerySummaryPackage())
      .expand("user", this.SubQuerySummaryVendor())
      .expand("taskTrackings")
      .expand("orderLine", (drs: ExpandObjectQuery<IOrderLine>) => drs.select("shippingDate", "shippingStatus"))
      .orderBy("saleDate", "asc")
      .count();

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllUnsoldSalesWithProductByVendor(vendorKey: string): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => s.status.in([SaleStatus.Unsold]))
      .filter(s => s.flagApproved.equals(true))
      .filter(s => s.userId.equals(vendorKey, { ignoreGuid: true }))
      .expand("pigeon", this.SubQuerySummaryPigeon())
      .expand("package", this.SubQuerySummaryPackage())
      .orderBy("createdAt", "desc")
      .count();

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllPendingExpiredForSaleSalesByVendor(vendorKey: string): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => s.status.equals(SaleStatus.OnSale))
      .filter(s => s.flagApproved.equals(true))
      .filter(s => (s.startDate as any as FilterDate).isBefore(new Date()))
      .filter(s => (s.endDate as any as FilterDate).isBefore(/*dayjs().add(-PAYMENT_MAX_DELAY, "days").toDate()*/ new Date()))
      .filter(s => s.userId.equals(vendorKey, { ignoreGuid: true }))
      .expand("pigeon", this.SubQuerySummaryPigeon())
      .expand("package", this.SubQuerySummaryPackage())
      .expand("user", this.SubQuerySummaryVendor())
      .expand("taskTrackings")
      .orderBy("approvedAt", "desc")
      .count();

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllPendingTransferSettledSalesByVendor(vendorKey: string): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== vendorKey)
      return Promise.reject(new Error("unauthorized"));

    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => s.status.in([SaleStatus.Sold, SaleStatus.Reserved]))
      .filter(s => s.settlement.notNull())
      .filter((s) => 
        s.orderLine.isNull()
        .or(
          s.orderLine.order.transferStatus.notEquals(TransferStatus.TransferCreated)
          .and(s.orderLine.order.paymentCollectedBy.equals(vendorKey, { ignoreGuid: true }))
        )
       )
      .filter(s => s.userId.equals(vendorKey, { ignoreGuid: true }))
      .expand("pigeon", this.SubQuerySummaryPigeon())
      .expand("package", this.SubQuerySummaryPackage())
      .expand("user", this.SubQuerySummaryVendor())
      .expand("taskTrackings")
      .orderBy("approvedAt", "desc")
      .count();

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllAvailableBidSalesByVendor(vendorKey: string): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsAdministrator) return Promise.reject("Not authorized");

    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => s.status.equals(SaleStatus.OnSale))
      .filter(s => s.flagApproved.equals(true))
      .filter(s => s.type.equals(SaleTypes.Bid))
      .filter(s => s.userId.equals(vendorKey, { ignoreGuid: true }))
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
        .select("id", "name", "ring")
      )
      .expand("package", (p: ExpandObjectQuery<IPackage>) => p
        .select("id", "name")
      )
      .orderBy("approvedAt", "desc");

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllSalesWithProductByIds(ids: number[]): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!(authStore.IsAdministrator || authStore.IsVendor)) return Promise.reject("Not authorized");
    if (!ids?.length) return Promise.reject("Argument exception: parameter ids is empty");

    // prettier-ignore
    let query = odataQuery<ISale>()
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
        .select("id", "name", "ring")
      )
      .expand("package", (p: ExpandObjectQuery<IPackage>) => p
        .select("id", "name")
      )
      .orderBy("approvedAt", "desc");

    if (ids.length) query = query.filter((s) => s.id.in(ids.filter((id) => id).map((id) => id as number)));

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllSalesWithProduct(fromDate?: Date, toDate?: Date): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsAdministrator) return Promise.reject("Not authorized");

    // prettier-ignore
    let query = odataQuery<ISale>()
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
        .select("id", "name", "ring")
      )
      .expand("package", (p: ExpandObjectQuery<IPackage>) => p
        .select("id", "name")
      )
      .orderBy("approvedAt", "desc");

    // prettier-ignore
    if (fromDate) query = query.filter((s) => (s.startDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((s) => (s.startDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllOverview(): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsAdministrator) return Promise.reject("Not authorized");

    // prettier-ignore
    const query = odataQuery<ISale>()
      .select("id", "product", "feesRate", "status", "price", "flagApproved", "createdAt")
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p
        .select("id", "name", "ring")
      )
      .expand("package", (p: ExpandObjectQuery<IPackage>) => p
        .select("id", "name", "discipline")
        .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) => p
          .select("id", "name", "ring")
        )
      )
      .expand("user", (u: ExpandObjectQuery<IUser>) => u
        .select("firstname", "lastname")  
      )

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public FetchAllSalesWithProductByVendor(
    userKey: string,
    fromDate?: Date,
    toDate?: Date
  ): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<ISale>().filter((s) => s.userId.equals(userKey, { ignoreGuid: true }));

    // prettier-ignore
    if (fromDate) query = query.filter((s) => (s.startDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((s) => (s.startDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    query = query
      .orderBy("startDate", "desc")
      //  Issue MAX DTU = split the query => call IOrderService.FetchAllWithCustomerAndDeliveryAddressByIds(orderKeys)
      // .expand("bids", this.SubQueryBidsWithBidder())
      //  Issue MAX DTU = split the query => call IBidService.FetchAllBidsWithUserBySales(saleKeys
      //.expand(
      // "orderLine",
      // (ol: ExpandObjectQuery<IOrderLine>) => ol
      // .expand("order", (o: ExpandObjectQuery<IOrder>) => o
      //   .expand("deliveryAddress")
      //   .expand("user", (u: ExpandObjectQuery<IUser>) =>
      //     u.select("firstname", "lastname", "email", "phoneNumber", "countryCode")
      //   )
      //   .expand("guest", (g: ExpandObjectQuery<IGuest>) =>
      //     g.select("firstname", "lastname", "email", "phoneNumber")
      //   )
      // )
      //)
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) => p.select("name", "ring", "sex", "flagApproved"))
      .expand("package", (p: ExpandObjectQuery<IPackage>) => p.select("name", "discipline"));

    return this.Client().get(`Sales?${query.toString()}`);
  }

  /** @deprecated split the query in 2 separate calls: FetchNewSales and FetchHotSales */
  public FetchHeadlineSales(count: number): AxiosPromise<IODataCollectionResponse<ISale>> {
    const query = odataQuery<ISale>()
      .expand("bids", this.SubQueryBids())
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) =>
        p
          .select("id", "name", "ring", "sex", "ringArea")
          .expand("pictures", (p: ExpandObjectQuery<IPicture>) =>
            p.select("filename", "type", "url", "urlWebOptimized")
          )
      )
      .expand("package", (p: ExpandObjectQuery<IPackage>) =>
        p
          .select("id", "name", "discipline")
          .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) =>
            p
              .select("id", "name", "ring", "sex", "pictures")
              .expand("pictures", (p: ExpandObjectQuery<IPicture>) =>
                p.select("filename", "type", "url", "urlWebOptimized", "pigeonId")
              )
          )
          .expand("pictures", (p: ExpandObjectQuery<IPicture>) =>
            p.select("filename", "type", "url", "urlWebOptimized", "pigeonId")
          )
      )
      .count();

    return this.Client().get(`Sales/MarketService.HeadlineSales(count=${count})?${query.toString()}`);
  }

  public FetchNewSales(top: number): AxiosPromise<IODataCollectionResponse<ISale>> {
    let query = odataQuery<ISale>()
      .expand("bids", this.SubQueryBids())
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) =>
        p
          .select("id", "name", "ring", "sex", "ringArea")
          .expand("pictures", (p: ExpandObjectQuery<IPicture>) =>
            p.select("filename", "type", "url", "urlWebOptimized")
          )
      )
      .expand("package", (p: ExpandObjectQuery<IPackage>) =>
        p
          .select("id", "name", "discipline")
          .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) =>
            p
              .select("id", "name", "ring", "sex", "pictures")
              .expand("pictures", (p: ExpandObjectQuery<IPicture>) =>
                p.select("filename", "type", "url", "urlWebOptimized", "pigeonId")
              )
          )
          .expand("pictures", (p: ExpandObjectQuery<IPicture>) =>
            p.select("filename", "type", "url", "urlWebOptimized", "pigeonId")
          )
      )
      .count();

    if (top) query = query.paginate(top);

    return this.Client().get(`Sales/MarketService.NewSales?${query.toString()}`);
  }

  public FetchHotSales(top: number): AxiosPromise<IODataCollectionResponse<ISale>> {
    let query = odataQuery<ISale>()
      .expand("bids", this.SubQueryBids())
      .expand("pigeon", (p: ExpandObjectQuery<IPigeon>) =>
        p
          .select("id", "name", "ring", "sex", "ringArea")
          .expand("pictures", (p: ExpandObjectQuery<IPicture>) =>
            p.select("filename", "type", "url", "urlWebOptimized")
          )
      )
      .expand("package", (p: ExpandObjectQuery<IPackage>) =>
        p
          .select("id", "name", "discipline")
          .expand("pigeons", (p: ExpandObjectQuery<IPigeon>) =>
            p
              .select("id", "name", "ring", "sex", "pictures")
              .expand("pictures", (p: ExpandObjectQuery<IPicture>) =>
                p.select("filename", "type", "url", "urlWebOptimized", "pigeonId")
              )
          )
          .expand("pictures", (p: ExpandObjectQuery<IPicture>) =>
            p.select("filename", "type", "url", "urlWebOptimized", "pigeonId")
          )
      )
      .count();

    if (top) query = query.paginate(top);

    return this.Client().get(`Sales/MarketService.HotSales?${query.toString()}`);
  }

  public GetNumberRevenues(): AxiosPromise<IODataValueResponse<number>> {
    return this.Client().get("Sales/MarketService.NumberRevenues");
  }

  public GetNumberTransactions(): AxiosPromise<IODataValueResponse<number>> {
    return this.Client().get("Sales/MarketService.NumberTransactions");
  }

  public GetNumberPosts(): AxiosPromise<IODataValueResponse<number>> {
    return this.Client().get("Sales/MarketService.NumberPosts");
  }

  public GetNumberSalesByFancier(fancierKey: number): AxiosPromise<IODataValueResponse<number>> {
    return this.Client().get(`Sales/MarketService.NumberSalesByFancier(fancier=${fancierKey})`);
  }

  public GetIsVendorOfThisSale(saleKey: number): AxiosPromise<IODataValueResponse<boolean>> {
    return this.Client().get(`Sales(${saleKey})/MarketService.IsVendorOfThisSale`);
  }

  public GetIsBuyerOfThisSale(saleKey: number): AxiosPromise<IODataValueResponse<boolean>> {
    return this.Client().get(`Sales(${saleKey})/MarketService.IsBuyerOfThisSale`);
  }

  public GetIsAvailableForPurchase(saleKey: number): AxiosPromise<IODataValueResponse<boolean>> {
    return this.Client().get(`Sales(${saleKey})/MarketService.IsAvailableForPurchase`);
  }

  public GetAllWinningSalesInPendingByUser(): AxiosPromise<IODataCollectionResponse<ISale>> {
    // prettier-ignore
    const query = odataQuery<ISale>()
      .filter(s => (s.snoozeDate as any as FilterDate).isBeforeOrEqual(new Date().toISOString()).or(s.snoozeDate.isNull()))
      .expand("pigeon")
      .expand("package")
      .expand("bids", this.SubQueryBidsWithBidder())
      .expand("user", this.SubQueryUserVendorSummaryWithContactData())
      .expand("pickUpAddress", (puAddress) =>
        puAddress.select("addressLine", "district", "locality", "postalCode", "country", "countryTwoIsoLetters")
      );

    return this.Client().get(`Sales/MarketService.GetAllWinningSalesInPendingByUser?${query.toString()}`);
  }

  public GetAllSalesCountByUser(userKey: string): AxiosPromise<IODataCollectionResponse<ISale>> {
    const query = odataQuery<ISale>()
      .filter((s) => s.userId.equals(userKey, { ignoreGuid: true }))
      .count()
      .paginate(0);

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public GetAllPackagePigeonItemIdentitiesBySale(
    saleKey: number
  ): AxiosPromise<IODataCollectionResponse<IPigeonIdentity>> {
    return this.Client().get(`Sales(${saleKey})/MarketService.GetAllPackagePigeonItemIdentities`);
  }

  public GetAllPackageParentReproducerIdentitiesBySale(
    saleKey: number
  ): AxiosPromise<IODataCollectionResponse<IPigeonIdentity>> {
    return this.Client().get(`Sales(${saleKey})/MarketService.GetAllPackageParentReproducerIdentities`);
  }

  public FetchAllBids(saleKey: number): AxiosPromise<IODataCollectionResponse<IBid>> {
    // prettier-ignore
    const query = odataQuery<IBid>()
      .expand("user", (u: ExpandObjectQuery<IUser>) => u
        .select("firstname", "lastname", "buyerAlias", "countryCode", "email", "phoneNumber")
      );

    return this.Client().get(`Sales(${saleKey})/Bids?${query.toString()}`);
  }

  public GetHighestBid(saleKey: number): AxiosPromise<IBid> {
    return this.Client().get(`Sales(${saleKey})/MarketService.HighestBid`);
  }

  public GetHighestBidWithSaleAndBidder(saleKey: number): AxiosPromise<IBid> {
    // prettier-ignore
    const query = odataQuery<IBid>()
      .expand("user", (u: ExpandObjectQuery<IUser>) => u
        .select("firstname", "lastname", "buyerAlias", "countryCode")
      )
      .expand("sale", (s: ExpandObjectQuery<ISale>) => s
        .select("id", "endDate")
      );

    return this.Client().get(`Sales(${saleKey})/MarketService.HighestBid?${query.toString()}`);
  }

  public GetHighestBidAmount(saleKey: number): AxiosPromise<IODataValueResponse<number>> {
    return this.Client().get(`Sales(${saleKey})/MarketService.HighestBidAmount`);
  }

  public GetDateRangeOfNextSales(vendorKey: string): AxiosPromise<IODataValueResponse<ISaleDateRange[]>> {
    return this.Client().get(`Sales/MarketService.GetDateRangeOfNextSales(vendorKey='${vendorKey}')`);
  }

  public CountSalesByVendorId(vendorKey: string): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!vendorKey) return Promise.reject(new Error("ArgumentException"));
    if (userStore.user?.id != vendorKey && !authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<ISale>()
      .select("id")
      .filter((s) => s.userId.equals(vendorKey, { ignoreGuid: true }))
      .count();

    return this.Client().get(`Sales?${query.toString()}`);
  }

  public CountSalesByVendorIdAndBySaleStatus(
    vendorKey: string,
    saleStatus: SaleStatus
  ): AxiosPromise<IODataCollectionResponse<ISale>> {
    if (!vendorKey) return Promise.reject(new Error("ArgumentException"));
    if (userStore.user?.id != vendorKey && !authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<ISale>()
      .select("id")
      .filter((s) => s.userId.equals(vendorKey, { ignoreGuid: true }))
      .filter((s) => s.status.equals(saleStatus))
      .count();

    return this.Client().get(`Sales?${query.toString()}`);
  }
}
