import { render, staticRenderFns } from "./TheFooterEmpty.vue?vue&type=template&id=6bff0265&scoped=true&"
import script from "./TheFooterEmpty.ts?vue&type=script&lang=ts&"
export * from "./TheFooterEmpty.ts?vue&type=script&lang=ts&"
import style0 from "./TheFooterEmpty.vue?vue&type=style&index=0&id=6bff0265&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bff0265",
  null
  
)

export default component.exports