import VueI18n from "vue-i18n";

const standardDateTimeFormats = {
  numeric: {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  },
  numeric_time: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  },
  month: {
    month: "short"
  },
  short: {
    year: "numeric",
    month: "short",
    day: "numeric"
  },
  regular: {
    year: "numeric",
    month: "long",
    day: "numeric"
  },
  extended: {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  },
  extended_year: {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  },
  long: {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric"
  }
};

export const dateTimeFormats: VueI18n.DateTimeFormats = {
  en: standardDateTimeFormats as VueI18n.DateTimeFormat,
  fr: standardDateTimeFormats as VueI18n.DateTimeFormat,
  nl: standardDateTimeFormats as VueI18n.DateTimeFormat,
  pl: standardDateTimeFormats as VueI18n.DateTimeFormat,
  pt: standardDateTimeFormats as VueI18n.DateTimeFormat
};
