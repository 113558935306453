import { CharacteristicBackTypes, DISCRIMINATOR_BACK } from "@api/models/market/constants/CharacteristicBackTypes";
import {
  CharacteristicBalanceTypes,
  DISCRIMINATOR_BALANCE
} from "@api/models/market/constants/CharacteristicBalanceTypes";
import { CharacteristicEyeTypes, DISCRIMINATOR_EYE } from "@api/models/market/constants/CharacteristicEyeTypes";
import {
  CharacteristicFeathersTypes,
  DISCRIMINATOR_FEATHERS
} from "@api/models/market/constants/CharacteristicFeathersTypes";
import {
  CharacteristicHumerusTypes,
  DISCRIMINATOR_HUMERUS
} from "@api/models/market/constants/CharacteristicHumerusTypes";
import {
  CharacteristicLengthTypes,
  DISCRIMINATOR_LENGTH
} from "@api/models/market/constants/CharacteristicLengthTypes";
import {
  CharacteristicPlumageTypes,
  DISCRIMINATOR_PLUMAGE
} from "@api/models/market/constants/CharacteristicPlumageTypes";
import { CharacteristicSizeTypes, DISCRIMINATOR_SIZE } from "@api/models/market/constants/CharacteristicSizeTypes";
import {
  CharacteristicVentbonesPositionTypes,
  DISCRIMINATOR_VENTBONES_POSITION
} from "@api/models/market/constants/CharacteristicVentbonesPositionTypes";
import {
  CharacteristicVentbonesStrengthTypes,
  DISCRIMINATOR_VENTBONES_STRENGTH
} from "@api/models/market/constants/CharacteristicVentbonesStrengthTypes";
import { IPigeon } from "@api/models/market/IPigeon";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProductCharacteristicsView extends Vue {
  @Prop()
  readonly pigeon: IPigeon;

  private GetCharacteristic(discriminator: string) {
    if (!this.pigeon || !this.pigeon.characteristics || !this.pigeon.characteristics.length) return undefined;

    const characteristic = this.pigeon.characteristics.find((c) => c.discriminator == discriminator);
    return characteristic ? characteristic.characteristic : undefined;
  }

  get BackCharacteristic(): CharacteristicBackTypes | undefined {
    return this.GetCharacteristic(DISCRIMINATOR_BACK) as CharacteristicBackTypes;
  }

  get BalanceCharacteristic(): CharacteristicBalanceTypes | undefined {
    return this.GetCharacteristic(DISCRIMINATOR_BALANCE) as CharacteristicBalanceTypes;
  }

  get EyeCharacteristic(): CharacteristicEyeTypes | undefined {
    return this.GetCharacteristic(DISCRIMINATOR_EYE) as CharacteristicEyeTypes;
  }

  get FeathersCharacteristic(): CharacteristicFeathersTypes | undefined {
    return this.GetCharacteristic(DISCRIMINATOR_FEATHERS) as CharacteristicFeathersTypes;
  }

  get LengthCharacteristic(): CharacteristicLengthTypes | undefined {
    return this.GetCharacteristic(DISCRIMINATOR_LENGTH) as CharacteristicLengthTypes;
  }

  get PlumageCharacteristic(): CharacteristicPlumageTypes | undefined {
    return this.GetCharacteristic(DISCRIMINATOR_PLUMAGE) as CharacteristicPlumageTypes;
  }

  get SizeCharacteristic(): CharacteristicSizeTypes | undefined {
    return this.GetCharacteristic(DISCRIMINATOR_SIZE) as CharacteristicSizeTypes;
  }

  get VentbonesPositionCharacteristic(): CharacteristicVentbonesPositionTypes | undefined {
    return this.GetCharacteristic(DISCRIMINATOR_VENTBONES_POSITION) as CharacteristicVentbonesPositionTypes;
  }

  get VentbonesStrengthCharacteristic(): CharacteristicVentbonesStrengthTypes | undefined {
    return this.GetCharacteristic(DISCRIMINATOR_VENTBONES_STRENGTH) as CharacteristicVentbonesStrengthTypes;
  }

  get HumerusCharacteristic(): CharacteristicHumerusTypes | undefined {
    return this.GetCharacteristic(DISCRIMINATOR_HUMERUS) as CharacteristicHumerusTypes;
  }
}
