import { IConnectedAccountsService } from "@api/contracts/webapi/IConnectedAccountsService";
import { IConnectVerification } from "@api/models/auth/IConnectVerification";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { accountStore, authStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import Stripe from "stripe";
import { memoizeAsync } from "utils-decorators";

export class ConnectedAccountsService extends BaseApiService implements IConnectedAccountsService {
  constructor() {
    super();
  }

  public FetchState(): AxiosPromise<string> {
    return this.Client().get("ConnectedAccounts/State");
  }

  @memoizeAsync()
  public FetchConnectedAccount(connectedAccountKey: string): AxiosPromise<Stripe.Account> {
    if (!authStore.IsAdministrator && (!authStore.IsVendor || !accountStore.AccountVendorIsVerified))
      return Promise.reject("Fetch stripe account is authorized only for a certified vendor");

    return this.Client().get(`connectedAccounts/${connectedAccountKey}`);
  }

  @memoizeAsync()
  public FetchDashboardLink(vendorKey?: string): AxiosPromise<string> {
    if (!authStore.IsAdministrator && (!authStore.IsVendor || !accountStore.AccountVendorIsVerified))
      return Promise.reject("Fetch Dashboard stripe is authorized only for a certified vendor");

    const currentVendorKey = authStore.IsAdministrator ? vendorKey : userStore.user?.id;
    return this.Client().get(`ConnectedAccounts/Dashboard/${currentVendorKey}`);
  }

  public FetchBalance(): AxiosPromise<Stripe.Balance> {
    if (!authStore.IsAdministrator) return Promise.reject("Fetch balance is not authorized");

    return this.Client().get("ConnectedAccounts/Balance");
  }

  public PostOAuthConnectVerification(code: string, stateToken: string): AxiosPromise<void> {
    const verification: IConnectVerification = {
      code: code,
      state: stateToken
    };

    return this.Client().post("ConnectedAccounts/OAuth", verification);
  }
}
