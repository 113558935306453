import { Component, Prop, Vue } from "vue-property-decorator";

export enum BadgeLevels {
  Low = "low",
  Medium = "medium",
  High = "high"
}

@Component
export default class AppBadge extends Vue {
  @Prop()
  readonly count: number;
  @Prop()
  readonly max?: number;
  @Prop({
    default: BadgeLevels.Low,
    validator: (value: BadgeLevels) => Object.values(BadgeLevels).includes(value)
  })
  readonly level: BadgeLevels;

  get Count(): string {
    if (!this.max) {
      return this.count.toString();
    }

    if (this.count > this.max) {
      return this.max + "+";
    } else {
      return this.count.toString();
    }
  }
}
