import TheFooterNewsletter from "@market/components/layout/TheFooterNewsletter.vue";
import TheLayout from "@market/components/layout/TheLayout.vue";
import TheBannerCookies from "@pigeon/components/layout/TheBannerCookies.vue";
import TheBody from "@pigeon/components/layout/TheBody.vue";
import TheFooterEmpty from "@pigeon/components/layout/TheFooterEmpty.vue";
import TheFooterHelp from "@pigeon/components/layout/TheFooterHelp.vue";
import TheHeader from "@pigeon/components/layout/TheHeader.vue";
import Vue from "vue";

// Layout components
Vue.component("TheLayout", TheLayout);

Vue.component("TheHeader", TheHeader);
Vue.component("TheBody", TheBody);
Vue.component("TheFooterNewsletter", TheFooterNewsletter);
Vue.component("TheFooterHelp", TheFooterHelp);
Vue.component("TheFooterEmpty", TheFooterEmpty);
Vue.component("TheBannerCookies", TheBannerCookies);
