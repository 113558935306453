import { authStore, userStore } from "@market/stores/App.store.modules";
import dayjs from "@pigeon/i18n/dayjs";
import { AspNetCoreCookieCulture } from "@pigeon/i18n/i18n.const";
import { Cookies } from "vue-cookies-ts";
import VueI18n from "vue-i18n";
import { II18nManager } from "./contracts/II18nManager";

export class I18nManager implements II18nManager {
  public UpdateClientUrl($i18n: VueI18n, locale: string): void {
    if (!locale) return;

    const urlWithNewLocale: string = window.location.href.replace(`/${$i18n.locale}/`, `/${locale}/`);
    window.history.pushState(null, `PigeonMarket ${locale.toUpperCase()}`, urlWithNewLocale);
  }

  public UpdateUILocale($i18n: VueI18n, locale: string): void {
    if (!locale) return;

    $i18n.locale = locale;
    dayjs.locale(locale);
    // localize(locale); // Fix: Comment to fix vee validation bug localization {field} name
  }

  public UpdateUserPreferredLocale(locale: string): void {
    if (!locale) return;

    if (authStore.IsAuthenticated) {
      userStore.UpdateUserPreferredLanguage(locale);
    }
  }

  // See: https://docs.microsoft.com/en-us/aspnet/core/fundamentals/localization?view=aspnetcore-2.1#cookierequestcultureprovider
  public UpdateAspNetCoreCookieRequestCulture($cookies: Cookies, locale: string): void {
    // Cookie value c=en-US|uic=en-UK
    $cookies.set(AspNetCoreCookieCulture, `c=${locale}|uic=${locale}`);
  }
}
