import { SearchTypes } from "@api/models/market/constants/SearchTypes";
import { store } from "@market/stores";
import { ISearchStore } from "@market/stores/contracts/ISearchStore";
import { IRingManager } from "@pigeon/services/contracts/IRingManager";
import { cid, container, Inject } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "search" = "search";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class SearchStore extends VuexModule implements ISearchStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private ringManager: IRingManager;

  searchBarIsOpen: boolean = false;
  searchType: SearchTypes = SearchTypes.SearchByRing; // default search type
  searchQueryInput: string | null = null;
  searchQueryRingArea: string | null = "BE";
  searchQueryRingClub: string | null = null;
  searchQueryRingBirthyear: number | null = new Date().getFullYear();
  searchQuery: string | null = null;

  @Mutation
  TOGGLE_SEARCH_BAR(): void {
    this.searchBarIsOpen = !this.searchBarIsOpen;
  }

  @Mutation
  UPDATE_SEARCH_TYPE(searchType: SearchTypes): void {
    this.searchType = searchType;
  }

  @Mutation
  UPDATE_SEARCH_QUERY(searchQuery: string | null): void {
    this.searchQueryInput = searchQuery;
  }

  @Mutation
  UPDATE_SEARCH_QUERY_RING_AREA(ringArea: string | null): void {
    this.searchQueryRingArea = ringArea;
  }

  @Mutation
  UPDATE_SEARCH_QUERY_RING_CLUB(ringClub: string | null): void {
    this.searchQueryRingClub = ringClub;
  }

  @Mutation
  UPDATE_SEARCH_QUERY_RING_BIRTHYEAR(ringBirthyear: number | null): void {
    this.searchQueryRingBirthyear = ringBirthyear;
  }

  @Mutation
  UPDATE_SEARCH(search: string | null): void {
    this.searchQuery = search;
  }

  @Mutation
  RESET(): void {
    this.searchQueryInput = null;
    // this.searchQueryRingArea = "BE";
    // this.searchQueryRingClub = null;
    // this.searchQueryRingBirthyear = new Date().getFullYear();
    this.searchQuery = null;
  }

  @Action
  ToggleSearchBar(): void {
    this.TOGGLE_SEARCH_BAR();
  }

  @Action
  UpdateSearchType(value: SearchTypes): void {
    this.UPDATE_SEARCH_TYPE(value);
  }

  @Action
  UpdateSearchQuery(value: string | null): void {
    this.UPDATE_SEARCH_QUERY(value);
  }

  @Action
  UpdateSearchQueryRingArea(value: string | null): void {
    this.UPDATE_SEARCH_QUERY_RING_AREA(value);
  }

  @Action
  UpdateSearchQueryRingClub(value: string | null): void {
    this.UPDATE_SEARCH_QUERY_RING_CLUB(value);
  }

  @Action
  UpdateSearchQueryRingBirthyear(value: number | null): void {
    this.UPDATE_SEARCH_QUERY_RING_BIRTHYEAR(value);
  }

  @Action
  UpdateSearch(): void {
    const ringManager = container.get<IRingManager>(cid.RingManager);

    let search: string | null = null;

    switch (this.searchType) {
      case SearchTypes.SearchByRing:
        if (!this.searchQueryInput) {
          this.Reset();
          return;
        }

        search = ringManager.RawFormatRing(
          this.searchQueryRingArea,
          this.searchQueryRingClub,
          this.searchQueryRingBirthyear,
          this.searchQueryInput
        );
        break;

      case SearchTypes.SearchByName:
        search = this.searchQueryInput;
        break;

      case SearchTypes.SearchByRingOrName:
        search = this.searchQueryInput;
        break;

      default:
        throw new Error(`Operation exception: Unsupported search type: ${this.searchType}`);
    }

    this.UPDATE_SEARCH(search);
  }

  @Action
  Reset(): void {
    this.RESET();
  }
}
