import { IResizeVNodeDirective } from "./IResizeVNodeDirective";

export default {
  inserted(el: HTMLElement, binding: IResizeVNodeDirective) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const callback = binding.value!;
    const options = binding.options || { passive: true };

    window.addEventListener("resize", callback, options);
    (el as any)._onResize = {
      callback,
      options
    };

    if (!binding.modifiers || !binding.modifiers.quiet) {
      callback();
    }
  },
  unbind(el: HTMLElement) {
    if (!(el as any)._onResize) return;

    const { callback, options } = (el as any)._onResize;
    window.removeEventListener("resize", callback, options);
    delete (el as any)._onResize;
  }
};
