import { ITaskTrackingsService } from "@api/contracts/odata/ITaskTrackingsService";
import { SaleStatus } from "@api/models/market/constants/SaleStatus";
import { ShippingStatus } from "@api/models/market/constants/ShippingStatus";
import { SaleTaskTypes } from "@api/models/market/constants/SubtaskTypes";
import { TaskPriorityLevels } from "@api/models/market/constants/TaskPriorityLevels";
import { TaskTypes } from "@api/models/market/constants/TaskTypes";
import { ISale } from "@api/models/market/ISale";
import { ITaskTracking } from "@api/models/market/ITaskTracking";
import { PAYMENT_MAX_DELAY, TRANSFER_DELAY_HOURS } from "@market/App.const";
import { authStore, userStore } from "@market/stores/App.store.modules";
import dayjs from "dayjs";
import { Inject } from "inversify-props";
import { ITaskManager } from "./contracts/ITaskManager";

export class TaskManager implements ITaskManager {
  @Inject()
  private taskTrackingsService: ITaskTrackingsService;

  //#region TrackingTasks
  public CalculateStatusTrackingDueDate(sale: ISale): Date | undefined {
    if (!sale || !sale.endDate) return undefined;

    return dayjs(sale.endDate).add(PAYMENT_MAX_DELAY, "days").toDate();
  }
  //#endregion

  //#region SettlementTasks
  public CalculateCollectDueDate(sale: ISale): Date | undefined {
    if (!sale || !sale.saleDate) return undefined;

    return dayjs(sale.saleDate).add(PAYMENT_MAX_DELAY, "days").toDate();
  }

  public CalculateTransferDueDate(sale: ISale): Date | undefined {
    if (!sale || !sale.saleDate) return undefined;

    const dateToUse = sale?.orderLine?.order?.paymentDate || sale.endDate;
    return dayjs(dateToUse).add(TRANSFER_DELAY_HOURS, "hours").toDate();
  }
  //#endregion

  public HasTaskType(sale: ISale, taskType: TaskTypes): boolean {
    if (!sale || !sale.taskTrackings || !sale.taskTrackings.length || !taskType) return false;

    return (sale.taskTrackings as ITaskTracking[]).some((tt) => tt.task == taskType);
  }

  public CanEditRelatedApprovalTask(sale: ISale): boolean {
    if (!sale) return false;

    return sale.flagApproved !== true && sale.status == SaleStatus.OnSale;
  }

  public CanEditRelatedTrackingTask(sale: ISale): boolean {
    if (!sale) return false;

    return (
      ![SaleStatus.Unsold, SaleStatus.Suspended, SaleStatus.Stopped, SaleStatus.Reserved, SaleStatus.Sold].includes(
        sale.status as SaleStatus
      ) &&
      !sale.orderLine &&
      !sale.deposit &&
      !sale.settlement
    );
  }

  public CanEditRelatedSettlementTask(sale: ISale): boolean {
    if (!sale) return false;

    return !sale.settlement || !sale.settlement.flagProcessed;
  }

  public CanEditRelatedShippingTask(sale: ISale): boolean {
    if (!sale || !sale.orderLine) return false;

    return !sale.orderLine.shippingStatus
      ? true
      : ![ShippingStatus.Delivered, ShippingStatus.PickedUp, ShippingStatus.Transit].includes(
          sale.orderLine.shippingStatus
        );
  }

  public async SaveAdditionalSubtask(answer: string, sale: ISale): Promise<ITaskTracking> {
    if (!answer || !sale?.id) return Promise.reject();

    const subtask: ITaskTracking = {
      answer: answer,
      comment: null,
      dueDate: this.CalculateStatusTrackingDueDate(sale)?.toISOString() ?? null,
      priority: TaskPriorityLevels.Medium,
      task: TaskTypes.SaleTracking,
      subtask: SaleTaskTypes.SelectPurchaseAbortReason,
      doneBy: userStore.user?.id as string,
      doneAt: new Date().toISOString(),
      saleId: sale.id,
      userId: sale.userId as string
    };
    return await this.SaveTask(subtask);
  }

  public async SaveTask(taskTracking: ITaskTracking): Promise<ITaskTracking> {
    if (!authStore.IsVendor || !userStore.user) throw new Error("Unauthorized");
    if (
      !taskTracking ||
      !taskTracking.saleId ||
      !taskTracking.userId ||
      !taskTracking.answer ||
      !taskTracking.task ||
      !taskTracking.subtask
    )
      throw new Error("Argument exception");

    const { data: createdTask } = await this.taskTrackingsService.Insert(taskTracking);
    return createdTask;
  }
}
