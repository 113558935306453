import { Component, Prop, Vue } from "vue-property-decorator";

export enum HelpTypes {
  Info = "info",
  Warning = "warning"
  // Danger = "danger",
  // Success = "success"
}

@Component
export default class AppHelp extends Vue {
  @Prop()
  readonly title: string;
  @Prop()
  readonly icon?: string | Array<string>;
  @Prop({ default: HelpTypes.Info })
  readonly type: HelpTypes;

  HelpTypes = HelpTypes;

  get Icon(): string | Array<string> {
    if (this.icon) return this.icon;

    switch (this.type) {
      case HelpTypes.Info:
        return "question-circle";

      case HelpTypes.Warning:
        return "exclamation-circle";

      default:
        console.error("Not implemented help type");
        return "";
    }
  }
}
