// DI
import "reflect-metadata"; // WARNING: must be the first import
// App
import "./App.index";
import { i18n, router, store } from "./App.index";
import App from "./App.vue";
// Vue
import { Vue } from "vue-property-decorator";
import { BuildContainer } from "./App.container";
import { authStore, InitUserData, InitVisitorData, RestoreUserData, WatchAuthData } from "./stores/App.store.modules";

// See: https://github.com/vue-perf-devtool/vue-perf-devtool
// Vue.config.devtools = process.env.NODE_ENV !== 'production';
// Vue.config.performance = process.env.NODE_ENV !== 'production';

export class AppModule {
  appVue: Vue;

  constructor() {
    BuildContainer();
    RestoreUserData();
    InitVisitorData();
    if (authStore.IsAuthenticated) InitUserData();
    WatchAuthData();

    this.appVue = this.BootstrapVueApp();
  }

  private BootstrapVueApp(): Vue {
    return new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App)
    });
  }
}
