import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from "./i18n.const";

export function GetUserLocale() {
  let userLocale: string = navigator.language || DEFAULT_LOCALE;
  if (userLocale.length > 2) {
    userLocale = userLocale.substring(0, 2);
  }

  // Check if user local is supported
  const supportedLocales: string[] = Object.keys(SUPPORTED_LOCALES);
  if (!supportedLocales.includes(userLocale)) {
    userLocale = DEFAULT_LOCALE;
  }

  return userLocale;
}
