import { IOrderLinesService } from "@api/contracts/odata/IOrderLinesService";
import { IGuest } from "@api/models/market/IGuest";
import { IOrder } from "@api/models/market/IOrder";
import { IOrderLine } from "@api/models/market/IOrderLine";
import { IUser } from "@api/models/market/IUser";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import odataQuery, { ExpandObjectQuery } from "odata-fluent-query";

export class OrderLinesService extends BaseODataService implements IOrderLinesService {
  constructor() {
    super();
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IOrderLine>> {
    const query = odataQuery<IOrderLine>();

    return this.Client().get(`OrderLines?${query.toString()}`);
  }

  public FetchAllOrderLinesWithOrderDetailsBySalesIds(
    salesIds: number[]
  ): AxiosPromise<IODataCollectionResponse<IOrderLine>> {
    const query = odataQuery<IOrderLine>()
      .filter((ol) => ol.saleId.in(salesIds))
      .expand("order", (o: ExpandObjectQuery<IOrder>) =>
        o
          .expand("deliveryAddress")
          .expand("user", (u: ExpandObjectQuery<IUser>) =>
            u.select("firstname", "lastname", "email", "phoneNumber", "preferredLanguage")
          )
          .expand("guest", (g: ExpandObjectQuery<IGuest>) =>
            g.select("firstname", "lastname", "email", "phoneNumber", "preferredLanguage")
          )
      );

    return this.Client().get(`OrderLines?${query.toString()}`);
  }
}
