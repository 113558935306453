import { Route } from "vue-router";
import { isAuthorized } from "./isAuthorized";

export function authRoleGuard(to: Route): boolean {
  const toMatched = to.matched.filter((record) => record.meta.requiresAuth)?.[0];
  const requiresAuthRole = toMatched ? true : false;
  const requiredAuthRole: string | undefined = toMatched?.meta?.requiresAuthRole;
  const requiredAuthRoles: string[] | undefined = toMatched?.meta?.requiresAuthRoles;
  const hasRequiredRole = isAuthorized(requiredAuthRoles || requiredAuthRole);

  // prettier-ignore
  return !requiresAuthRole || (requiresAuthRole && hasRequiredRole)
    ? true
    : false;
}
