import { IBiddingOrdersService } from "@api/contracts/odata/IBiddingOrdersService";
import { IBidsService } from "@api/contracts/odata/IBidsService";
import { IDepositsService } from "@api/contracts/odata/IDepositsService";
import { IFavoritesService } from "@api/contracts/odata/IFavoritesService";
import { IOrderLinesService } from "@api/contracts/odata/IOrderLinesService";
import { IOrdersService } from "@api/contracts/odata/IOrdersService";
import { ISalesService } from "@api/contracts/odata/ISalesService";
import { ISettlementsService } from "@api/contracts/odata/ISettlementsService";
import { ITaskTrackingsService } from "@api/contracts/odata/ITaskTrackingsService";
import { IUsersService } from "@api/contracts/odata/IUsersService";
import { ProductMapper } from "@api/mappers/ProductMapper";
import { ProductTypes } from "@api/models/market/constants/ProductTypes";
import { SaleStatus } from "@api/models/market/constants/SaleStatus";
import { SaleTypes } from "@api/models/market/constants/SaleTypes";
import { ShippingMethodTypes } from "@api/models/market/constants/ShippingMethodTypes";
import { TransferStatus } from "@api/models/market/constants/TransferStatus";
import { IAbandonedOrderLine } from "@api/models/market/IAbandonedOrderLine";
import { IBid } from "@api/models/market/IBid";
import { IBiddingOrder } from "@api/models/market/IBiddingOrder";
import { IFavorite } from "@api/models/market/IFavorite";
import { IOrder } from "@api/models/market/IOrder";
import { IPackage } from "@api/models/market/IPackage";
import { IPigeon } from "@api/models/market/IPigeon";
import { ISale } from "@api/models/market/ISale";
import {
  AUCTION_TIME_CLOSE_END_OF_SALE,
  BASE_DEPOSIT_RATE,
  PLATFORM_VAT_RATE,
  REFERRAL_FEES_RATE
} from "@market/App.const";
import { IProduct } from "@market/components/sale/SaleProductAbstract";
import { AppRoutes } from "@market/routers/App.routes";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { IFilePicture } from "@pigeon/models/IFilePicture";
import { IFileVideo } from "@pigeon/models/IFileVideo";
import { IBlobManager } from "@pigeon/services/contracts/IBlobManager";
import { ISaleManager } from "@pigeon/services/contracts/ISaleManager";
import { AxiosPromise, AxiosResponse } from "axios";
import { Inject } from "inversify-props";
import { uniq } from "lodash-es";
import cloneDeep from "lodash-es/cloneDeep";
import VueRouter from "vue-router";
import { Dictionary, Location } from "vue-router/types/router";
import { IAmountManager } from "./contracts/IAmountManager";
import { IPackageManager } from "./contracts/IPackageManager";
import { IPigeonManager } from "./contracts/IPigeonManager";

export class SaleManager implements ISaleManager {
  static readonly MAX_INT32 = Math.pow(2, 31) - 1;

  @Inject()
  private salesService: ISalesService;
  @Inject()
  private favoritesService: IFavoritesService;
  @Inject()
  private bidsService: IBidsService;
  @Inject()
  private biddingOrdersService: IBiddingOrdersService;
  @Inject()
  private ordersService: IOrdersService;
  @Inject()
  private orderLinesService: IOrderLinesService;
  @Inject()
  private usersService: IUsersService;
  @Inject()
  private depositsService: IDepositsService;
  @Inject()
  private settlementsService: ISettlementsService;
  @Inject()
  private taskTrackingsService: ITaskTrackingsService;
  @Inject()
  private pigeonManager: IPigeonManager;
  @Inject()
  private packageManager: IPackageManager;
  @Inject()
  private amountManager: IAmountManager;
  @Inject()
  private blobManager: IBlobManager;

  //#region Upload
  private async UploadPictureFilesAsync(pigeonId: number, pictureFiles: IFilePicture[]): Promise<void> {
    for (const pictureFile of pictureFiles) {
      await this.blobManager.InsertPigeonPictureAsync(pigeonId, pictureFile);
    }
  }

  private async UploadVideoFilesAsync(pigeonId: number, videoFiles: IFileVideo[]): Promise<void> {
    for (const videoFile of videoFiles) {
      await this.blobManager.InsertPigeonVideoAsync(pigeonId, videoFile);
    }
  }

  private async UploadMediaFilesAsync(
    sale: ISale,
    pictureFiles: IFilePicture[],
    videoFiles: IFileVideo[]
  ): Promise<void> {
    if (!sale.id) throw new Error("Operation exception: Error on upload media files because the sale id is undefined.");

    // Insert Media (pictures, videos)
    // Product pigeon
    if (sale.product == ProductTypes.Pigeon) {
      if (!sale.pigeonId)
        throw new Error("Operation exception: Error on upload media files because the pigeon id is undefined.");
      if (pictureFiles.length > 0) await this.UploadPictureFilesAsync(sale.pigeonId, pictureFiles);
      if (videoFiles.length > 0) await this.UploadVideoFilesAsync(sale.pigeonId, videoFiles);
    }
    // Product package
    else if (sale.product == ProductTypes.Package) {
      // fetch product package to retrieve graph children Ids (= package Id, pigeonItems Id, reproducerItems Id)
      const { data: salePackageData } = await this.salesService.FetchProductPackage(sale.id);
      sale.package = salePackageData.package;
      sale.packageId = salePackageData.packageId;

      if (!sale.packageId)
        throw new Error("Operation exception: Error on upload media files because the package id is undefined.");
      if (!sale.package)
        throw new Error("Operation exception: Error on upload media files because the package is undefined.");
      if (pictureFiles.length > 0 && sale.package) {
        // pigeons
        if (sale.package.pigeons) {
          for (const pigeon of sale.package.pigeons) {
            const pigeonItemsPictureFiles = pictureFiles.filter(
              (pf) => pf.pigeonKey === pigeon.id || pf.pigeonKeyClient === pigeon.idClient
            );

            if (pigeonItemsPictureFiles.length > 0 && pigeon.id) {
              await this.UploadPictureFilesAsync(pigeon.id, pigeonItemsPictureFiles);
            }
          }
        }
        // reproducers
        if (sale.package.reproducerParents) {
          for (const reproducerParent of sale.package.reproducerParents) {
            if (reproducerParent.parent) {
              const reproducerItemsPictureFiles = pictureFiles.filter(
                (pf) =>
                  pf.pigeonKey === reproducerParent?.parent?.id ||
                  pf.pigeonKeyClient === reproducerParent?.parent?.idClient
              );
              if (reproducerItemsPictureFiles.length > 0 && reproducerParent.parent?.id)
                await this.UploadPictureFilesAsync(reproducerParent.parent?.id, reproducerItemsPictureFiles);
            }
          }
        }
      }
    }
  }
  //#endregion

  //#region Queries
  private async PrefetchSaleToEdit(saleKey: number) {
    return await Promise.all([
      this.salesService.FetchProductType(saleKey),
      this.salesService.FetchSaleType(saleKey),
      this.salesService.GetIsVendorOfThisSale(saleKey)
    ]);
  }

  private async PrefetchSaleProduct(saleKey: number) {
    return await Promise.all([
      this.salesService.FetchProductType(saleKey),
      this.salesService.FetchSaleType(saleKey),
      authStore.IsAuthenticated
        ? this.salesService.GetIsBuyerOfThisSale(saleKey)
        : Promise.resolve({ data: { value: false } }),
      authStore.IsAuthenticated
        ? this.salesService.GetIsVendorOfThisSale(saleKey)
        : Promise.resolve({ data: { value: false } })
    ]);
  }

  private FetchSaleProduct(saleKey: number, productType: ProductTypes) {
    switch (productType) {
      case ProductTypes.Pigeon:
        return this.salesService.FetchProductPigeon(saleKey);

      case ProductTypes.Package:
        return this.salesService.FetchProductPackage(saleKey);

      default:
        return Promise.reject("Invalid product type");
    }
  }

  private FetchSaleProductWithFinancialData(saleKey: number, productType: ProductTypes) {
    switch (productType) {
      case ProductTypes.Pigeon:
        return this.salesService.FetchProductPigeonWithPaymentSummary(saleKey);

      case ProductTypes.Package:
        return this.salesService.FetchProductPackageWithPaymentSummary(saleKey);

      default:
        return Promise.reject("Invalid product type");
    }
  }

  private FetchSaleProductToEdit(saleKey: number, productType: ProductTypes) {
    switch (productType) {
      case ProductTypes.Pigeon:
        return this.salesService.FetchProductPigeonToEdit(saleKey);

      case ProductTypes.Package:
        return this.salesService.FetchProductPackageToEdit(saleKey);

      default:
        return Promise.reject("Invalid product type");
    }
  }

  private FetchSaleProductToSupervise(saleKey: number, productType: ProductTypes) {
    switch (productType) {
      case ProductTypes.Pigeon:
        return this.salesService.FetchSupervisedPigeon(saleKey);

      case ProductTypes.Package:
        return this.salesService.FetchSupervisedPackage(saleKey);

      default:
        return Promise.reject("Invalid product type");
    }
  }

  private FetchAllSaleBids(saleKey: number, saleType: SaleTypes) {
    if (saleType == SaleTypes.Bid) return this.salesService.FetchAllBids(saleKey);
  }

  public async FetchAllBidsBySale(saleKey: number): Promise<IBid[]> {
    if (!saleKey) return Promise.reject("Argument exception: saleKey is undefined");

    const { data } = await this.salesService.FetchAllBids(saleKey);
    return data.value;
  }

  public async FetchAllBidsBySales(sales: ISale[]): Promise<IBid[]> {
    const saleIds: number[] = uniq(sales.filter((s) => s.id).map((s) => s.id) as number[]);
    if (!saleIds?.length) return Promise.reject("Argument exception: no sale id.");

    const { data: bidsData } = await this.bidsService.FetchAllBidsWithUserBySales(saleIds);
    return bidsData.value;
  }

  private FetchMyHighestSaleBiddingOrders(saleKey: number, saleType: SaleTypes) {
    if (authStore.IsAuthenticated && authStore.IsMember && saleType == SaleTypes.Bid)
      return this.biddingOrdersService.FetchMyHighestBiddingOrderBySale(saleKey);
  }

  // Issue MAX DTU = split the query
  private async FetchSaleFinancialData(saleKey: number, orderId?: string | null) {
    const promiseOrder = orderId ? this.ordersService.FetchSummaryWithBuyer(orderId) : undefined;
    const promiseDeposit = this.depositsService.FetchAllSummaryWithUserBySale(saleKey);
    const promiseSettlement = this.settlementsService.FetchAllSummaryWithUserBySale(saleKey);
    const [responseOrder, responseDeposit, responseSettlement] = await Promise.all([
      promiseOrder,
      promiseDeposit,
      promiseSettlement
    ]);

    return { responseOrder, responseDeposit, responseSettlement };
  }

  // Issue MAX DTU = split the query
  public async FetchSaleWithProduct(saleKey: number): Promise<ISale> {
    let saleWithProduct: ISale | null = null;

    // prettier-ignore
    const [
      { data: productType },
      { data: saleType },
      { data: { value: isTheBuyer } },
      { data: { value: isTheVendor } }
    ] = await this.PrefetchSaleProduct(saleKey);

    const [responseProduct, responseBids, responseHighestBiddingOrder] = await Promise.all([
      isTheBuyer || isTheVendor || authStore.IsAdministrator
        ? this.FetchSaleProductWithFinancialData(saleKey, productType)
        : this.FetchSaleProduct(saleKey, productType),
      this.FetchAllSaleBids(saleKey, saleType),
      this.FetchMyHighestSaleBiddingOrders(saleKey, saleType)
    ]);
    if (responseProduct) saleWithProduct = responseProduct.data;
    if (responseBids && saleWithProduct) saleWithProduct.bids = responseBids.data.value;
    if (responseHighestBiddingOrder && saleWithProduct)
      saleWithProduct.biddingOrders = responseHighestBiddingOrder.data.value;

    if (isTheBuyer || isTheVendor || authStore.IsAdministrator) {
      // prettier-ignore
      const { responseOrder, responseDeposit, responseSettlement } = await this.FetchSaleFinancialData(saleKey, saleWithProduct?.orderLine?.orderId);
      if (responseOrder && saleWithProduct?.orderLine) saleWithProduct.orderLine.order = responseOrder.data;
      if (responseDeposit && saleWithProduct) saleWithProduct.deposit = responseDeposit.data.value?.[0];
      if (responseSettlement && saleWithProduct) saleWithProduct.settlement = responseSettlement.data.value?.[0];
    }

    return saleWithProduct as ISale;
  }

  public async FetchSaleWithProductToEdit(saleKey: number): Promise<ISale> {
    const [{ data: productType }, { data: isVendorOfThisSale }] = await this.PrefetchSaleToEdit(saleKey);
    if (!isVendorOfThisSale && !authStore.IsAdministrator) return Promise.reject("Unauthorized");

    const { data: saleWithProductToEdit } = await this.FetchSaleProductToEdit(saleKey, productType);
    return saleWithProductToEdit;
  }

  public async FetchSaleWithProductToSupervise(saleKey: number): Promise<ISale> {
    const [{ data: productType }, { data: isVendorOfThisSale }] = await Promise.all([
      this.salesService.FetchProductType(saleKey),
      this.salesService.GetIsVendorOfThisSale(saleKey)
    ]);

    if (!isVendorOfThisSale && !authStore.IsAdministrator) {
      return Promise.reject("Unauthorized action");
    }

    const { data: saleWithProductToSupervise } = await this.FetchSaleProductToSupervise(saleKey, productType);

    // Issue MAX DTU = split the query
    const [promiseSaleBids, promiseSaleBiddingOrders, promiseUserVendor, promiseOrder, promiseTrackingTasks] =
      await Promise.all([
        saleWithProductToSupervise.type == SaleTypes.Bid
          ? this.bidsService.FetchAllBidsWithUserBySale(saleWithProductToSupervise.id as number)
          : Promise.resolve(null),
        saleWithProductToSupervise.type == SaleTypes.Bid && authStore.IsAdministrator
          ? this.biddingOrdersService.FetchAllBiddingOrdersWithUserBySale(saleWithProductToSupervise.id as number)
          : Promise.resolve(null),
        saleWithProductToSupervise.userId
          ? this.usersService.FetchWithVendor(saleWithProductToSupervise.userId as string)
          : Promise.resolve(null),
        saleWithProductToSupervise.orderLine?.orderId
          ? this.ordersService.FetchSheet(saleWithProductToSupervise.orderLine.orderId)
          : Promise.resolve(null),
        saleWithProductToSupervise.userId
          ? this.taskTrackingsService.FetchAllBySaleAndByVendor(
              saleWithProductToSupervise.id as number,
              saleWithProductToSupervise.userId
            )
          : Promise.resolve(null)
      ]);

    if (promiseSaleBids) saleWithProductToSupervise.bids = promiseSaleBids.data.value;
    if (promiseSaleBiddingOrders) saleWithProductToSupervise.biddingOrders = promiseSaleBiddingOrders.data.value;
    if (promiseUserVendor) saleWithProductToSupervise.user = promiseUserVendor.data;
    if (promiseOrder && saleWithProductToSupervise.orderLine)
      saleWithProductToSupervise.orderLine.order = promiseOrder.data;
    if (promiseTrackingTasks) saleWithProductToSupervise.taskTrackings = promiseTrackingTasks.data.value;

    if (authStore.IsAdministrator && saleWithProductToSupervise.orderLinesAbandoned) {
      await this.FetchThenPopulateAbandonedOrders(saleWithProductToSupervise.orderLinesAbandoned);
    }

    return saleWithProductToSupervise;
  }

  private async FetchThenPopulateAbandonedOrders(abandonedOrderLines: IAbandonedOrderLine[]): Promise<void> {
    if (!authStore.IsAdministrator) return Promise.resolve();
    if (!abandonedOrderLines || !abandonedOrderLines.length) return Promise.resolve();

    const promisesAbandonedOrders: AxiosPromise<IOrder>[] = [];

    for (const orderLine of abandonedOrderLines) {
      if (orderLine.orderId) {
        promisesAbandonedOrders.push(this.ordersService.FetchSheet(orderLine.orderId));
      }
    }

    const abandonedOrders = (await Promise.all(promisesAbandonedOrders)).map((promise) => promise.data);
    for (const abandonedOrder of abandonedOrders) {
      const currentAbandonedOrderLine = abandonedOrderLines.find((aol) => aol.orderId == abandonedOrder.id);
      if (currentAbandonedOrderLine) {
        currentAbandonedOrderLine.order = abandonedOrder;
      }
    }
  }

  public async FetchThenPopulateSalesBidsProperty(sales: ISale[]): Promise<void> {
    const saleIds: number[] = uniq(sales.filter((s) => s.id).map((s) => s.id) as number[]);
    if (!saleIds?.length) return;

    const { data: salesBidsData } = await this.bidsService.FetchAllBidsWithUserBySales(saleIds);
    for (const sale of sales) {
      sale.bids = salesBidsData.value.filter((b) => b.saleId == sale.id).sort(this.CompareBids);
    }
  }

  public async FetchThenPopulateSalesOrderLineProperty(sales: ISale[]): Promise<void> {
    const salesIds: number[] = uniq(sales.filter((s) => s.id).map((s) => s.id as number));
    if (!salesIds?.length) return;

    const { data: orderLinesData } = await this.orderLinesService.FetchAllOrderLinesWithOrderDetailsBySalesIds(
      salesIds
    );
    for (const sale of sales) {
      if (!sale.id) continue;

      sale.orderLine = orderLinesData.value.find((ol) => ol.saleId == sale.id);
    }
  }
  //#endregion

  //#region Commands
  public async Insert(sale: ISale, pictureFiles: IFilePicture[], videoFiles: IFileVideo[]): Promise<ISale> {
    try {
      const { data: insertedSale } = await this.salesService.Insert(sale);
      if (!insertedSale.id) throw new Error("Operation exception: undefined id for created sale");
      // Insert Media (pictures, videos)
      await this.UploadMediaFilesAsync(insertedSale, pictureFiles, videoFiles);
      return Promise.resolve(insertedSale);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  public async Update(updatedSale: ISale, pictureFiles: IFilePicture[], videoFiles: IFileVideo[]): Promise<void> {
    try {
      if (!updatedSale.id) throw new Error("Argument exception: undefined id for updated sale");

      await this.salesService.Update(updatedSale.id, updatedSale);
      // Insert Media (pictures, videos)
      await this.UploadMediaFilesAsync(updatedSale, pictureFiles, videoFiles);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  public async AddToFavorite(saleId: number, userId: string): Promise<IFavorite> {
    if (!authStore.IsAuthenticated) return Promise.reject("Unauthorized exception");
    if (!saleId || !userId) return Promise.reject("Argument exception");
    if (userId != userStore.user?.id) return Promise.reject("Forbidden exception");

    const newFavorite: IFavorite = {
      recordedDate: new Date().toISOString(),
      saleId: saleId,
      userId: userId
    };

    try {
      const { data: insertedFavorite } = await this.favoritesService.Insert(newFavorite);
      return Promise.resolve(insertedFavorite);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  public async RemoveFromFavorite(favoriteId: number): Promise<AxiosResponse<void>> {
    if (!authStore.IsAuthenticated || !userStore.user?.id) return Promise.reject("Unauthorized exception");
    if (!favoriteId) return Promise.reject("Argument exception: undefined favorite id");

    try {
      return this.favoritesService.Delete(favoriteId);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
  //#endregion

  //#region Handlers

  public SetTimeoutBeforeEndOfSale(handler: TimerHandler, sale: ISale): number | undefined {
    if (!sale || !sale.endDate) return;

    const closeDelayBeforeEndOfSale = new Date(sale.endDate).getTime() - AUCTION_TIME_CLOSE_END_OF_SALE;

    let remainingTime = closeDelayBeforeEndOfSale - new Date().getTime(); // milliseconds
    if (remainingTime < 0) remainingTime = 0;

    // Fix timeout delay who is limited to MAX INT32 value by the browser
    if (remainingTime < SaleManager.MAX_INT32) {
      return window.setTimeout(handler, remainingTime);
    }
  }

  public SetTimeoutAtEndOfSale(handler: TimerHandler, sale: ISale): number | undefined {
    if (!sale || !sale.endDate) return;

    let remainingTime = new Date(sale.endDate).getTime() - new Date().getTime(); // milliseconds
    if (remainingTime < 0) remainingTime = 0;

    // Fix timeout delay who is limited to MAX INT32 value by the browser
    if (remainingTime < SaleManager.MAX_INT32) {
      return window.setTimeout(handler, remainingTime);
    }
  }

  public SetTimeoutWithEndOfSaleGuard(handler: TimerHandler, sale: ISale, timeoutDuration: number): number | undefined {
    if (!sale || !sale.endDate) return;
    if (timeoutDuration >= SaleManager.MAX_INT32) return;

    const remainingTime = new Date(sale.endDate).getTime() - new Date().getTime(); // milliseconds
    if (remainingTime < 0) return;

    return window.setTimeout(handler, timeoutDuration);
  }

  public SetTimeoutWithEndOfSalesGuard(
    handler: TimerHandler,
    sales: ISale[],
    timeoutDuration: number
  ): number | undefined {
    if (!sales?.length) return;
    if (timeoutDuration >= SaleManager.MAX_INT32) return;
    if (!this.IsAnAuctionSaleAvailableForBidding(sales, new Date())) return;

    return window.setTimeout(handler, timeoutDuration);
  }
  //#endregion

  //#region Functions
  public GetProduct(sale: ISale): IProduct | null {
    return ProductMapper.MapToProduct(sale);
  }

  public GetProductReferenceSlug(sale: ISale): string | undefined {
    if (!sale) return;

    if (sale.product == ProductTypes.Pigeon && sale.pigeon) return this.pigeonManager.GetRingSlug(sale.pigeon);
    else if (sale.product == ProductTypes.Package && sale.package) return;
    else return;
  }

  public GetProductNameSlug(sale: ISale): string | undefined {
    if (!sale) return;

    if (sale.product == ProductTypes.Pigeon && sale.pigeon) return this.pigeonManager.GetNameSlug(sale.pigeon);
    else if (sale.product == ProductTypes.Package && sale.package) return this.packageManager.GetNameSlug(sale.package);
    else return;
  }

  public ResolveSaleUrl($router: VueRouter, sale: ISale): any {
    if (!sale) throw new Error("Argument exception: sale is undefined.");

    return $router.resolve({
      name: AppRoutes.Sale,
      params: this.BuildSaleUrlParameters(sale)
    });
  }

  public BuildSaleRoute(sale: ISale): Location {
    if (!sale) throw new Error("Argument exception: sale is undefined.");

    return {
      name: AppRoutes.Sale,
      params: this.BuildSaleUrlParameters(sale)
    };
  }

  private GetPigeonNameSlug(pigeon: IPigeon): string | undefined {
    if (!pigeon) throw new Error("Argument exception: pigeon is undefined.");

    return this.pigeonManager.GetNameSlug(pigeon);
  }

  private GetPigeonRingSlug(pigeon: IPigeon): string | undefined {
    if (!pigeon) throw new Error("Argument exception: pigeon is undefined.");

    return this.pigeonManager.GetRingSlug(pigeon);
  }

  private GetPackageNameSlug(pack: IPackage): string | undefined {
    if (!pack) throw new Error("Argument exception: pack is undefined.");

    return this.packageManager.GetNameSlug(pack);
  }

  private BuildSaleUrlParameters(sale: ISale): Dictionary<string> {
    if (!sale) throw new Error("Argument exception: sale is undefined.");
    if (!sale.id) throw new Error("Argument exception: sale id is undefined.");

    if (sale.product == ProductTypes.Pigeon && sale.pigeon) {
      return {
        saleKey: sale.id.toString(),
        productRef: this.GetPigeonRingSlug(sale.pigeon),
        productName: this.GetPigeonNameSlug(sale.pigeon)
      } as Dictionary<string>;
    } else if (sale.product == ProductTypes.Package && sale.package) {
      return {
        saleKey: sale.id.toString(),
        productName: this.GetPackageNameSlug(sale.package)
      } as Dictionary<string>;
    } else throw new Error("Argument exception: Product is undefined or unsupported.");
  }

  // Business rule: The higest bid is the bid with the highest amount with the earlist bidding order date if exists
  public GetHighestBid(sale: ISale): IBid | null {
    if (sale.type != SaleTypes.Bid || !sale.bids?.length) return null;

    return cloneDeep(sale.bids).sort(this.CompareBids)[0];
  }

  public OrderSalesBidsByHighestBid(sales: ISale[]): ISale[] {
    if (!sales) return [];

    const salesWithOrderedBids = cloneDeep(sales);
    for (const sale of salesWithOrderedBids) {
      if (sale.type == SaleTypes.Bid && sale.bids && sale.bids.length) {
        sale.bids = this.OrderSaleBidsByHighestBid(sale.bids as IBid[]);
      }
    }

    return salesWithOrderedBids;
  }

  public OrderSaleBidsByHighestBid(bids: IBid[]): IBid[] {
    if (!bids) return [];

    return cloneDeep(bids).sort(this.CompareBids);
  }

  // Sort by amount Descending then by biddingOrderId ascending (=equivalents to biddingOrder created date ascending)
  public CompareBids(bidA: IBid, bidB: IBid): number {
    if (bidB.amount == bidA.amount) {
      return (
        (bidA?.biddingOrderId ?? Number.MAX_VALUE) - (bidB?.biddingOrderId ?? Number.MAX_VALUE) ||
        new Date(bidA.bidDate).getTime() - new Date(bidB.bidDate).getTime()
      );
    }

    return bidB.amount - bidA.amount || new Date(bidA.bidDate).getTime() - new Date(bidB.bidDate).getTime();
  }

  // Business rule: The higest bidding order is the bidding order with the highest limit amount with the earlist bidding order date
  public GetHighestBiddingOrder(sale: ISale): IBiddingOrder | null {
    if (sale.type != SaleTypes.Bid || !sale.biddingOrders?.length) return null;

    return cloneDeep(sale.biddingOrders).sort(this.CompareBiddingOrders)[0];
  }

  // Sort by limitAmount Descending then by orderDate ascending
  public CompareBiddingOrders(biddingOrderA: IBiddingOrder, biddingOrderB: IBiddingOrder): number {
    return (
      biddingOrderB.limitAmount - biddingOrderA.limitAmount ||
      new Date(biddingOrderA.orderDate).getTime() - new Date(biddingOrderB.orderDate).getTime()
    );
  }

  public GetLatestBidByUser(sale: ISale, userKey: string): IBid | undefined | null {
    if (!sale.bids?.length) return null;

    const saleBids = cloneDeep(sale.bids);
    const bidsSortedDescending = saleBids.sort(this.CompareBids);
    const latestBid = bidsSortedDescending.find((b) => b.userId == userKey);

    return latestBid;
  }

  public IsSaleExpired(sale: ISale): boolean | undefined {
    if (!sale.endDate) return undefined;

    return sale.endDate && new Date() < new Date(sale.endDate) ? false : true;
  }

  public IsSaleStarted(sale: ISale): boolean {
    return new Date() >= new Date(sale.startDate) ? true : false;
  }

  public IsSaleCurrent(sale: ISale): boolean {
    return (
      this.IsSaleStarted(sale) &&
      (typeof sale.endDate === "undefined" || (typeof sale.endDate !== "undefined" && !this.IsSaleExpired(sale)))
    );
  }

  public IsSaleAvailableForBidding(sale: ISale, now: Date): boolean {
    if (!sale) throw new Error("Argument exception: sale is undefined.");
    if (sale.type !== SaleTypes.Bid) throw new Error("Argument exception: sale type is incorrect.");
    if (!sale.endDate) throw new Error("Argument exception: sale end date is undefined.");

    return (
      sale.status === SaleStatus.OnSale &&
      sale.flagApproved === true &&
      now.getTime() >= new Date(sale.startDate).getTime() &&
      now.getTime() < new Date(sale.endDate).getTime()
    );
  }

  public IsAnAuctionSaleAvailableForBidding(sales: ISale[], now: Date): boolean {
    if (!sales?.length) return false;
    if (!sales.some((s) => s.type === SaleTypes.Bid)) return false;

    const allSalesEndDate: Date[] = sales.filter((s) => s.endDate).map((s) => new Date(s.endDate as string));
    const allSalesEndDateTime: number[] = allSalesEndDate.map((d) => d.getTime());
    const latestSaleEndDateTime: number = Math.max(...allSalesEndDateTime);
    const remainingTime = latestSaleEndDateTime - now.getTime(); // milliseconds

    return remainingTime > 0 ? true : false;
  }

  public IsSaleAvailableForPurchase(sale: ISale, now: Date): boolean {
    if (sale.type === SaleTypes.Bid && !sale.endDate)
      new Error("Argument exception: an bid sale must have an end date.");

    let isAvailable = sale.status == SaleStatus.OnSale && now.getTime() >= new Date(sale.startDate).getTime();

    if (sale.type === SaleTypes.Fix && sale.endDate) {
      isAvailable = isAvailable && now.getTime() <= new Date(sale.endDate).getTime();
    } else if (sale.type === SaleTypes.Bid && sale.endDate) {
      isAvailable = isAvailable && now.getTime() > new Date(sale.endDate).getTime();
    }

    return isAvailable;
  }

  public IsVendorOfThisSale(sale: ISale): boolean {
    if (!authStore.IsAuthenticated || !authStore.IsVendor || !userStore.user || !sale) return false;

    return sale.userId === userStore.user?.id;
  }

  public IsBuyerOfThisSale(sale: ISale): boolean {
    if (!authStore.IsAuthenticated || !authStore.IsMember || !userStore.user || !sale) return false;

    if (this.HasOrder(sale) && sale.orderLine?.order) {
      return sale.orderLine.order.userId === userStore.user.id;
    } else if (this.HasSettlement(sale) && sale.settlement) {
      return sale.settlement.userId == userStore.user.id;
    } else if (this.HasDeposit(sale) && sale.deposit) {
      return sale.deposit.userId == userStore.user.id;
    } else {
      return false;
    }
  }

  public IsBidderOfThisSale(sale: ISale): boolean {
    if (!authStore.IsAuthenticated || !authStore.IsMember || !userStore.user || !sale || !sale.bids?.length)
      return false;

    return sale.bids.some((b) => b.userId === userStore.user?.id);
  }

  public IsVatInclusive(sale: ISale): boolean | null {
    if (!sale) return null;

    const isVatInclusive = sale.orderLine ? sale.orderLine.vatInclusive : sale.vatInclusive;
    return isVatInclusive ?? null;
  }

  public IsTransportIncluded(sale: ISale): boolean {
    if (!sale) return false;

    return false;
  }

  public HasVat(sale: ISale): boolean {
    return sale.vatRate ? true : false;
  }

  public HasOrder(sale: ISale): boolean {
    if (!sale) return false;

    return sale.orderLine?.order ? true : false;
  }

  public HasSettlement(sale: ISale): boolean {
    if (!sale) return false;

    return sale.settlement ? true : false;
  }

  public HasDeposit(sale: ISale): boolean {
    if (!sale) return false;

    return sale.deposit ? true : false;
  }

  public HasTasks(sale: ISale): boolean {
    if (!sale) return false;

    return sale.taskTrackings?.length == 0;
  }

  public HasProductPicture(sale: ISale): boolean {
    if (!sale) return false;

    switch (sale.product) {
      case ProductTypes.Pigeon:
        return sale.pigeon?.pictures?.length ? true : false;

      case ProductTypes.Package:
        return sale.package?.pictures?.length ? true : false;

      default:
        return false;
    }
  }

  public CanBeComptabilisedAsFinancialAsset(sale: ISale): boolean {
    if (!sale || !sale.type || !sale.status) return false;

    return (sale.type == SaleTypes.Bid && sale?.bids?.length) ||
      (sale.type == SaleTypes.Fix && (sale.status == SaleStatus.OnHold || sale.status == SaleStatus.Sold))
      ? true
      : false;
  }

  public CanEditSale(sale: ISale): boolean {
    if (!sale) return false;

    const isCurrentApprovedForSale = this.IsSaleCurrent(sale) && sale.flagApproved && sale.status == SaleStatus.OnSale;
    const isAuctionExpiredWithABid =
      this.IsSaleExpired(sale) &&
      sale.flagApproved &&
      sale.status == SaleStatus.OnSale &&
      sale.type == SaleTypes.Bid &&
      sale.bids &&
      sale.bids.length >= 1;
    const isSold = sale.status == SaleStatus.Sold || sale.status == SaleStatus.OnHold;

    return isCurrentApprovedForSale || isAuctionExpiredWithABid || isSold ? false : true;
  }

  public CompareSalesById(sale1: ISale, sale2: ISale): boolean {
    return sale1.id === sale2.id;
  }
  //#endregion

  //#region SumFunctions
  public CalculateSaleAmount(sale: ISale): number {
    // Fix: cloneDeep sale.bids because a sort trigger reactive vue js mecanic
    return sale.type == SaleTypes.Bid && sale.bids && sale.bids.length > 0
      ? cloneDeep(sale.bids).sort(this.CompareBids)[0].amount
      : sale.price;
  }

  public CalculateSaleAmountDue(sale: ISale): number {
    const saleAmountIncludingVat = this.CalculateSaleAmountIncludingVat(sale);
    const depositAmount = this.CalculateDepositAmount(sale) ?? 0;

    return sale.deposit ? saleAmountIncludingVat - depositAmount : saleAmountIncludingVat;
  }

  public CalculateSaleAmountIncludingVat(sale: ISale): number {
    const isVatInclusive = this.IsVatInclusive(sale);
    const saleAmount = this.CalculateSaleAmount(sale);
    const vatAmount = this.CalculateVatAmount(sale) ?? 0;

    return this.amountManager.CalculateAmountIncludingVat(saleAmount, vatAmount, isVatInclusive);
  }

  public CalculateSaleAmountExcludingVat(sale: ISale): number {
    const isVatInclusive = this.IsVatInclusive(sale);
    const saleAmount = this.CalculateSaleAmount(sale);
    const vatAmount = this.CalculateVatAmount(sale) ?? 0;

    return this.amountManager.CalculateAmountExcludingVat(saleAmount, vatAmount, isVatInclusive);
  }

  public CalculatePlatformFeesRate(sale: ISale): number {
    const hasUsedReferralCode = sale.orderLine?.order?.referralCode ? true : false;
    const isReferralCodeOfVendorOfThisSale = sale.user?.vendor?.referralCode == sale.orderLine?.order?.referralCode;

    if (hasUsedReferralCode && isReferralCodeOfVendorOfThisSale) return sale.feesRate - REFERRAL_FEES_RATE;
    else return sale.feesRate;
  }

  public CalculatePlatformFeesAmount(sale: ISale): number {
    return this.CalculatePlatformFeesRate(sale) * this.CalculateSaleAmount(sale);
  }

  public CalculateVendorProfitsRate(sale: ISale): number {
    return 1 - this.CalculatePlatformFeesRate(sale);
  }

  public CalculatePlatformFeesVatRate(sale: ISale): number {
    return PLATFORM_VAT_RATE;
  }

  public CalculatePlatformFeesVatAmount(sale: ISale): number {
    return this.CalculatePlatformFeesAmount(sale) * this.CalculatePlatformFeesVatRate(sale);
  }

  public CalculatePlatformFeesAmountIncludingVat(sale: ISale): number {
    return this.CalculatePlatformFeesAmount(sale) + this.CalculatePlatformFeesVatAmount(sale);
  }

  public CalculatePlatformFeesAmountExcludingVat(sale: ISale): number {
    return this.CalculatePlatformFeesAmount(sale);
  }

  public CalculateVendorProfitsAmount(sale: ISale): number {
    return this.CalculateVendorProfitsRate(sale) * this.CalculateSaleAmount(sale);
  }

  public CalculateVendorRemainingProfitsAmount(sale: ISale): number {
    return (
      this.CalculateVendorProfitsAmount(sale) -
      (this.CalculateVendorDepositProfits(sale) ?? 0) -
      (this.CalculateVendorSettlementProfits(sale) ?? 0) -
      (this.CalculateVendorTransferProfits(sale) ?? 0)
    );
  }

  public CalculateVendorReferralFeesRate(vendorReferralCode: string, sale: ISale): number {
    const hasUsedReferralCode = sale.orderLine?.order?.referralCode ? true : false;

    if (hasUsedReferralCode) {
      return sale.orderLine?.order?.referralCode == vendorReferralCode ? REFERRAL_FEES_RATE : 0;
    }

    return 0;
  }

  public CalculateVendorReferralFeesAmount(vendorReferralCode: string, sale: ISale): number {
    return this.CalculateVendorReferralFeesRate(vendorReferralCode, sale) * this.CalculateSaleAmount(sale);
  }

  public CalculateSettlementAmount(sale: ISale): number | undefined {
    if (!sale || !sale.settlement) return;

    return sale.settlement.amount;
  }

  public CalculateVendorSettlementProfits(sale: ISale): number | undefined {
    if (!sale || !sale.settlement) return;

    return this.CalculateVendorProfitsRate(sale) * (this.CalculateSettlementAmount(sale) as number);
  }

  public CalculateDepositRate(sale: ISale): number {
    if (!sale) return BASE_DEPOSIT_RATE;

    return sale.deposit ? sale.deposit.rateOfTotal : BASE_DEPOSIT_RATE;
  }

  public CalculateDepositAmount(sale: ISale): number | undefined {
    if (!sale) return;

    return sale.deposit ? sale.deposit.amount : this.CalculateSaleAmount(sale) * this.CalculateDepositRate(sale);
  }

  public CalculateBuyerDepositAmountIncludingVat(sale: ISale): number | undefined {
    const depositTaxAmount = (this.CalculateVatAmount(sale) ?? 0) * this.CalculateDepositRate(sale);
    const depositAmount = this.CalculateDepositAmount(sale) ?? 0;
    return depositAmount + depositTaxAmount;
  }

  public CalculateBuyerDepositRemainingAmountIncludingVat(sale: ISale): number | undefined {
    if (!sale || !sale.deposit) return;

    return this.CalculateSaleAmount(sale) - (this.CalculateBuyerDepositAmountIncludingVat(sale) as number);
  }

  public CalculateVendorDepositProfits(sale: ISale): number | undefined {
    if (!sale || !sale.deposit) return;

    return this.CalculateVendorProfitsRate(sale) * (this.CalculateDepositAmount(sale) as number);
  }

  public CalculateVendorDepositRemainingProfits(sale: ISale): number | undefined {
    if (!sale || !sale.deposit) return;

    return this.CalculateVendorProfitsAmount(sale) - (this.CalculateVendorDepositProfits(sale) as number);
  }

  public CalculateVendorTransferProfits(sale: ISale): number | undefined {
    if (!sale || !sale.orderLine) return;

    let transferProfitsAmount = 0;
    if (sale.orderLine?.transferStatus == TransferStatus.TransferCreated)
      transferProfitsAmount = this.CalculateVendorProfitsAmount(sale);
    if (sale.deposit) transferProfitsAmount -= this.CalculateVendorDepositProfits(sale) ?? 0;
    if (sale.settlement) transferProfitsAmount -= this.CalculateVendorSettlementProfits(sale) ?? 0;

    return transferProfitsAmount;
  }

  public CalculateVatRate(sale: ISale): number | null {
    return sale.vatRate;
  }

  public CalculateVatAmount(sale: ISale): number | null {
    const saleVatRate = this.CalculateVatRate(sale);
    const saleAmount = this.CalculateSaleAmount(sale);
    const isVatInclusive = this.IsVatInclusive(sale);

    return this.amountManager.CalculateVatAmount(saleAmount, saleVatRate, isVatInclusive);
  }

  public DetermineVatOriginApplied(sale: ISale): string {
    if (!sale.user?.countryCode) throw new Error("Argument exception: vendor country is undefined");

    return sale.orderLine?.order &&
      sale.orderLine.order.shippingMethod == ShippingMethodTypes.Deliver &&
      sale.orderLine.order.deliveryAddress
      ? sale.orderLine.order.deliveryAddress.countryTwoIsoLetters
      : sale.user.countryCode;
  }
  //#endregion
}
