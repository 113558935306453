import en from "@pigeon/i18n/locales/en.json";
import fr from "@pigeon/i18n/locales/fr.json";
import nl from "@pigeon/i18n/locales/nl.json";
import pl from "@pigeon/i18n/locales/pl.json";
import pt from "@pigeon/i18n/locales/pt.json";

const locales = {
  en: en,
  fr: fr,
  nl: nl,
  pl: pl,
  pt: pt
} as any;

export default locales;
