import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { PayloadMapper } from "@api/mappers/PayloadMapper";
import { IAuth, IAuthResponse } from "@api/models/auth/IAuth";
import { IAuthSession } from "@api/models/auth/IAuthSession";
import { IJwt } from "@api/models/auth/IJwt";
import { IPayload } from "@api/models/auth/IPayload";
import { store } from "@market/stores";
import { authStore } from "@market/stores/App.store.modules";
import { IJwtStore } from "@market/stores/contracts/IJwtStore";
import AuthStore from "@market/stores/modules/authStore";
import { HasFunctionalityCookieConsent } from "@pigeon/components/layout/TheBannerCookies";
import dayjs from "@pigeon/i18n/dayjs";
import { cid, container, Inject } from "inversify-props";
import jwtDecode from "jwt-decode";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "jwt" = "jwt";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class JwtStore extends VuexModule implements IJwtStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private accountsService: IAccountsService;

  static readonly ACCESS_TOKEN_STORE_KEY: string = "access_token";
  static readonly REFRESH_TOKEN_STORE_KEY: string = "refresh_token";
  static readonly AUTH_AT_STORE_KEY: string = "last_auth_at";
  pending: boolean = false;
  accessToken: string | null = null;
  refreshToken: string | null = null;
  lastAuthDate: Date | null = null;
  payload: IPayload | null = null;
  refreshPromise: Promise<void> | null = null;

  // Use method to not cache the result (cannot cache a result with datetime)
  get HasTokenExpired(): () => boolean {
    return () => {
      if (!this.accessToken) return false;
      const jwt: any = jwtDecode(this.accessToken);
      const current_time = new Date().getTime() / 1000;
      const hasExpired = current_time > jwt.exp;
      return hasExpired;
    };
  }

  // Check if the expiration date from token is lower than 2 minutes
  get IsTokenCloseToExpire(): () => boolean {
    return () => {
      if (!authStore.IsAuthenticated) return false;
      if (!this.accessToken) return true;

      const now = dayjs();
      const closeToExpire = now.add(2, "minute");
      const closeToExpireTimestamp = closeToExpire.toDate().getTime() / 1000;
      const jwt: any = jwtDecode(this.accessToken);

      return (jwt.exp as number) < closeToExpireTimestamp;
    };
  }

  @Mutation
  UPDATE_PENDING(pendingStatus: boolean): void {
    this.pending = pendingStatus;
  }

  @Mutation
  UPDATE_REFRESH_PROMISE(refreshPromise: Promise<void> | null): void {
    this.refreshPromise = refreshPromise;
  }

  @Mutation
  UPDATE_TOKENS(tokens: IAuth): void {
    if (tokens.accessToken) {
      this.accessToken = tokens.accessToken;
      const jwt: IJwt = jwtDecode(tokens.accessToken);
      this.payload = PayloadMapper.Map(jwt);
    }
    if (tokens.refreshToken) {
      this.refreshToken = tokens.refreshToken;
    }

    this.pending = false;
  }

  @Mutation
  UPDATE_LAST_AUTH_DATE(lastAuthDate: string | null): void {
    if (!lastAuthDate) {
      this.lastAuthDate = null;
      return;
    }

    this.lastAuthDate = new Date(lastAuthDate);
  }

  @Mutation
  RESET(): void {
    this.accessToken = null;
    this.refreshToken = null;
    this.lastAuthDate = null;
    this.payload = null;
  }

  @Action
  RefreshTokens(): Promise<void> {
    if (!this.accessToken || !this.refreshToken) {
      console.warn("WARNING: Invalid tokens");
      authStore.Logout();
      return Promise.resolve();
    }

    // Handle concurrency (only one call at once)
    if (this.pending && this.refreshPromise) {
      return this.refreshPromise as Promise<void>;
    }

    const accountsService = container.get<IAccountsService>(cid.AccountsService);
    this.UPDATE_PENDING(true);

    const refreshPromise = accountsService
      .RefreshAuth({
        accessToken: this.accessToken,
        refreshToken: this.refreshToken
      })
      .then((response) => {
        const { accessToken, refreshToken, authAt } = response.data;
        this.UPDATE_TOKENS({ accessToken, refreshToken });
        this.UPDATE_LAST_AUTH_DATE(authAt);
        this.Store();
      })
      .catch((error) => {
        authStore.Logout();
      })
      .finally(() => {
        this.UPDATE_REFRESH_PROMISE(null);
        this.UPDATE_PENDING(false);
      });

    this.UPDATE_REFRESH_PROMISE(refreshPromise);
    return refreshPromise;
  }

  @Action
  InitTokens(tokens: IAuth) {
    if (!tokens.accessToken) console.warn("Invalid access token");
    if (!tokens.refreshToken) console.warn("Invalid refresh token");

    this.UPDATE_TOKENS(tokens);
    this.UPDATE_LAST_AUTH_DATE(null);
    this.Store();
  }

  @Action
  Reset(): void {
    this.RESET();
    this.ClearStorage();
  }

  @Action
  Store(): void {
    if (!HasFunctionalityCookieConsent()) return;

    localStorage.setItem(JwtStore.ACCESS_TOKEN_STORE_KEY, JSON.stringify(this.accessToken));
    localStorage.setItem(JwtStore.REFRESH_TOKEN_STORE_KEY, JSON.stringify(this.refreshToken));
    localStorage.setItem(JwtStore.AUTH_AT_STORE_KEY, JSON.stringify(this.lastAuthDate));
  }

  @Action
  ClearStorage(): void {
    localStorage.removeItem(JwtStore.ACCESS_TOKEN_STORE_KEY);
    localStorage.removeItem(JwtStore.REFRESH_TOKEN_STORE_KEY);
    localStorage.removeItem(JwtStore.AUTH_AT_STORE_KEY);
  }

  @Action
  Restore() {
    const accessTokenStored = localStorage.getItem(JwtStore.ACCESS_TOKEN_STORE_KEY);
    const refreshTokenStored = localStorage.getItem(JwtStore.REFRESH_TOKEN_STORE_KEY);
    const authAtStored = localStorage.getItem(JwtStore.AUTH_AT_STORE_KEY);

    try {
      if (authAtStored) {
        this.UPDATE_LAST_AUTH_DATE(JSON.parse(authAtStored));
      }

      if (accessTokenStored && refreshTokenStored) {
        const accessToken = JSON.parse(accessTokenStored);
        const refreshToken = JSON.parse(refreshTokenStored);

        this.UPDATE_TOKENS({ accessToken, refreshToken });
      }
      // Retro compatibility
      else if (localStorage.getItem(AuthStore.STORE_KEY)) {
        const sessionStored: string = localStorage.getItem(AuthStore.STORE_KEY) as string;
        const session: IAuthSession = JSON.parse(sessionStored) as IAuthSession;
        if (session.accessToken && session.refreshToken) this.UPDATE_TOKENS(session);
      }

      if (this.HasTokenExpired()) {
        // token expired
        // Fix: issue "Error: No matching bindings found for serviceIdentifier: Symbol(AccountsService) (from RefreshTokens method)"
        // Use setTimeout 0 to trigger methods to end of stack
        setTimeout(async () => {
          await this.RefreshTokens();
        }, 0);
      }
    } catch (error: any) {
      console.error(error);
      this.ClearStorage();
    }
  }
}
