import { render, staticRenderFns } from "./PigeonSheet.vue?vue&type=template&id=246a2988&scoped=true&"
import script from "./PigeonSheet.ts?vue&type=script&lang=ts&"
export * from "./PigeonSheet.ts?vue&type=script&lang=ts&"
import style0 from "./PigeonSheet.vue?vue&type=style&index=0&id=246a2988&prod&lang=scss&scoped=true&"
import style1 from "./PigeonSheet.vue?vue&type=style&index=1&id=246a2988&prod&lang=scss&scoped=true&"
import style2 from "./PigeonSheet.vue?vue&type=style&index=2&id=246a2988&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246a2988",
  null
  
)

/* custom blocks */
import block0 from "./PigeonSheet.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports