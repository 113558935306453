import { ISalesService } from "@api/contracts/odata/ISalesService";
import { SaleTypes } from "@api/models/market/constants/SaleTypes";
import { IBid } from "@api/models/market/IBid";
import { ISale } from "@api/models/market/ISale";
import { nameof } from "@pigeon/extensions/nameof";
import { ISaleManager } from "@pigeon/services/contracts/ISaleManager";
import { Inject } from "inversify-props";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class BidDoubleCheckHighestBidAbstract extends Vue {
  @Prop()
  readonly sale: ISale;

  @Inject()
  protected saleManager: ISaleManager;
  @Inject()
  protected salesService: ISalesService;

  highestBidDoubleChecked: IBid | null = null;
  IsDoubleCheckingBids: boolean = false;
  salePreviousEndDate: Date | null = null;
  endOfSaleTimeoutHandler: number | null | undefined = null;
  now: Date = new Date();
  faulty: boolean = false;

  get HighestBid(): IBid | null | undefined {
    if (!this.sale) return;

    return this.saleManager.GetHighestBid(this.sale);
  }

  get DoubleCheckHighestBidHasPassed(): boolean {
    if (!this.sale || !this.sale.id) return false;
    if (!this.sale.bids?.length && !this.HighestBid && !this.highestBidDoubleChecked) return true;

    return (
      this.highestBidDoubleChecked?.amount == this.HighestBid?.amount &&
      this.highestBidDoubleChecked?.userId == this.HighestBid?.userId &&
      // this.bidsDoubleChecked?.saleId == this.HighestBid?.saleId && // HighestBid doesn't have a saleId because it s a child of sale object
      this.highestBidDoubleChecked?.saleId == this.sale.id
    );
  }

  get EndDateHasChanged(): boolean {
    if (!this.salePreviousEndDate || !this.EffectiveSaleEndDate) return false;

    if (this.salePreviousEndDate.getTime() != new Date(this.EffectiveSaleEndDate).getTime()) {
      this.StoreInMemorySalePreviousEndDate(this.EffectiveSaleEndDate);
      return true;
    } else {
      return false;
    }
  }

  get EffectiveSaleEndDate(): string | null | undefined {
    return this.HighestBid?.sale?.endDate ?? this.sale.endDate;
  }

  get BidSaleIsOver(): boolean {
    if (!this.EffectiveSaleEndDate) return false;

    return this.now.getTime() > new Date(this.EffectiveSaleEndDate).getTime();
  }

  get IsHighestBidder(): boolean {
    if (
      !this.sale ||
      !this.$auth.IsAuthenticated ||
      !this.$auth.IsMember ||
      !this.$auth.User?.id ||
      !this.sale?.bids?.length
    )
      return false;

    const latestBid = this.sale.bids[0];
    const highestBidAmount = Math.max(...this.sale.bids.map((b) => b.amount), 0);
    return latestBid.userId === this.$auth.User?.id && latestBid.amount === highestBidAmount;
  }

  async created() {
    this.StoreInMemorySalePreviousEndDate(this.sale?.endDate);
    this.now = new Date();

    if (this.sale.type == SaleTypes.Bid && this.sale.shouldDoubleCheckBids) {
      await this.FetchHighestBid();
    }
  }

  private StoreInMemorySalePreviousEndDate(newEndDate?: string | null) {
    if (!newEndDate) return;

    this.salePreviousEndDate = new Date(newEndDate);
  }

  destroyed() {
    if (this.endOfSaleTimeoutHandler) clearTimeout(this.endOfSaleTimeoutHandler);
  }

  @Watch(nameof<BidDoubleCheckHighestBidAbstract>("EffectiveSaleEndDate"), { immediate: true })
  async ObserveEffectiveSaleEndDate() {
    if (this.endOfSaleTimeoutHandler) clearTimeout(this.endOfSaleTimeoutHandler);
    this.endOfSaleTimeoutHandler = this.saleManager.SetTimeoutAtEndOfSale(async () => {
      this.now = new Date();
      if (this.sale.shouldDoubleCheckBids) await this.FetchHighestBid();
    }, this.sale);
  }

  protected async FetchHighestBid() {
    if (!this.sale || !this.sale.endDate || !this.sale.id || this.sale.type !== SaleTypes.Bid) return;

    // Fetch latest bid
    this.faulty = false;
    this.IsDoubleCheckingBids = true;
    setTimeout(async () => {
      try {
        const { data } = await this.salesService.GetHighestBidWithSaleAndBidder(this.sale.id as number);
        this.highestBidDoubleChecked = data;
      } catch (error: any) {
        this.highestBidDoubleChecked = null;
        this.faulty = true;
      } finally {
        this.IsDoubleCheckingBids = false;
      }
    }, 3_000); // Note: need to add an extra time to let the system time to record new bid in DB
  }
}
