import { BaseThemes } from "@api/models/market/constants/BaseThemes";
import { IMenuItem } from "@pigeon/models/IMenuItem";
import { Emitter } from "mitt";
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator";

export enum MenuItemsPositions {
  Left = "left",
  Right = "right"
}

export enum MenuItemsVerticalPositions {
  Bottom = "bottom",
  Top = "top"
}

export type AppMenuEvents = {
  ["menu-toggle"]: () => void;
  ["menu-open"]: () => void;
  ["menu-close"]: () => void;
};

@Component
export default class AppMenuItems extends Vue {
  @Model("change")
  readonly selected: IMenuItem | null;
  @Prop()
  readonly bus?: Emitter<AppMenuEvents>;
  @Prop({ type: Array })
  readonly items: IMenuItem[];
  @Prop({ default: false })
  readonly open: boolean;
  @Prop({ default: true })
  readonly closeOnClick: boolean;
  @Prop({ default: true })
  readonly closeOnSelect: boolean;
  @Prop({
    default: MenuItemsPositions.Left,
    validator: (value: MenuItemsPositions) => Object.values(MenuItemsPositions).includes(value)
  })
  readonly position: MenuItemsPositions;
  @Prop({
    default: MenuItemsVerticalPositions.Bottom,
    validator: (value: MenuItemsVerticalPositions) => Object.values(MenuItemsVerticalPositions).includes(value)
  })
  readonly positionVertical: MenuItemsVerticalPositions;
  @Prop({ validator: (value: BaseThemes) => Object.values(BaseThemes).includes(value) })
  readonly theme?: BaseThemes;

  isOpened: boolean = false;

  mounted() {
    this.isOpened = this.open;

    if (this.bus) {
      this.bus.on("menu-toggle", this.Toggle);
      this.bus.on("menu-open", this.Open);
      this.bus.on("menu-close", this.Close);
    }
  }

  destroyed() {
    if (this.bus) {
      this.bus.off("menu-toggle", this.Toggle);
      this.bus.off("menu-open", this.Open);
      this.bus.off("menu-close", this.Close);
    }
  }

  public Toggle(): void {
    this.isOpened = !this.isOpened;
  }

  public Open(): void {
    this.isOpened = true;
  }

  public Close(): void {
    this.isOpened = false;
  }

  @Emit("change")
  public SelectItem(selected: IMenuItem) {
    if (selected !== this.selected || !this.selected) {
      return selected;
    }

    if (this.closeOnSelect) {
      this.Close();
    }
  }
}
