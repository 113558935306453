import { nameof } from "@pigeon/extensions/nameof";
import { v4 as UUIDv4 } from "uuid";
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";

export enum ValidationModes {
  Aggressive = "aggressive",
  Passive = "passive",
  Lazy = "lazy",
  Eager = "eager",
  Custom = "custom"
}

// See Validation: https://baianat.github.io/vee-validate/concepts/components.html#component-constructor-options
@Component({
  inheritAttrs: false
})
export default class FormInput extends Vue {
  @Prop({ default: () => `input-${UUIDv4()}` })
  readonly id: string;
  @Prop({ default: true })
  readonly hasFloatingLabel: boolean;
  @Prop({ default: false })
  readonly focused: boolean;
  @Prop()
  readonly label: string;
  @Prop()
  readonly value: string;
  @Prop()
  readonly tooltip?: string;
  @Prop()
  readonly validationRules?: string | Record<string, any>;
  @Prop()
  readonly validationCustomMessages?: Record<string, any>;
  @Prop({ validator: (value: ValidationModes) => Object.values(ValidationModes).includes(value) })
  readonly validationMode?: ValidationModes;
  @Prop()
  readonly validationName?: string;
  @Prop({ default: () => `input_${UUIDv4().toString()}` })
  readonly validationId: string;
  @Prop()
  readonly hasError?: boolean;

  @Ref()
  readonly refInput: HTMLInputElement;

  hasFocus: boolean = false;
  innerValue: any | null = null;

  get HasFocus(): boolean {
    return this.hasFocus;
  }

  set HasFocus(value: boolean) {
    this.hasFocus = value;
  }

  get Attrs(): Record<string, string> {
    return { ...this.$attrs, value: this.value };
  }

  get Listeners(): Record<string, Function | Function[]> {
    return { ...this.$listeners, input: this.OnInput };
  }

  @Watch(nameof<FormInput>("value"), { immediate: true })
  ObserveValueModel() {
    this.innerValue = this.value;
  }

  @Emit("input")
  public OnInput(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  public stepUp(): void {
    if (this.Attrs.type !== "number") return;
    this.refInput.stepUp();
  }

  public stepDown(): void {
    if (this.Attrs.type !== "number") return;
    this.refInput.stepDown();
  }
}
