import Axios, { AxiosInstance } from "axios";

export class BaseApiBingMapsRestService {
  private client: AxiosInstance;

  constructor() {
    this.client = Axios.create({
      baseURL: process.env.VUE_APP_BING_MAPS_API_REST_BASE_URL
    });
  }

  public Client(): AxiosInstance {
    return this.client;
  }
}
