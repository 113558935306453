import { INewslettersService } from "@api/contracts/odata/INewslettersService";
import { INewsletter } from "@api/models/market/INewsletter";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { odataQuery } from "odata-fluent-query";

export class NewslettersService extends BaseODataService implements INewslettersService {
  constructor() {
    super();
  }

  public Insert(newsletter: INewsletter): AxiosPromise<INewsletter> {
    return this.Client().post("Newsletters", newsletter);
  }

  public Delete(email: string): AxiosPromise<void> {
    return this.Client().delete(`Newsletters('${email}')`);
  }

  public FetchAllOverview(): AxiosPromise<IODataCollectionResponse<INewsletter>> {
    const query = odataQuery<INewsletter>().select("email");

    return this.Client().get(`Newsletters?${query.toString()}`);
  }
}
