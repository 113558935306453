import { IErrorManager } from "@pigeon/services/contracts/IErrorManager";
import { ErrorManager } from "@pigeon/services/ErrorManager";
import { ValidationObserver } from "vee-validate";
import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";

// See: https://medium.com/@dobromir_hristov/reacting-to-promises-from-event-listeners-in-vue-js-8959b6d03f52
@Component
export default class FormAsync extends Vue {
  // if notification time is defined to 0 is equivalent to infiny
  @Prop({ default: 0 })
  readonly notificationTime: number;

  @Ref()
  readonly refForm: InstanceType<typeof ValidationObserver>;

  errorManager: IErrorManager = new ErrorManager();
  isProcessing: boolean = false;
  hasDone: boolean = false;

  get Listeners(): Record<string, Function | Function[]> {
    return { ...this.$listeners, submit: this.HandleSubmit };
  }

  mounted() {
    this.errorManager = new ErrorManager(this.refForm);
  }

  private ResetNotification(): void {
    if (!this.notificationTime) return;

    setTimeout(() => {
      this.hasDone = false;
    }, this.notificationTime);
  }

  @Emit("done")
  public Done(response: any): void {
    this.hasDone = true;

    return response;
  }

  @Emit("fail")
  public Fail(error: any): void {
    return error;
  }

  public async IsValid(): Promise<boolean> {
    return this.refForm && (await this.refForm.validate()) ? true : false;
  }

  public async IsInvalid(): Promise<boolean> {
    return this.refForm && !(await this.refForm.validate()) ? true : false;
  }

  public async HandleSubmit($event: any): Promise<void> {
    $event.preventDefault();

    const isFormValid: boolean = await this.refForm.validate();
    if (!isFormValid) return;

    this.isProcessing = true;
    this.hasDone = false;
    this.errorManager.Reset();

    try {
      const submitAsync: ($event: any) => any = (this.$listeners as any)["submit-async"];
      const response = await submitAsync($event);
      this.Done(response);
      if (this.notificationTime) this.ResetNotification();
    } catch (error: any) {
      console.error(error);
      this.errorManager.HandleError(error);
      this.Fail(error);
    } finally {
      this.isProcessing = false;
    }
  }
}
