var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pane",class:{
    't-primary-inverted': _vm.theme === 'primary-inverted',
    't-secondary-inverted': _vm.theme === 'secondary-inverted',
    't-black': _vm.theme === 'black',
    't-dark': _vm.theme === 'dark',
    't-light': _vm.theme === 'light',
    't-gray': _vm.theme === 'gray',
    'bg-info text-white': _vm.theme === 'info',
    'bg-success text-white': _vm.theme === 'success',
    'bg-warning text-white': _vm.theme === 'warning',
    'bg-danger text-white': _vm.theme === 'danger',
  }},[_c('div',{staticClass:"pane_caption primary-pane_caption"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }