import { IPackagesService } from "@api/contracts/odata/IPackagesService";
import { IFancier } from "@api/models/market/IFancier";
import { IPackage } from "@api/models/market/IPackage";
import { IParentAsset } from "@api/models/market/IParentAsset";
import { IPicture } from "@api/models/market/IPicture";
import { IPigeon } from "@api/models/market/IPigeon";
import { IPrize } from "@api/models/market/IPrize";
import { IRankingAsset } from "@api/models/market/IRankingAsset";
import { ISale } from "@api/models/market/ISale";
import { IStrain } from "@api/models/market/IStrain";
import { IUser } from "@api/models/market/IUser";
import { IVideo } from "@api/models/market/IVideo";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, odataQuery } from "odata-fluent-query";

export class PackagesService extends BaseODataService implements IPackagesService {
  constructor() {
    super();
  }

  public FetchAllWithPigeonsByUser(userKey: string): AxiosPromise<IODataCollectionResponse<IPackage>> {
    // prettier-ignore
    const query = odataQuery<IPackage>()
      .filter(p => p.userId.equals(userKey, {ignoreGuid: true}))
      .expand("sales", (s: ExpandObjectQuery<ISale>) => s.select("id", "flagApproved", "status", "startDate", "endDate"))
      .expand("pigeons", p => p
        .select("id", "name", "ring", "sex", "ringArea", "userId")
        .expand("user", (u: ExpandObjectQuery<IUser>) => u.select("firstname", "lastname"))
        .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f.select("id", "name"))
        .expand("strain", (s: ExpandObjectQuery<IStrain>) => s.select("id", "name"))
      );

    return this.Client().get(`Packages?${query.toString()}`);
  }

  public FetchSalePackagePigeon(packageKey: number, pigeonKey: number): AxiosPromise<IPigeon> {
    // prettier-ignore
    const query = odataQuery<IPigeon>()
      .expand("characteristics")
      .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
        .select("id", "name", "createdAt", "createdBy")
      )
      .expand("strain", (s: ExpandObjectQuery<IStrain>) => s
        .select("id", "name")
      )
      .expand("pictures", (p: ExpandObjectQuery<IPicture>) => p
        .select("id", "filename", "type", "url", "urlWebOptimized", "createdAt", "createdBy")
      )
      .expand("videos", (v: ExpandObjectQuery<IVideo>) => v
        .select("id", "filename", "type", "url", "urlWebOptimized")
      )
      .expand("father", (f: ExpandObjectQuery<IPigeon>) => f
        .select("id", "name", "ring", "sex", "userId")
      )
      .expand("mother", (m: ExpandObjectQuery<IPigeon>) => m
        .select("id", "name", "ring", "sex", "userId")
      );

    return this.Client().get(
      `Packages/MarketService.SalePackagePigeon(packageKey=${packageKey},pigeonKey=${pigeonKey})?${query.toString()}`
    );
  }

  public FetchSalePackageReproducer(packageKey: number, parentKey: number): AxiosPromise<IPigeon> {
    // prettier-ignore
    const query = odataQuery<IPigeon>()
      .expand("characteristics")
      .expand("fancier", (f: ExpandObjectQuery<IFancier>) => f
        .select("id", "name")
      )
      .expand("strain", (s: ExpandObjectQuery<IStrain>) => s
        .select("id", "name")
      )
      .expand("pictures", (p: ExpandObjectQuery<IPicture>) => p
        .select("id", "filename", "type", "url", "urlWebOptimized")
      )
      .expand("videos", (v: ExpandObjectQuery<IVideo>) => v
        .select("id", "filename", "type", "url", "urlWebOptimized")
      )
      .expand("prizes", (p: ExpandObjectQuery<IPrize>) => p
        .select("id", "rank", "order", "distance", "participants", "area", "year", "place", "taggedAsAsset")
        .orderBy("order")
      )
      .expand("assetRankings", (ar: ExpandObjectQuery<IRankingAsset>) => ar
        .select("areaType")  
      )
      .expand("assetParents", (ap: ExpandObjectQuery<IParentAsset>) => ap
        .select("parentId")
        .expand("parent", (p: ExpandObjectQuery<IPigeon>) => p
          .select("id", "name", "ring", "sex", "asset", "assetText")
          .expand("prizes", (p: ExpandObjectQuery<IPrize>) => p
            .select("id", "rank", "order", "distance", "participants", "area", "year", "place", "taggedAsAsset")
            .orderBy("order")
          )
          .expand("assetRankings", (ar: ExpandObjectQuery<IRankingAsset>) => ar
            .select("areaType")
          )
        )
      )
      .expand("father", (f: ExpandObjectQuery<IPigeon>) => f
        .select("id", "name", "ring", "sex", "userId")
      )
      .expand("mother", (m: ExpandObjectQuery<IPigeon>) => m
        .select("id", "name", "ring", "sex", "userId")
      );

    return this.Client().get(
      `Packages/MarketService.SalePackageReproducer(packageKey=${packageKey},parentKey=${parentKey})?${query.toString()}`
    );
  }
}
