import { authStore, jwtStore } from "@market/stores/App.store.modules";
import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

export class BaseApiService {
  private client: AxiosInstance;

  constructor() {
    this.client = Axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      timeout: Number(process.env.VUE_APP_API_TIME_OUT),
      withCredentials: true // pass cookies, headers, ...
    });

    this.client.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        if (config.baseURL === process.env.VUE_APP_API_BASE_URL && !config.headers?.Authorization) {
          if (!config.headers) config.headers = {};

          if (authStore.IsAuthenticated) {
            // Refresh token if it is expired
            if (jwtStore.HasTokenExpired()) {
              await jwtStore.RefreshTokens();
            }

            config.headers.Authorization = `Bearer ${jwtStore.accessToken}`;
          }
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    this.client.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<any>) => {
        if (!error.response) return Promise.reject(error);

        const { status, headers } = error.response;
        if (status === 401 && headers && "token-expired" in headers) {
          await jwtStore.RefreshTokens();

          if (authStore.IsAuthenticated) {
            if (!error?.config) error.config = {};
            if (!error?.config?.headers) error.config.headers = {};

            error.config.headers.Authorization = `Bearer ${jwtStore.accessToken}`;
            return Axios.request(error.config);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  public Client(): AxiosInstance {
    return this.client;
  }
}
