import { IFancierProfilesService } from "@api/contracts/odata/IFancierProfilesService";
import { IFancierProfile } from "@api/models/market/IFancierProfile";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { odataQuery } from "odata-fluent-query";

export class FancierProfilesService extends BaseODataService implements IFancierProfilesService {
  constructor() {
    super();
  }

  public Insert(fancierProfile: IFancierProfile): AxiosPromise<IFancierProfile> {
    return this.Client().post("FancierProfiles", fancierProfile);
  }

  public Update(fancierProfileKey: number, fancierProfile: IFancierProfile): AxiosPromise<void> {
    return this.Client().put(`FancierProfiles(${fancierProfileKey})`, fancierProfile);
  }

  public Patch(fancierProfileKey: number, fancierProfile: Partial<IFancierProfile>): AxiosPromise<void> {
    return this.Client().patch(`FancierProfiles(${fancierProfileKey})`, fancierProfile);
  }

  public Delete(fancierProfileKey: number): AxiosPromise<void> {
    return this.Client().delete(`FancierProfiles(${fancierProfileKey})`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IFancierProfile>> {
    const query = odataQuery<IFancierProfile>().orderBy("lastname");

    return this.Client().get(`FancierProfiles?${query.toString()}`);
  }

  public FetchAllWith(): AxiosPromise<IODataCollectionResponse<IFancierProfile>> {
    const query = odataQuery<IFancierProfile>()
      .orderBy("lastname")
      .expand("translations")
      .expand("prizes")
      .expand("ressources");

    return this.Client().get(`FancierProfiles?${query.toString()}`);
  }

  public Fetch(fancierProfileKey: number): AxiosPromise<IFancierProfile> {
    return this.Client().get(`FancierProfiles(${fancierProfileKey})`);
  }

  public FetchSheetByFancierProfile(fancierProfileKey: number): AxiosPromise<IFancierProfile> {
    const query = odataQuery<IFancierProfile>()
      .expand("picture")
      .expand("translations")
      .expand("prizes")
      .expand("ressources")
      .expand("ratings")
      .expand("colony");

    return this.Client().get(`FancierProfiles(${fancierProfileKey})?${query.toString()}`);
  }

  public FetchAllSheetsByFancier(fancierKey: number): AxiosPromise<IODataCollectionResponse<IFancierProfile>> {
    const query = odataQuery<IFancierProfile>()
      .expand("picture")
      .expand("translations")
      .expand("prizes")
      .expand("ressources")
      .expand("ratings")
      .expand("colony")
      .filter((fp) => fp.fancierId.equals(fancierKey));

    return this.Client().get(`FancierProfiles?${query.toString()}`);
  }

  public FetchOwnerId(fancierProfileKey: number): AxiosPromise<IFancierProfile> {
    return this.Client().get(`FancierProfiles(${fancierProfileKey})/OwnerId`);
  }

  public FetchOwnerIdValue(fancierProfileKey: number): AxiosPromise<string> {
    return this.Client().get(`FancierProfiles(${fancierProfileKey})/OwnerId/$value`);
  }
}
