import { AppRoutes } from "@market/routers/App.routes";
import { Component, Ref, Vue } from "vue-property-decorator";
import "@pwabuilder/pwainstall";

@Component
export default class TheFooter extends Vue {
  AppRoutes = AppRoutes;

  //#region PWAInstall
  pwaInstall: any;

  @Ref()
  readonly refPwaInstallButton: HTMLElement;
  //#endregion

  get BuildVersion(): string {
    return process.env.VUE_APP_BUILD_VERSION as string;
  }

  mounted() {
    this.pwaInstall = document.querySelector("pwa-install");

    this.ShowHidePwaInstall();
    window.addEventListener("beforeinstallprompt", this.ShowHidePwaInstall);
    window.addEventListener("appinstalled", this.ShowHidePwaInstall);
  }

  //#region PWAInstall
  // See: https://www.pwabuilder.com/feature/Install%20your%20PWA
  public ShowHidePwaInstall(): void {
    if (!this.pwaInstall || !this.refPwaInstallButton) return;

    const isInstalled: boolean = this.pwaInstall.getInstalledStatus();
    this.refPwaInstallButton.style.display = isInstalled ? "none" : "block";
  }

  // See: https://github.com/pwa-builder/PWABuilder/blob/master/components/InstallButton.vue
  public OpenInstall(): void {
    if (!this.pwaInstall) return;

    this.pwaInstall.openPrompt();
  }
  //#endregion
}
