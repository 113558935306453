export enum RelationshipTypes {
  Father = "Father",
  Mother = "Mother",
  GrandFather = "Grandfather",
  GrandMother = "Grandmother",
  PaternalGrandFather = "Grandfather",
  PaternalGrandMother = "Grandmother",
  MaternalGrandFather = "Grandfather",
  MaternalGrandMother = "Grandmother",
  GreatGrandFather = "Great Grandfather",
  GreatGrandMother = "Great Grandmother",
  Unknown = "Unknown"
}
