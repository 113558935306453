import { IVendorsService } from "@api/contracts/odata/IVendorsService";
import { ICorporation } from "@api/models/market/ICorporation";
import { IVendor } from "@api/models/market/IVendor";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { IODataValueResponse } from "@api/models/shared/IODataValueResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, odataQuery } from "odata-fluent-query";

export class VendorsService extends BaseODataService implements IVendorsService {
  constructor() {
    super();
  }

  public Put(vendorKey: string, vendor: IVendor): AxiosPromise<void> {
    return this.Client().put(`Vendors('${vendorKey}')`, vendor);
  }

  public Patch(vendorKey: string, vendor: Partial<IVendor>): AxiosPromise<void> {
    return this.Client().patch(`Vendors('${vendorKey}')`, vendor);
  }

  public Fetch(vendorKey: string): AxiosPromise<IVendor> {
    if (!authStore.IsVendor) return Promise.reject(new Error("unauthorized"));

    return this.Client().get(`Vendors('${vendorKey}')`);
  }

  public FetchWithUser(vendorKey: string): AxiosPromise<IVendor> {
    if (!authStore.IsVendor) return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<IVendor>().expand("user", (u) => u.select("firstname", "lastname", "email"));
    return this.Client().get(`Vendors('${vendorKey}')?${query.toString()}`);
  }

  public FetchWithPictures(vendorKey: string): AxiosPromise<IVendor> {
    if (!authStore.IsVendor) return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<IVendor>().expand("pictures");

    return this.Client().get(`Vendors('${vendorKey}')?${query.toString()}`);
  }

  public FetchAllWithUserIdentities(): AxiosPromise<IODataCollectionResponse<IVendor>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    const query = odataQuery<IVendor>()
      .expand("user", (u) => u.select("id", "firstname", "lastname", "stripeConnectedAccountId"))
      .orderBy((u) => u.user.firstname);
    return this.Client().get(`Vendors?${query.toString()}`);
  }

  public FetchAllOverview(): AxiosPromise<IODataCollectionResponse<IVendor>> {
    // prettier-ignore
    const query = odataQuery<IVendor>()
      .select("id", "vendorAlias", "shopId", "countryCode", "flagReferralProgram")
      .expand("user", u => u
        .select("firstname", "lastname", "email", "phoneNumber", "preferredLanguage", "stripeConnectedAccountId")
        .expand("sales", s => s
          .select("id")
        )
        .expand("corporation", (c: ExpandObjectQuery<ICorporation>) => c
          .select("name")
        )
      );

    return this.Client().get(`Vendors?${query.toString()}`);
  }

  public GetVendorByShop(shopId: string): AxiosPromise<IVendor> {
    const query = odataQuery<IVendor>().expand("pictures");

    return this.Client().get(`Vendors/MarketService.GetVendorByShop(shop='${shopId}')?${query.toString()}`);
  }

  public IsCashPaymentAllowed(vendorKey: string): AxiosPromise<IODataValueResponse<boolean>> {
    return this.Client().get(`Vendors('${vendorKey}')/MarketService.IsCashPaymentAllowed`);
  }

  public ActivateReferralProgram(vendorKey: string) {
    return this.Client().post(`Vendors('${vendorKey}')/MarketService.ActivateReferralProgram`);
  }

  public DesactivateReferralProgram(vendorKey: string) {
    return this.Client().post(`Vendors('${vendorKey}')/MarketService.DesactivateReferralProgram`);
  }
}
