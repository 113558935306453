import { ISale } from "@api/models/market/ISale";
import { store } from "@market/stores";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { ISaleStore } from "@market/stores/contracts/ISaleStore";
import { IFilePicture } from "@pigeon/models/IFilePicture";
import { IFileVideo } from "@pigeon/models/IFileVideo";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "sale" = "sale";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class SaleStore extends VuexModule implements ISaleStore {
  sale: ISale | null = null;
  filePictures: IFilePicture[] = [];
  fileVideos: IFileVideo[] = [];

  //#region sale
  @Mutation
  UPDATE_SALE(sale: ISale | null): void {
    // set userId
    if (sale && sale.pigeon && !sale.userId && userStore.user) {
      sale.userId = userStore.user.id;
      sale.pigeon.userId = userStore.user.id;

      // A administrator can insert a product for someone (= acts as intermediary)
      if (authStore.IsAdministrator && userStore.intermediary) {
        sale.userId = userStore.intermediary.id;
        sale.pigeon.userId = userStore.intermediary.id;
      }
    }

    this.sale = sale;
  }

  @Action
  UpdateSale(sale: ISale | null): void {
    if (sale && sale.pigeon && !sale.pigeon.ringNumber && sale.pigeon.ring) {
      sale.pigeon.ringNumber = sale.pigeon.ring.substring(sale.pigeon.ring.indexOf("-") + 1);
    }

    this.UPDATE_SALE(sale);
  }
  //#endregion

  //#region fileMedia
  @Mutation
  UPDATE_FILE_PICTURES(files: IFilePicture[]): void {
    this.filePictures = files;
  }

  @Mutation
  UPDATE_FILE_VIDEOS(files: IFileVideo[]): void {
    this.fileVideos = files;
  }

  @Mutation
  PUSH_FILE_PICTURE(file: IFilePicture): void {
    this.filePictures.push(file);
  }

  @Mutation
  PUSH_FILE_VIDEO(file: IFileVideo): void {
    this.fileVideos.push(file);
  }

  @Action
  UpdateFilePictures(files: IFilePicture[]): void {
    this.UPDATE_FILE_PICTURES(files);
  }

  @Action
  UpdateFileVideos(files: IFileVideo[]): void {
    this.UPDATE_FILE_VIDEOS(files);
  }

  @Action
  AddFilePicture(file: IFilePicture): void {
    const existingIndexFile = this.filePictures.findIndex(
      (fp) =>
        fp.pictureType === file.pictureType &&
        ((file.pigeonKey && fp.pigeonKey === file.pigeonKey) ||
          (file.pigeonKeyClient && fp.pigeonKeyClient === file.pigeonKeyClient))
    );

    if (existingIndexFile > -1) {
      // replace
      this.filePictures[existingIndexFile] = file;
    } else {
      // add
      this.PUSH_FILE_PICTURE(file);
    }
  }

  @Action
  AddFileVideo(file: IFileVideo): void {
    const existingIndexFile = this.fileVideos.findIndex(
      (fp) =>
        fp.videoType === file.videoType &&
        ((file.pigeonKey && fp.pigeonKey === file.pigeonKey) ||
          (file.pigeonKeyClient && fp.pigeonKeyClient === file.pigeonKeyClient))
    );

    if (existingIndexFile > -1) {
      // replace
      this.fileVideos[existingIndexFile] = file;
    } else {
      // add
      this.PUSH_FILE_VIDEO(file);
    }
  }
  //#endregion

  @Action
  Reset(): void {
    this.UpdateSale(null);
    this.UpdateFilePictures([]);
    this.UpdateFileVideos([]);
  }
}
