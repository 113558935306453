import { IDialog } from "@market/models/IDialog";
import { store } from "@market/stores";
import { dialogsStore } from "@market/stores/App.store.modules";
import { IDialogStore } from "@market/stores/contracts/IDialogStore";
import DialogStore from "@market/stores/modules/dialogStore";
import { Action, Module } from "vuex-module-decorators";

export const STORE_TOKEN: "signUpVendorDialog" = "signUpVendorDialog";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class SignUpVendorDialogStore extends DialogStore implements IDialogStore {
  dialog: IDialog = {
    isOpen: false
  };

  @Action
  Open(): void {
    dialogsStore.CloseOtherDialogs(STORE_TOKEN);
    super.Open();
  }

  @Action
  Close(): void {
    super.Close();
  }

  @Action
  Toggle(): void {
    dialogsStore.CloseOtherDialogs(STORE_TOKEN);
    super.Toggle();
  }
}
