import { IDialog } from "@market/models/IDialog";
import { IDialogStore } from "@market/stores/contracts/IDialogStore";
import { Mutation, VuexModule } from "vuex-module-decorators";

export default class DialogStore extends VuexModule implements IDialogStore {
  dialog: IDialog;

  @Mutation
  OPEN(): void {
    this.dialog.isOpen = true;
  }

  @Mutation
  CLOSE(): void {
    this.dialog.isOpen = false;
  }

  @Mutation
  TOGGLE(): void {
    this.dialog.isOpen = !this.dialog.isOpen;
  }

  Open(): void {
    if (this.dialog.isOpen) return;

    this.OPEN();
  }

  Close(): void {
    if (!this.dialog.isOpen) return;

    this.CLOSE();
  }

  Toggle(): void {
    this.TOGGLE();
  }
}
