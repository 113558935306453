import { AppRoutes } from "@market/routers/App.routes";
import { signInDialogStore } from "@market/stores/App.store.modules";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppErrorNotFound extends Vue {
  public OpenSignInDialog() {
    if (!this.$auth.IsAuthenticated) signInDialogStore.Open();
  }

  public GoToCatalogPage() {
    this.$router.push({ name: AppRoutes.Buy });
  }
}
