import { ICheckoutSessionsService } from "@api/contracts/odata/ICheckoutSessionsService";
import { ICheckoutSession } from "@api/models/market/ICheckoutSession";
import { ICheckoutSessionCustomer } from "@api/models/market/ICheckoutSessionCustomer";
import { ICheckoutSessionPayment } from "@api/models/market/ICheckoutSessionPayment";
import { ICheckoutSessionShipping } from "@api/models/market/ICheckoutSessionShipping";
import { IGuest } from "@api/models/market/IGuest";
import { IUser } from "@api/models/market/IUser";
import { IUserData } from "@api/models/market/IUserData";
import { CheckoutStepNames } from "@market/enumerations/CheckoutStepNames";
import { ICartItem } from "@market/models/ICartItem";
import { store } from "@market/stores";
import { authStore, cartStore, userStore } from "@market/stores/App.store.modules";
import { ICheckoutStore } from "@market/stores/contracts/ICheckoutStore";
import { HasFunctionalityCookieConsent } from "@pigeon/components/layout/TheBannerCookies";
import { ICheckoutManager } from "@pigeon/services/contracts/ICheckoutManager";
import { uiBus } from "@pigeon/Ui.bus";
import { cid, container, Inject } from "inversify-props";
import { cloneDeep, debounce } from "lodash-es";
import { v4 as UUIDv4 } from "uuid";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "checkout" = "checkout";

@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class CheckoutStore extends VuexModule implements ICheckoutStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private checkoutSessionsService: ICheckoutSessionsService;
  // @Inject()
  // private checkoutManager: ICheckoutManager;

  static readonly STORE_KEY: string = "checkout";
  session: ICheckoutSession = {
    checkoutDate: null,
    checkoutSessionId: null,
    currentStepName: null,
    customer: null,
    shipping: null,
    payment: null,
    cartItems: [],
    cartId: undefined
  };

  @Mutation
  private INIT_CHECKOUT() {
    this.session = {
      checkoutDate: new Date().toISOString(),
      checkoutSessionId: UUIDv4(),
      currentStepName: "Cart",
      customerId: undefined,
      customer: null,
      shippingId: undefined,
      shipping: null,
      paymentId: undefined,
      payment: null,
      cartId: cartStore.cartId,
      cartItems: cartStore.items ?? [],
      userId: authStore.IsAuthenticated ? userStore?.user?.id : undefined,
      guestId: undefined
    };
  }

  @Mutation
  public INIT_CUSTOMER() {
    const user = userStore.user;

    // prettier-ignore
    this.session.customer = authStore.IsMember && user
      ? { firstname: user.firstname, lastname: user.lastname, countryCode: user.countryCode, email: user.email, phoneNumber: user.phoneNumber, corporation: user.corporation, userId: this.session.userId }
      : { firstname: undefined, lastname: undefined, countryCode: undefined, email: "", phoneNumber: undefined, corporation: undefined, guestId: this.session.guestId };
  }

  @Mutation
  public INIT_SHIPPING(useDeliveryName = false) {
    const user = userStore.user;
    const GetDeliveryName = (useDeliveryName: boolean, user: IUserData | null) =>
      useDeliveryName && user ? `${user.firstname} ${user.lastname}` : undefined;

    // prettier-ignore
    this.session.shipping = authStore.IsMember && user
      ? { method: null, deliveryName: GetDeliveryName(useDeliveryName, user), deliveryAddress: userStore.DefaultDeliveryAddress, deliveryServices: [], userId: this.session.userId }
      : { method: null, deliveryName: undefined, deliveryAddress: null, deliveryServices: [], guestId: this.session.guestId };
  }

  @Mutation
  public INIT_BILLING() {
    // prettier-ignore
    this.session.payment = authStore.IsMember && userStore.DefaultAddress
      ? { method: null, address: userStore.DefaultBillingAddress, userId: this.session.userId }
      : { method: null, address: null, guestId: this.session.guestId };
  }

  @Mutation
  private SET_CHECKOUT_SESSION_ID(uniqueId: string) {
    this.session.checkoutSessionId = uniqueId;
  }

  @Mutation
  RESTORE(session: ICheckoutSession): void {
    if (!session) return;

    this.session = session;
  }

  @Mutation
  UPDATE_STEP(stepName: string): void {
    this.session.currentStepName = stepName;
  }

  @Mutation
  RESET(): void {
    this.session = {
      checkoutDate: null,
      checkoutSessionId: null,
      currentStepName: null,
      customerId: undefined,
      customer: null,
      shippingId: undefined,
      shipping: null,
      paymentId: undefined,
      payment: null,
      cartId: null,
      cartItems: [],
      userId: undefined,
      guestId: undefined
    };
  }

  @Mutation
  UPDATE_ITEMS(items: ICartItem[]): void {
    if (!items) return;

    this.session.cartItems = items;
  }

  @Mutation
  UPDATE_CUSTOMER(customer: ICheckoutSessionCustomer): void {
    if (!customer) return;

    this.session.customer = customer;
  }

  @Mutation
  UPDATE_USER(user: IUserData) {
    this.session.userId = user.id;
    this.session.user = user as IUser;

    if (this.session.customer) this.session.customer.userId = user.id;
    if (this.session.shipping) this.session.shipping.userId = user.id;
    if (this.session.payment) this.session.payment.userId = user.id;
  }

  @Mutation
  UPDATE_GUEST(guest: IGuest) {
    this.session.guestId = guest.id;
    this.session.guest = guest;

    if (this.session.customer) this.session.customer.guestId = guest.id;
    if (this.session.shipping) this.session.shipping.guestId = guest.id;
    if (this.session.payment) this.session.payment.guestId = guest.id;
  }

  @Mutation
  UPDATE_SHIPPING(shipping: ICheckoutSessionShipping): void {
    if (!shipping) return;

    this.session.shipping = shipping;
  }

  @Mutation
  UPDATE_PAYMENT(payment: ICheckoutSessionPayment): void {
    if (!payment) return;

    this.session.payment = payment;
  }

  @Action
  Restore() {
    // Check if the store exists in storage
    const checkoutStored = localStorage.getItem(CheckoutStore.STORE_KEY);
    if (!checkoutStored) {
      this.Reset();
      return;
    }

    try {
      const checkoutSession: ICheckoutSession = JSON.parse(checkoutStored);
      this.RESTORE(checkoutSession);
      this.InitCheckoutSessionData();
    } catch (error: any) {
      console.error(error);
      // Potentially corrupted data
      // Clear stored data
      this.ClearStorage();
    }
  }

  @Action
  StoreState(): void {
    // Note: Use debounce here for performance reason
    debounce(() => {
      if (!HasFunctionalityCookieConsent()) return;

      localStorage.setItem(CheckoutStore.STORE_KEY, JSON.stringify(this.session));
    }, 2_000)();
  }

  @Action
  ClearStorage(): void {
    localStorage.removeItem(CheckoutStore.STORE_KEY);
  }

  @Action
  Reset(): void {
    this.RESET();
    this.ClearStorage();
  }

  @Action
  private InitCheckoutSessionData(): void {
    if (!cartStore.items?.length) return;

    if (!this.session.customer?.email) {
      this.INIT_CUSTOMER();
    }

    if (!this.session.shipping?.deliveryAddress) {
      this.INIT_SHIPPING();
    }

    if (!this.session.payment?.address && !this.session.payment?.stripePaymentMethod) {
      this.INIT_BILLING();
    }
  }

  @Action
  async Init(): Promise<void> {
    if (!cartStore.items?.length) return;

    if (!this.session || !this.session.checkoutSessionId) {
      this.INIT_CHECKOUT(); // Note: reset checkout session each time is triggered
      this.InitCheckoutSessionData();
      this.UPDATE_STEP(CheckoutStepNames.Cart);
      await this.InsertCheckoutSession();

      if (!authStore.IsAuthenticated) {
        uiBus.on("login", () => this.InitCheckoutSessionData());
      }
    } else {
      this.UPDATE_ITEMS(cartStore.items);
    }
  }

  @Action
  UpdateStep(stepName: string): void {
    if (!stepName) return;

    this.UPDATE_STEP(stepName);
  }

  @Action
  async UpdateItems(items: ICartItem[], shouldStoreState = true): Promise<void> {
    this.UPDATE_ITEMS(items);
    if (shouldStoreState) this.StoreState();
  }

  @Action
  UpdateCustomer(customer: ICheckoutSessionCustomer, shouldStoreState = true): void {
    if (authStore.IsAuthenticated && userStore.user) {
      this.UPDATE_USER(userStore.user);
    }

    this.UPDATE_CUSTOMER(customer);
    if (shouldStoreState) this.StoreState();
  }

  @Action
  UpdateShipping(shipping: ICheckoutSessionShipping, shouldStoreState = true): void {
    this.UPDATE_SHIPPING(shipping);
    if (shouldStoreState) this.StoreState();
  }

  @Action
  UpdatePayment(payment: ICheckoutSessionPayment, shouldStoreState = true): void {
    this.UPDATE_PAYMENT(payment);
    if (shouldStoreState) this.StoreState();
  }

  @Action
  RecycleSession() {
    const uniqueid: string = UUIDv4();
    this.SET_CHECKOUT_SESSION_ID(uniqueid);
    this.StoreState();
  }

  @Action
  async InsertCheckoutSession(): Promise<void> {
    const checkoutSessionsService = container.get<ICheckoutSessionsService>(cid.CheckoutSessionsService);
    const newSession = cloneDeep(this.session);
    this.CleanSessionModel(newSession);

    try {
      await checkoutSessionsService.Insert(newSession);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async UpdateCheckoutSession(): Promise<void> {
    if (!this.session?.checkoutSessionId) return Promise.reject("No checkout session id");

    const checkoutSessionsService = container.get<ICheckoutSessionsService>(cid.CheckoutSessionsService);
    const modifiedSession = cloneDeep(this.session);
    this.CleanSessionModel(modifiedSession);

    try {
      await checkoutSessionsService.Update(modifiedSession.checkoutSessionId as string, modifiedSession);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  private CleanSessionModel(session: ICheckoutSession): void {
    const checkoutManager = container.get<ICheckoutManager>(cid.CheckoutManager);
    delete session.cartItems;

    if (session.customer) {
      if (!session.customer.email) session.customer = null;
    }
    if (session.shipping) {
      if (!session.shipping?.method) session.shipping = null;
      if (session.shipping?.deliveryAddress && !checkoutManager.IsValidAddress(session.shipping.deliveryAddress))
        session.shipping.deliveryAddress = null;
    }
    if (session.payment) {
      if (!session.payment?.method) session.payment = null;
      if (session.payment?.stripePaymentMethod) session.payment.stripePaymentMethod = null;
      if (session.payment?.address && !checkoutManager.IsValidAddress(session.payment.address))
        session.payment.address = null;
    }

    if (session.user) delete session.user;
    if (session.guest) delete session.guest;
  }
}
