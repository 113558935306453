import { Channels } from "@api/enumerations/Channels";
import { IAccountEmailVerification } from "@api/models/auth/IAccountEmailVerification";
import { IAccountEmailVerificationToken } from "@api/models/auth/IAccountEmailVerificationToken";
import { IAccountProfileWithTokens } from "@api/models/auth/IAccountProfile";
import { IAuthSession } from "@api/models/auth/IAuthSession";
import { IContributorUpgradeRegistration } from "@api/models/auth/IContributorUpgradeRegistration";
import { IVendorUpgradeRegistration } from "@api/models/auth/IVendorUpgradeRegistration";
import { IUserData } from "@api/models/market/IUserData";
import { authStore, jwtStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { BaseAuthServiceUser } from "./base/BaseAuthService.user";

export class AccountsServiceUser extends BaseAuthServiceUser {
  //#region Account
  UpgradeAsVendor(registration: IVendorUpgradeRegistration): AxiosPromise<IAuthSession> {
    if (!authStore.IsAuthenticated && !jwtStore.accessToken) {
      return Promise.reject();
    }

    return this.Client().post("Accounts/Vendor/Upgrade", registration);
  }

  UpgradeAsContributor(registration: IContributorUpgradeRegistration): AxiosPromise<IAuthSession> {
    if (!authStore.IsAuthenticated && !jwtStore.accessToken) {
      return Promise.reject();
    }

    return this.Client().post("Accounts/Contributor/Upgrade", registration);
  }

  PatchUser(userData: IUserData): AxiosPromise<void> {
    if (!authStore.IsAuthenticated && !jwtStore.accessToken) {
      return Promise.reject();
    }

    return this.Client().patch(`Accounts/User`, userData);
  }

  FetchUser(userKey: string): AxiosPromise<IAccountProfileWithTokens> {
    if (!authStore.IsAdministrator) {
      return Promise.reject("unauthorized");
    }

    return this.Client().get<IAccountProfileWithTokens>(`Accounts/Profile/${userKey}`);
  }

  //#region Verification
  SendNewEmailVerification(
    channel: Channels,
    verification: IAccountEmailVerification,
    locale: string
  ): AxiosPromise<void> {
    return this.Client().post(`Accounts/SendNewEmailVerification/${channel}`, verification);
  }

  VerifyEmail(confirmation: IAccountEmailVerificationToken): AxiosPromise<boolean> {
    return this.Client().post("Accounts/VerifyEmail", confirmation);
  }
  //#endregion
  //#endregion

  //#region Auction
  AgreeToAuctionConditions(agree: boolean): AxiosPromise<void> {
    if (!agree) {
      return Promise.reject();
    }

    return this.Client().post(`Accounts/User/Policy/agree-to-auction-conditions`, null);
  }
  //#endregion
}
