import { IBreadcrumbItem } from "@pigeon/models/IBreadcrumbItem";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavBreadcrumb extends Vue {
  items: IBreadcrumbItem[] = [];

  created() {
    if (this.$route?.meta?.breadcrumb) this.items = this.$route.meta.breadcrumb as IBreadcrumbItem[];
  }
}
