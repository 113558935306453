export enum PictureTypes {
  Eye = "Eye",
  Fancier = "Fcr",
  Pedigree = "Ped",
  PedigreePdf = "Pef",
  Pigeon = "Pgn",
  PigeonAndEye = "PnE",
  Wing = "Wng",
  Package = "Pck",
  Auction = "Act",
  Shop = "Shp",
  Loft = "Lft",
  Network = "Ntw",
  Other = "Oth",
  PropertyTicket = "Tck"
}
