import { Component, Prop, Vue } from "vue-property-decorator";

export enum BarItemTypes {
  Primary = "primary",
  Secondary = "secondary"
}

@Component
export default class BlocBarItem extends Vue {
  @Prop({
    default: BarItemTypes.Secondary,
    validator: (value: BarItemTypes) => Object.values(BarItemTypes).includes(value)
  })
  readonly type: BarItemTypes;
  @Prop()
  readonly icon?: string | string[];
}
