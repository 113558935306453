const standardNumberFormats = {
  currency: {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0
  },
  percent: {
    style: "percent",
    minimumFractionDigits: 0
  }
};

export const numberFormats = {
  en: standardNumberFormats,
  fr: standardNumberFormats,
  nl: standardNumberFormats,
  pl: standardNumberFormats,
  pt: standardNumberFormats
};
