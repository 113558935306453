var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isOpened)?_c('div',{staticClass:"dialog"},[(_vm.title || _vm.secondaryTitle)?_c('div',{staticClass:"dialog_header"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),(_vm.secondaryTitle)?_c('span',{staticClass:"secondary-title fg-primary"},[_vm._v(_vm._s(_vm.secondaryTitle))]):_vm._e(),_c('span',{staticClass:"icon"},[(_vm.headerIcon)?_c('Icon',{attrs:{"icon":_vm.headerIcon}}):_vm._e()],1),_c('button',{staticClass:"btn btn-close",attrs:{"type":"button","title":_vm.$t('close_tooltip'),"data-cy":"dialog-close"},on:{"click":function($event){return _vm.Close()}}},[_c('Icon',{attrs:{"icon":"times"}})],1)]):_vm._e(),_c('div',{staticClass:"dialog_body"},[_vm._t("default")],2),(_vm.actionLabel)?_c('div',{staticClass:"dialog_footer"},[(_vm.actionLinkTo)?_c('I18nRouterLink',{staticClass:"btn dialog-btn",class:{
        'btn-primary': _vm.actionType == _vm.ActionTypes.PrimaryAction,
        'btn-secondary': _vm.actionType == _vm.ActionTypes.CallToAction
      },attrs:{"to":_vm.actionLinkTo,"tag":"button"},nativeOn:{"click":function($event){return _vm.Action()}}},[_vm._v(_vm._s(_vm.actionLabel))]):_vm._e(),(!_vm.actionLinkTo)?_c('button',{staticClass:"btn dialog-btn",class:{
        'btn-primary': _vm.actionType == _vm.ActionTypes.PrimaryAction,
        'btn-secondary': _vm.actionType == _vm.ActionTypes.CallToAction
      },attrs:{"type":"button","disabled":_vm.actionLoading},on:{"click":function($event){return _vm.Action()}}},[(_vm.actionLoading !== true)?_c('span',[_vm._v(_vm._s(_vm.actionLabel))]):_vm._e(),(_vm.actionLoading)?_c('AppLoader',{staticClass:"justify-content-center",attrs:{"inline":true,"color":"#FFF"}}):_vm._e()],1):_vm._e()],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }