import { render, staticRenderFns } from "./TheNavBarDialogs.vue?vue&type=template&id=c8b14b00&scoped=true&"
import script from "./TheNavBarDialogs.ts?vue&type=script&lang=ts&"
export * from "./TheNavBarDialogs.ts?vue&type=script&lang=ts&"
import style0 from "./TheNavBarDialogs.vue?vue&type=style&index=0&id=c8b14b00&prod&lang=scss&scoped=true&"
import style1 from "./TheNavBarDialogs.vue?vue&type=style&index=1&id=c8b14b00&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8b14b00",
  null
  
)

/* custom blocks */
import block0 from "./TheNavBarDialogs.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports