
import { Component, Vue } from "vue-property-decorator";

@Component({
  metaInfo() {
    const baseUrl = process.env.VUE_APP_PIGEON_MARKET;
    const { path } = this.$route;
    const pathWithSlash = path.endsWith("/") ? path : `${path}/`; // append trailing slash
    const canonicalUrl = baseUrl + pathWithSlash;

    return {
      title: this.$t("meta.title") as string,
      titleTemplate: this.$t("meta.title_template") as string,
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "keyword",
          content: this.$t("meta.keywords") as string
        },
        {
          name: "description",
          content: this.$t("meta.description") as string,
          vmid: "description"
        },
        { name: "robots", content: "index,follow", vmid: "robots" } // default value
      ],
      link: [{ rel: "canonical", href: canonicalUrl }]
    };
  }
})
export default class App extends Vue { }
