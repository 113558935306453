import { ShippingMethodTypes } from "@api/models/market/constants/ShippingMethodTypes";
import { IShippingService } from "@api/models/market/IShippingService";
import { IShippingManager } from "@pigeon/services/contracts/IShippingManager";

export class ShippingManager implements IShippingManager {
  public CanDeliver(shippingServices: IShippingService[]): boolean {
    return shippingServices.length > 0;
  }

  public GetShippingIcon(shippingMethod: ShippingMethodTypes): string | undefined {
    if (!shippingMethod) return;

    switch (shippingMethod) {
      case ShippingMethodTypes.Deliver:
        return "truck";

      case ShippingMethodTypes.PickUp:
        return "box";

      case ShippingMethodTypes.SelfArrangedDeliver:
        return "dolly";

      default:
        console.error("unsupported shipping method for icon");
        break;
    }
  }
}
