export const PLATFORM_VAT_RATE: number = 0; // for the moment PigeonMarket is not subject to VAT (change value to 0.21 when PigeonMarket should pay VAT)
export const SALE_FEES_RATE: number = 0.25;
export const SALE_PRICE_MAXIMUM: number = 100_000;
export const SALE_PRICE_MINIMUM: number = 50;
export const VAT_RATE_BELGIUM: number = 0.06;
export const REFERRAL_FEES_RATE: number = 0.025;
export const BASE_DEPOSIT_RATE: number = 0.5;
export const VIDEO_MAX_SIZE: number = 100; // MB
export const VIDEO_CHUNK_SIZE: number = 5; // MB
export const VIDEO_MIN_DURATION: number = 5; // seconds
export const VIDEO_MAX_DURATION: number = 45; // seconds
export const AUCTION_EXTRA_TIME_MINUTES: number = 3; // in minutes
export const PAYMENT_MAX_DELAY: number = 14; //days
export const SALE_SCHEDULE_MIN_START_HOUR = 6;
export const SALE_SCHEDULE_MAX_START_HOUR = 23;
export const SALE_SCHEDULE_MIN_END_HOUR = 6;
export const SALE_SCHEDULE_MAX_END_HOUR = 23;
export const AUCTION_DEFAULT_START_PRICE = 100; // 100 €
export const AUCTION_MAX_DURATION_IN_DAYS = 15;
export const AUCTION_MIN_DURATION_IN_DAYS = 5;
export const AUCTION_MAX_SCHEDULE_IN_DAYS = 90;
export const AUCTION_OFFER_LIST_LENGTH = 20;
export const AUCTION_TIME_CLOSE_END_OF_SALE = 2 * 60 * 1000; // in milliseconds. Note: used to check potential bids desynchronisation
export const PIGEON_MAX_DEAD_DELAY = 48; // hours
export const PIGEON_MAX_INFERTILE_DELAY = 2; // months
export const TRANSFER_DELAY_HOURS = 72; // hours
export const SHIPPING_DELIVERY_OPTION_SUPPORTED = false;
export const NOTIFICATION_LIMIT_TO_DISPLAY: number = 5;
export const NOTIFICATION_DEFAULT_TIMEOUT: number = 8 * 1000; //milliseconds

export const PLATFORM_ID: string = "PMSF000";
export const PLATFORM_CREATED_DATE: Date = new Date(2019, 6, 27);
export const BANK_IBAN: string = "BE30068901157511";
export const BANK_IBAN_FORMATTED: string = "BE30 0689 0115 7511";
export const BANK_BIC: string = "GKCCBEBB";
export const BANK_BIC_FORMATTED: string = "GKCCBEBB";
export const BANK_NAME: string = "Belfius";
export const BUSINESS_NUMBER: string = "0564921268";
export const BUSINESS_NUMBER_FORMATTED: string = "0564.921.268";
export const BUSINESS_VAT_FORMATTED: string = "BE" + BUSINESS_NUMBER_FORMATTED;
export const BANK_ACCOUNT_HOLDER_NAME: string = "Raphael Dhainaut";
export const BANK_ACCOUNT_HOLDER_ADDRESS: string = "Chaussée Saint-Pierre 316 1040 Bruxelles";

// See: https://www.europeanpaymentscouncil.eu/document-library/other/epc-list-sepa-scheme-countries
// See: https://www.ecb.europa.eu/paym/integration/retail/sepa/iban/html/index.en.html
export const SEPA_COUNTRIES: string[] = [
  "FI",
  "AD",
  "AT",
  "PT",
  "BE",
  "BG",
  "ES",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "GF",
  "DE",
  "GI",
  "GR",
  "GP",
  "GG",
  "HU",
  "IS",
  "IE",
  "IM",
  "IT",
  "JE",
  "LV",
  "LI",
  "LT",
  "LU",
  "PT",
  "MT",
  "MQ",
  "YT",
  "MC",
  "NL",
  "NO",
  "PL",
  "PT",
  "RE",
  "RO",
  "BL",
  "MF",
  "PM",
  "SM",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
  "GB",
  "VA"
];
