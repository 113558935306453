import { store } from "@market/stores";
import {
  cartDialogStore,
  signInDialogStore,
  signOnDialogStore,
  signUpDialogStore,
  signUpVendorDialogStore
} from "@market/stores/App.store.modules";
import { IDialogsStore } from "@market/stores/contracts/IDialogsStore";
import { Action, Module, VuexModule } from "vuex-module-decorators";

export type dialogStoreToken = "cartDialog" | "signInDialog" | "signOnDialog" | "signUpDialog" | "signUpVendorDialog";

export const STORE_TOKEN: "dialogs" = "dialogs";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class DialogsStore extends VuexModule implements IDialogsStore {
  @Action
  CloseOtherDialogs(storeToken: dialogStoreToken): void {
    if (storeToken != "cartDialog") cartDialogStore.Close();
    if (storeToken != "signInDialog") signInDialogStore.Close();
    if (storeToken != "signOnDialog") signOnDialogStore.Close();
    if (storeToken != "signUpDialog") signUpDialogStore.Close();
    if (storeToken != "signUpVendorDialog") signUpVendorDialogStore.Close();
  }

  @Action
  CloseDialogs(): void {
    cartDialogStore.Close();
    signInDialogStore.Close();
    signOnDialogStore.Close();
    signUpDialogStore.Close();
    signUpVendorDialogStore.Close();
  }
}
