import { appsStore } from "@market/stores/App.store.modules";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppsBar extends Vue {
  get HrefToPigeonMarket(): string {
    return process.env.VUE_APP_PIGEON_MARKET as string;
  }

  get HrefToPigeonCommunity(): string {
    return process.env.VUE_APP_PIGEON_COMMUNITY as string;
  }

  get IsOpen(): boolean {
    return appsStore.launcherIsOpen;
  }

  public Close(): void {
    appsStore.CloseAppsLauncher();
  }
}
