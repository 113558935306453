import { store } from "@market/stores";
import { IAccountStore } from "@market/stores/contracts/IAccountStore";
import { IAdminDashboardStore } from "@market/stores/contracts/IAdminDashboardStore";
import { IAppDataStore } from "@market/stores/contracts/IAppDataStore";
import { IApprovalStore } from "@market/stores/contracts/IApprovalStore";
import { IAppsStore } from "@market/stores/contracts/IAppsStore";
import { IAppStore } from "@market/stores/contracts/IAppStore";
import { IAuthStore } from "@market/stores/contracts/IAuthStore";
import { ICartStore } from "@market/stores/contracts/ICartStore";
import { ICheckoutStore } from "@market/stores/contracts/ICheckoutStore";
import { IDepositStore } from "@market/stores/contracts/IDepositStore";
import { IDialogsStore } from "@market/stores/contracts/IDialogsStore";
import { IDialogStore } from "@market/stores/contracts/IDialogStore";
import { IFavoriteStore } from "@market/stores/contracts/IFavoriteStore";
import { IJwtStore } from "@market/stores/contracts/IJwtStore";
import { INotificationStore } from "@market/stores/contracts/INotificationStore";
import { IPigeonStore } from "@market/stores/contracts/IPigeonStore";
import { ISalesCatalogStore } from "@market/stores/contracts/ISalesCatalogStore";
import { ISaleStore } from "@market/stores/contracts/ISaleStore";
import { ISearchStore } from "@market/stores/contracts/ISearchStore";
import { ISettlementStore } from "@market/stores/contracts/ISettlementStore";
import { IUserStore } from "@market/stores/contracts/IUserStore";
import { IVendorDashboardStore } from "@market/stores/contracts/IVendorDashboardStore";
import AccountStore from "@market/stores/modules/accountStore";
import AppDataStore from "@market/stores/modules/appDataStore";
import ApprovalStore from "@market/stores/modules/approvalStore";
import AppsStore from "@market/stores/modules/appsStore";
import AppStore from "@market/stores/modules/appStore";
import AuthStore from "@market/stores/modules/authStore";
import CartStore from "@market/stores/modules/cartStore";
import SalesCatalogStore from "@market/stores/modules/catalogStore.sales";
import CheckoutStore from "@market/stores/modules/checkoutStore";
import AdminDashboardStore from "@market/stores/modules/dashboard.admin";
import VendorDashboardStore from "@market/stores/modules/dashboard.vendor";
import DepositStore from "@market/stores/modules/depositStore";
import DialogsStore from "@market/stores/modules/dialogsStore";
import CartDialogStore from "@market/stores/modules/dialogStore.cart";
import SignInDialogStore from "@market/stores/modules/dialogStore.signIn";
import SignOnDialogStore from "@market/stores/modules/dialogStore.signOn";
import SignUpDialogStore from "@market/stores/modules/dialogStore.signUp";
import SignUpVendorDialogStore from "@market/stores/modules/dialogStore.signUpVendor";
import FavoriteStore from "@market/stores/modules/favoriteStore";
import JwtStore from "@market/stores/modules/jwtStore";
import NotificationStore from "@market/stores/modules/notificationStore";
import PigeonStore from "@market/stores/modules/pigeonStore";
import SaleStore from "@market/stores/modules/saleStore";
import SearchStore from "@market/stores/modules/searchStore";
import SettlementStore from "@market/stores/modules/settlementStore";
import UserStore from "@market/stores/modules/userStore";
import { getModule } from "vuex-module-decorators";

// Store
// export const store = new Vuex.Store<IStoreModules>({}); // Must be instantiated in stores/index.ts file
// Store modules
export const accountStore: IAccountStore = getModule(AccountStore);
export const appDataStore: IAppDataStore = getModule(AppDataStore);
export const appStore: IAppStore = getModule(AppStore);
export const approvalStore: IApprovalStore = getModule(ApprovalStore);
export const appsStore: IAppsStore = getModule(AppsStore);
export const authStore: IAuthStore = getModule(AuthStore);
export const cartDialogStore: IDialogStore = getModule(CartDialogStore);
export const cartStore: ICartStore = getModule(CartStore);
export const checkoutStore: ICheckoutStore = getModule(CheckoutStore);
export const adminDashboardStore: IAdminDashboardStore = getModule(AdminDashboardStore);
export const vendorDashboardStore: IVendorDashboardStore = getModule(VendorDashboardStore);
export const depositStore: IDepositStore = getModule(DepositStore);
export const dialogsStore: IDialogsStore = getModule(DialogsStore);
export const favoriteStore: IFavoriteStore = getModule(FavoriteStore);
export const jwtStore: IJwtStore = getModule(JwtStore);
export const notificationStore: INotificationStore = getModule(NotificationStore);
export const pigeonStore: IPigeonStore = getModule(PigeonStore);
export const saleStore: ISaleStore = getModule(SaleStore);
export const salesCatalogStore: ISalesCatalogStore = getModule(SalesCatalogStore);
export const searchStore: ISearchStore = getModule(SearchStore);
export const settlementStore: ISettlementStore = getModule(SettlementStore);
export const signInDialogStore: IDialogStore = getModule(SignInDialogStore);
export const signOnDialogStore: IDialogStore = getModule(SignOnDialogStore);
export const signUpDialogStore: IDialogStore = getModule(SignUpDialogStore);
export const signUpVendorDialogStore: IDialogStore = getModule(SignUpVendorDialogStore);
export const userStore: IUserStore = getModule(UserStore);

export function RestoreUserData() {
  // Restore data
  jwtStore.Restore();
  userStore.Restore();
  accountStore.Restore();
  cartStore.Restore();
  checkoutStore.Restore();

  // Trigger when storage has changed
  window.addEventListener("storage", (event: StorageEvent) => {
    if (event.key === JwtStore.ACCESS_TOKEN_STORE_KEY || event.key === JwtStore.REFRESH_TOKEN_STORE_KEY)
      jwtStore.Restore();
    if (event.key === UserStore.STORE_KEY) userStore.Restore();
    if (event.key === AccountStore.STORE_KEY) accountStore.Restore();
    if (event.key === CartStore.STORE_KEY) cartStore.Restore();
    if (event.key === CheckoutStore.STORE_KEY) checkoutStore.Restore();
  });
}

export async function InitVisitorData() {
  await appDataStore.InitializeCountries();
  await appDataStore.InitializeRingAreas();
}

export async function InitUserData() {
  if (!authStore.IsMember) return;

  RestoreUserData();
  await favoriteStore.FetchFavorites();
  await notificationStore.FetchNotifications();
}

export function WatchAuthData() {
  // See: https://vuex.vuejs.org/api/#watch
  store.watch(
    () => authStore.IsMember,
    async (isMember: boolean) => {
      // Fetch on user login
      if (isMember) {
        await favoriteStore.FetchFavorites();
        await notificationStore.FetchNotifications();
        if (!accountStore.AccountIsVerified) {
          signUpDialogStore.Open();
        }
      }
      // Clear on user logout
      else {
        favoriteStore.Reset();
        notificationStore.Reset();
      }
    }
  );

  store.watch(
    () => authStore.IsApprover,
    async (isApprover: boolean) => {
      // Fetch on approver login
      if (isApprover) await approvalStore.FetchApprovalSales();
      // Clear on approver logout
      else approvalStore.Reset();
    }
  );

  store.watch(
    () => authStore.IsAdministrator,
    async (isAdministrator: boolean) => {
      // Fetch on administrator login
      if (isAdministrator) await settlementStore.FetchPendingSettlements();
      // Clear on administraotr logout
      else settlementStore.Reset();
    }
  );
}
