import { AppRoutes } from "@market/routers/App.routes";
import {
  approvalStore,
  authStore,
  depositStore,
  notificationStore,
  settlementStore,
  signOnDialogStore
} from "@market/stores/App.store.modules";
import ModuleStripeConnect from "@pigeon/components/module/ModuleStripeConnect.vue";
import ProductCountryFlag from "@pigeon/components/product/ProductCountryFlag.vue";
import { Component, Vue } from "vue-property-decorator";

import { BadgeLevels } from "@pigeon/components/app/AppBadge";

@Component({
  components: {
    ModuleStripeConnect,
    CountryFlag: ProductCountryFlag
  }
})
export default class AccountSignOnView extends Vue {
  BadgeLevels = BadgeLevels;

  //#region Notifications
  get UnreadNotificationsCount(): number {
    return notificationStore.UnreadPMarketNotificationsCount;
  }

  get AwaitingSettlementsCount(): number {
    return settlementStore.pendingSettlementsCount;
  }

  get AwaitingDepositsCount(): number {
    return depositStore.pendingDepositsCount;
  }

  get PendingApprovalSalesCount(): number {
    return approvalStore.pendingApprovalSalesCount;
  }
  //#endregion

  public CloseSignOnDialog(): void {
    signOnDialogStore.Close();
  }

  public SignOut(): void {
    authStore.Logout();
    signOnDialogStore.Close();
  }

  public GoToBuyerDashboard(): void {
    if (this.$route.name === AppRoutes.BuyerDashboard) return;

    this.$router.push({ name: AppRoutes.BuyerDashboard });
  }

  public GoToBreederDashboard(): void {
    if (this.$route.name === AppRoutes.BreederDashboard) return;

    this.$router.push({ name: AppRoutes.BreederDashboard });
  }

  public GoToBusinessDashboard(): void {
    if (this.$route.name === AppRoutes.VendorDashboard) return;

    this.$router.push({ name: AppRoutes.VendorDashboard });
  }

  public GoToApproverPage(): void {
    if (this.$route.name === AppRoutes.AdminApprovalSales) return;

    this.$router.push({ name: AppRoutes.AdminApprovalSales });
  }

  public GoToAdminDashboard(): void {
    if (this.$route.name === AppRoutes.AdminDashboard) return;

    this.$router.push({ name: AppRoutes.AdminDashboard });
  }

  public GoToMessagesCenter(): void {
    if (this.$route.name === AppRoutes.AccountMessages) return;

    this.$router.push({ name: AppRoutes.AccountMessages });
  }

  public GoToNotificationsCenter(): void {
    if (this.$route.name === AppRoutes.AccountNotifications) return;

    this.$router.push({ name: AppRoutes.AccountNotifications });
  }
}
