import { Component, Prop, Vue } from "vue-property-decorator";

export enum DividerDirections {
  Horizontal = "horizontal",
  Vertical = "vertical"
}

@Component
export default class AppDivider extends Vue {
  @Prop({
    default: DividerDirections.Horizontal,
    validator: (value: DividerDirections) => Object.values(DividerDirections).includes(value)
  })
  readonly direction: DividerDirections;
}
