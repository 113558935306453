import { DEFAULT_LOCALE } from "@pigeon/i18n/i18n.const";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";

@Component
export default class I18nRouterLink extends Vue {
  @Prop()
  readonly to: Location | string;
  @Prop()
  readonly tag?: string;

  public LocalizeTo(): Location | string {
    if (!this.to) return "";

    const locale: string = this.$i18n.locale || DEFAULT_LOCALE;

    if (typeof this.to === "string" || this.to instanceof String) {
      // test if a remote url
      if (/(http(s?)):\/\//gi.test(this.to as string)) {
        return this.to;
      } else if (this.to === "/") {
        return `/${locale}`;
      }

      // we strip leading and trailing slashes and prefix
      // the current locale
      return `/${locale}/${this.to.replace(/^\/|\/$/g, "")}`;
    } else {
      if (this.to.params) this.to.params.locale = locale;
      else this.to.params = { locale: locale };
    }

    return this.to;
  }
}
