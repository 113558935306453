import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { IAuthentication } from "@api/models/auth/IAuthentication";
import { IAuthSession } from "@api/models/auth/IAuthSession";
import { AppRoles } from "@api/models/market/constants/AppRoles";
import { store } from "@market/stores";
import {
  accountStore,
  cartStore,
  checkoutStore,
  jwtStore,
  pigeonStore,
  saleStore,
  userStore
} from "@market/stores/App.store.modules";
import { IAuthStore } from "@market/stores/contracts/IAuthStore";
import { AspNetCoreCookieCulture } from "@pigeon/i18n/i18n.const";
import { uiBus } from "@pigeon/Ui.bus";
import { cid, container, Inject } from "inversify-props";
import Vue from "vue";
import { Action, Module, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "auth" = "auth";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class AuthStore extends VuexModule implements IAuthStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private accountsService: IAccountsService;

  /** @deprecated */
  static readonly STORE_KEY: string = "account";

  get IsAuthenticated(): boolean {
    return !!jwtStore.accessToken;
  }

  get IsMember(): boolean {
    return this.IsAuthenticated && this.IsAuthorized(AppRoles.Member);
  }

  get IsContributor(): boolean {
    return this.IsAuthenticated && this.IsAuthorized(AppRoles.Contributor);
  }

  get IsVendor(): boolean {
    return this.IsAuthenticated && this.IsAuthorized(AppRoles.Vendor);
  }

  get IsApprover(): boolean {
    return this.IsAuthenticated && this.IsAuthorized(AppRoles.Approver);
  }

  get IsAdministrator(): boolean {
    return this.IsAuthenticated && this.IsAuthorized(AppRoles.Administrator);
  }

  get IsAuthorized(): (requiredRole: string) => boolean {
    return (requiresRole: string) => {
      if (!requiresRole) return true;
      if (!jwtStore.payload) return false;

      if (Array.isArray(jwtStore.payload.userRoles)) {
        return jwtStore.payload.userRoles.includes(requiresRole);
      }

      return jwtStore.payload.userRoles === requiresRole;
    };
  }

  @Action
  async Login(authentication: IAuthentication) {
    // eslint-disable-next-line no-console
    console.debug("Login");

    const accountsService = container.get<IAccountsService>(cid.AccountsService);
    return accountsService.SignIn(authentication).then(async (response) => {
      jwtStore.InitTokens({ accessToken: response.data.accessToken, refreshToken: response.data.refreshToken });
      accountStore.InitAccountData(response.data.accountData);
      userStore.InitUserData(response.data.userData);

      uiBus.emit("login");
    });
  }

  @Action
  Logout() {
    // eslint-disable-next-line no-console
    console.debug("Logout");
    jwtStore.Reset();
    userStore.Reset();
    accountStore.Reset();
    cartStore.Reset();
    checkoutStore.Reset();
    pigeonStore.Reset();
    saleStore.Reset();
    Vue.cookies.remove(AspNetCoreCookieCulture);
    uiBus.emit("logout");
  }

  @Action
  AuthSession(session: IAuthSession) {
    if (session.accessToken && session.refreshToken) jwtStore.UPDATE_TOKENS(session);
    if (session.userData) userStore.UPDATE_USER_DATA(session.userData);
    if (session.accountData) accountStore.UPDATE_ACCOUNT_DATA(session.accountData);
  }

  @Action
  ClearStorage() {
    localStorage.removeItem(AuthStore.STORE_KEY);
  }
}
