import { Component, Emit, Prop, Vue } from "vue-property-decorator";

export enum BoxTypes {
  Modal = "modal"
}

@Component
export default class AppBox extends Vue {
  @Prop({
    default: BoxTypes.Modal,
    validator: (value: BoxTypes) => Object.values(BoxTypes).includes(value)
  })
  readonly type: BoxTypes;

  isOpen: boolean = true;

  @Emit("close")
  public Close(): void {
    this.isOpen = false;
  }
}
