import { IPaymentIntentsService } from "@api/contracts/webapi/IPaymentIntentsService";
import { IPaymentRequest } from "@api/models/market/IPaymentRequest";
import { IPaymentResponse } from "@api/models/market/IPaymentResponse";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { AxiosPromise } from "axios";

// Note: Stripe offers differents API and UI Integration to proceed to payment.
// Charge API is obsolete
// PaymentIntents API is new, supports wilde payment methods and differentes payment flows/scenari. See: https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements#web-create-intent

// Technical rules: the project uses Card Element Integration (and custom payment elements) + PaymentIntent API because it is flexible and supports wanted payment scenario.
// Warning this project use different payment controller / solution to integrate other payment methods(cash, paypal, western union,...).
export class PaymentIntentsService extends BaseApiService implements IPaymentIntentsService {
  public GetPaymentClientSecret(paymentIntentId: string): AxiosPromise<string> {
    return this.Client().get(`PaymentIntents/${paymentIntentId}/client-secret`);
  }

  // See https://stripe.com/docs/payments/payment-intents/creating-payment-intents
  // See https://stripe.com/docs/api/payment_intents/create
  // Note: Best practice use IdempotencyKey https://stripe.com/docs/api/idempotent_requests
  public CreatePayment(paymentRequest: IPaymentRequest): AxiosPromise<IPaymentResponse> {
    return this.Client().post("PaymentIntents/pay", paymentRequest);
  }
}
