import { signUpDialogStore } from "@market/stores/App.store.modules";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AccountSignUpDoneView extends Vue {
  @Prop()
  readonly email: string;

  public CloseDialog(): void {
    signUpDialogStore.Close();
  }
}
