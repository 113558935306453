import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { Channels } from "@api/enumerations/Channels";
import { IAccountEmailVerification } from "@api/models/auth/IAccountEmailVerification";
import { IAccountEmailVerificationToken } from "@api/models/auth/IAccountEmailVerificationToken";
import { IAccountProfileWithTokens } from "@api/models/auth/IAccountProfile";
import { IAuth, IAuthResponse } from "@api/models/auth/IAuth";
import { IAuthentication } from "@api/models/auth/IAuthentication";
import { IAuthSession } from "@api/models/auth/IAuthSession";
import { IContributorRegistration } from "@api/models/auth/IContributorRegistration";
import { IContributorUpgradeRegistration } from "@api/models/auth/IContributorUpgradeRegistration";
import { IRecovery } from "@api/models/auth/IRecovery";
import { IRegistration } from "@api/models/auth/IRegistration";
import { IReset } from "@api/models/auth/IReset";
import { IVendorRegistration } from "@api/models/auth/IVendorRegistration";
import { IVendorUpgradeRegistration } from "@api/models/auth/IVendorUpgradeRegistration";
import { IGuest } from "@api/models/market/IGuest";
import { IUserData } from "@api/models/market/IUserData";
import { AxiosPromise } from "axios";
import { AccountsServiceAnonymous } from "./AccountsService.anonymous";
import { AccountsServiceUser } from "./AccountsService.user";

export class AccountsService implements IAccountsService {
  private readonly accountsServiceAnonymous;
  private readonly accountsServiceUser;

  get Client() {
    return this.accountsServiceUser.Client;
  }

  constructor() {
    this.accountsServiceAnonymous = new AccountsServiceAnonymous();
    this.accountsServiceUser = new AccountsServiceUser();
  }

  //#region Anonymous
  //#region Token
  RefreshAuth(expiredAuth: IAuth): AxiosPromise<IAuthResponse> {
    return this.accountsServiceAnonymous.RefreshAuth(expiredAuth);
  }
  //#endregion

  //#region Account
  SignIn(authentication: IAuthentication): AxiosPromise<IAuthSession> {
    return this.accountsServiceAnonymous.SignIn(authentication);
  }

  SignUp(channel: Channels, registration: IRegistration): AxiosPromise<IAuthSession> {
    return this.accountsServiceAnonymous.SignUp(channel, registration);
  }

  SignUpAsVendor(registration: IVendorRegistration): AxiosPromise<IAuthSession> {
    return this.accountsServiceAnonymous.SignUpAsVendor(registration);
  }

  SignUpAsContributor(registration: IContributorRegistration): AxiosPromise<IAuthSession> {
    return this.accountsServiceAnonymous.SignUpAsContributor(registration);
  }

  Register(guest: IGuest): AxiosPromise<IGuest> {
    return this.accountsServiceAnonymous.Register(guest);
  }

  RecoverAccount(channel: Channels, recovery: IRecovery, locale: string): AxiosPromise<void> {
    return this.accountsServiceAnonymous.RecoverAccount(channel, recovery, locale);
  }

  ResetPassword(reset: IReset): AxiosPromise<void> {
    return this.accountsServiceAnonymous.ResetPassword(reset);
  }

  //#region Guest
  FindGuest(email: string, lastname: string, firstname: string): AxiosPromise<IGuest> {
    return this.accountsServiceAnonymous.FindGuest(email, lastname, firstname);
  }
  //#endregion
  //#endregion

  //#region IsUnique
  IsMemberEmailUnique(email: string): AxiosPromise<boolean> {
    return this.accountsServiceAnonymous.IsMemberEmailUnique(email);
  }

  IsVendorNameUnique(name: string): AxiosPromise<boolean> {
    return this.accountsServiceAnonymous.IsVendorNameUnique(name);
  }

  IsShopUnique(shop: string): AxiosPromise<boolean> {
    return this.accountsServiceAnonymous.IsShopUnique(shop);
  }

  IsBuyerNameUnique(name: string): AxiosPromise<boolean> {
    return this.accountsServiceAnonymous.IsBuyerNameUnique(name);
  }

  IsContributorNameUnique(name: string): AxiosPromise<boolean> {
    return this.accountsServiceAnonymous.IsContributorNameUnique(name);
  }
  //#endregion
  //#endregion

  //#region User
  //#region Account
  UpgradeAsVendor(registration: IVendorUpgradeRegistration): AxiosPromise<IAuthSession> {
    return this.accountsServiceUser.UpgradeAsVendor(registration);
  }

  UpgradeAsContributor(registration: IContributorUpgradeRegistration): AxiosPromise<IAuthSession> {
    return this.accountsServiceUser.UpgradeAsContributor(registration);
  }

  PatchUser(userData: IUserData): AxiosPromise<void> {
    return this.accountsServiceUser.PatchUser(userData);
  }

  FetchUser(userKey: string): AxiosPromise<IAccountProfileWithTokens> {
    return this.accountsServiceUser.FetchUser(userKey);
  }

  //#region Verification
  SendNewEmailVerification(
    channel: Channels,
    verification: IAccountEmailVerification,
    locale: string
  ): AxiosPromise<void> {
    return this.accountsServiceUser.SendNewEmailVerification(channel, verification, locale);
  }

  VerifyEmail(confirmation: IAccountEmailVerificationToken): AxiosPromise<boolean> {
    return this.accountsServiceUser.VerifyEmail(confirmation);
  }
  //#endregion
  //#endregion

  //#region Auction
  AgreeToAuctionConditions(agree: boolean): AxiosPromise<void> {
    return this.accountsServiceUser.AgreeToAuctionConditions(agree);
  }
  //#endregion
  //#endregion
}
