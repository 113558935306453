import { NOTIFICATION_DEFAULT_TIMEOUT, NOTIFICATION_LIMIT_TO_DISPLAY } from "@market/App.const";
import { BreakpointPlugin } from "@pigeon/plugins";
import AuthPlugin from "@pigeon/plugins/index.auth";
import Vue from "vue";
import VueCookies from "vue-cookies-ts";
import VueMeta from "vue-meta";
import LoadScript from "vue-plugin-load-script";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
Vue.use(VueCookies);
Vue.use(BreakpointPlugin);
Vue.use(AuthPlugin);
Vue.use(LoadScript);
Vue.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
  timeout: NOTIFICATION_DEFAULT_TIMEOUT,
  maxToasts: NOTIFICATION_LIMIT_TO_DISPLAY
});
