import { IAddressesService } from "@api/contracts/odata/IAddressesService";
import { IAddress } from "@api/models/market/IAddress";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";

export class AddressesService extends BaseODataService implements IAddressesService {
  constructor() {
    super();
  }

  public Insert(address: IAddress): AxiosPromise<IAddress> {
    return this.Client().post("Addresses", address);
  }

  public Update(addressKey: number, address: IAddress): AxiosPromise<IAddress> {
    return this.Client().put(`Addresses(${addressKey})`, address);
  }

  public Upsert(address: IAddress): AxiosPromise<IAddress> {
    if (address.id) return this.Update(address.id, address);
    else return this.Insert(address);
  }

  public Fetch(addressKey: number): AxiosPromise<IAddress> {
    return this.Client().get(`Addresses(${addressKey})`);
  }
}
