import "./__global";

if (!String.prototype.toUpperCaseFirstLetter) {
  (function () {
    String.prototype.toUpperCaseFirstLetter = function () {
      return this.charAt(0).toUpperCase() + this.slice(1);
    };
  })();
}

if (!String.prototype.toReverse) {
  (function () {
    String.prototype.toReverse = function () {
      return this.split("").reverse().join("");
    };
  })();
}
