import { AppThemes } from "@api/models/market/constants/AppThemes";
import { SexTypes } from "@api/models/market/constants/SexTypes";
import { IPigeon } from "@api/models/market/IPigeon";
import { AppRoutes } from "@market/routers/App.routes";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { Inject } from "inversify-props";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class PigeonAssetParent extends Vue {
  @Prop()
  readonly parent: IPigeon;
  @Prop()
  readonly childId?: number;
  @Prop()
  readonly relationship?: string;
  @Prop()
  readonly selected?: IPigeon;
  @Prop({ default: false })
  readonly isSelectable: boolean;
  @Prop({ default: true })
  readonly showAsset: boolean;
  @Prop({ default: false })
  readonly showLink: boolean;
  @Prop({ validator: (value: AppThemes) => Object.values(AppThemes).includes(value) })
  readonly theme: AppThemes;

  @Inject()
  private pigeonManager: IPigeonManager;

  AppRoutes = AppRoutes;
  SexTypes = SexTypes;
  familyRelationship = "Parent";

  get IsSelected(): boolean {
    if (!this.selected) {
      return false;
    }

    return this.selected === this.parent;
  }

  get PigeonIcon(): string {
    return this.pigeonManager.GetSexIcon(this.parent);
  }

  get HasParentLinkable(): boolean {
    return (this.parent && this.parent.id && this.parent.flagApproved && this.parent.publicData) ||
      (this.parent && this.parent.id && this.$auth.IsAdministrator)
      ? true
      : false;
  }

  @Emit("select")
  public Select() {
    if (!this.isSelectable) return;

    return this.parent;
  }
}
