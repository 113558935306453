import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/nl";
import "dayjs/locale/pl";
import "dayjs/locale/pt";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";
import { DEFAULT_LOCALE } from "./i18n.const";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(localeData); // See: https://day.js.org/docs/en/plugin/locale-data
dayjs.locale(DEFAULT_LOCALE); // Note:  dayjs locale is update in I18nLanguageDropdown components

export default dayjs;
