import { IUserData } from "@api/models/market/IUserData";
import { IVendor } from "@api/models/market/IVendor";
import { accountStore, authStore, jwtStore, userStore } from "@market/stores/App.store.modules";
import { IAuthService } from "./IAuthService";

export class AuthService implements IAuthService {
  //#region authenticate
  get IsAuthenticated(): boolean {
    return authStore.IsAuthenticated;
  }

  // Check if the expiration date from token is lower than 2 minutes
  IsCloseToExpire(): boolean {
    return jwtStore.IsTokenCloseToExpire();
  }

  // Use method to not cache the result (cannot cache a result with datetime)
  IsExpired(): boolean {
    return jwtStore.HasTokenExpired();
  }
  //#endregion

  //#region role
  IsAuthorized(requiredRole: string): boolean {
    return authStore.IsAuthorized(requiredRole);
  }

  get IsMember(): boolean {
    return authStore.IsMember;
  }

  get IsContributor(): boolean {
    return authStore.IsContributor;
  }

  get IsVendor(): boolean {
    return authStore.IsVendor;
  }

  get IsApprover(): boolean {
    return authStore.IsApprover;
  }

  get IsAdministrator(): boolean {
    return authStore.IsAdministrator;
  }
  //#endregion

  //#region User
  get User(): IUserData | null {
    return userStore.user;
  }

  get Vendor(): IVendor | null {
    if (!this.IsVendor || !userStore.user?.vendor) return null;

    return userStore.user.vendor;
  }

  get MailProviderWebSite(): string {
    return userStore.MailProviderWebSite;
  }
  //#endregion

  //#region Account
  get AccountIsVerified(): boolean {
    return accountStore.AccountIsVerified;
  }

  get AccountVendorIsVerified(): boolean {
    return accountStore.AccountVendorIsVerified;
  }
  //#endregion
}
