import AppAlert from "@pigeon/components/app/AppAlert.vue";
import AppBadge from "@pigeon/components/app/AppBadge.vue";
import AppBox from "@pigeon/components/app/AppBox.vue";
import AppButtonDropdown from "@pigeon/components/app/AppButtonDropdown.vue";
import AppButtonLoader from "@pigeon/components/app/AppButtonLoader.vue";
import AppDivider from "@pigeon/components/app/AppDivider.vue";
import AppDividerToggler from "@pigeon/components/app/AppDividerToggler.vue";
import AppDropdown from "@pigeon/components/app/AppDropdown.vue";
import AppError from "@pigeon/components/app/AppError.vue";
import AppErrorNotFound from "@pigeon/components/app/AppErrorNotFound.vue";
import AppErrors from "@pigeon/components/app/AppErrors.vue";
import AppErrorUnexpected from "@pigeon/components/app/AppErrorUnexpected.vue";
import AppHelp from "@pigeon/components/app/AppHelp.vue";
import AppLoader from "@pigeon/components/app/AppLoader.vue";
import AppMenuItem from "@pigeon/components/app/AppMenuItem.vue";
import AppMenuItems from "@pigeon/components/app/AppMenuItems.vue";
import AppOverlay from "@pigeon/components/app/AppOverlay.vue";
import AppProgressBar from "@pigeon/components/app/AppProgressBar.vue";
import Vue from "vue";

// App components
Vue.component("AppAlert", AppAlert);
Vue.component("AppBadge", AppBadge);
Vue.component("AppBox", AppBox);
Vue.component("AppButtonDropdown", AppButtonDropdown);
Vue.component("AppButtonLoader", AppButtonLoader);
Vue.component("AppDivider", AppDivider);
Vue.component("AppDividerToggler", AppDividerToggler);
Vue.component("AppDropdown", AppDropdown);
Vue.component("AppError", AppError);
Vue.component("AppErrorNotFound", AppErrorNotFound);
Vue.component("AppErrorUnexpected", AppErrorUnexpected);
Vue.component("AppErrors", AppErrors);
Vue.component("AppHelp", AppHelp);
Vue.component("AppLoader", AppLoader);
Vue.component("AppMenuItem", AppMenuItem);
Vue.component("AppMenuItems", AppMenuItems);
Vue.component("AppOverlay", AppOverlay);
Vue.component("AppProgressBar", AppProgressBar);
