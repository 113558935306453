import { ISettlementsService } from "@api/contracts/odata/ISettlementsService";
import { ISettlement } from "@api/models/market/ISettlement";
import { store } from "@market/stores";
import { authStore } from "@market/stores/App.store.modules";
import { ISettlementStore } from "@market/stores/contracts/ISettlementStore";
import { cid, container, Inject } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "settlement" = "settlement";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class SettlementStore extends VuexModule implements ISettlementStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private settlementsService: ISettlementsService;

  pendingSettlements: ISettlement[] = [];
  showPendingSettlementTask: boolean = true;

  get pendingSettlementsCount(): number {
    return this.pendingSettlements?.length ?? 0;
  }

  @Mutation
  SET_PENDING_SETTLEMENTS(data: ISettlement[]): void {
    this.pendingSettlements = data;
  }

  @Mutation
  REMOVE_PENDING_SETTLEMENT(settlementKeyToRemove: string): void {
    const settlementIndex = this.pendingSettlements.findIndex((s) => s.id == settlementKeyToRemove);

    if (settlementIndex > -1) {
      this.pendingSettlements.splice(settlementIndex, 1);
    }
  }

  @Mutation
  SET_SHOW_PENDING_SETTLEMENT_TASK(show: boolean): void {
    this.showPendingSettlementTask = show;
  }

  @Action
  public DismissPendingSettlementTask() {
    this.showPendingSettlementTask = false;
  }

  @Action
  public async FetchPendingSettlements(): Promise<void> {
    const settlementsService = container.get<ISettlementsService>(cid.SettlementsService);

    if (authStore.IsAdministrator) {
      settlementsService.FetchAllPendingProcessing().then((response) => {
        this.SET_PENDING_SETTLEMENTS(response.data.value);
      });
    }
  }

  @Action
  public async RemovePendingSettlements(settlementKey: string): Promise<void> {
    this.REMOVE_PENDING_SETTLEMENT(settlementKey);
  }

  @Action
  public async Reset(): Promise<void> {
    this.SET_PENDING_SETTLEMENTS([]);
  }
}
