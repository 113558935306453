import { Component, Prop, Ref, Vue } from "vue-property-decorator";

export enum TabTypes {
  Stretch = "stretch",
  Compact = "compact"
}

@Component
export default class NavTabs extends Vue {
  @Prop({
    default: TabTypes.Stretch,
    validator: (value: TabTypes) => Object.values(TabTypes).includes(value)
  })
  readonly type: TabTypes;
  @Prop({ default: true })
  readonly sliderBehavior: boolean;

  @Ref()
  readonly refNav: HTMLElement;

  TabTypes = TabTypes;
  private grab: boolean = false;
  private startX: number = 0;
  private scrollLeft: number = 0;

  public GrabStart(event: MouseEvent): void {
    if (!this.sliderBehavior) return;

    this.grab = true;
    const slider: HTMLElement = this.refNav as HTMLElement;
    slider.classList.add("is-grabbed");
    this.startX = event.pageX - slider.offsetLeft;
    this.scrollLeft = slider.scrollLeft;
  }

  public GrabStop(event: Event): void {
    if (!this.sliderBehavior) return;

    this.grab = false;
    this.refNav.classList.remove("is-grabbed");
  }

  public GrabMove(event: MouseEvent): void {
    if (!this.sliderBehavior) return;
    if (!this.grab) return;

    event.preventDefault();

    const slider: HTMLElement = this.refNav;
    const x = event.pageX - slider.offsetLeft;
    const walk = (x - this.startX) * 1;
    slider.scrollLeft = this.scrollLeft - walk;
  }
}
