import { IAuctionsService } from "@api/contracts/odata/IAuctionsService";
import { IAuction } from "@api/models/market/IAuction";
import { IPicture } from "@api/models/market/IPicture";
import { IUser } from "@api/models/market/IUser";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { ExpandObjectQuery, FilterDate, FilterString, odataQuery } from "odata-fluent-query";

export class AuctionsService extends BaseODataService implements IAuctionsService {
  constructor() {
    super();
  }

  public Insert(auction: IAuction): AxiosPromise<IAuction> {
    return this.Client().post("Auctions", auction);
  }

  public Update(auctionKey: number, auction: IAuction): AxiosPromise<void> {
    return this.Client().put(`Auctions(${auctionKey})`, auction);
  }

  public Delete(auctionKey: number): AxiosPromise<void> {
    return this.Client().delete(`Auctions(${auctionKey})`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IAuction>> {
    return this.Client().get(`Auctions`);
  }

  public FetchAllOverview(): AxiosPromise<IODataCollectionResponse<IAuction>> {
    const query = odataQuery<IAuction>()
      .expand("user", (fp) => fp.select("firstname", "lastname"))
      .expand("sales", (s) => s.expand("pigeon").expand("package", (p) => p.expand("pigeons")));

    return this.Client().get(`Auctions?${query.toString()}`);
  }

  public FetchAllAuctions(fromDate?: Date, toDate?: Date): AxiosPromise<IODataCollectionResponse<IAuction>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<IAuction>()
      .select("id", "userId", "startDate", "endDate")
      .expand("sales", (as) => as.select("id"));

    if (fromDate)
      query = query.filter((a) => (a.startDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((a) => (a.startDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`Auctions?${query.toString()}`);
  }

  public FetchAllAuctionsByVendor(
    userKey: string,
    fromDate?: Date,
    toDate?: Date
  ): AxiosPromise<IODataCollectionResponse<IAuction>> {
    if (!authStore.IsAdministrator && userStore.user && userStore.user.id !== userKey)
      return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<IAuction>().filter((a) => a.userId.equals(userKey, { ignoreGuid: true }));

    if (fromDate)
      query = query.filter((a) => (a.startDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((a) => (a.startDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    query = query.select("id", "userId", "startDate", "endDate").expand("sales", (as) => as.select("id"));

    return this.Client().get(`Auctions?${query.toString()}`);
  }

  public FetchMyAuctions(vendorId: string): AxiosPromise<IODataCollectionResponse<IAuction>> {
    const query = odataQuery<IAuction>().filter((a) => a.userId.equals(vendorId, { ignoreGuid: true }));

    return this.Client().get(`Auctions?${query.toString()}`);
  }

  public Fetch(auctionKey: number): AxiosPromise<IAuction> {
    return this.Client().get(`Auctions(${auctionKey})`);
  }

  public FetchAuctionsCatalog(shopKey?: string): AxiosPromise<IODataCollectionResponse<IAuction>> {
    let query = odataQuery<IAuction>()
      .expand("sales", (q) => q.select("id"))
      .expand("user", (q: ExpandObjectQuery<IUser>) => q.select("firstname", "lastname"))
      .expand("pictures", (q: ExpandObjectQuery<IPicture>) => q.select("filename", "type", "url", "urlWebOptimized"))
      .orderBy("startDate", "asc");

    if (shopKey) query = query.filter((a) => (a.user.vendor.shopId as FilterString).equals(shopKey));
    return this.Client().get(`Auctions?${query.toString()}`);
  }
}
