import { IPigeon } from "@api/models/market/IPigeon";
import { store } from "@market/stores";
import { userStore } from "@market/stores/App.store.modules";
import { IPigeonStore } from "@market/stores/contracts/IPigeonStore";
import { IFilePicture } from "@pigeon/models/IFilePicture";
import { IFileVideo } from "@pigeon/models/IFileVideo";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "pigeon" = "pigeon";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class PigeonStore extends VuexModule implements IPigeonStore {
  pigeon: IPigeon | null = null;
  filePictures: IFilePicture[] = [];
  fileVideos: IFileVideo[] = [];
  expertMode: boolean = false;

  //#region Mode
  @Mutation
  UPDATE_MODE(isExpertModeActivated: boolean): void {
    this.expertMode = isExpertModeActivated;
  }

  @Action
  UpdateMode(isExpertModeActivated: boolean): void {
    this.UPDATE_MODE(isExpertModeActivated);
  }
  //#endregion

  //#region product
  @Mutation
  UPDATE_PIGEON(pigeon: IPigeon | null): void {
    // set userId
    if (pigeon && !pigeon.userId && userStore.user) {
      pigeon.userId = userStore.user.id;
    }

    this.pigeon = pigeon;
  }

  @Action
  UpdatePigeon(pigeon: IPigeon | null): void {
    if (pigeon && !pigeon.ringNumber && pigeon.ring) {
      pigeon.ringNumber = pigeon.ring.substring(pigeon.ring.indexOf("-") + 1);
    }

    this.UPDATE_PIGEON(pigeon);
  }
  //#endregion

  //#region fileMedia
  @Mutation
  UPDATE_FILE_PICTURES(files: IFilePicture[]): void {
    this.filePictures = files;
  }

  @Mutation
  UPDATE_FILE_VIDEOS(files: IFileVideo[]): void {
    this.fileVideos = files;
  }

  @Mutation
  PUSH_FILE_PICTURE(file: IFilePicture): void {
    this.filePictures.push(file);
  }

  @Mutation
  PUSH_FILE_VIDEO(file: IFileVideo): void {
    this.fileVideos.push(file);
  }

  @Action
  UpdateFilePictures(files: IFilePicture[]): void {
    this.UPDATE_FILE_PICTURES(files);
  }

  @Action
  UpdateFileVideos(files: IFileVideo[]): void {
    this.UPDATE_FILE_VIDEOS(files);
  }

  @Action
  AddFilePicture(file: IFilePicture): void {
    const existingIndexFile = this.filePictures.findIndex(
      (fp) =>
        fp.pictureType === file.pictureType &&
        ((file.pigeonKey && fp.pigeonKey === file.pigeonKey) ||
          (file.pigeonKeyClient && fp.pigeonKeyClient === file.pigeonKeyClient))
    );

    if (existingIndexFile > -1) {
      // replace
      this.filePictures[existingIndexFile] = file;
    } else {
      // add
      this.PUSH_FILE_PICTURE(file);
    }
  }

  @Action
  AddFileVideo(file: IFileVideo): void {
    const existingIndexFile = this.fileVideos.findIndex(
      (fp) =>
        fp.videoType === file.videoType &&
        ((file.pigeonKey && fp.pigeonKey === file.pigeonKey) ||
          (file.pigeonKeyClient && fp.pigeonKeyClient === file.pigeonKeyClient))
    );

    if (existingIndexFile > -1) {
      // replace
      this.fileVideos[existingIndexFile] = file;
    } else {
      // add
      this.PUSH_FILE_VIDEO(file);
    }
  }
  //#endregion

  @Action
  Reset(): void {
    this.UpdatePigeon(null);
    this.UpdateFilePictures([]);
    this.UpdateFileVideos([]);
  }
}
