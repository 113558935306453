import { ICountry } from "@api/models/shared/ICountry";
import { ICountryManager } from "./contracts/ICountryManager";

export class CountryManager implements ICountryManager {
  public static readonly countriesHardcoded: ICountry[] = [
    { code: "AE", label: "United Arab Emirates" },
    { code: "AF", label: "Afghanistan" },
    { code: "AL", label: "Albania" },
    { code: "AM", label: "Armenia" },
    { code: "AR", label: "Argentina" },
    { code: "AT", label: "Austria" },
    { code: "AU", label: "Australia" },
    { code: "AZ", label: "Azerbaijan" },
    { code: "BA", label: "Bosnia and Herzegovina" },
    { code: "BD", label: "Bangladesh" },
    { code: "BE", label: "Belgium" },
    { code: "BG", label: "Bulgaria" },
    { code: "BH", label: "Bahrain" },
    { code: "BN", label: "Brunei" },
    { code: "BO", label: "Bolivia" },
    { code: "BR", label: "Brazil" },
    { code: "BT", label: "Bhutan" },
    { code: "BW", label: "Botswana" },
    { code: "BY", label: "Belarus" },
    { code: "BZ", label: "Belize" },
    { code: "CA", label: "Canada" },
    { code: "CD", label: "Congo (DRC)" },
    { code: "CH", label: "Switzerland" },
    { code: "CI", label: "Côte d'Ivoire" },
    { code: "CL", label: "Chile" },
    { code: "CM", label: "Cameroon" },
    { code: "CN", label: "China" },
    { code: "CO", label: "Colombia" },
    { code: "CR", label: "Costa Rica" },
    { code: "CU", label: "Cuba" },
    { code: "CZ", label: "Czechia" },
    { code: "DE", label: "Germany" },
    { code: "DJ", label: "Djibouti" },
    { code: "DK", label: "Denmark" },
    { code: "DO", label: "Dominican Republic" },
    { code: "DZ", label: "Algeria" },
    { code: "EC", label: "Ecuador" },
    { code: "EE", label: "Estonia" },
    { code: "EG", label: "Egypt" },
    { code: "ER", label: "Eritrea" },
    { code: "ES", label: "Spain" },
    { code: "ET", label: "Ethiopia" },
    { code: "FI", label: "Finland" },
    { code: "FO", label: "Faroe Islands" },
    { code: "FR", label: "France" },
    { code: "GB", label: "United Kingdom" },
    { code: "GE", label: "Georgia" },
    { code: "GL", label: "Greenland" },
    { code: "GR", label: "Greece" },
    { code: "GT", label: "Guatemala" },
    { code: "HK", label: "Hong Kong SAR" },
    { code: "HN", label: "Honduras" },
    { code: "HR", label: "Croatia" },
    { code: "HT", label: "Haiti" },
    { code: "HU", label: "Hungary" },
    { code: "ID", label: "Indonesia" },
    { code: "IE", label: "Ireland" },
    { code: "IL", label: "Israel" },
    { code: "IN", label: "India" },
    { code: "IQ", label: "Iraq" },
    { code: "IR", label: "Iran" },
    { code: "IS", label: "Iceland" },
    { code: "IT", label: "Italy" },
    { code: "JM", label: "Jamaica" },
    { code: "JO", label: "Jordan" },
    { code: "JP", label: "Japan" },
    { code: "KE", label: "Kenya" },
    { code: "KG", label: "Kyrgyzstan" },
    { code: "KH", label: "Cambodia" },
    { code: "KR", label: "Korea" },
    { code: "KW", label: "Kuwait" },
    { code: "KZ", label: "Kazakhstan" },
    { code: "LA", label: "Laos" },
    { code: "LB", label: "Lebanon" },
    { code: "LI", label: "Liechtenstein" },
    { code: "LK", label: "Sri Lanka" },
    { code: "LT", label: "Lithuania" },
    { code: "LU", label: "Luxembourg" },
    { code: "LV", label: "Latvia" },
    { code: "LY", label: "Libya" },
    { code: "MA", label: "Morocco" },
    { code: "MC", label: "Monaco" },
    { code: "MD", label: "Moldova" },
    { code: "ME", label: "Montenegro" },
    { code: "MK", label: "North Macedonia" },
    { code: "ML", label: "Mali" },
    { code: "MM", label: "Myanmar" },
    { code: "MN", label: "Mongolia" },
    { code: "MO", label: "Macao SAR" },
    { code: "MT", label: "Malta" },
    { code: "MV", label: "Maldives" },
    { code: "MX", label: "Mexico" },
    { code: "MY", label: "Malaysia" },
    { code: "NG", label: "Nigeria" },
    { code: "NI", label: "Nicaragua" },
    { code: "NL", label: "Netherlands" },
    { code: "NO", label: "Norway" },
    { code: "NP", label: "Nepal" },
    { code: "NZ", label: "New Zealand" },
    { code: "OM", label: "Oman" },
    { code: "PA", label: "Panama" },
    { code: "PE", label: "Peru" },
    { code: "PH", label: "Philippines" },
    { code: "PK", label: "Pakistan" },
    { code: "PL", label: "Poland" },
    { code: "PR", label: "Puerto Rico" },
    { code: "PT", label: "Portugal" },
    { code: "PY", label: "Paraguay" },
    { code: "QA", label: "Qatar" },
    { code: "RE", label: "Réunion" },
    { code: "RO", label: "Romania" },
    { code: "RS", label: "Serbia" },
    { code: "RU", label: "Russia" },
    { code: "RW", label: "Rwanda" },
    { code: "SA", label: "Saudi Arabia" },
    { code: "SE", label: "Sweden" },
    { code: "SG", label: "Singapore" },
    { code: "SI", label: "Slovenia" },
    { code: "SK", label: "Slovakia" },
    { code: "SN", label: "Senegal" },
    { code: "SO", label: "Somalia" },
    { code: "SV", label: "El Salvador" },
    { code: "SY", label: "Syria" },
    { code: "TH", label: "Thailand" },
    { code: "TJ", label: "Tajikistan" },
    { code: "TM", label: "Turkmenistan" },
    { code: "TN", label: "Tunisia" },
    { code: "TR", label: "Turkey" },
    { code: "TT", label: "Trinidad and Tobago" },
    { code: "TW", label: "Taiwan" },
    { code: "UA", label: "Ukraine" },
    { code: "US", label: "United States" },
    { code: "UY", label: "Uruguay" },
    { code: "UZ", label: "Uzbekistan" },
    { code: "VE", label: "Venezuela" },
    { code: "VN", label: "Vietnam" },
    { code: "YE", label: "Yemen" },
    { code: "ZA", label: "South Africa" },
    { code: "ZW", label: "Zimbabwe" }
  ];

  get CountriesHardcoded() {
    return CountryManager.countriesHardcoded;
  }

  RetrieveCountryByIsoCode(twoIsoLettersCode: string | undefined): ICountry | undefined {
    if (!twoIsoLettersCode) return undefined;

    return this.CountriesHardcoded.find((c) => c.code == twoIsoLettersCode);
  }
}
