import { INewslettersService } from "@api/contracts/odata/INewslettersService";
import { IErrorManager } from "@pigeon/services/contracts/IErrorManager";
import { ErrorManager } from "@pigeon/services/ErrorManager";
import { Inject } from "inversify-props";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component
export default class TheFooterNewsletter extends Vue {
  @Prop()
  readonly brandName: string;
  @Prop({ default: "All" })
  readonly channel: string;

  @Ref()
  readonly refValidation: InstanceType<typeof ValidationObserver>;

  @Inject()
  private newslettersService: INewslettersService;

  errorManager: IErrorManager = new ErrorManager();
  isProccessing: boolean = false;
  newsletterEmail: string | null = null;
  hasSuccess: boolean = false;

  mounted() {
    this.errorManager = new ErrorManager(this.refValidation);
  }

  public NotifySuccess(): void {
    this.hasSuccess = true;
  }

  public async SubscribeToNewsletter(): Promise<any> {
    if (!this.newsletterEmail) {
      this.errorManager.HandleMessageError(this.$t("error.email_required") as string);
      return Promise.reject(this.$t("error.email_required"));
    }

    const isValid = await this.refValidation.validate();
    if (!isValid) {
      this.errorManager.HandleMessageError(this.$t("error.email_invalid") as string);
      return Promise.reject(this.$t("error.email_invalid"));
    }

    this.errorManager.Reset();
    this.hasSuccess = false;
    this.isProccessing = true;

    return this.newslettersService
      .Insert({
        email: this.newsletterEmail,
        channel: this.channel,
        userId: this.$auth.IsAuthenticated && this.$auth.User ? this.$auth.User.id : undefined
      })
      .catch((error) => {
        this.errorManager.HandleError(error);
        throw error;
      })
      .finally(() => {
        this.isProccessing = false;
      });
  }
}
