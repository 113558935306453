import Axios, { AxiosInstance, AxiosPromise } from "axios";
import { IWorldAtlasService } from "@api/contracts/external/IWorldAtlasService";

// See https://msdn.microsoft.com/en-us/library/ff701715.aspx
export class WorldAtlasService implements IWorldAtlasService {
  private client: AxiosInstance;

  constructor() {
    this.client = Axios.create({
      baseURL: process.env.VUE_APP_WORLD_ATLAS_API_BASE_URL,
      timeout: Number(process.env.VUE_APP_WORLD_ATLAS_API_TIME_OUT)
    });
  }

  FetchAllCountries10M(): AxiosPromise<TopoJSON.Topology> {
    return this.client.get("countries-10m.json");
  }

  FetchAllCountries50M(): AxiosPromise<TopoJSON.Topology> {
    return this.client.get("countries-50m.json");
  }

  FetchAllCountries110M(): AxiosPromise<TopoJSON.Topology> {
    return this.client.get("countries-110m.json");
  }

  FetchAllLands10M(): AxiosPromise<TopoJSON.Topology> {
    return this.client.get("land-10m.json");
  }

  FetchAllLands50M(): AxiosPromise<TopoJSON.Topology> {
    return this.client.get("land-50m.json");
  }

  FetchAllLands110M(): AxiosPromise<TopoJSON.Topology> {
    return this.client.get("land-110m.json");
  }
}
