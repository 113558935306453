import { Component, Prop, Vue } from "vue-property-decorator";

export enum ProgressBarTypes {
  Primary = "primary",
  Secondary = "secondary",
  Info = "info",
  Success = "success",
  Warning = "warning",
  Danger = "danger"
}

export interface IProgressBarAnimated {
  Play(): void;
  Pause(): void;
  Stop(): void;
}

@Component
export default class AppProgressBar extends Vue implements IProgressBarAnimated {
  @Prop({
    default: ProgressBarTypes.Primary,
    validator: (value: ProgressBarTypes) => Object.values(ProgressBarTypes).includes(value)
  })
  readonly type: ProgressBarTypes;
  @Prop({ default: 0 })
  readonly progression: number;
  @Prop({ default: 2000 })
  readonly duration: number; // animation duration in ms
  @Prop({ default: false })
  readonly isStriped: boolean;

  isProgressing: boolean = false;
  isPaused: boolean = false;

  get CssClass(): string {
    let cssClass = `bg-${this.type}`;

    if (this.isStriped) cssClass += " progress-bar-striped";
    if (this.isProgressing) cssClass += " bar-progressing";
    if (this.isPaused) cssClass += " progressing-paused";

    return cssClass;
  }

  public Play(): void {
    this.isProgressing = true;
    this.isPaused = false;
  }

  public Pause(): void {
    this.isPaused = true;
  }

  public Stop(): void {
    this.isProgressing = false;
    this.isPaused = false;
  }
}
