import { IAlertsService } from "@api/contracts/odata/IAlertsService";
import { IAlert } from "@api/models/market/IAlert";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import odataQuery, { FilterDate } from "odata-fluent-query";

export class AlertsService extends BaseODataService implements IAlertsService {
  constructor() {
    super();
  }

  public Insert(alert: IAlert): AxiosPromise<IAlert> {
    return this.Client().post("Alerts", alert);
  }

  public Update(alertKey: number, alert: IAlert): AxiosPromise<void> {
    return this.Client().put(`Alerts(${alertKey})`, alert);
  }

  public Patch(alertKey: number, alert: Partial<IAlert>): AxiosPromise<void> {
    return this.Client().patch(`Alerts(${alertKey})`, alert);
  }

  public Delete(alertKey: number): AxiosPromise<void> {
    return this.Client().delete(`Alerts(${alertKey})`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IAlert>> {
    const query = odataQuery<IAlert>().orderBy("fromDate").orderBy("toDate");

    return this.Client().get(`Alerts?${query.toString()}`);
  }

  public FetchAllAlerts(fromDate?: Date, toDate?: Date): AxiosPromise<IODataCollectionResponse<IAlert>> {
    if (!authStore.IsAdministrator) return Promise.reject(new Error("unauthorized"));

    let query = odataQuery<IAlert>().orderBy("fromDate").orderBy("toDate");

    if (fromDate) query = query.filter((a) => (a.fromDate as any as FilterDate).isAfterOrEqual(fromDate.toISOString()));
    if (toDate) query = query.filter((a) => (a.fromDate as any as FilterDate).isBeforeOrEqual(toDate.toISOString()));

    return this.Client().get(`Alerts?${query.toString()}`);
  }

  public FetchAllByUser(userKey?: string): AxiosPromise<IODataCollectionResponse<IAlert>> {
    let query = odataQuery<IAlert>()
      .filter((a) => (a.fromDate as any as FilterDate).isBeforeOrEqual(new Date().toISOString()))
      .filter((a) => (a.toDate as any as FilterDate).isAfterOrEqual(new Date().toISOString()))
      .filter((a) => a.flagAsRead.notEquals(true));

    if (userKey) {
      query = query.filter((a) => a.userId.equals(userKey, { ignoreGuid: true }).or(a.userId.isNull()));
    } else {
      query = query.filter((a) => a.userId.isNull());
    }

    return this.Client().get(`Alerts?${query.toString()}`);
  }
}
