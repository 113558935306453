export class MailHelper {
  public static HrefToMailProviderWebSite(email: string): string {
    if (!email) {
      return "";
    }

    const chunks = email.split("@");
    const domain = chunks[1];

    return "//" + domain;
  }
}
