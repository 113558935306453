import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator";

@Component
export default class FormPaneCheck extends Vue {
  @Model("change", { type: [String, Number, Boolean, Object, Array] })
  readonly model: any;
  @Prop({ default: undefined, type: [String, Number, Boolean, Object] })
  readonly value?: string | number | boolean | object;
  @Prop({ type: [Boolean, String], default: true })
  readonly trueValue: boolean | string;
  @Prop({ type: [Boolean, String], default: false })
  readonly falseValue: boolean | string;
  @Prop()
  readonly validationRules?: string | Record<string, any>;
  @Prop()
  readonly isInvalid: boolean;
  @Prop()
  readonly icon?: string | string[];

  get IsSelected(): boolean {
    return this.model === this.value || this.model === this.trueValue;
  }

  @Emit("select")
  public OnClick(): string | number | boolean | object | undefined {
    return this.value;
  }
}
