import { nameof } from "@pigeon/extensions/nameof";
import { IListOption } from "@pigeon/models/IListOption";
import { v4 as UUIDv4 } from "uuid";
import { Component, Emit, Model, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class FormInputSelect extends Vue {
  @Model("change", { type: [String, Number, Object] })
  readonly model: string | number | object | null;

  @Prop()
  readonly label: string;
  @Prop({ default: true })
  readonly hasFloatingLabel: boolean;

  @Prop({ type: Array })
  readonly options?: IListOption[];
  @Prop()
  readonly optionsGroups?: { [key: string]: IListOption[] };
  @Prop({ type: String, default: () => `select-${UUIDv4()}` })
  readonly id: string;
  @Prop({ default: false })
  readonly isBoxed: boolean;
  @Prop()
  readonly validationRules?: string | Record<string, any>;
  @Prop()
  readonly validationName?: string;
  @Prop({ default: () => `input_${UUIDv4().toString()}` })
  readonly validationId: string;
  @Prop()
  readonly hasError?: boolean;

  selectedValue: string | number | object | null = null;
  hovered: boolean = false;
  hasFocus: boolean = false;

  get HasAnOptionSelected(): boolean {
    if (!this.options) return false;

    let hasAnOptionSelected: boolean = false;

    for (const option of this.options) {
      if (option.value === this.selectedValue) {
        hasAnOptionSelected = true;
      }
    }

    return hasAnOptionSelected;
  }

  get Attrs(): any {
    return { ...this.$attrs };
  }

  get Listeners() {
    return { ...this.$listeners, change: this.Select };
  }

  get HasFocus(): boolean {
    return this.hasFocus;
  }

  set HasFocus(value: boolean) {
    this.hasFocus = value;
  }

  @Watch(nameof<FormInputSelect>("model"), { immediate: true })
  ObserveModelValue() {
    this.selectedValue = this.model;
  }

  @Emit("change")
  public Select() {
    return this.selectedValue;
  }
}
