import { IMenuItem } from "@pigeon/models/IMenuItem";

export const AspNetCoreCookieCulture = ".AspNetCore.Culture";
export const DEFAULT_LOCALE = process.env.VUE_APP_I18N_LOCALE || "en";
export const FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";
export const SUPPORTED_LOCALES: { [key: string]: IMenuItem } = {
  // default language
  en: {
    label: "EN",
    description: "English",
    value: "en"
  },
  fr: {
    label: "FR",
    description: "Français",
    value: "fr"
  },
  nl: {
    label: "NL",
    description: "Nederlands",
    value: "nl"
  },
  pl: {
    label: "PL",
    description: "Polskie",
    value: "pl"
  },
  pt: {
    label: "PT",
    description: "Português",
    value: "pt"
  }
};
