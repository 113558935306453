import router from "@market/routers";
import Vue from "vue";
import VueAppInsights from "vue-application-insights";
import VueGtag from "vue-gtag";

if (process.env.NODE_ENV === "production") {
  Vue.use(VueAppInsights, {
    id: "c89077e8-a7da-4067-8489-0122fd7a30d9",
    router
  });

  Vue.use(
    VueGtag,
    {
      config: {
        id: "UA-67421427-2"
      },
      bootstrap: false
    },
    router
  );
}
