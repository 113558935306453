import { AuthService } from "@pigeon/plugins/auth/AuthService";
import Vue from "vue";

// See https://fr.vuejs.org/v2/guide/plugins.html
const authPlugin: any = {
  install(Vue: Vue) {
    (Vue as any).prototype.$auth = new AuthService();
  }
};

export default authPlugin;
