import { SearchTypes } from "@api/models/market/constants/SearchTypes";
import { IOrder } from "@api/models/market/IOrder";
import { IOrderLine } from "@api/models/market/IOrderLine";
import { IPackage } from "@api/models/market/IPackage";
import { IPigeon } from "@api/models/market/IPigeon";
import { ISale } from "@api/models/market/ISale";
import { CatalogFilterTypes } from "@pigeon/enumerations/CatalogFilterTypes";
import { CatalogSortTypes } from "@pigeon/enumerations/CatalogSortTypes";
import { nameof } from "@pigeon/extensions/nameof";
import dayjs from "dayjs";
import { FilterDate, FilterExpression, ODataQuery } from "odata-fluent-query";
import { ISalesQueryManager } from "./contracts/ISalesQueryManager";

type OrderDirection = "asc" | "desc" | undefined;

// See https://github.com/rosostolato/odata-fluent-query/issues/9

export class SalesQueryManager implements ISalesQueryManager {
  //#region Sort
  // Business rule: Sort archived sales at the end of the results
  public OrderBy(query: ODataQuery<ISale>, sort?: string): ODataQuery<ISale> {
    if (!sort) return query;

    const sortParams: string[] = sort.split(";");
    const orderPropertyName: string = sortParams[0];
    const orderDirection: string = sortParams[1];

    if (orderPropertyName == CatalogSortTypes.Name) {
      // Workaround for issue https://github.com/rosostolato/odata-fluent-query/issues/8
      // return query.orderBy(`package/name ${orderDirection || "asc"}, pigeon/name ${orderDirection || "asc"}` as any);
      return query
        .orderBy("flagArchived")
        .orderBy(`${nameof<ISale>("pigeon")}/${nameof<IPigeon>("name")}` as any, orderDirection as OrderDirection)
        .orderBy(`${nameof<ISale>("package")}/${nameof<IPackage>("name")}` as any, orderDirection as OrderDirection);
    } else if (orderPropertyName == CatalogSortTypes.Ring) {
      return query
        .orderBy("flagArchived")
        .orderBy(
        `${nameof<ISale>("pigeon")}/${nameof<IPigeon>("ring")}` as any,
        orderDirection as OrderDirection
      );
    } else if (orderPropertyName == CatalogSortTypes.Price) {
      return query.orderBy("flagArchived").orderBy("price", orderDirection as OrderDirection);
    } else if (orderPropertyName == CatalogSortTypes.SaleEndDate) {
      return query.orderBy("flagArchived").orderBy("endDate", orderDirection as OrderDirection);
    } else if (orderPropertyName == CatalogSortTypes.SaleStartDate) {
      return query.orderBy("flagArchived").orderBy("startDate", orderDirection as OrderDirection);
    } else if (orderPropertyName == CatalogSortTypes.PurchaseDate) {
      return query.orderBy(
        `${nameof<ISale>("orderLine")}/${nameof<IOrderLine>("order")}/${nameof<IOrder>("orderDate")}` as any,
        orderDirection as OrderDirection
      );
    }

    return query;
  }
  //#endregion

  //#region Filter
  private ConvertToArray(filterValue: any): any[] {
    if (Array.isArray(filterValue)) return filterValue;

    return [filterValue];
  }

  public FilterBy(
    query: ODataQuery<ISale>,
    filters?: Map<CatalogFilterTypes, string[] | boolean[]>
  ): ODataQuery<ISale> {
    if (!filters) return query;

    if (filters.has(CatalogFilterTypes.Favorite)) {
      query = this.FilterQueryByFavorite(
        query,
        this.ConvertToArray(filters.get(CatalogFilterTypes.Favorite)) as string[]
      );
    }
    if (filters.has(CatalogFilterTypes.Vendor)) {
      query = this.FilterQueryByVendor(query, this.ConvertToArray(filters.get(CatalogFilterTypes.Vendor)) as string[]);
    }
    if (filters.has(CatalogFilterTypes.Status)) {
      query = this.FilterQueryByStatus(query, this.ConvertToArray(filters.get(CatalogFilterTypes.Status)) as string[]);
    }
    if (filters.has(CatalogFilterTypes.FlagApproved)) {
      query = this.FilterQueryByFlagApproved(
        query,
        this.ConvertToArray(filters.get(CatalogFilterTypes.FlagApproved)) as boolean[]
      );
    }
    if (
      filters.has(CatalogFilterTypes.StartDateGreaterOrEqualAt) ||
      filters.has(CatalogFilterTypes.StartDateLowerThat)
    ) {
      query = this.FilterQueryByStartDate(
        query,
        filters.get(CatalogFilterTypes.StartDateGreaterOrEqualAt) as string[] | undefined,
        filters.get(CatalogFilterTypes.StartDateLowerThat) as string[] | undefined
      );
    }
    if (filters.has(CatalogFilterTypes.EndDateGreaterOrEqualAt) || filters.has(CatalogFilterTypes.EndDateLowerThat)) {
      query = this.FilterQueryByEndDate(
        query,
        filters.get(CatalogFilterTypes.EndDateGreaterOrEqualAt) as string[] | undefined,
        filters.get(CatalogFilterTypes.EndDateLowerThat) as string[] | undefined
      );
    }
    if (filters.has(CatalogFilterTypes.CreatedDateBetween)) {
      query = this.FilterQueryByCreatedDatePeriod(
        query,
        filters.get(CatalogFilterTypes.CreatedDateBetween)?.[0] as string | undefined,
        filters.get(CatalogFilterTypes.CreatedDateBetween)?.[1] as string | undefined
      );
    }
    if (filters.has(CatalogFilterTypes.PaymentStatus)) {
      query = this.FilterQueryByPaymentStatus(
        query,
        this.ConvertToArray(filters.get(CatalogFilterTypes.PaymentStatus)) as string[]
      );
    }
    if (filters.has(CatalogFilterTypes.TransferStatus)) {
      query = this.FilterQueryByTransferStatus(
        query,
        this.ConvertToArray(filters.get(CatalogFilterTypes.TransferStatus)) as string[]
      );
    }

    if (filters.has(CatalogFilterTypes.Sex)) {
      query = this.FilterQueryBySex(query, this.ConvertToArray(filters.get(CatalogFilterTypes.Sex)) as string[]);
    }
    if (filters.has(CatalogFilterTypes.Age)) {
      query = this.FilterQueryByAge(query, this.ConvertToArray(filters.get(CatalogFilterTypes.Age)) as string[]);
    }
    if (filters.has(CatalogFilterTypes.Discipline)) {
      query = this.FilterQueryByDiscipline(
        query,
        this.ConvertToArray(filters.get(CatalogFilterTypes.Discipline)) as string[]
      );
    }
    if (filters.has(CatalogFilterTypes.Country)) {
      query = this.FilterQueryByCountry(
        query,
        this.ConvertToArray(filters.get(CatalogFilterTypes.Country)) as string[]
      );
    }
    if (filters.has(CatalogFilterTypes.Auction)) {
      query = this.FilterQueryByAuction(
        query,
        this.ConvertToArray(filters.get(CatalogFilterTypes.Auction)) as string[]
      );
    }
    if (filters.has(CatalogFilterTypes.Shop)) {
      query = this.FilterQueryByShop(query, this.ConvertToArray(filters.get(CatalogFilterTypes.Shop)) as string[]);
    }
    if (filters.has(CatalogFilterTypes.FlagSharedCatalog)) {
      query = this.FilterQueryByFlagSharedCatalog(
        query,
        this.ConvertToArray(this.ConvertToArray(filters.get(CatalogFilterTypes.FlagSharedCatalog))) as boolean[]
      );
    }
    if (filters.has(CatalogFilterTypes.FlagArchived)) {
      query = this.FilterQueryByFlagArchived(
        query,
        this.ConvertToArray(this.ConvertToArray(filters.get(CatalogFilterTypes.FlagArchived))) as boolean[]
      );
    }
    if (filters.has(CatalogFilterTypes.FlagVoucher)) {
      query = this.FilterQueryByFlagVoucher(
        query,
        this.ConvertToArray(filters.get(CatalogFilterTypes.FlagVoucher)) as boolean[]
      );
    }
    if (filters.has(CatalogFilterTypes.SaleType)) {
      query = this.FilterQueryBySaleType(
        query,
        this.ConvertToArray(filters.get(CatalogFilterTypes.SaleType)) as string[]
      );
    }
    if (filters.has(CatalogFilterTypes.ProductType)) {
      query = this.FilterQueryByProductType(
        query,
        this.ConvertToArray(filters.get(CatalogFilterTypes.ProductType)) as string[]
      );
    }
    if (filters.has(CatalogFilterTypes.PriceMin)) {
      query = this.FilterQueryByPriceMin(
        query,
        filters.get(CatalogFilterTypes.PriceMin)?.shift() as string | undefined
      );
    }
    if (filters.has(CatalogFilterTypes.PriceMax)) {
      query = this.FilterQueryByPriceMax(
        query,
        filters.get(CatalogFilterTypes.PriceMax)?.shift() as string | undefined
      );
    }

    return query;
  }

  private FilterQueryByFavorite(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => s.id.in(filterValues.map((v) => parseInt(v))));
  }

  private FilterQueryByVendor(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => s.userId.in(filterValues));
  }

  private FilterQueryByFlagSharedCatalog(query: ODataQuery<ISale>, filterValues?: boolean[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => {
      let filterExpression: FilterExpression | undefined = undefined;

      for (const filterValue of filterValues) {
        filterExpression = !filterExpression
          ? s.flagSharedCatalog.equals(filterValue)
          : filterExpression.or(s.flagSharedCatalog.equals(filterValue));
      }

      return filterExpression as any;
    });
  }

  private FilterQueryByFlagArchived(query: ODataQuery<ISale>, filterValues?: boolean[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => {
      let filterExpression: FilterExpression | undefined = undefined;

      for (const filterValue of filterValues) {
        filterExpression = !filterExpression
          ? s.flagArchived.equals(filterValue)
          : filterExpression.or(s.flagArchived.equals(filterValue));
      }

      return filterExpression as any;
    });
  }

  private FilterQueryByFlagApproved(query: ODataQuery<ISale>, filterValues?: boolean[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => {
      let filterExpression: FilterExpression | undefined = undefined;

      for (const filterValue of filterValues) {
        filterExpression = !filterExpression
          ? s.flagApproved.equals(filterValue)
          : filterExpression.or(s.flagApproved.equals(filterValue));
      }

      return filterExpression as any;
    });
  }

  private FilterQueryByFlagVoucher(query: ODataQuery<ISale>, filterValues?: boolean[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => {
      let filterExpression: FilterExpression | undefined = undefined;

      for (const filterValue of filterValues) {
        filterExpression = !filterExpression
          ? s.flagVoucher.equals(filterValue)
          : filterExpression.or(s.flagVoucher.equals(filterValue));
      }

      return filterExpression as any;
    });
  }

  private FilterQueryByStatus(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => s.status.in(filterValues));
  }

  private FilterQueryBySex(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    // prettier-ignore
    return query.filter((s) =>
      s.pigeon.sex.in(filterValues)
      .or(
          s.package.pigeons.any((p) => p.sex.in(filterValues))
      )
    );
  }

  private FilterQueryByAge(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    let filteredQuery = query;

    // Start Year
    filteredQuery = filteredQuery.filter((s) => {
      let filterExpression: FilterExpression | undefined = undefined;

      // Note: AgeFilters must be combine with odata OR
      // Example $filter= (ageFilter1) OR (ageFilter2)
      for (const ageFilterValue of filterValues) {
        const startAge = this.ConvertAgeCategoryToStartYear(ageFilterValue);
        const endAge = this.ConvertAgeCategoryToEndYear(ageFilterValue);

        if (startAge) {
          // prettier-ignore
          filterExpression = !filterExpression 
          ? s.pigeon.birthYear.biggerOrEqualThan(startAge)
          : filterExpression.or(s.pigeon.birthYear.biggerOrEqualThan(startAge));

          // Package query
          // .or(
          //   s.package.pigeons.any((p) => p.birthYear.biggerOrEqualThan(startAge))
          // )
        }

        if (endAge) {
          // WARNING: Handle the case 'Yearling' Age that combines startAge and endAge
          // Example: odata query ?filter= ((...) AND (...)) // note the parenthese that wrap the AND
          if (startAge) {
            //prettier-ignore
            filterExpression = !filterExpression 
            ? s.pigeon.birthYear.lessOrEqualThan(endAge)
            : filterExpression.and(s.pigeon.birthYear.lessOrEqualThan(endAge));
          } else {
            // prettier-ignore
            filterExpression = !filterExpression 
            ? s.pigeon.birthYear.lessOrEqualThan(endAge)
            : filterExpression.or(s.pigeon.birthYear.lessOrEqualThan(endAge));
          }

          // Package query
          // .or(
          //   s.package.pigeons.any((p) => p.birthYear.lessOrEqualThan(endAge))
          // )
        }
      }
      return filterExpression as any;
    });

    return filteredQuery;
  }

  private FilterQueryByStartDate(
    query: ODataQuery<ISale>,
    filterValuesGreaterOrEqualAt?: string[],
    filterValuesLowerThat?: string[]
  ): ODataQuery<ISale> {
    if (
      (!filterValuesGreaterOrEqualAt || !filterValuesGreaterOrEqualAt.length) &&
      (!filterValuesLowerThat || !filterValuesLowerThat.length)
    )
      return query;

    return query.filter((s) => {
      let filterExpression: FilterExpression | undefined = undefined;

      if (filterValuesGreaterOrEqualAt) {
        for (const filterValue of filterValuesGreaterOrEqualAt) {
          // prettier-ignore
          filterExpression = !filterExpression
            ? ((s.startDate as any) as FilterDate).isAfterOrEqual(filterValue)
            : filterExpression.or(((s.startDate as any) as FilterDate).isAfterOrEqual(filterValue));
        }
      }

      if (filterValuesLowerThat) {
        for (const filterValue of filterValuesLowerThat) {
          filterExpression = !filterExpression
            ? (s.startDate as any as FilterDate).isBefore(filterValue)
            : filterExpression.or((s.startDate as any as FilterDate).isBefore(filterValue));
        }
      }

      return filterExpression as any;
    });
  }

  private FilterQueryByEndDate(
    query: ODataQuery<ISale>,
    filterValuesGreaterOrEqualAt?: string[],
    filterValuesLowerThat?: string[]
  ): ODataQuery<ISale> {
    if (
      (!filterValuesGreaterOrEqualAt || !filterValuesGreaterOrEqualAt.length) &&
      (!filterValuesLowerThat || !filterValuesLowerThat.length)
    )
      return query;

    return query.filter((s) => {
      let filterExpression: FilterExpression | undefined = undefined;

      if (filterValuesGreaterOrEqualAt) {
        for (const filterValue of filterValuesGreaterOrEqualAt) {
          // prettier-ignore
          filterExpression = !filterExpression
            ? ((s.endDate as any) as FilterDate).isAfterOrEqual(filterValue)
            : filterExpression.or(((s.endDate as any) as FilterDate).isAfterOrEqual(filterValue));
        }
      }

      if (filterValuesLowerThat) {
        for (const filterValue of filterValuesLowerThat) {
          filterExpression = !filterExpression
            ? (s.endDate as any as FilterDate).isBefore(filterValue)
            : filterExpression.or((s.endDate as any as FilterDate).isBefore(filterValue));
        }
      }

      return filterExpression as any;
    });
  }

  private FilterQueryByCreatedDatePeriod(
    query: ODataQuery<ISale>,
    filterValueStartPeriod?: string,
    filterValueEndPeriod?: string
  ): ODataQuery<ISale> {
    if (!filterValueStartPeriod || !filterValueEndPeriod) return query;

    return query.filter((s) =>
      (s.createdAt as FilterDate)
        .isAfterOrEqual(filterValueStartPeriod)
        .and((s.createdAt as FilterDate).isBeforeOrEqual(filterValueEndPeriod))
    );
  }

  private FilterQueryByDiscipline(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) =>
      s.pigeon.discipline.in(filterValues).or(s.package.pigeons.any((p) => p.discipline.in(filterValues)))
    );
  }

  private FilterQueryByCountry(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) =>
      s.pigeon.ringArea.in(filterValues).or(s.package.pigeons.any((p) => p.ringArea.in(filterValues)))
    );
  }

  private FilterQueryByAuction(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => s.auctionId.in(filterValues?.filter((fv) => fv)?.map((fv) => parseInt(fv))));
  }

  private FilterQueryByShop(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => s.user.vendor.shopId.in(filterValues));
  }

  private FilterQueryBySaleType(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => s.type.in(filterValues));
  }

  private FilterQueryByProductType(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    return query.filter((s) => s.product.in(filterValues));
  }

  private FilterQueryByPaymentStatus(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    // prettier-ignore
    return query
      .filter((s) => s.orderLine.order.id.notNull())
      .filter((s) => s.orderLine.order.paymentStatus.in(filterValues));
  }

  private FilterQueryByTransferStatus(query: ODataQuery<ISale>, filterValues?: string[]): ODataQuery<ISale> {
    if (!filterValues || !filterValues.length) return query;

    // prettier-ignore
    query = query
      .filter((s) => s.orderLine.order.id.notNull());

    if(filterValues.some(v => v == 'undefined' || v == 'null'))
      // prettier-ignore
      query = query.filter((s) => s.orderLine.order.transferStatus.isNull().or(s.orderLine.order.transferStatus.in(filterValues)));
    else
      // prettier-ignore
      query = query.filter((s) => s.orderLine.order.transferStatus.in(filterValues));

    return query;
  }

  private FilterQueryByPriceMin(query: ODataQuery<ISale>, filterValue?: string): ODataQuery<ISale> {
    if (!filterValue) return query;

    const minPriceFilterValue: number = parseInt(filterValue);
    return query.filter((s) => s.price.biggerOrEqualThan(minPriceFilterValue));
  }

  private FilterQueryByPriceMax(query: ODataQuery<ISale>, filterValue?: string): ODataQuery<ISale> {
    if (!filterValue) return query;

    const maxPriceFilterValue: number = parseInt(filterValue);
    return query.filter((s) => s.price.lessOrEqualThan(maxPriceFilterValue));
  }

  private ConvertAgeCategoryToStartYear(ageCategory: string): number | undefined {
    const now = dayjs();

    switch (ageCategory) {
      case "J":
        return now.year();
      case "Y":
        return now.subtract(1, "year").year();
      case "S":
      default:
        return undefined;
    }
  }

  private ConvertAgeCategoryToEndYear(ageCategory: string): number | undefined {
    const now = dayjs();

    switch (ageCategory) {
      case "J":
        return undefined;
      case "Y":
        return now.subtract(1, "year").year();
      case "S":
      default:
        return now.subtract(2, "year").year();
    }
  }
  //#endregion

  //#region Search
  public SearchBy(query: ODataQuery<ISale>, search: string | null, searchType: SearchTypes): ODataQuery<ISale> {
    if (!search) return query;
    if (!searchType) return query;

    // should return `contains(pigeon/name,'${query}') or contains(pigeon/ring,'${query}') or contains(package/name, '${query}') or package/pigeons/any(p:contains(p/name, '${query}')) or package/pigeons/any(p:contains(p/ring, '${query}'))`; // search by like
    // prettier-ignore
    switch (searchType) {
      case SearchTypes.SearchByRing:
        return query.filter((s) =>
          s.pigeon.ring.contains(search)
          .or(
            s.package.pigeons.any(p => p.ring.contains(search))
          )
        );
    
      case SearchTypes.SearchByName:
        return query.filter((s) =>
          s.pigeon.name.contains(search)
          .or(
            s.package.name.contains(search)
            .or(
              s.package.pigeons.any(p => p.name.contains(search))
            )
          )
        );

      case SearchTypes.SearchByRingOrName:
        return query.filter((s) =>
          s.pigeon.name.contains(search)
          .or(
            s.pigeon.ring.contains(search)
            .or(
              s.package.name.contains(search)
              .or(
                s.package.pigeons.any(p => 
                  p.name.contains(search)
                  .or(
                    p.ring.contains(search)
                  )
                )
              )
            )
          )
        );

      default:
        throw new Error(`Operation exception: search type '${searchType}' not supported.`);
    }
  }
  //#endregion
}
