import { IServersService } from "@api/contracts/webapi/IServersService";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { AxiosPromise } from "axios";

export class ServersService extends BaseApiService implements IServersService {
  constructor() {
    super();
  }

  public GetDateTime(): AxiosPromise<string> {
    return this.Client().get("Server/DateTime");
  }
}
