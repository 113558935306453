import { IAccountsService } from "@api/contracts/auth/IAccountsService";
import { Channels } from "@api/enumerations/Channels";
import { RegistrationFactory } from "@api/factories/registrationFactory";
import { IAuthSession } from "@api/models/auth/IAuthSession";
import { IRegistration } from "@api/models/auth/IRegistration";
import AccountSignUpDoneView from "@market/pages/views/auth/AccountSignUpDone.vue";
import AccountSignUpVerificationView from "@market/pages/views/auth/AccountSignUpVerification.vue";
import { AppRoutes } from "@market/routers/App.routes";
import {
  appDataStore,
  authStore,
  signInDialogStore,
  signUpDialogStore,
  signUpVendorDialogStore
} from "@market/stores/App.store.modules";
import { nameof } from "@pigeon/extensions/nameof";
import { IListOption } from "@pigeon/models/IListOption";
import { IErrorManager } from "@pigeon/services/contracts/IErrorManager";
import { ErrorManager } from "@pigeon/services/ErrorManager";
import { Inject } from "inversify-props";
import { ValidationObserver } from "vee-validate";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    SignUpDone: AccountSignUpDoneView,
    SignUpVerification: AccountSignUpVerificationView
  }
})
export default class AccountSignUpView extends Vue {
  @Ref()
  readonly refValidation: InstanceType<typeof ValidationObserver>;

  @Inject()
  private accountsService: IAccountsService;

  AppRoutes = AppRoutes;
  registration: IRegistration = RegistrationFactory.GetDefaultRegistration();
  registrationIsDone: boolean = false;
  isProcessing: boolean = false;
  errorManager: IErrorManager = new ErrorManager();

  get CountryOptions(): IListOption[] {
    return appDataStore.CountryOptions;
  }

  mounted() {
    this.errorManager = new ErrorManager(this.refValidation);
  }

  @Watch(nameof<AccountSignUpView>("$route"), { immediate: true })
  ObserveRedirectUrl(): void {
    this.registration.redirectUrl = this.$route.path;
  }

  public SwitchToSignUpVendorDialog(): void {
    signUpDialogStore.Close();
    signUpVendorDialogStore.Open();
  }

  public SwitchToSignInDialog(): void {
    signUpDialogStore.Close();
    signInDialogStore.Open();
  }

  // sign up form submit
  public async Submit() {
    if (!this.registration) return;

    this.isProcessing = true;
    this.errorManager.Reset();

    // Set current UI locale as preferred language
    this.registration.preferredLanguage = this.$i18n.locale;

    this.accountsService
      .SignUp(Channels.Market, this.registration)
      .then((response) => {
        this.registrationIsDone = true;
        authStore.AuthSession(response.data); // auto sign on (after signing up)
        // signOnDialogStore.Open();
      })
      .catch((error: any) => {
        this.errorManager.HandleError(error);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }
}
