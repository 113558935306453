import { IPigeon } from "@api/models/market/IPigeon";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { Inject } from "inversify-props";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PigeonPedigreePicture extends Vue {
  @Prop()
  readonly pigeon: IPigeon;
  @Prop({ default: false })
  readonly useRawPicture: boolean;

  @Inject()
  private pigeonManager: IPigeonManager;

  forceFallbackPicture: boolean = false;

  get PedigreePictureUrl(): string {
    if (!this.pigeon) {
      return this.pigeonManager.defaultPedigreePicture;
    }

    return this.pigeonManager.GetPicturePedigree(this.pigeon.pictures, this.useRawPicture || this.forceFallbackPicture);
  }

  public OnPedigreePictureUrl(): void {
    this.forceFallbackPicture = true;
  }
}
