import { IBingMapsRestService } from "@api/contracts/external/IBingMapsRestService";
import { BaseApiBingMapsRestService } from "@api/services/external/base/BaseApiBingMapsRestService";
import { AxiosPromise } from "axios";

// See https://msdn.microsoft.com/en-us/library/ff701715.aspx
export class BingMapsRestService extends BaseApiBingMapsRestService implements IBingMapsRestService {
  constructor() {
    super();
  }

  // See https://msdn.microsoft.com/en-us/library/ff701711.aspx
  public FindLocationByQuery(
    locationQuery: string,
    maxResults?: number,
    includeNeighborhood?: number,
    includeValue?: string
  ): AxiosPromise<any> {
    if (!process.env.VUE_APP_BING_MAPS_API_KEY) return Promise.reject();

    let query = `Locations/`;
    const filters: string[] = [];

    filters.push(`key=${process.env.VUE_APP_BING_MAPS_API_KEY}`);
    // Output setted to Json format
    filters.push(`o=json`);

    if (query) {
      filters.push(`query=${locationQuery}`);
    }

    if (typeof maxResults !== "undefined") {
      filters.push(`maxResults=${maxResults}`);
    }

    if (typeof includeNeighborhood !== "undefined") {
      filters.push(`includeNeighborhood=${includeNeighborhood}`);
    }

    if (typeof includeValue !== "undefined") {
      filters.push(`includeValue=${includeValue}`);
    }

    if (filters.length) {
      query += `?${filters.join("&")}`;
    }

    return this.Client().get<any>(query);
  }
}
