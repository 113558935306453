import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class AppDividerToggler extends Vue {
  @Prop()
  readonly toggle: boolean;
  @Prop()
  readonly labelExpand: string;
  @Prop()
  readonly labelCollapse: string;

  @Emit("toggled")
  Toggle(): void {
    this.toggle;
  }
}
