import { PictureTypes } from "@api/models/market/constants/PictureTypes";
import { SaleTypes } from "@api/models/market/constants/SaleTypes";
import { IAuction } from "@api/models/market/IAuction";
import { IPicture } from "@api/models/market/IPicture";
import { ISale } from "@api/models/market/ISale";
import { IUser } from "@api/models/market/IUser";
import { authStore } from "@market/stores/App.store.modules";
import { MathHelper } from "@pigeon/helpers/MathHelper";
import { IAuctionManager } from "@pigeon/services/contracts/IAuctionManager";
import { Inject } from "inversify-props";
import { ISaleManager } from "./contracts/ISaleManager";

export class AuctionManager implements IAuctionManager {
  readonly defaultAuctionPicture = require("@pigeon/assets/images/pictures/auction-generic.webp");

  @Inject()
  private saleManager: ISaleManager;

  //#region Picture/Video
  public GetPictureRemoteUrl(relativeUrl: string | undefined): string {
    if (!relativeUrl) return this.defaultAuctionPicture;

    // test if parameter is a remote url
    if (/(http(s?)):\/\//gi.test(relativeUrl)) return relativeUrl;

    // test if parameter is a inline data image
    if (/(data):/gi.test(relativeUrl)) return relativeUrl;

    return `${process.env.VUE_APP_AZURE_STORAGE_HOST}${relativeUrl}`;
  }

  public GetPictureAuction(pictures?: IPicture[], useRawPicture = false): string {
    if (!pictures || !pictures.length) {
      return `${this.defaultAuctionPicture}`;
    }

    const picture: IPicture | undefined = pictures.find((picture) => picture.type === PictureTypes.Auction);
    if (!picture) return this.defaultAuctionPicture;

    const pictureUrl = picture.urlWebOptimized && !useRawPicture ? picture.urlWebOptimized : picture.url;
    if (!pictureUrl) return this.defaultAuctionPicture;

    return this.GetPictureRemoteUrl(pictureUrl);
  }

  public GetThumbnailAuction(auction: IAuction, useRawPicture = false): string {
    if (!auction.pictures?.length) return "";

    const picture = this.GetPictureAuction(auction.pictures, useRawPicture);
    if (!picture) return "";

    try {
      const thumbnailUrl = new URL(picture);
      // if (!useRawPicture) {
      //   const thumbnailPathSegments = thumbnailUrl.pathname.split("/");

      //   if (thumbnailPathSegments.length > 0) {
      //     thumbnailPathSegments[thumbnailPathSegments.length - 1] = "thumbnail.webp";
      //     const webOptimizedThumbnailPath = thumbnailPathSegments.join("/");
      //     thumbnailUrl.pathname = webOptimizedThumbnailPath;
      //   }
      // }

      return thumbnailUrl.href;
    } catch (error: any) {
      console.error(`Invalid URL picture : '${picture}'`);
      return "";
    }
  }
  //#endregion

  //#region Function
  public IsBidSaleClose(sale: ISale) {
    if (!sale || !sale.endDate) return false;

    return new Date() > new Date(sale.endDate);
  }

  public IsVendorOfThisSale(sale: ISale, currentUser: IUser): boolean {
    if (!sale || !authStore.IsVendor || !currentUser) return false;

    return sale.userId === currentUser.id;
  }

  public ShouldShowBidderContact(sale: ISale, currentUser: IUser): boolean {
    if (!sale || !currentUser) return false;

    return this.IsBidSaleClose(sale) && (authStore.IsAdministrator || this.IsVendorOfThisSale(sale, currentUser))
      ? true
      : false;
  }

  public ShouldShowProfits(sale: ISale, currentUser: IUser): boolean {
    if (!sale || !currentUser) return false;

    return sale.type == SaleTypes.Bid &&
      this.IsBidSaleClose(sale) &&
      (authStore.IsAdministrator || this.IsVendorOfThisSale(sale, currentUser))
      ? true
      : false;
  }
  //#endregion

  //#region SalesByAuction
  public FilterSalesByAuction(sales: ISale[], auction: IAuction): ISale[] {
    if (!sales?.length || !auction) return [];

    const saleIdsOfAuction = auction.sales.map((s) => s.id);
    return sales.filter((s) => saleIdsOfAuction.includes(s.id));
  }

  public CountSalesByAuction(sales: ISale[], auction: IAuction): number {
    const salesOfAuction = this.FilterSalesByAuction(sales, auction);

    return salesOfAuction?.length ?? 0;
  }

  public CountAuctionSales(auction: IAuction): number {
    if (!auction || !auction.sales || !auction.sales.length) return 0;

    return auction.sales.length;
  }
  //#endregion

  //#region AverageAndTotal
  public CalculateAuctionTotalSalePrices(salesOfAuction: ISale[]): number {
    let total: number = 0;

    for (const sale of salesOfAuction) {
      total += sale.price;
    }

    return Math.floor(total);
  }

  public CalculateAuctionTotalBusinessFigures(salesOfAuction: ISale[]): number {
    let total: number = 0;

    const salesOfAuctionWithBids = salesOfAuction.filter((s) => s.bids?.length);
    for (const sale of salesOfAuctionWithBids) {
      total += this.saleManager.CalculateSaleAmount(sale);
    }

    return Math.floor(total);
  }

  public CalculateAuctionSalesAverage(salesOfAuction: ISale[]): number {
    if (salesOfAuction.length <= 0) return 0;

    const totalBusinessFigures = this.CalculateAuctionTotalBusinessFigures(salesOfAuction);
    const saleWithAtLeastOneOffer = salesOfAuction.filter((s) => s.bids && s.bids.length >= 1)?.length ?? 1;
    const salesAverage = totalBusinessFigures / saleWithAtLeastOneOffer;
    return MathHelper.RoundToInteger(salesAverage);
  }

  public CalculateAuctionTotalProfits(salesOfAuction: ISale[]): number {
    let total: number = 0;

    const salesOfAuctionWithBids = salesOfAuction.filter((s) => s.bids?.length);
    for (const sale of salesOfAuctionWithBids) {
      total += this.saleManager.CalculateVendorProfitsAmount(sale);
    }

    return Math.floor(total);
  }

  public CalculateAuctionTotalFees(salesOfAuction: ISale[]): number {
    let total: number = 0;

    const salesOfAuctionWithBids = salesOfAuction.filter((s) => s.bids?.length);
    for (const sale of salesOfAuctionWithBids) {
      total += this.saleManager.CalculatePlatformFeesAmount(sale);
    }

    return Math.floor(total);
  }
  //#endregion

  //#region Count
  public CountAuctionSalesInProgress(salesOfAuction: ISale[]): number {
    const salesInProgressOfAuction = salesOfAuction.filter((s) => s.endDate && new Date(s.endDate) > new Date());

    return salesInProgressOfAuction?.length ?? 0;
  }

  public CountAuctionSalesOver(salesOfAuction: ISale[]): number {
    const salesOverOfAuction = salesOfAuction.filter((s) => s.endDate && new Date(s.endDate) < new Date());

    return salesOverOfAuction?.length ?? 0;
  }

  public CountAuctionBidSales(salesOfAuction: ISale[]): number {
    let bidsCount: number = 0;

    // bidders
    for (const sale of salesOfAuction.filter((s) => s.type === SaleTypes.Bid)) {
      bidsCount += sale.bids?.length ?? 0;
    }

    return bidsCount;
  }

  public CountAuctionBidders(salesOfAuction: ISale[]): number {
    const bidders: Set<string> = new Set();

    // bidders
    for (const sale of salesOfAuction.filter((s) => s.type === SaleTypes.Bid)) {
      if (sale.bids) {
        for (const bid of sale.bids) {
          bidders.add(bid.userId);
        }
      }
    }

    return bidders.size;
  }

  public CountAuctionBiddersCountry(salesOfAuction: ISale[]): number {
    return this.RetrieveAuctionBiddersCountries(salesOfAuction)?.size ?? 0;
  }

  public RetrieveAuctionBiddersCountries(salesOfAuction: ISale[]): Set<string> {
    const countriesOfBidders: Set<string> = new Set();

    // bidders
    for (const sale of salesOfAuction.filter((s) => s.type === SaleTypes.Bid)) {
      if (sale.bids) {
        for (const bid of sale.bids) {
          if (bid.user && bid.user.countryCode) countriesOfBidders.add(bid.user.countryCode);
        }
      }
    }

    return countriesOfBidders;
  }
  //#endregion
}
