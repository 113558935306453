import { IFavoritesService } from "@api/contracts/odata/IFavoritesService";
import { IFavorite } from "@api/models/market/IFavorite";
import { ISale } from "@api/models/market/ISale";
import { store } from "@market/stores";
import { IFavoriteStore } from "@market/stores/contracts/IFavoriteStore";
import { ISaleManager } from "@pigeon/services/contracts/ISaleManager";
import { cid, container, Inject } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { userStore } from "../App.store.modules";

export const STORE_TOKEN: "favorite" = "favorite";
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class FavoriteStore extends VuexModule implements IFavoriteStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private favoritesService: IFavoritesService;
  // @Inject()
  // private saleManager: ISaleManager;

  favorites: IFavorite[] = [];

  @Mutation
  SET_FAVORITES(data: IFavorite[]): void {
    this.favorites = data;
  }

  @Mutation
  ADD_A_FAVORITE(favorite: IFavorite): void {
    this.favorites.unshift(favorite);
  }

  @Mutation
  REMOVE_A_FAVORITE(favorite: IFavorite): void {
    const indexToRemove = this.favorites.findIndex((f) => f.id == favorite.id);
    if (indexToRemove != -1) this.favorites.splice(indexToRemove, 1);
  }

  @Action
  public async Reset(): Promise<void> {
    this.SET_FAVORITES([]);
  }

  @Action
  public async FetchFavorites(): Promise<void> {
    const favoritesService = container.get<IFavoritesService>(cid.FavoritesService);

    try {
      const { data: favoritesData } = await favoritesService.FetchAllWithSaleProductByUser(userStore.user?.id);
      this.SET_FAVORITES(favoritesData.value);
    } catch (error: any) {
      console.error(error?.message || error);
    }
  }

  @Action
  public async AddToFavorite(sale: ISale): Promise<void> {
    if (!sale?.id) return Promise.reject("Argument exception: sale id is undefined");
    if (!userStore?.user?.id) return Promise.reject("Unauthorized exception: user id is undefined");

    const saleManager = container.get<ISaleManager>(cid.SaleManager);
    const insertedFavorite = await saleManager.AddToFavorite(sale.id, userStore.user?.id);
    insertedFavorite.sale = sale;
    this.ADD_A_FAVORITE(insertedFavorite);
  }

  @Action
  public async RemoveFromFavorite(favorite: IFavorite): Promise<void> {
    if (!favorite?.id) return Promise.reject("Argument exception: favorite is undefined");

    const saleManager = container.get<ISaleManager>(cid.SaleManager);
    await saleManager.RemoveFromFavorite(favorite.id);
    this.REMOVE_A_FAVORITE(favorite);
  }
}
