import { FarmSizeTypes } from "@api/models/market/constants/FarmSizeTypes";
import { IFancierProfile } from "@api/models/market/IFancierProfile";
import { IFancierProfileTranslation } from "@api/models/market/IFancierProfileTranslation";
import { DEFAULT_LOCALE } from "@pigeon/i18n/i18n.const";
import { IFancierProfileManager } from "./contracts/IFancierProfileManager";

export class FancierProfileManager implements IFancierProfileManager {
  static readonly RANGE_RATING_SCORE = 5;
  static readonly DEFAULT_RATING_SCORE = 5;
  static readonly defaultFancierProfilePicture = require("@market/assets/images/pictures/thumbnail.webp");

  public GetFancierProfilePicture(fancierProfile: IFancierProfile | null, forceFallbackPicture = false): string {
    if (!fancierProfile || !fancierProfile.picture) return "";

    const relativeUrl = forceFallbackPicture ? fancierProfile?.picture.url : fancierProfile?.picture.urlWebOptimized;
    if (!relativeUrl) return FancierProfileManager.defaultFancierProfilePicture;

    // test if parameter is a remote url
    if (/(http(s?)):\/\//gi.test(relativeUrl)) return relativeUrl;

    // test if parameter is a inline data image
    if (/(data):/gi.test(relativeUrl)) return relativeUrl;

    return `${process.env.VUE_APP_AZURE_STORAGE_HOST}${relativeUrl}`;
  }

  public GetFancierProfileAboutLocalized(
    fancierProfile: IFancierProfile | null,
    localeCode: string
  ): IFancierProfileTranslation | undefined {
    if (!fancierProfile) return;

    let translation = fancierProfile.translations.find((t) => t.localeCode == localeCode);
    // Default locale fallback
    if (!translation) {
      translation = fancierProfile.translations.find((t) => t.localeCode == DEFAULT_LOCALE);
    }
    // Default fallback
    if (!translation) {
      translation = fancierProfile.translations[0];
    }

    return translation;
  }

  public GetRatingAverageScore(fancierProfile: IFancierProfile) {
    if (!fancierProfile || !fancierProfile.ratings || !fancierProfile.ratings.length)
      return FancierProfileManager.DEFAULT_RATING_SCORE;

    let average = 0;

    fancierProfile?.ratings.forEach((r) => (average += r.rating));
    average = average / fancierProfile?.ratings.length; // score on 100
    average = Math.ceil(average / 5) * 5; // around to multiple of 5
    return average / (100 / FancierProfileManager.RANGE_RATING_SCORE); // score on 5
  }

  public GetRatingRemainingScore(fancierProfile: IFancierProfile): number {
    return 5 - this.GetRatingAverageScore(fancierProfile);
  }

  public GetFarmSizeMininum(fancierProfile: IFancierProfile): number {
    if (!fancierProfile) return 0;

    switch (fancierProfile.farmSize) {
      case FarmSizeTypes.Size20:
        return 0;
      case FarmSizeTypes.Size50:
        return 20;
      case FarmSizeTypes.Size100:
        return 50;
      case FarmSizeTypes.Size250:
        return 100;
      case FarmSizeTypes.Size500:
        return 250;
      case FarmSizeTypes.Size1000:
        return 500;

      default:
        return 0;
    }
  }

  public GetFarmSizeMaximum(fancierProfile: IFancierProfile): number {
    if (!fancierProfile) return 0;

    switch (fancierProfile.farmSize) {
      case FarmSizeTypes.Size20:
        return 20;
      case FarmSizeTypes.Size50:
        return 50;
      case FarmSizeTypes.Size100:
        return 100;
      case FarmSizeTypes.Size250:
        return 250;
      case FarmSizeTypes.Size500:
        return 500;
      case FarmSizeTypes.Size1000:
        return 1000;

      default:
        return 0;
    }
  }
}
