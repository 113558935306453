import { IPlacesService } from "@api/contracts/odata/IPlacesService";
import { IPlace } from "@api/models/market/IPlace";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { odataQuery } from "odata-fluent-query";

export class PlacesService extends BaseODataService implements IPlacesService {
  constructor() {
    super();
  }

  public Insert(place: IPlace): AxiosPromise<IPlace> {
    return this.Client().post("Places", place);
  }

  public Fetch(placeKey: number): AxiosPromise<IPlace> {
    return this.Client().get(`Places(${placeKey})`);
  }

  public FetchAll(): AxiosPromise<IODataCollectionResponse<IPlace>> {
    const query = odataQuery<IPlace>().orderBy("name").count();

    return this.Client().get(`Places?${query.toString()}`);
  }
}
