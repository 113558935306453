import { Component, Prop, Vue } from "vue-property-decorator";

export enum NavThemes {
  Light = "light",
  Black = "black",
  Dark = "dark",
  Primary = "primary",
  Info = "info",
  Success = "success",
  Warning = "warning",
  Danger = "danger"
}
@Component
export default class NavTab extends Vue {
  @Prop({ default: false })
  readonly isActive: boolean;
  @Prop({ default: NavThemes.Light, validator: (value: NavThemes) => Object.values(NavThemes).includes(value) })
  readonly theme: NavThemes;

  NavThemes = NavThemes;
}
