import { dateTimeFormats } from "@pigeon/i18n/dateFormats";
import { GetUserLocale } from "@pigeon/i18n/i18n";
import { DEFAULT_LOCALE, FALLBACK_LOCALE } from "@pigeon/i18n/i18n.const";
import locales from "@pigeon/i18n/locales/index";
import { numberFormats } from "@pigeon/i18n/numberFormats";
import Vue from "vue";
// Vue plugins - Internationalization
import VueI18n from "vue-i18n";

// See https://kazupon.github.io/vue-i18n/started.html
Vue.use(VueI18n);

export default new VueI18n({
  fallbackLocale: FALLBACK_LOCALE,
  fallbackRoot: true,
  locale: GetUserLocale() || DEFAULT_LOCALE,
  numberFormats: numberFormats,
  dateTimeFormats: dateTimeFormats,
  silentTranslationWarn: process.env.NODE_ENV === "production",
  silentFallbackWarn: true, // process.env.NODE_ENV === "production" // See issue: https://github.com/kazupon/vue-i18n/issues/689,
  messages: locales
});
