import { ICheckoutSessionsService } from "@api/contracts/odata/ICheckoutSessionsService";
import { ICheckoutSession } from "@api/models/market/ICheckoutSession";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { authStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import { odataQuery } from "odata-fluent-query";

export class CheckoutSessionsService extends BaseODataService implements ICheckoutSessionsService {
  constructor() {
    super();
  }

  public Insert(checkoutSession: ICheckoutSession): AxiosPromise<ICheckoutSession> {
    if (!checkoutSession.checkoutSessionId) return Promise.reject("CheckoutSession invalid");

    return this.Client().post("CheckoutSessions", checkoutSession);
  }

  public Update(checkoutSessionKey: string, checkoutSession: ICheckoutSession): AxiosPromise<void> {
    if (!checkoutSession.checkoutSessionId) return Promise.reject("CheckoutSession invalid");

    return this.Client().put(`CheckoutSessions('${checkoutSessionKey}')`, checkoutSession);
  }

  public Patch(checkoutSessionKey: string, checkoutSession: Partial<ICheckoutSession>): AxiosPromise<void> {
    return this.Client().patch(`CheckoutSessions('${checkoutSessionKey}')`, checkoutSession);
  }

  public Delete(checkoutSessionKey: string): AxiosPromise<void> {
    return this.Client().delete(`CheckoutSessions('${checkoutSessionKey}')`);
  }

  public Fetch(checkoutSessionKey: string): AxiosPromise<ICheckoutSession> {
    return this.Client().get(`CheckoutSessions('${checkoutSessionKey}')`);
  }

  public FetchAllOverview(): AxiosPromise<IODataCollectionResponse<ICheckoutSession>> {
    if (!authStore.IsAdministrator) return Promise.reject("Not authorized");

    // prettier-ignore
    const query = odataQuery<ICheckoutSession>()
      .expand("customer")
      .expand("shipping")
      .expand("payment");

    return this.Client().get(`CheckoutSessions?${query.toString()}`);
  }

  public FetchAllBySale(saleKey: number): AxiosPromise<IODataCollectionResponse<ICheckoutSession>> {
    const query = odataQuery<ICheckoutSession>()
      .filter((cs) => cs.cart.cartItems.any((ci: any) => ci.saleId.equals(saleKey)))
      .expand("customer")
      .expand("shipping")
      .expand("payment")
      .orderBy("checkoutDate", "desc");

    return this.Client().get(`CheckoutSessions?${query.toString()}`);
  }
}
