import { AlertTypes } from "@pigeon/components/app/AppAlert";
import { TYPE } from "vue-toastification";

export class ToastTypeMapper {
  public static MapAlertTypeToToastType(alertType: AlertTypes): TYPE {
    switch (alertType) {
      case AlertTypes.Danger:
        return TYPE.ERROR;

      case AlertTypes.Warning:
        return TYPE.WARNING;

      case AlertTypes.Success:
        return TYPE.SUCCESS;

      case AlertTypes.Info:
        return TYPE.INFO;

      default:
        return TYPE.DEFAULT;
    }
  }
}
