import { IInvoicesService } from "@api/contracts/webapi/IInvoicesService";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { AxiosPromise } from "axios";

export class InvoicesService extends BaseApiService implements IInvoicesService {
  constructor() {
    super();
  }

  DownloadInvoiceVendor(saleKey: number): AxiosPromise<File> {
    return this.Client().get(`Invoices/DownloadInvoiceVendor(saleKey=${saleKey})`, {
      responseType: "arraybuffer"
    });
  }

  DownloadInvoicePlatform(saleKey: number): AxiosPromise<File> {
    return this.Client().get(`Invoices/DownloadInvoicePlatform(saleKey=${saleKey})`, {
      responseType: "arraybuffer"
    });
  }
}
