export const ADMINISTRATOR_ID = "PMSF000";
export const CONTRIBUTOR_ID = "PMSF001";

export enum AppRoles {
  Administrator = "Administrator",
  Approver = "Approver",
  Vendor = "Vendor",
  Contributor = "Contributor",
  Member = "Member"
}
