import { PackageCartItemMapper } from "@api/mappers/PackageCartItemMapper";
import { PigeonCartItemMapper } from "@api/mappers/PigeonCarttemMapper";
import { ProductTypes } from "@api/models/market/constants/ProductTypes";
import { ISale } from "@api/models/market/ISale";
import { ICartItem } from "@market/models/ICartItem";

export class CartItemMapper {
  public static Map(sale: ISale): ICartItem {
    if (!sale || !sale.id) throw new Error("Argument exception");

    if (sale.product === ProductTypes.Pigeon) {
      return PigeonCartItemMapper.Map(sale);
    } else if (sale.product === ProductTypes.Package) {
      return PackageCartItemMapper.Map(sale);
    } else {
      throw new Error("Unsupported product type mapping");
    }
  }
}
