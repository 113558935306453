import { Component, Prop, Vue } from "vue-property-decorator";

export enum SectionBarThemes {
  Light = "light",
  Gray = "gray",
  Dark = "dark",
  Black = "black",
  PrimaryInverted = "primary-inverted",
  SecondaryInverted = "secondary-inverted",
  Info = "info",
  Success = "success",
  Warning = "warning",
  Danger = "danger"
}

@Component
export default class BlocSectionBar extends Vue {
  @Prop({
    validator: (value: SectionBarThemes) => Object.values(SectionBarThemes).includes(value)
  })
  readonly theme?: SectionBarThemes;
}
