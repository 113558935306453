import { IStripeClientService } from "@api/contracts/external/IStripeClientService";
import { Stripe } from "@stripe/stripe-js";

// See https://msdn.microsoft.com/en-us/library/ff701715.aspx
export class StripeClientService implements IStripeClientService {
  stripe: Stripe;

  constructor() {
    const STRIPE_PUBISHABLE_KEY: string | undefined = process.env.VUE_APP_STRIPE_PUBISHABLE_KEY;

    if (STRIPE_PUBISHABLE_KEY) {
      // Warning stripe must be loaded globally (via tag script) and so is available in global window context
      // Note: To be PCI compliant, stripe must be loaded globally (and if stripe is loaded async then we are not PCI compliant anymore)
      this.stripe = (window as any).Stripe(STRIPE_PUBISHABLE_KEY); // See: StripeConstructor
    }
  }

  get StripeClient(): Stripe {
    return this.stripe;
  }
}
