import { IStripePayoutsService } from "@api/contracts/webapi/IStripePayoutsService";
import { IPayout } from "@api/models/market/IPayout";
import { BaseApiService } from "@api/services/webapi/base/BaseApiService";
import { authStore, userStore } from "@market/stores/App.store.modules";
import { AxiosPromise } from "axios";
import Stripe from "stripe";

export class StripePayoutsService extends BaseApiService implements IStripePayoutsService {
  public FetchAll(): AxiosPromise<IPayout[]> {
    if (!authStore.IsAdministrator) throw new Error("Unauthorized");

    return this.Client().get(`stripe-payouts/payouts`);
  }

  public FetchAllPayouts(from?: Date, to?: Date): AxiosPromise<IPayout[]> {
    if (!authStore.IsAdministrator) throw new Error("Unauthorized");

    let queryParam = "";
    if (from) queryParam += `/${from.toISOString()}`;
    if (to) queryParam += `/${to.toISOString()}`;

    return this.Client().get(`stripe-payouts/payouts${queryParam}`);
  }

  public FetchAllPayoutsByStripeVendor(
    stripeConnectedAccountId: string,
    from?: Date,
    to?: Date
  ): AxiosPromise<IPayout[]> {
    if (!authStore.IsVendor && !authStore.IsAdministrator) throw new Error("Unauthorized");
    if (stripeConnectedAccountId != userStore.user?.stripeConnectedAccountId && !authStore.IsAdministrator)
      throw new Error("Unauthorized");
    if (!stripeConnectedAccountId) return Promise.reject("Argument exception: stripeConnectedAccountId is undefined");

    let queryParam = "";
    if (from) queryParam += `/${from.toISOString()}`;
    if (to) queryParam += `/${to.toISOString()}`;

    return this.Client().get(`stripe-payouts/payouts/vendor/${stripeConnectedAccountId}${queryParam}`);
  }

  public FetchAllStripePayoutsByStripeVendor(
    stripeConnectedAccountId: string
  ): AxiosPromise<Stripe.ApiList<Stripe.Payout>> {
    if (!authStore.IsVendor && !authStore.IsAdministrator) throw new Error("Unauthorized");
    if (
      !stripeConnectedAccountId ||
      (stripeConnectedAccountId != userStore.user?.stripeConnectedAccountId && !authStore.IsAdministrator)
    )
      throw new Error("Unauthorized");

    return this.Client().get(`stripe-payouts/vendor/${stripeConnectedAccountId}`);
  }

  public FetchStripePayout(payoutKey: string): AxiosPromise<Stripe.Payout> {
    if (!authStore.IsAdministrator) throw new Error("Unauthorized");

    return this.Client().get(`stripe-payout/${payoutKey}`);
  }

  public FetchStripePayoutByStripeVendor(
    payoutKey: string,
    stripeConnectedAccountId: string
  ): AxiosPromise<Stripe.Payout> {
    if (!authStore.IsVendor && !authStore.IsAdministrator) throw new Error("Unauthorized");
    if (
      !stripeConnectedAccountId ||
      (stripeConnectedAccountId != userStore.user?.stripeConnectedAccountId && !authStore.IsAdministrator)
    )
      throw new Error("Unauthorized");

    return this.Client().get(`stripe-payout/${payoutKey}/vendor/${stripeConnectedAccountId}`);
  }
}
