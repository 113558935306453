import { ISaleNotificationsService } from "@api/contracts/odata/ISaleNotificationsService";
import { ISaleNotification } from "@api/models/market/ISaleNotification";
import { store } from "@market/stores";
import { authStore, jwtStore, userStore } from "@market/stores/App.store.modules";
import { INotificationStore } from "@market/stores/contracts/INotificationStore";
import { NotificationsFilterTypes } from "@pigeon/enumerations/NotificationsFilterTypes";
import { cid, container, Inject } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const STORE_TOKEN: "notification" = "notification";
const ONE_MONTH: number = 30;
@Module({ name: STORE_TOKEN, namespaced: true, dynamic: true, store: store })
export default class NotificationStore extends VuexModule implements INotificationStore {
  // WARNING: Don't use Inject decorator here because VuexModuleDecorator will transform it (into store state)
  // @Inject()
  // private saleNotificationsService: ISaleNotificationsService;

  lastAuthDate: Date = new Date();
  saleNotifications: ISaleNotification[] = [];
  saleNotificationsSeen: ISaleNotification[] = [];
  saleNotificationsCount: number = 0;

  get saleNotificationsUnread(): ISaleNotification[] {
    const unreadNotififs = this.saleNotifications.filter((sn) => sn.flagAsRead !== true);

    return unreadNotififs;
  }

  get UnreadPMarketNotificationsCount(): number {
    return this.saleNotificationsUnread?.length ?? 0;
  }

  @Mutation
  SET_SALE_NOTIFICATIONS(data: ISaleNotification[]): void {
    this.saleNotifications = data;
    this.saleNotificationsCount = data.length;
  }

  @Mutation
  UPDATE_SALE_NOTIFICATION(updatedSaleNotification: ISaleNotification): void {
    const notifToFlagIndex = this.saleNotifications.findIndex((sn) => sn.id === updatedSaleNotification.id);
    if (notifToFlagIndex >= 0) {
      this.saleNotifications[notifToFlagIndex] = { ...updatedSaleNotification };
    }
  }

  @Mutation
  ADD_SALE_NOTIFICATION_TO_SEEN_STACK(notification: ISaleNotification): void {
    this.saleNotificationsSeen.push(notification);
  }

  @Action
  public async Reset(): Promise<void> {
    this.SET_SALE_NOTIFICATIONS([]);
  }

  @Action
  public async FetchSalesNotifications(filters?: Map<NotificationsFilterTypes, string[]>): Promise<void> {
    const saleNotificationsService = container.get<ISaleNotificationsService>(cid.ISaleNotificationsService);

    const defaultFilters: Map<NotificationsFilterTypes, string[]> = new Map();
    if (jwtStore.lastAuthDate)
      defaultFilters.set(NotificationsFilterTypes.FromDate, [new Date(jwtStore.lastAuthDate)?.toISOString()]);
    else if (userStore.user && userStore.user.lastLoginAt)
      defaultFilters.set(NotificationsFilterTypes.FromDate, [new Date(userStore.user.lastLoginAt)?.toISOString()]);
    else defaultFilters.set(NotificationsFilterTypes.FromDaysAgo, [ONE_MONTH.toString()]);

    if(!filters) filters = defaultFilters;

    if (authStore.IsAdministrator) {
      const { data } = await saleNotificationsService.FetchAllWithSale(filters, 50);
      this.SET_SALE_NOTIFICATIONS(data.value);
    } else {
      const { data } = await saleNotificationsService.FetchAllWithSaleByUser(filters, 30);
      this.SET_SALE_NOTIFICATIONS(data.value);
    }
  }

  @Action
  public async FetchNotifications(filters?: Map<NotificationsFilterTypes, string[]>): Promise<void> {
    const defaultFilters: Map<NotificationsFilterTypes, string[]> = new Map();

    if (jwtStore.lastAuthDate)
      defaultFilters.set(NotificationsFilterTypes.FromDate, [new Date(jwtStore.lastAuthDate)?.toISOString()]);
    else if (userStore.user && userStore.user.lastLoginAt)
      defaultFilters.set(NotificationsFilterTypes.FromDate, [new Date(userStore.user.lastLoginAt)?.toISOString()]);
    else defaultFilters.set(NotificationsFilterTypes.FromDaysAgo, [ONE_MONTH.toString()]);

    this.FetchSalesNotifications(filters || defaultFilters);
  }

  @Action
  public FlagNotificationAsRead(notification: ISaleNotification): void {
    notification.flagAsRead = true;
    notification.flaggedDate = new Date().toISOString();

    this.UPDATE_SALE_NOTIFICATION(notification);
  }

  @Action
  public FlagNotificationAsSeen(notification: ISaleNotification): void {
    this.ADD_SALE_NOTIFICATION_TO_SEEN_STACK(notification);
  }
}
