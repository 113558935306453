import { IConnectedAccountsService } from "@api/contracts/webapi/IConnectedAccountsService";
import { Inject } from "inversify-props";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ModuleStripeConnect extends Vue {
  @Prop({ default: "btn-outline-primary" })
  readonly buttonType: string;

  @Inject()
  private connectedAccountsService: IConnectedAccountsService;

  connectState: string = "";
  dashboardLink: string = "";

  get StripeDashboardConnectUrl(): string {
    if (!this.$auth.IsVendor || !this.$auth.AccountVendorIsVerified) return "";

    return this.dashboardLink;
  }

  // See: https://stripe.com/docs/connect/oauth-reference#redirect-uri
  get StripeOAuthConnectUrl(): string {
    if (!this.$auth.IsVendor || !this.connectState) return "";

    const stripeClientId = process.env.VUE_APP_STRIPE_CONNECT_KEY;
    const stripeCapabilities = process.env.VUE_APP_STRIPE_CONNECT_CAPABILITIES;
    const appRedirectUri = process.env.VUE_APP_STRIPE_CONNECT_REDIRECT_URI;
    const appStateToken = this.connectState; // an unique CSRF token
    const vendorEmail = this.$auth.User?.email;
    const vendorPhoneNumber = this.$auth.User?.phoneNumber;
    const vendorBusinessName = this.$auth.User?.corporation?.name;
    const vendorFirstname = this.$auth.User?.firstname;
    const vendorLastname = this.$auth.User?.lastname;

    // See: https://stripe.com/docs/connect/oauth-reference#get-authorize-request
    let stripeConnectUrl = `https://connect.stripe.com/express/oauth/authorize?client_id=${stripeClientId}&state=${appStateToken}&suggested_capabilities[]=${stripeCapabilities}&redirect_uri=${appRedirectUri}`;
    if (vendorEmail) stripeConnectUrl += `&stripe_user[email]=${vendorEmail}`;
    if (vendorPhoneNumber) stripeConnectUrl += `&stripe_user[phone_number]=${vendorPhoneNumber}`;
    if (vendorBusinessName) stripeConnectUrl += `&stripe_user[business_name]=${vendorBusinessName}`;
    if (vendorFirstname) stripeConnectUrl += `&stripe_user[first_name]=${vendorFirstname}`;
    if (vendorLastname) stripeConnectUrl += `&stripe_user[last_name]=${vendorLastname}`;
    // if(vendorBirthDate){
    //   stripeConnectUrl += `stripe_user[dob_day]=${vendorBirthDate.getDate()}`;
    //   stripeConnectUrl += `stripe_user[dob_month]=${vendorBirthDate.getMonth() + 1}`;
    //   stripeConnectUrl += `stripe_user[dob_year]=${vendorBirthDate.getFullYear()}`;
    // }

    return stripeConnectUrl;
  }

  async created() {
    if (this.$auth.IsVendor) {
      const { data: stateData } = await this.connectedAccountsService.FetchState(); // an unique CSRF token
      this.connectState = stateData;

      if (this.$auth.AccountVendorIsVerified) {
        const { data: dashboardData } = await this.connectedAccountsService.FetchDashboardLink();
        this.dashboardLink = dashboardData;
      }
    }
  }
}
