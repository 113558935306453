import { AppThemes } from "@api/models/market/constants/AppThemes";
import { IPrize } from "@api/models/market/IPrize";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PigeonAssetPrizes extends Vue {
  @Prop({ default: () => [] })
  readonly prizes: IPrize[];
  @Prop({ default: "" })
  readonly tableHeader: string;
  @Prop({ default: true })
  readonly highlightBestPrize: boolean;
  @Prop({ validator: (value: AppThemes) => Object.values(AppThemes).includes(value) })
  readonly theme?: AppThemes;

  public GetRankLabel(rank: number): string {
    if (rank == 1) return this.$i18n.t("rank.first") as string;
    else if (rank == 2) return this.$i18n.t("rank.second") as string;
    else if (rank == 3) return this.$i18n.t("rank.third") as string;
    else if (rank == 0) return "";
    else return this.$i18n.t("rank.nth") as string;
  }
}
