import { PictureTypes } from "@api/models/market/constants/PictureTypes";
import { IPackage } from "@api/models/market/IPackage";
import { IPicture } from "@api/models/market/IPicture";
import { IPackageManager } from "@pigeon/services/contracts/IPackageManager";
import { Inject } from "inversify-props";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PackagePictureCollage extends Vue {
  @Prop()
  readonly package: IPackage;
  @Prop()
  readonly pictures?: IPicture[];
  @Prop({ default: false })
  readonly useRawPicture: boolean;

  @Inject()
  private packageManager: IPackageManager;

  forceFallbackPicture: boolean = false;

  get PackagePictures(): string[] {
    if (!this.package) return [];

    if (this.pictures && this.pictures.length) {
      return this.packageManager.GetPicturesThumbnail(this.pictures, this.useRawPicture);
    } else {
      return this.packageManager.GetPicturesUrl(this.package, this.useRawPicture || this.forceFallbackPicture, [
        PictureTypes.Pigeon,
        PictureTypes.PigeonAndEye
      ]);
    }
  }

  get PackagePictureStyle(): string {
    const packagePicturesLength = this.PackagePictures.length;

    if (packagePicturesLength <= 1) return "";
    else if (packagePicturesLength <= 4) return "max-height: 50%; max-width: 50%;";
    else if (packagePicturesLength <= 6) return "max-height: 50%; max-width: 33.33%;";
    else return "max-height: 33.33%; max-width: 33.33%;";
  }

  public OnPictureError(): void {
    this.forceFallbackPicture = true;
  }

  public GetPigeonIdFromUrl(imageUrl: string): string {
    const urlChunks = imageUrl.split("/");
    return urlChunks[urlChunks.length - 2];
  }
}
