import { authStore, jwtStore } from "@market/stores/App.store.modules";
import Axios, { AxiosError, AxiosInstance } from "axios";
import { config } from "vue/types/umd";

export class BaseODataService {
  private client: AxiosInstance;

  constructor() {
    this.client = Axios.create({
      baseURL: process.env.VUE_APP_ODATA_BASE_URL,
      timeout: Number(process.env.VUE_APP_ODATA_TIME_OUT)
    });

    this.client.interceptors.request.use(
      async (config) => {
        if (config.baseURL === process.env.VUE_APP_ODATA_BASE_URL && !config.headers?.Authorization) {
          if (!config.headers) config.headers = {};

          if (authStore.IsAuthenticated) {
            // Refresh token if it is expired
            if (jwtStore.HasTokenExpired()) {
              await jwtStore.RefreshTokens();
            }

            config.headers.Authorization = `Bearer ${jwtStore.accessToken}`;
            config.withCredentials = true;
          }

          if (process.env.NODE_ENV === "production") {
            // Don t emit metadata info for performance and security reason
            config.headers.Accept = "application/json;odata.metadata=none";
          }

          if (process.env.VUE_APP_ODATA_DEBUG === "true") {
            console.warn("The odata metadata is setted to full. Use this option only for DEBUG purpose.");
            config.headers.Accept = "application/json;odata.metadata=full";
          }
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    this.client.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<any>) => {
        if (!error.response) return Promise.reject(error);

        const { status, headers } = error.response;
        if (status === 401 && headers && "token-expired" in headers) {
          // Refresh token if it is expired
          await jwtStore.RefreshTokens();

          if (authStore.IsAuthenticated) {
            if (!error?.config) error.config = {};
            if (!error?.config?.headers) error.config.headers = {};

            error.config.headers.Authorization = `Bearer ${jwtStore.accessToken}`;
            return Axios.request(error.config);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  public Client(): AxiosInstance {
    return this.client;
  }
}
