export enum TransferMethodTypes {
  StripeTransfer = "StrpTf",
  BankTransfer = "BnkTf",
  Cash = "Cash"
}

export enum StripeTransferMethodTypes {
  Card = "card",
  Fpx = "fpx",
  BankAccount = "bank_account"
}

export type ExtendedTransferMethodTypes = TransferMethodTypes | StripeTransferMethodTypes;
