import { IPigeon } from "@api/models/market/IPigeon";
import { PedigreeDiagramOrientations } from "@pigeon/components/pigeon/PigeonPedigreeDiagram";
import PigeonPedigreeDiagram from "@pigeon/components/pigeon/PigeonPedigreeDiagram.vue";
import PigeonPedigreeSheet from "@pigeon/components/pigeon/PigeonPedigreeSheet.vue";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component({
  components: {
    PigeonPedigreeSheet,
    PigeonPedigreeDiagram
  }
})
export default class PigeonPedigree extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly pedigree: IPigeon[];
  @Prop({ default: 2 })
  readonly generation: number;
  @Prop({ default: false })
  readonly parentsLinked: boolean;
  @Prop({
    default: PedigreeDiagramOrientations.Horizontal,
    validator: (value: PedigreeDiagramOrientations) => Object.values(PedigreeDiagramOrientations).includes(value)
  })
  readonly orientation: PedigreeDiagramOrientations;

  @Ref()
  refPedigree: HTMLElement;

  selectedPigeon: IPigeon | null = null;
  isAsideOpened: boolean = false;
  documentIsInFullScreen: boolean = false;

  mounted(): void {
    this.ListenFullScreenChange();
  }

  private ListenFullScreenChange(): void {
    document.addEventListener("fullscreenchange", () => {
      this.documentIsInFullScreen = document.fullscreen || !!document.fullscreenElement;
    });
    document.addEventListener("mozfullscreenchange", () => {
      this.documentIsInFullScreen = document.fullscreen || !!(document as any).mozFullScreenElement;
    });
    document.addEventListener("webkitfullscreenchange", () => {
      this.documentIsInFullScreen = document.fullscreen || !!(document as any).webkitFullscreenElement;
    });
    document.addEventListener("msfullscreenchange", () => {
      this.documentIsInFullScreen = document.fullscreen || !!(document as any).msFullscreenElement;
    });
  }

  public ToggleAsideWindow(): void {
    this.isAsideOpened = !this.isAsideOpened;
  }

  public OpenAside(): void {
    this.isAsideOpened = true;
  }

  public SwitchPedigreeToFullScreen(): void {
    if (!this.refPedigree) return;
    const element: any = this.refPedigree;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else {
      console.warn("Fullscreen API is not supported.");
    }
  }
}
