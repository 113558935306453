import { ProductTypes } from "@api/models/market/constants/ProductTypes";
import { SexTypes } from "@api/models/market/constants/SexTypes";
import { ICartItem } from "@market/models/ICartItem";
import { IPackageCartItem } from "@market/models/IPackageCartItem";
import { IPigeonCartItem } from "@market/models/IPigeonCartItem";
import { AppRoutes } from "@market/routers/App.routes";
import { cartStore } from "@market/stores/App.store.modules";
import PackagePictureCollage from "@pigeon/components/package/PackagePictureCollage.vue";
import PigeonPicture from "@pigeon/components/pigeon/PigeonPicture.vue";
import { IPigeonManager } from "@pigeon/services/contracts/IPigeonManager";
import { IProductManager } from "@pigeon/services/contracts/IProductManager";
import { Inject } from "inversify-props";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    PigeonPicture,
    PackagePicture: PackagePictureCollage
  }
})
export default class CartTable extends Vue {
  @Inject()
  private pigeonManager: IPigeonManager;
  @Inject()
  private productManager: IProductManager;

  AppRoutes = AppRoutes;
  SexTypes = SexTypes;
  ProductTypes = ProductTypes;
  forceFallbackPicture: boolean = false;

  get CartItems(): ICartItem[] | IPigeonCartItem[] | IPackageCartItem[] {
    return cartStore.items;
  }

  public RemoveCartItem(itemId: number): void {
    cartStore.RemoveItem(itemId);
  }

  public PigeonSexIcon(cartItem: ICartItem): string {
    if (cartItem.product !== ProductTypes.Pigeon) return "";

    const pigeon: IPigeonCartItem = cartItem as IPigeonCartItem;
    return this.pigeonManager.GetSexIcon(pigeon);
  }

  public GetProductReferenceSlug(cartItem: ICartItem): string | undefined {
    return this.productManager.GetProductReferenceSlug(cartItem);
  }

  public GetProductNameSlug(cartItem: ICartItem): string | undefined {
    return this.productManager.GetProductNameSlug(cartItem);
  }
}
