import { ProductFactory } from "@api/factories/productFactory";
import { RelationshipTypes } from "@api/models/market/constants/RelationshipTypes";
import { SexTypes } from "@api/models/market/constants/SexTypes";
import { IPigeon } from "@api/models/market/IPigeon";
import PigeonAssetParent from "@pigeon/components/pigeon/PigeonAssetParent.vue";
import { nameof } from "@pigeon/extensions/nameof";
import { Component, Emit, Model, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { AlertTypes } from "../app/AppAlert";

export enum PedigreeDiagramOrientations {
  Horizontal = "horizontal",
  Vertical = "vertical"
}

@Component({
  components: {
    AssetParent: PigeonAssetParent
  }
})
export default class PigeonPedigreeDiagram extends Vue {
  @Model("select")
  readonly selected: IPigeon | null;

  @Prop({ type: Array, default: () => [] })
  readonly pedigree: IPigeon[];
  @Prop({ default: 2 })
  readonly generation!: number;
  @Prop({ default: true })
  readonly hasGenerationSelect: boolean;
  @Prop({ default: false })
  readonly parentsLinked: boolean;
  @Prop({
    default: PedigreeDiagramOrientations.Horizontal,
    validator: (value: PedigreeDiagramOrientations) => Object.values(PedigreeDiagramOrientations).includes(value)
  })
  readonly orientation: PedigreeDiagramOrientations;

  @Ref()
  readonly refDiagramTree: HTMLElement;

  AlertTypes = AlertTypes;
  documentIsInFullScreen: boolean = false;
  selectedGeneration: number = this.generation;
  generationOptions: any = [
    { label: "1 generation [parents]", value: 1 },
    { label: "2 generations [grand parents]", value: 2 },
    { label: "3 generations [great grand parents]", value: 3 }
  ];

  private grab: boolean = false;
  private startX: number = 0;
  private scrollLeft: number = 0;

  mounted(): void {
    this.ListenFullScreenChange();
  }

  private ListenFullScreenChange(): void {
    document.addEventListener("fullscreenchange", () => {
      this.documentIsInFullScreen = document.fullscreen || !!document.fullscreenElement;
    });
    document.addEventListener("mozfullscreenchange", () => {
      this.documentIsInFullScreen = document.fullscreen || !!(document as any).mozFullScreenElement;
    });
    document.addEventListener("webkitfullscreenchange", () => {
      this.documentIsInFullScreen = document.fullscreen || !!(document as any).webkitFullscreenElement;
    });
    document.addEventListener("msfullscreenchange", () => {
      this.documentIsInFullScreen = document.fullscreen || !!(document as any).msFullscreenElement;
    });
  }

  @Watch(nameof<PigeonPedigreeDiagram>("generation"), { immediate: true })
  ObserveGeneration() {
    this.selectedGeneration = this.generation;
  }

  public GetRelationship(pigeon: IPigeon | undefined): string {
    if (!pigeon || !pigeon.relationship) return "";

    return pigeon.relationship;
  }

  public GrabStart(event: MouseEvent): void {
    this.grab = true;

    const slider: HTMLElement = this.refDiagramTree;
    if (!slider) return;
    slider.classList.add("is-grabbed");
    this.startX = event.pageX - slider.offsetLeft;
    this.scrollLeft = slider.scrollLeft;
  }

  public GrabStop(event: Event): void {
    this.grab = false;

    if (!this.refDiagramTree) return;
    this.refDiagramTree.classList.remove("is-grabbed");
  }

  public GrabMove(event: MouseEvent): void {
    if (!this.grab) return;

    event.preventDefault();

    const slider: HTMLElement = this.refDiagramTree;
    if (!slider) return;

    const x = event.pageX - slider.offsetLeft;
    const walk = (x - this.startX) * 1;
    slider.scrollLeft = this.scrollLeft - walk;
  }

  @Emit("fullscreen")
  public SwitchPedigreeToFullScreen(): void {}

  public SwitchDiagramToFullScreen(): void {
    if (!this.refDiagramTree) return;
    const element: any = this.refDiagramTree;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else {
      console.warn("Fullscreen API is not supported.");
    }
  }

  public ExitFullScreen(): void {
    const browserDocument: any = document as any;

    if (browserDocument.exitFullscreen) {
      browserDocument.exitFullscreen();
    } else if (browserDocument.webkitExitFullscreen) {
      /* Safari */
      browserDocument.webkitExitFullscreen();
    } else if (browserDocument.msExitFullscreen) {
      /* IE11 */
      browserDocument.msExitFullscreen();
    }
  }

  @Emit("select")
  public OnSelect(pigeon: IPigeon) {
    if (pigeon === this.selected) return;

    return pigeon;
  }

  @Emit("select-generation")
  public OnSelectGeneration(selectedGeneration: string) {
    if (!selectedGeneration) return;

    this.selectedGeneration = parseInt(selectedGeneration);
    return this.selectedGeneration;
  }

  public GetUnknowPigeon(relationship = "Parent"): IPigeon {
    return ProductFactory.GetUnknownPigeon(relationship) as IPigeon;
  }

  // Getter Child
  // Warning: First pigeon of pedigree must be the child
  get Child(): IPigeon | undefined {
    const child: IPigeon = this.pedigree[0];

    if (!child || !child.id) {
      return child;
    }

    return this.GetParentPigeon("Pigeon", child.id);
  }

  // Getters Parents
  get Father(): IPigeon | undefined {
    if (!this.Child || !this.Child.fatherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Male,
        relationship: RelationshipTypes.Father
      };
    }

    return this.GetParentPigeon("Father", this.Child.fatherId);
  }

  get Mother(): IPigeon | undefined {
    if (!this.Child || !this.Child.motherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Female,
        relationship: RelationshipTypes.Mother
      };
    }

    return this.GetParentPigeon("Mother", this.Child.motherId);
  }

  // Getters GrandParents
  get PaternalGrandFather(): IPigeon | undefined {
    if (!this.Father || !this.Father.fatherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Male,
        relationship: RelationshipTypes.GrandFather
      };
    }

    return this.GetParentPigeon("G.Father", this.Father.fatherId);
  }

  get PaternalGrandMother(): IPigeon | undefined {
    if (!this.Father || !this.Father.motherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Female,
        relationship: RelationshipTypes.GrandMother
      };
    }

    return this.GetParentPigeon("G.Mother", this.Father.motherId);
  }

  get MaternalGrandFather(): IPigeon | undefined {
    if (!this.Mother || !this.Mother.fatherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Male,
        relationship: RelationshipTypes.GrandFather
      };
    }

    return this.GetParentPigeon("G.Father", this.Mother.fatherId);
  }

  get MaternalGrandMother(): IPigeon | undefined {
    if (!this.Mother || !this.Mother.motherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Female,
        relationship: RelationshipTypes.GrandMother
      };
    }

    return this.GetParentPigeon("G.Mother", this.Mother.motherId);
  }

  // Getters Great GrandParents
  get PaternalGreatGrandFatherFromGFather(): IPigeon | undefined {
    if (!this.PaternalGrandFather || !this.PaternalGrandFather.fatherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Male,
        relationship: RelationshipTypes.GreatGrandFather
      };
    }

    return this.GetParentPigeon("G. G.Father", this.PaternalGrandFather.fatherId);
  }

  get PaternalGreatGrandMotherFromGFather(): IPigeon | undefined {
    if (!this.PaternalGrandFather || !this.PaternalGrandFather.motherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Female,
        relationship: RelationshipTypes.GreatGrandMother
      };
    }

    return this.GetParentPigeon("G. G.Mother", this.PaternalGrandFather.motherId);
  }

  get PaternalGreatGrandFatherFromGMother(): IPigeon | undefined {
    if (!this.PaternalGrandMother || !this.PaternalGrandMother.fatherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Male,
        relationship: RelationshipTypes.GreatGrandFather
      };
    }

    return this.GetParentPigeon("G. G.Father", this.PaternalGrandMother.fatherId);
  }

  get PaternalGreatGrandMotherFromGMother(): IPigeon | undefined {
    if (!this.PaternalGrandMother || !this.PaternalGrandMother.motherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Female,
        relationship: RelationshipTypes.GreatGrandMother
      };
    }

    return this.GetParentPigeon("G. G.Mother", this.PaternalGrandMother.motherId);
  }

  get MaternalGreatGrandFatherFromGFather(): IPigeon | undefined {
    if (!this.MaternalGrandFather || !this.MaternalGrandFather.fatherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Male,
        relationship: RelationshipTypes.GreatGrandFather
      };
    }

    return this.GetParentPigeon("G. G.Father", this.MaternalGrandFather.fatherId);
  }

  get MaternalGreatGrandMotherFromGFather(): IPigeon | undefined {
    if (!this.MaternalGrandFather || !this.MaternalGrandFather.motherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Female,
        relationship: RelationshipTypes.GreatGrandMother
      };
    }

    return this.GetParentPigeon("G. G.Mother", this.MaternalGrandFather.motherId);
  }

  get MaternalGreatGrandFatherFromGMother(): IPigeon | undefined {
    if (!this.MaternalGrandMother || !this.MaternalGrandMother.fatherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Male,
        relationship: RelationshipTypes.GreatGrandFather
      };
    }

    return this.GetParentPigeon("G. G.Father", this.MaternalGrandMother.fatherId);
  }

  get MaternalGreatGrandMotherFromGMother(): IPigeon | undefined {
    if (!this.MaternalGrandMother || !this.MaternalGrandMother.motherId) {
      return {
        ...(ProductFactory.GetDefaultParentIdentity() as IPigeon),
        sex: SexTypes.Female,
        relationship: RelationshipTypes.GreatGrandMother
      };
    }

    return this.GetParentPigeon("G. G.Mother", this.MaternalGrandMother.motherId);
  }

  // Generic getter parent
  private GetParentPigeon(relationship: string, parentId: number): IPigeon | undefined {
    const parent = this.pedigree.find((pigeon) => {
      return pigeon.id === parentId;
    });

    if (parent === null || typeof parent === "undefined") {
      return this.GetUnknowPigeon(relationship);
    }

    return parent;
  }
}
