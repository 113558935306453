import { IBingMapsWebControlService } from "@api/contracts/external/IBingMapsWebControlService";
import i18n from "@market/i18n";
import { uiBus } from "@pigeon/Ui.bus";

// See https://www.bing.com/api/maps/sdkrelease/mapcontrol/isdk
// <script src="https://www.bing.com/api/maps/mapcontrol?key=${apiKey}" async defer></script>

export class BingMapsWebControlService implements IBingMapsWebControlService {
  public apiIsInitialized: boolean;
  public apiIsLoaded: boolean;

  get IsInitialized(): boolean {
    return this.apiIsInitialized;
  }

  get IsLoaded(): boolean {
    return this.apiIsLoaded;
  }

  constructor() {
    this.apiIsInitialized = false;
    this.apiIsLoaded = false;
    this.LoadAPI();
  }

  private LoadAPI(): void {
    if (!process.env.VUE_APP_BING_MAPS_API_KEY) return;

    // Load the api only once
    if (this.apiIsInitialized || this.apiIsLoaded) return;

    // Create the script element
    const apiKey: string = process.env.VUE_APP_BING_MAPS_API_KEY as string;
    const scriptTag = document.createElement("script");
    scriptTag.src = `https://www.bing.com/api/maps/mapcontrol?key=${apiKey}&callback=OnLoadBingMaps&setLang=${i18n.locale}`;
    scriptTag.defer = true;
    scriptTag.async = true;
    // Inject the script element in the DOM
    document.body.appendChild(scriptTag);

    // Set the init state
    this.apiIsInitialized = true;
    // Add a global function for the callback from Bing Maps api
    (window as any).OnLoadBingMaps = () => {
      this.apiIsLoaded = true;
      uiBus.emit("bingmaps-loaded");
    };
  }
}
