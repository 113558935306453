import { SaleTypes } from "@api/models/market/constants/SaleTypes";
import { ISale } from "@api/models/market/ISale";
import i18n from "@market/i18n";
import { IPigeonCartItem } from "@market/models/IPigeonCartItem";
import { ISaleManager } from "@pigeon/services/contracts/ISaleManager";
import { cid, container } from "inversify-props";

export class PigeonCartItemMapper {
  // Warning: Cannot use DI with static with property injection
  // @Inject()
  // private static saleManager: ISaleManager;

  public static Map(sale: ISale): IPigeonCartItem {
    if (!sale || !sale.id || !sale.pigeon || !sale.pigeonId || (sale.type === SaleTypes.Bid && !sale.bids?.length))
      throw new Error("Argument exception");

    const saleManager = container.get<ISaleManager>(cid.SaleManager);
    const salePigeonItem: IPigeonCartItem = {
      id: sale.pigeonId,
      product: sale.product,
      saleId: sale.id,
      sale: sale,
      reference: sale.pigeon.ring ?? (i18n.t("common.undefined") as string),
      name: sale.pigeon.name,
      pictures: sale.pigeon.pictures,
      taxRate: saleManager.CalculateVatRate(sale),
      taxAmount: saleManager.CalculateVatAmount(sale),
      taxInclusive: sale.vatInclusive,
      price: saleManager.CalculateSaleAmountExcludingVat(sale),
      total: saleManager.CalculateSaleAmountIncludingVat(sale),
      currency: sale.currency,
      endDate: sale.endDate ? new Date(sale.endDate) : undefined,
      pickUpAddress: sale.pickUpAddress,
      sex: sale.pigeon.sex,
      birthYear: sale.pigeon.birthYear,
      fancierName: sale.pigeon.fancier ? sale.pigeon.fancier.name : ""
    };

    if (sale.type === SaleTypes.Bid) {
      const bidNumber: number = sale.bids ? sale.bids.length + 1 : 1;
      salePigeonItem.bidNumber = bidNumber;
    }

    return salePigeonItem;
  }
}
