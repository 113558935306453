import { IFanciersService } from "@api/contracts/odata/IFanciersService";
import { IFancier } from "@api/models/market/IFancier";
import { IODataCollectionResponse } from "@api/models/shared/IODataCollectionResponse";
import { BaseODataService } from "@api/services/odata/base/BaseODataService";
import { AxiosPromise } from "axios";
import { odataQuery } from "odata-fluent-query";
import { memoizeAsync } from "utils-decorators";

export class FanciersService extends BaseODataService implements IFanciersService {
  constructor() {
    super();
  }

  public Insert(fancier: IFancier): AxiosPromise<IFancier> {
    return this.Client().post("Fanciers", fancier);
  }

  public Fetch(fancierKey: number): AxiosPromise<IFancier> {
    return this.Client().get(`Fanciers(${fancierKey})`);
  }

  @memoizeAsync(10 * 60 * 1000)
  public FetchAll(): AxiosPromise<IODataCollectionResponse<IFancier>> {
    const query = odataQuery<IFancier>().orderBy("name").count();

    return this.Client().get(`Fanciers?${query.toString()}`);
  }

  public FetchAllByUser(userKey: string | undefined): AxiosPromise<IODataCollectionResponse<IFancier>> {
    if (!userKey) return Promise.reject("Argument exception: userKey is undefined");

    const query = odataQuery<IFancier>()
      .orderBy("name")
      .filter((f) => f.createdBy.equals(userKey, { ignoreGuid: true }))
      .count();

    return this.Client().get(`Fanciers?${query.toString()}`);
  }

  public FetchByValue(firstname: string, lastname: string): AxiosPromise<IODataCollectionResponse<IFancier>> {
    const query = odataQuery<IFancier>()
      .filter((f) => f.name.contains(lastname) && f.name.contains(firstname))
      .paginate(1, 0);

    return this.Client().get<IODataCollectionResponse<IFancier>>(`Fanciers?${query.toString()}`);
  }
}
