import { render, staticRenderFns } from "./PigeonPedigreeDiagram.vue?vue&type=template&id=04577389&scoped=true&"
import script from "./PigeonPedigreeDiagram.ts?vue&type=script&lang=ts&"
export * from "./PigeonPedigreeDiagram.ts?vue&type=script&lang=ts&"
import style0 from "./PigeonPedigreeDiagram.vue?vue&type=style&index=0&id=04577389&prod&lang=scss&scoped=true&"
import style1 from "@/Pigeon/styles/components/zoomable.scss?vue&type=style&index=1&id=04577389&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04577389",
  null
  
)

/* custom blocks */
import block0 from "./PigeonPedigreeDiagram.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports