import { Component, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";

@Component
export default class NavTabBloc extends Vue {
  @Prop()
  readonly text: string;
  @Prop()
  readonly textSecondary?: string;
  @Prop()
  readonly to: Location | string;
  @Prop()
  readonly icon?: string | string[] | null;
  @Prop()
  readonly isDisabled?: boolean;
}
