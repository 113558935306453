import { default as Step } from "@pigeon/components/nav/NavStepperVerticalStep";
import { nameof } from "@pigeon/extensions/nameof";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

export enum StepperDirections {
  Vertical = "vertical"
}

@Component
export default class NavStepperVertical extends Vue {
  @Prop({ default: 0 })
  readonly activeStep: number;
  @Prop({
    default: StepperDirections.Vertical,
    validator: (value: StepperDirections) => Object.values(StepperDirections).includes(value)
  })
  readonly direction: StepperDirections | string;

  steps: Step[] = [];

  @Watch(nameof<NavStepperVertical>("steps"), { immediate: true })
  ObserveIndexStep() {
    this.steps.forEach((child, index) => {
      child.index = index;
    });
  }

  @Emit("change")
  SetActiveStep(stepIndex: number) {
    return stepIndex;
  }
}
