import { IRing } from "@api/models/market/IRing";
import { AppRoutes } from "@market/routers/App.routes";
import { appDataStore, authStore, searchStore } from "@market/stores/App.store.modules";
import { IListOption } from "@pigeon/models/IListOption";
import { IRingManager } from "@pigeon/services/contracts/IRingManager";
import { Inject } from "inversify-props";
import { Component, Vue, Watch } from "vue-property-decorator";
import { SearchTypes } from "../../../Api/models/market/constants/SearchTypes";
import { nameof } from "@pigeon/extensions/nameof";
import { BreakpointService } from "@pigeon/plugins/breakpoint/BreakpointService";
@Component
export default class TheSearchBar extends Vue {
  @Inject()
  private ringManager: IRingManager;

  SearchTypes = SearchTypes;

  get SearchQueryPlaceholderKey(): string {
    switch (this.searchType) {
      case SearchTypes.SearchByRing:
        return "placeholder_ring";

      case SearchTypes.SearchByName:
        return "placeholder_name";

      case SearchTypes.SearchByRingOrName:
      default:
        return "placeholder";
    }
  }

  get ringAreas(): IRing[] {
    return appDataStore.RingAreas;
  }

  get ringAreasGroupOptions(): { [key: string]: IListOption[] } {
    return appDataStore.RingAreasGroupOptions;
  }

  get HasRingClub(): boolean {
    if (!this.searchQueryRingArea) return false;

    return this.ringManager.IsRingAreaHasRingClub(this.searchQueryRingArea);
  }

  get MaxBirthYear(): number {
    return this.ringManager.GetMaxBirthYear();
  }

  get SearchTypeOptions(): IListOption[] {
    // prettier-ignore
    return authStore.IsAdministrator
      ? Object.values(SearchTypes)
          .map((type) => ({ value: type, label: this.$i18n.t(`enum.search_type.${type}`) } as IListOption))
      : Object.values(SearchTypes)
          .filter((st) => st !== SearchTypes.SearchByRingOrName)
          .map((type) => ({ value: type, label: this.$i18n.t(`enum.search_type.${type}`) } as IListOption))
      ;
  }

  get SearchBarIsOpen(): boolean {
    return searchStore.searchBarIsOpen;
  }

  get searchType(): SearchTypes {
    return searchStore.searchType;
  }

  set searchType(value: SearchTypes) {
    searchStore.UpdateSearchType(value);
  }

  //#region searchQuery
  get searchQuery(): string | null {
    return searchStore.searchQueryInput;
  }

  set searchQuery(value: string | null) {
    searchStore.UpdateSearchQuery(value);
  }

  get searchQueryRingArea(): string | null {
    return searchStore.searchQueryRingArea;
  }

  set searchQueryRingArea(value: string | null) {
    searchStore.UpdateSearchQueryRingArea(value);
  }

  get searchQueryRingClub(): string | null {
    return searchStore.searchQueryRingClub;
  }

  set searchQueryRingClub(value: string | null) {
    searchStore.UpdateSearchQueryRingClub(value);
  }

  get searchQueryRingBirthyear(): number | null {
    return searchStore.searchQueryRingBirthyear;
  }

  set searchQueryRingBirthyear(value: number | null) {
    searchStore.UpdateSearchQueryRingBirthyear(value);
  }
  //#endregion

  @Watch(`${nameof<TheSearchBar>("$breakpoint")}.${nameof<BreakpointService>("name")}`)
  public ObserveBreakpoint(): void {
    if (this.$breakpoint.smAndDown) {
      if (
        this.searchType == SearchTypes.SearchByRing &&
        this.searchQueryRingArea &&
        !this.searchQuery?.startsWith(this.searchQueryRingArea)
      ) {
        this.searchQuery = this.ringManager.RawFormatRing(
          this.searchQueryRingArea,
          this.searchQueryRingClub,
          this.searchQueryRingBirthyear,
          this.searchQuery
        );
      }
    } else {
      if (this.searchQuery && this.searchQueryRingArea && this.searchQuery.startsWith(this.searchQueryRingArea)) {
        if (!this.searchQuery) return;

        let temp = this.searchQuery;
        if (this.searchQueryRingArea) temp = temp.replace(this.searchQueryRingArea, "").trim() + " ";
        if (this.searchQueryRingClub) temp = temp.replace(this.searchQueryRingClub, "").trim();
        if (this.searchQueryRingBirthyear)
          temp = temp.replace(this.searchQueryRingBirthyear?.toString()?.substring(2), "").trim();
        if (temp[0] === "-") temp = temp.substring(1).trim();

        this.searchQuery = temp;
      }
    }
  }

  public ClearSearch(): void {
    searchStore.Reset();
  }

  public Search(): void {
    searchStore.UpdateSearch();

    // Navigate to catalog
    if (this.$route?.name != AppRoutes.Buy) {
      this.$router.push({ name: AppRoutes.Buy });
    }
  }
}
