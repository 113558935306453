import { AssetTypes } from "@api/models/market/constants/AssetTypes";
import { DisciplineTypes } from "@api/models/market/constants/DisciplineTypes";
import { RelationshipTypes } from "@api/models/market/constants/RelationshipTypes";
import { SexTypes } from "@api/models/market/constants/SexTypes";
import { IFancier } from "@api/models/market/IFancier";
import { IPigeon } from "@api/models/market/IPigeon";
import { IStrain } from "@api/models/market/IStrain";
import i18n from "@market/i18n";
import { userStore } from "@market/stores/App.store.modules";

export class ProductFactory {
  public static GetDefaultPigeon(): IPigeon {
    return {
      name: "",
      ring: "",
      ringArea: "BE",
      birthYear: new Date().getFullYear(),
      ringNumber: "",
      discipline: undefined,
      sex: undefined,
      asset: AssetTypes.Text,
      assetText: "",
      characteristics: [],
      pictures: [],
      prizes: [],
      publicData: true,
      flagBreeding: false,
      flagApproved: null,
      createdAt: new Date(),
      userId: userStore.user && userStore.user.id ? userStore.user.id : undefined
    };
  }

  public static GetDefaultFancier(): IFancier {
    return { name: "", profiles: [] };
  }

  public static GetDefaultStrain(): IStrain {
    return { name: "" };
  }

  public static GetUnknownPigeon(relationship: string): Partial<IPigeon> {
    return { name: "Unknown", ring: "-", birthYear: 1900, sex: SexTypes.Male, relationship: relationship };
  }

  public static GetDefaultParent(parentRelationship: RelationshipTypes | string, parentPublicData: boolean): IPigeon {
    let defaultSex: SexTypes = SexTypes.Unknown;

    switch (parentRelationship) {
      case RelationshipTypes.Father:
      case RelationshipTypes.GrandFather:
      case RelationshipTypes.PaternalGrandFather:
      case RelationshipTypes.MaternalGrandFather:
      case RelationshipTypes.GreatGrandFather:
        defaultSex = SexTypes.Male;
        break;

      case RelationshipTypes.Mother:
      case RelationshipTypes.GrandMother:
      case RelationshipTypes.PaternalGrandMother:
      case RelationshipTypes.MaternalGrandMother:
      case RelationshipTypes.GreatGrandMother:
        defaultSex = SexTypes.Female;
        break;

      case RelationshipTypes.Unknown:
      default:
        defaultSex = SexTypes.Unknown;
        break;
    }

    return {
      name: "",
      ring: "",
      ringNumber: "",
      ringArea: "BE",
      discipline: DisciplineTypes.LongDistance,
      birthYear: new Date().getFullYear(),
      sex: defaultSex,
      asset: AssetTypes.Prize,
      assetText: undefined,
      pictures: [],
      prizes: [],
      publicData: parentPublicData,
      flagBreeding: false,
      flagApproved: null,
      createdAt: new Date(),
      fancier: this.GetDefaultFancier(),
      strain: this.GetDefaultStrain(),
      userId: userStore.user && userStore.user.id ? userStore.user.id : undefined
    };
  }

  public static GetDefaultParentIdentity(): Partial<IPigeon> {
    return {
      relationship: "",
      name: i18n.t("relationship.unknown") as string,
      ring: "XX 00-000000",
      sex: SexTypes.Unknown
    };
  }
}
