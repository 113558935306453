import "./__global";

// Code adapted from https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Date/toISOString#Proth%C3%A8se_d'%C3%A9mulation_(polyfill)
if (!Date.prototype.toInputDateString) {
  (function () {
    function pad(number: number) {
      if (number < 10) {
        return "0" + number;
      }

      return number;
    }

    // Format returned yyyy-MM-dd
    Date.prototype.toInputDateString = function () {
      return `${this.getFullYear()}-${pad(this.getMonth() + 1)}-${pad(this.getDate())}`;
    };
  })();
}

if (!Date.prototype.toInputTimeString) {
  (function () {
    function pad(number: number) {
      if (number < 10) {
        return "0" + number;
      }

      return number;
    }

    // Format returned hh:mm
    Date.prototype.toInputTimeString = function () {
      return `${pad(this.getHours())}:${pad(this.getMinutes())}`;
    };
  })();

  if (!Date.prototype.toInputDateTimeString) {
    (function () {
      // Format returned hh:mm
      Date.prototype.toInputDateTimeString = function () {
        return `${this.toInputDateString()}T${this.toInputTimeString()}`;
      };
    })();
  }
}
